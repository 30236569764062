import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { BrowserHelper } from '../utils/utils';
import { Session } from '../entity/entities';

@Pipe({ name: 'dateString' })
export class DateStringPipe implements PipeTransform {
  transform(value): string {
    if (!value)
      return '';
    let date = new Date(value);
    return date.toDateString() + ' ' + date.toLocaleTimeString();
  }
}
