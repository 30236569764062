import { DOCUMENT } from '@angular/common';
import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewContainerRef,
  Injector,
  NgModuleRef,
  ComponentRef,
} from '@angular/core';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { SecurityClient } from 'src/app/client/security.client';
import { Session } from '../entity/entities';

@Directive({
  selector: '[user-manual]',
})
export class UserManualDirective implements OnInit, OnDestroy {
  @Input('code') code: string;

  isInsideClick = false;
  dialog;
  contPdf;
  userManual;

  constructor(
    private securityClient: SecurityClient,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private element: ElementRef,
    private resolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit() {
    this.checkIsVisible();
    if (this.userManual) {
      let container = document.createElement('span');
      this.renderer.setStyle(container, 'position', 'absolute');
      this.renderer.setStyle(container, 'right', '5px');
      this.renderer.setStyle(container, 'top', '1px');
      this.renderer.setStyle(container, 'padding', '5px');
      this.renderer.setStyle(container, 'font-size', '15px');

      let image = document.createElement('i');
      this.renderer.addClass(image, 'fas');
      this.renderer.addClass(image, 'fa-question-circle');

      this.renderer.appendChild(container, image);
      this.renderer.appendChild(this.element.nativeElement, container);
    }
  }

  documentClick = () => {
    if (!this.isInsideClick) {
      this.renderer.removeChild(this.document.body, this.dialog);
      this.document.body.removeEventListener('click', this.documentClick);
    }
    this.isInsideClick = false;
  };

  ngOnDestroy() {
    if (this.dialog) this.renderer.removeChild(this.document.body, this.dialog);
  }

  setPdf() {
    this.dialog = document.createElement('p-overlayPanel');

    let dialogCont = document.createElement('div');

    dialogCont.addEventListener('click', (e) => {
      console.log('inside');
      this.isInsideClick = true;
    });

    this.renderer.setStyle(dialogCont, 'position', 'absolute');
    this.renderer.setStyle(dialogCont, 'width', '80%');
    this.renderer.setStyle(dialogCont, 'height', '90%');
    this.renderer.setStyle(dialogCont, 'z-index', '2000');
    this.renderer.setStyle(dialogCont, 'top', '40px');
    this.renderer.setStyle(dialogCont, 'right', '10%');

    this.renderer.addClass(dialogCont, 'ui-overlaypanel');
    this.renderer.addClass(dialogCont, 'ui-widget');
    this.renderer.addClass(dialogCont, 'ui-widget-content');
    this.renderer.addClass(dialogCont, 'ui-corner-all');
    this.renderer.addClass(dialogCont, 'ui-shadow');
    this.renderer.addClass(dialogCont, 'ui-overlaypanel-shifted');

    let cont = document.createElement('div');
    this.renderer.addClass(cont, 'ui-overlaypanel-content');

    this.contPdf = document.createElement('div');

    this.renderer.appendChild(cont, this.contPdf);
    this.renderer.appendChild(dialogCont, cont);
    this.renderer.appendChild(this.dialog, dialogCont);
    this.renderer.appendChild(this.document.body, this.dialog);
    this.document.body.addEventListener('click', this.documentClick);
  }

  @HostListener('click', ['$event'])
  show(e?) {
    if(this.userManual){
      console.log(`User manual: ${this.code}`);
      this.setPdf();
      e?.stopPropagation();
      this.securityClient
        .showHelp(this.userManual.Key, null)
        .then((response) => {
          let helpSrc = `data:application/pdf;base64,${response?.result}`;

          const factory = this.resolver.resolveComponentFactory(
            PdfViewerComponent
          );
          const ref: ComponentRef<PdfViewerComponent> = this.viewContainerRef.createComponent(
            factory
          );
          ref.instance.src = helpSrc;
          ref.instance.originalSize = true;
          ref.instance.fitToPage = true;
          ref.instance.renderText = false;
          ref.instance.pageRendered.subscribe((e) => {
            this.renderer.setStyle(this.contPdf, 'height', '95%');
            this.renderer.setStyle(this.contPdf, 'position', 'absolute');
          });
          this.renderer.appendChild(this.contPdf, ref.location.nativeElement);
        });
    }
  }

  private checkIsVisible() {
    Session.menu.menuItems.values.forEach((menu) => {
      if (
        !this.userManual &&
        menu.data.UserManuals?.some((x) => x.PageObjectCode == this.code)
      )
        this.userManual = menu.data.UserManuals?.find(
          (x) => x.PageObjectCode == this.code
        );
    });
  }
}
