import { Pipe, PipeTransform } from '@angular/core';
import { MenuModel } from '../entity/enum/menuModel';
import { WizardSteps } from '../entity/enum/wizardSteps';
import { IconGropus } from '../entity/enum/iconGroups';

@Pipe({
    name: 'icons'
})
export class IconsPipe implements PipeTransform {
    transform(value: any, ...args: any[]) {
        let icon = ''
        let section = args[0];
        switch (section) {
            case IconGropus.Wizard:
                switch (value) {
                    case WizardSteps.CompanyInfo: icon = 'fas fa-info'; break;
                    case WizardSteps.BusinessLocalization: icon = 'fas fa-briefcase'; break;
                    case WizardSteps.Datasource: icon = 'fas fa-database'; break;
                    case WizardSteps.InstalationPackage: icon = 'fas fa-cube'; break;
                }
                break;            
            default:
                switch (value) {
                    case MenuModel.Home: icon = ''; break;
                    case MenuModel.Setup: icon = 'fas fa-cogs'; break;
                    case MenuModel.Setup_Customers: icon = ''; break;
                    case MenuModel.Setup_Wizard: icon = 'fas fa-magic'; break;
                  case MenuModel.Security: icon = 'fas fa-shield-alt'; break;

                  case MenuModel.PDP_Dashboard: icon = 'fas fa-columns'; break;
                  case MenuModel.PDP_Mydata: icon = 'fas fa-database'; break;
                  case MenuModel.PDP_Profile: icon = 'fas fa-user-cog'; break;
                  case MenuModel.PDP_Reports: icon = 'fas fa-file'; break;
                  case MenuModel.PDP_Controlpanel: icon = 'fas fa-cogs'; break;
                  case MenuModel.PDP_Help: icon = 'fas fa-h-square'; break;
                  case MenuModel.PDP_Inbox: icon = 'fas fa-file-import'; break;
                  case MenuModel.PDP_Setup: icon = 'fas fa-sliders-h'; break;
                  case MenuModel.PDP_Timeattend: icon = 'fas fa-clock'; break;
                  
                }
                break;
        }

        
        return icon;
    }

}
