<div class="row form-property">
  <div
    class="col-lg-{{tooltip?11:12}}"
    [ngClass]="{'no-padding-right':tooltip}"
    style="display: flex"
  >
    <ng-container *ngIf="!leftLabel && !rightLabel">
      <span class="p-float-label">
        <p-inputSwitch
          *ngIf="!property.rowspan"
          id="{{name}}"
          class="ui-inputwrapper-focus ui-inputwrapper-focus"
          [ngClass]="{'pointer-events-none':readonly}"
          [ngStyle]="{'opacity':readonly?'0.5':'1'}"
          (ngModelChange)="change($event)"
          [(ngModel)]="value"
        ></p-inputSwitch>
        <label
          class="gs-float-label inputswitch-label"
          [ngClass]="{'error-color':notValid}"
          >{{label}}</label
        >
        <!--<span *ngIf="notValid" class="form-error" pTooltip="{{property.validationMessage}}">
        <i class="fas fa-info-circle fa-2x form-valid-icon error-color"></i>
      </span>-->
      </span>
    </ng-container>
    <ng-container *ngIf="!leftLabel && rightLabel">
      <span class="float-left">
        <p-inputSwitch
          *ngIf="!property.rowspan"
          id="{{name}}"
          class="ui-inputwrapper-focus ui-inputwrapper-focus"
          [ngClass]="{'pointer-events-none':readonly}"
          (ngModelChange)="change($event)"
          [(ngModel)]="value"
        ></p-inputSwitch>
      </span>
      <label
        style="margin-left: 15px; margin-bottom: 0px; margin-top: 5px"
        class="master-font inputswitch-label"
        [ngClass]="{'error-color':notValid}"
        >{{label}}</label
      >
    </ng-container>
    <ng-container *ngIf="leftLabel && !rightLabel">
      <label
        class="master-font inputswitch-label"
        [ngClass]="{'error-color':notValid}"
        >{{label}}</label
      >
      <span class="float-right inputswitch-right">
        <p-inputSwitch
          *ngIf="!property.rowspan"
          id="{{name}}"
          class="ui-inputwrapper-focus ui-inputwrapper-focus"
          [ngClass]="{'pointer-events-none':readonly}"
          (ngModelChange)="change($event)"
          [(ngModel)]="value"
        ></p-inputSwitch>
      </span>
    </ng-container>
    <div *ngIf="tooltip" class="col-lg-1 property-tooltip">
      <span pTooltip="{{property.tooltip}}">
        <i class="fas fa-info-circle master-color"></i>
      </span>
    </div>
  </div>
</div>
