import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  EventEmitter,
  Output,
  AfterViewInit,
  ViewChild,
  TemplateRef,
  QueryList,
  ContentChildren,
  ChangeDetectorRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Item } from '../entity/entities';
import { DatePipe } from '@angular/common';
import { PrimeTemplate } from 'primeng/api';

@Component({
  selector: 'gs-month-calendar',
  templateUrl: './monthCalendar.component.html',
})
export class GSMotnhCalendarControl
  implements OnInit, OnDestroy, AfterViewInit
{
  _events;
  @Input() params;
  @Input() set events(value) {
    this._events = value;
  }
  @Input() wide = true; //defines will table be wide as parent element
  @Output() dayEventClicked = new EventEmitter<any>();

  @ContentChildren(PrimeTemplate) templates: QueryList<PrimeTemplate>;
  gsEventContent: TemplateRef<any>;

  item: Item;
  lastDate: Date;
  _monthString;
  _yearString: string;

  get monthString() {
    if (
      !this.lastDate ||
      new Date(this.params.dateInMonth).getMonth() !=
        this.lastDate.getMonth() ||
      new Date(this.params.dateInMonth).getFullYear() !=
        this.lastDate.getFullYear()
    ) {
      this.makeDays();
    }
    return this._monthString + ' ' + this._yearString;
  }

  columns = [
    { description: 'general.days.sunday', dayIndex: 0, key: 'dayOfWeek' },
    { description: 'general.days.monday', dayIndex: 1, key: 'dayOfWeek' },
    { description: 'general.days.tuesday', dayIndex: 2, key: 'dayOfWeek' },
    { description: 'general.days.wednesday', dayIndex: 3, key: 'dayOfWeek' },
    { description: 'general.days.thirsday', dayIndex: 4, key: 'dayOfWeek' },
    { description: 'general.days.friday', dayIndex: 5, key: 'dayOfWeek' },
    { description: 'general.days.saturday', dayIndex: 6, key: 'dayOfWeek' },
  ];

  get weeks() {
    return this.item.items.values;
  }

  constructor(
    private translate: TranslateService,
    private datepipe: DatePipe,
    private cdr: ChangeDetectorRef
  ) {
    this.item = new Item(null, null);
  }

  ngOnDestroy(): void {}

  ngOnInit() {
    this.makeDays();
  }

  ngAfterViewInit() {
    this.templates.forEach((t) => {
      switch (t.getType()) {
        case 'gsEventContent':
          this.gsEventContent = t.template;
          break;
      }
    });
    this.cdr.detectChanges();
  }

  onSelectRow(data) {}

  eventClick($event) {}

  eventsForDay(day) {
    return this._events.filter((ev) => ev.start == day);
  }

  getMonthString(month) {
    return this.translate.instant('general.months.' + month.toString());
  }

  makeDays() {
    this.lastDate = new Date(this.params.dateInMonth);
    let month = this.lastDate.getMonth();
    let year = this.lastDate.getFullYear();
    this._monthString = this.getMonthString(month);
    this._yearString = year.toString();
    this.item.items.clear();

    let firstDateInMonth = new Date(year, month, 1);
    let dayOffsetBackwards = -firstDateInMonth.getDay();
    let weekIndex = 0;
    let currentWeekItem;
    if (dayOffsetBackwards) {
      currentWeekItem = new Item(null, { weekIndex: weekIndex });
      this.item.items.add(weekIndex.toString(), currentWeekItem);
      weekIndex++;
    }
    let dayTotalIndex = 0;

    for (let i = dayOffsetBackwards; i++; i <= 0) {
      let date = new Date(year, month, 1);
      date.setDate(firstDateInMonth.getDate() + i - 1);
      currentWeekItem.setItem(
        {
          index: dayTotalIndex,
          date: date,
          datePiped: this.datepipe.transform(date, 'yyyy-MM-dd'),
          dayOfMonth: date.getDate(),
          dayOfWeek: date.getDay(),
          otherMonth: date.getMonth() != month,
        },
        date.getDay().toString()
      );
      dayTotalIndex++;
    }

    let i = 0;

    while (true) {
      let date = new Date(year, month, 1);
      date.setDate(firstDateInMonth.getDate() + i++);

      if (date.getDay() == 0) {
        if (date.getMonth() > month || date.getFullYear() > year) break;
        currentWeekItem = new Item(null, { weekIndex: weekIndex });
        this.item.items.add(weekIndex.toString(), currentWeekItem);
        weekIndex++;
      }

      currentWeekItem.setItem(
        {
          index: dayTotalIndex,
          date: date,
          datePiped: this.datepipe.transform(date, 'yyyy-MM-dd'),
          dayOfMonth: date.getDate(),
          dayOfWeek: date.getDay(),
          otherMonth: date.getMonth() != month,
        },
        date.getDay().toString()
      );

      if (
        (date.getMonth() > month || date.getFullYear() > year) &&
        date.getDay() == 6
      )
        break;

      dayTotalIndex++;
    }
  }
}
