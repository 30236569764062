import {
  ClientTypes,
  WorkflowExecutionTypes,
} from 'src/app/common/entity/enum/workflowExecutionStatuses';
import { Item } from '../../../../common/entity/entities';
import { WorkflowClientExecutionTypes } from './workflow-definitions/workflow-definitions.component';
import { take } from 'rxjs';

export function getWorkflowDefinitions(
  customerCode,
  workflowService,
  customerStore,
  wizardItem
) {
  workflowService.getWorkflowDefinitions(customerCode).subscribe((data) => {
    customerStore.setCustomerWorkflowDefinitions(data.result, +customerCode);
    wizardItem.onRenderFinish$.pipe(take(2)).subscribe(() => {
      wizardItem.items.get('wfDefinitions')?.items.values.forEach((i) => {
        if (!i.parameters.contains('column')) i.parameters['column'] = {};
        if (!i.parameters.column['Delete']) i.parameters.column['Delete'] = {};

        i.parameters.column['Delete'].class = 'background-color-erorr';

        if (
          i.items
            .get('Configurations')
            ?.items.values.find(
              (x) =>
                x.data.FK_WorkflowClientExecutionType ==
                WorkflowClientExecutionTypes.MANUALGENERIC
            ) != undefined
        )
          i.parameters.column['Delete'].visible = false;
        else i.parameters.column['Delete'].visible = true;

        const confItem = i?.items.get('Configurations');
        if (
          i.data?.Configurations?.some(
            (conf) =>
              conf.FK_WorkflowClientExecutionType ==
              WorkflowExecutionTypes.ManualGeneric
          )
        ) {
          i.parameters.column['Code'] = { hide: true };
          i.parameters['Active_readOnly'] = true;
        }
        confItem?.items?.values.forEach((exeItm) => {
          exeItm.parameters['FK_WorkflowClientExecutionType_readOnly'] =
            exeItm.data.PK_ID > 0;
          handleExecutionConfigColumns(
            exeItm,
            exeItm.data.FK_WorkflowClientExecutionType,
            i
          );
          if (!exeItm.parameters.contains('column'))
            exeItm.parameters['column'] = {};
          if (!exeItm.parameters.column['Delete'])
            exeItm.parameters.column['Delete'] = {};

          exeItm.parameters.column['Delete'].class = 'background-color-erorr';

          if (
            exeItm.data.FK_WorkflowClientExecutionType ==
            WorkflowExecutionTypes.ManualGeneric
          )
            exeItm.parameters.column['Delete'].visible = false;
          else exeItm.parameters.column['Delete'].visible = true;
        });
      });
    });
  });
}

export function handleExecutionConfigColumns(item: Item, efExecType, parent) {
  const isReadOnly = efExecType != WorkflowExecutionTypes.Scheduled;
  item.parameters['TimeWithSpinnersReadOnly'] = isReadOnly;
  if (parent?.data.ClientType == ClientTypes.Location)
    item.parameters['FK_TimeZone_readOnly'] = isReadOnly;
  if (
    parent?.data.hasAdmin == true &&
    item.original['FK_WorkflowClientExecutionType'] ==
      WorkflowExecutionTypes.Admin
  )
    item.parameters['FK_WorkflowClientExecutionType_readOnly'] = true;
  item.parameters['FK_ScheduleFrequency_readOnly'] = isReadOnly;
  item.parameters['ScheduleFrequencyValue_readOnly'] =
    item.parameters['ScheduleFrequencyValue_readOnly'] == true || isReadOnly;
  item.parameters['WaitBetweenAttempts_readOnly'] =
    item.parameters['WaitBetweenAttempts_readOnly'] == true || isReadOnly;
  item.parameters['MaxAttempts_readOnly'] = isReadOnly;
  item.parameters['FunctionType_readOnly'] =
    efExecType != WorkflowExecutionTypes.Manual;
  item.parameters['Active_readOnly'] =
    efExecType == WorkflowExecutionTypes.ManualGeneric;
  if (item.data.PK_ID < 0)
    item.data.Active =
      item.data.FK_WorkflowClientExecutionType === WorkflowExecutionTypes.Admin;

  if (
    item.data.FK_WorkflowClientExecutionType ===
    WorkflowExecutionTypes.ManualGeneric
  ) {
    item.acceptChanges();
    item.readonly = true;
  }

  item.reValidate();

  if (item.data.Deleted == true || parent.data.Deleted == true) {
    item.parameters['FK_WorkflowClientExecutionType_readOnly'] = true;
    item.parameters['FunctionType_readOnly'] = true;
    item.parameters['FK_TimeZone_readOnly'] = true;
    item.parameters['TimeWithSpinnersReadOnly'] = true;
    item.parameters['MaxAttempts_readOnly'] = true;
    item.parameters['WaitBetweenAttempts_readOnly'] = true;
    item.parameters['FK_ScheduleFrequency_readOnly'] = true;
    item.parameters['ScheduleFrequencyValue_readOnly'] = true;
    item.parameters['Active_readOnly'] = true;
  }
}
