import { PropertyTypes } from '../enum/propertyTypes';
import { Injectable } from '@angular/core';
import { GSSubject } from '../entities';
import { ValidationTypes } from '../enum/validationTypes';

export class FormProperty {
  label?: string;
  name?: string;
  type?: number;
  column?: number = 0;
  _validate?: boolean = false;
  row?: number = 0;
  rowspan?: number = 0;
  columnspan?: number = 1;
  properties?: FormProperty[] = [];
  validationMessage?: string;
  visible?: boolean = true;
  readonly?: boolean = false;
  tooltip?: string;
  leftLabel?: boolean = false;
  validationRegEx?: RegExp;
  validationType?: ValidationTypes;
  align?;
  component?: any;
  rightLabel?: boolean = false;
  styleClass?: string;

  set visibleAndValidate(value) {
    this.visible = value;
    this.validate = value;
  }

  public onDoValidate = new GSSubject();
  get validate() {
    return this._validate;
  }

  set validate(value) {
    this._validate = value;
    if (value) this.onDoValidate.next(this);
  }

  constructor(
    name: string,
    label: string,
    row?: number,
    column?: number,
    rowspan?: number,
    columnspan?: number,
    validate?: boolean,
    validationRegEx?: RegExp,
    align?,
    public autocompleteOff?: boolean,
    public validateAllowNull?: boolean,
    styleClass?: string
  ) {
    this.name = name;
    this.label = label;
    this.row = row ? row : this.row;
    this.rowspan = rowspan ? rowspan : this.rowspan;
    this.column = column ? column : this.column;
    this.columnspan = columnspan ? columnspan : this.columnspan;
    this.validate = validate ? validate : this.validate;
    this.validationRegEx = validationRegEx;
    this.align = align;
    this.styleClass = styleClass;
  }

  public reposition() {}
}
