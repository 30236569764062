import { Component, Input, OnInit, Optional } from '@angular/core';
import { Item } from '../../entity/entities';
import { FormTextboxProperty } from '../../entity/form/formTextbox.property';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

@Component({
  selector: 'app-gs.email.control',
  templateUrl: './gs.email.control.component.html',
  styleUrls: ['./gs.email.control.component.css'],
})
export class GsEmailControlComponent implements OnInit {
  @Input() item;
  @Input() property;

  emailItem: Item;

  showEmails = false;
  form: FormGroup<{ emails }>;

  addText = 'Click to add';

  get itemValue() {
    return this.item.data[this.property.name];
  }

  get emails() {
    return this.form.get('emails') as FormArray;
  }

  constructor(@Optional() item: Item, private fb: FormBuilder) {
    this.item = item;
  }

  get notValid() {
    if (this.item && this.property) {
      let validationInfo = this.item.validationInfo.find(
        (vi) => vi.fieldName == this.property.name
      );
      if (validationInfo) return !validationInfo.valid;
    }
    return false;
  }

  ngOnInit(): void {
    const emails = this.itemValue?.split(';') || [];
    this.form = this.fb.group({
      emails: this.fb.array(
        emails.map((email) =>
          this.fb.control(email, this.customEmailValidator())
        )
      ),
    });
  }

  addEmail() {
    this.emails.push(this.fb.control('', this.customEmailValidator()));
  }
  removeEmail(index) {
    this.emails.removeAt(index);
  }

  hide() {
    if (this.form.valid) {
      const emails = this.emails.value.filter((x) => x).join(';');
      this.item.data[this.property.name] = emails;
      this.property.changed.emit({
        propertyName: this.property.name,
        newValue: emails,
        item: this.item,
        property: this.property,
      });
    }
  }

  customEmailValidator = (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | [] => {
      const EMAIL = /[\w-\.]+@([\w-]+\.)+[\w-]{2,4}/;
      const value = control.value;
      let errors: any = {};
      if (!value) {
        return;
      }
      if (!EMAIL.test(value)) {
        errors = {
          email: true,
        };
      } else if (errors?.email) {
        delete errors.email;
      }
      return errors;
    };
  };
}
