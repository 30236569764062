<div class="control-title">
  <!--DO NOT REMOVE THIS!!!! THIS IS USED TO QUERY OLD VS NEW MONTH AND TRIGGER RELOAD-->
  {{monthString}}
</div>
<p-table class="gs-table-month-calendar" [value]="weeks" >
  <ng-template pTemplate="header">
    <tr> 
      <th class="table-header-text"
          pSortableColumn="{{col.sort ? col.name : undefined}}"
          style="text-align: center; text-transform: uppercase"
          *ngFor="let col of columns">
        <span *ngIf="col.description">
          {{col.description | gstranslate}}         
        </span>
      </th>
      <th *ngIf="!wide">
        <div style="width: auto"></div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-week let-rowIndex="rowIndex" let-expanded="expanded">
    <tr class="table-row-text" 
        style="height:36px"      
        (click)="onSelectRow(item.data)">
      <td *ngFor="let col of columns"
          class="{{col.class}}"
          [ngStyle]="{'text-align': col.align, 'max-width': col.minWidthPX ?  (col.minWidthPX+5) +'px' : 'auto'}"
           [ngClass]="{'month-calendar-day-disabled' : week.items.get(col.dayIndex.toString()).data.otherMonth}">
        <div style="min-height: 80px; max-height:250px; overflow-y:auto">
          <div style="text-align:right">
            {{week.items.get(col.dayIndex.toString()).data.dayOfMonth}}
          </div>        
          <ng-container *ngFor="let event of eventsForDay(week.items.get(col.dayIndex.toString()).data.datePiped)">
            <ng-container *ngIf="gsEventContent">
              <ng-container *ngTemplateOutlet="gsEventContent; context {$implicit: event}"></ng-container>
            </ng-container>
          </ng-container>
        </div>        
      </td>
      <td *ngIf="!wide">
        <div style="width: auto"></div>
      </td>
    </tr>
  </ng-template>
</p-table>

