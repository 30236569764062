<div
  class="row form-property"
  style="white-space: nowrap"
  [ngStyle]="{'padding': inTable ? ' 0px':''}"
  [ngClass]="{' table-control-inherit': !inTable}"
>
  <ng-container
    *ngIf="!minutesFormat && (!property.type  || property.type && property.type!=PropertyTypes.TimeSpan && property.type!=PropertyTypes.TimeUSFormat) && column?.hoursFormat!=12"
  >
    <div
      class="col-lg-{{tooltip?11:12}}"
      pTooltip="{{notValid?property.validationMessage:null}}"
      [ngClass]="{'no-padding-right':tooltip}"
    >
      <span class="p-float-label">
        <span>
          <p-inputSwitch
            *ngIf="property.withEnableSwitch"
            id="{{name}}"
            class="ui-inputwrapper-focus ui-inputwrapper-focus"
            style="vertical-align: -webkit-baseline-middle; margin-right: 10px"
            [ngClass]="{'pointer-events-none':readonly}"
            (ngModelChange)="switchEnabledChange($event)"
            [(ngModel)]="switchEnabled"
          ></p-inputSwitch>
        </span>
        <p-inputNumber
          *ngIf="!column?.readonly"
          class="ui-inputwrapper-filled"
          [ngClass]="{'disabled':readOnly || readonly || (property.withEnableSwitch && !switchEnabled),'error-spinner':notValid}"
          #hoursSpinner
          [ngModel]="hours"
          mode="decimal"
          [showButtons]="true"
          inputId="minmax-buttons"
          [min]="0"
          [max]="23"
          (onBlur)="formatHours($event, hours)"
          [readonly]="readOnly || readonly || (property.withEnableSwitch && !switchEnabled)"
          [prefix]="hours&& hours.length<2?'0':null"
          (onInput)="changeHours($event)"
        ></p-inputNumber>
        <ng-container *ngIf="column?.readonly"
          >{{hours| number:'2.0'}}</ng-container
        >
        :
        <p-inputNumber
          *ngIf="!column?.readonly"
          #minutesSpinner
          [ngClass]="{'disabled':readOnly || readonly || (property.withEnableSwitch && !switchEnabled),'error-spinner':notValid}"
          [ngModel]="minutes"
          mode="decimal"
          [showButtons]="true"
          inputId="minutes"
          [min]="0"
          [max]="59"
          [prefix]="minutes&& minutes.length<2?'0':null"
          (onBlur)="formatMinutes($event, minutes)"
          [readonly]="readOnly || readonly || (property.withEnableSwitch && !switchEnabled)"
          (onInput)="changeMinutes($event)"
        ></p-inputNumber>
        <ng-container *ngIf="column?.readonly"
          >{{minutes| number:'2.0'}}</ng-container
        >
        <label class="gs-float-label" [ngClass]="{'error-color':notValid}"
          >{{label}}</label
        >
        <!-- <span *ngIf="notValid" class="form-error" pTooltip="{{property.validationMessage}}">
          <i class="fas fa-info-circle fa-2x form-valid-icon error-color"></i>
        </span> -->
      </span>
    </div>
  </ng-container>
  <ng-container
    *ngIf="minutesFormat  || (property && property.type==PropertyTypes.TimeSpan)"
  >
    <div
      class="col-lg-{{tooltip?11:12}}"
      pTooltip="{{notValid?property.validationMessage:null}}"
      [ngClass]="{'no-padding-right':tooltip}"
    >
      <span
        class="ui-float-label"
        [ngClass]="{'central-text':column?.readonly}"
      >
        <p-inputSwitch
          *ngIf="property.withEnableSwitch"
          id="{{name}}"
          class="ui-inputwrapper-focus ui-inputwrapper-focus"
          [ngClass]="{'pointer-events-none':readonly}"
          style="vertical-align: -webkit-baseline-middle; margin-right: 10px"
          (ngModelChange)="switchEnabledChange($event)"
          [(ngModel)]="switchEnabled"
        ></p-inputSwitch>
        <p-inputNumber
          *ngIf="!column?.readonly"
          class="ui-inputwrapper-filled"
          [ngClass]="{'disabled':readOnly || readonly || (property.withEnableSwitch && !switchEnabled),'error-spinner':notValid}"
          [(ngModel)]="minutesFormatHours"
          mode="decimal"
          [showButtons]="true"
          inputId="minmax-buttons"
          [min]="0"
          [max]="23"
          (onBlur)="formatHours($event, hours)"
          [readonly]="readOnly || readonly || (property.withEnableSwitch && !switchEnabled)"
          (ngModelChange)="minutesFormatHoursChanged($event)"
        ></p-inputNumber>
        <ng-container *ngIf="column?.readonly"
          >{{minutesFormatHours | number:'2.0'}}</ng-container
        >
        :
        <!-- <p-spinner
          *ngIf="!column?.readonly"
          [ngClass]="{'disabled':readonly,'error-spinner':notValid}"
          [(ngModel)]="minutesFormatMinutes"
          [min]="0"
          [max]="59"
          maxlength="2"
          [readonly]="readOnly || readonly"
          (ngModelChange)="minutesFormatMinutesChanged($event)"
        >
        </p-spinner> -->
        <p-inputNumber
          *ngIf="!column?.readonly"
          [ngClass]="{'disabled':readOnly || readonly || (property.withEnableSwitch && !switchEnabled),'error-spinner':notValid}"
          [(ngModel)]="minutesFormatMinutes"
          mode="decimal"
          [showButtons]="true"
          inputId="minutes"
          [min]="0"
          [max]="59"
          [readonly]="readOnly || readonly || (property.withEnableSwitch && !switchEnabled)"
          (ngModelChange)="minutesFormatMinutesChanged($event)"
        ></p-inputNumber>
        <ng-container *ngIf="column?.readonly"
          >{{minutesFormatMinutes | number:'2.0'}}</ng-container
        >
        <label class="gs-float-label" [ngClass]="{'error-color':notValid}"
          >{{label}}</label
        >
        <!-- <span *ngIf="notValid" class="form-error" pTooltip="{{property.validationMessage}}">
          <i class="fas fa-info-circle fa-2x form-valid-icon error-color"></i>
        </span> -->
        <span
          *ngIf="item.parameters[property.name+'Indicator']"
          class="form-error"
          style="position: absolute; margin-left: 40px"
          pTooltip="{{item.parameters[property.name+'Info']}}"
        >
          <i
            class="{{item.parameters[property.name+'Indicator']}} fa-2x form-valid-icon error-color"
          ></i>
        </span>
      </span>
    </div>
  </ng-container>
  <ng-container
    *ngIf="(property && property.type==PropertyTypes.TimeUSFormat) || column?.hoursFormat==12"
  >
    <div
      class="col-lg-{{tooltip?11:12}}"
      pTooltip="{{notValid?property.validationMessage:null}}"
      [ngClass]="{'no-padding-right':tooltip}"
    >
      <span class="p-float-label">
        <p-inputSwitch
          *ngIf="property.withEnableSwitch"
          id="{{name}}"
          class="ui-inputwrapper-focus ui-inputwrapper-focus"
          [ngClass]="{'pointer-events-none':readonly}"
          style="vertical-align: -webkit-baseline-middle; margin-right: 10px"
          (ngModelChange)="switchEnabledChange($event)"
          [(ngModel)]="switchEnabled"
        ></p-inputSwitch>
        <ng-container *ngIf="!column?.showAsString else showAsString">
          <p-inputNumber
            class="ui-inputwrapper-filled"
            [ngClass]="{'disabled':readOnly || readonly || (property.withEnableSwitch && !switchEnabled),'error-spinner':notValid}"
            #hoursSpinner
            [ngModel]="hoursUS"
            mode="decimal"
            [showButtons]="true"
            inputId="minmax-buttons"
            [min]="1"
            [max]="12"
            (onBlur)="formatHours($event, hours)"
            [readonly]="readOnly || readonly || (property.withEnableSwitch && !switchEnabled)"
            [prefix]="null"
            (onInput)="changeHoursUS($event)"
          ></p-inputNumber>
          <!--<ng-container *ngIf="column?.readonly">{{hours| number:'2.0'}}</ng-container>-->
          :
          <p-inputNumber
            *ngIf="!column?.readonly"
            #minutesSpinner
            [ngClass]="{'disabled':readOnly || readonly || (property.withEnableSwitch && !switchEnabled),'error-spinner':notValid}"
            [ngModel]="minutes"
            mode="decimal"
            [showButtons]="true"
            inputId="minutes"
            [min]="0"
            [max]="59"
            [prefix]="minutes&& minutes.length<2?'0':null"
            (onBlur)="formatMinutes($event, minutes)"
            [readonly]="readOnly || readonly || (property.withEnableSwitch && !switchEnabled)"
            (onInput)="changeMinutes($event)"
          ></p-inputNumber>
          <p-dropdown
            [appendTo]="'body'"
            [options]="optionsAmPm"
            [ngClass]="{'disabled':readOnly || readonly || (property.withEnableSwitch && !switchEnabled),'error-spinner':notValid}"
            (onChange)="changeAmPm($event)"
            [ngModel]="AmPmvalue"
            [readonly]="readOnly || readonly || (property.withEnableSwitch && !switchEnabled)"
          ></p-dropdown>
          <!--<ng-container *ngIf="column?.readonly">{{minutes| number:'2.0'}}</ng-container>-->
          <label class="gs-float-label" [ngClass]="{'error-color':notValid}"
            >{{label}}</label
          >

          <!-- <span *ngIf="notValid" class="form-error" pTooltip="{{property.validationMessage}}">
            <i class="fas fa-info-circle fa-2x form-valid-icon error-color"></i>
          </span> -->
        </ng-container>
        <ng-template #showAsString>
          g-
          <span style="padding-left: 10px">
            {{hours| number:'2.0'|amPmTime}} {{value? ':':''}}{{ minutes|
            number:'2.0' }} {{value? AmPmvalue:''}}
          </span>
        </ng-template>
      </span>
    </div>
  </ng-container>
  <div *ngIf="tooltip" class="col-lg-1 property-tooltip">
    <span pTooltip="{{property.tooltip}}">
      <i class="fas fa-info-circle master-color"></i>
    </span>
  </div>
</div>
