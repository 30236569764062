import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
  Input,
  Inject,
  Optional,
  Injectable,
  QueryList,
  ElementRef,
  ViewChildren,
  ChangeDetectorRef,
} from '@angular/core';
import { GSBaseControl } from './gs.base.control';
import { FormDropdownProperty } from '../entity/form/formDropdown.property';
import { Item } from '../entity/entities';
import { FormLocationDropdownProperty } from '../entity/form/formLocationDropdown.property';
import { FormDepartmentDropdownProperty } from '../entity/form/formDepartmentDropdown.property';
import { TableColumn } from '../entity/table/tableColumn';

@Component({
  selector: 'gs-department-dropdown',
  templateUrl: './gs.departmentDropdown.control.html',
})
@Injectable()
export class GSDepartmentDropdownControl
  extends GSBaseControl
  implements OnInit, OnDestroy
{
  //#region -------Properties

  @Input() item: any;
  @Input() column: TableColumn;

  get value() {
    if (this.options) {
      if (this.item.data.DepartmentLocation)
        return `${this.item.data[this.name]}_${
          this.item.data.DepartmentLocation
        }`;
      else {
        const optValue = this.options.find(
          (o) => o.data.Code == this.item.data[this.name]
        );
        if (optValue?.data)
          return `${this.item.data[this.name]}_${optValue.data?.FKey}`;
      }
    }
  }

  set value(value) {}

  get options() {
    if (this.property) return this.property.options;
    else if (this.column) return this.column.options;
  }

  get name() {
    if (this.property) return this.property.name;
    else if (this.column) return this.column.name;
  }
  @ViewChildren('selectOption') selectOption: QueryList<ElementRef>;
  optionHandled = false;
  //#endregion

  //#region -------Constructor
  constructor(
    @Optional() prop: FormDepartmentDropdownProperty,
    @Optional() item: Item,
    private changeDr: ChangeDetectorRef
  ) {
    super(changeDr);
    this.property = prop;
    this.item = item;
  }
  //#endregion

  //#region -------NG Eents
  ngOnInit() {
    //if (this.property) {
    //  (this.property as FormDepartmentDropdownProperty).optionsSet.subscribe(this.preselect);
    //  if ((this.property as FormDepartmentDropdownProperty).options)
    //    this.preselect();
    //}
  }

  ngOnDestroy() {}

  ngAfterContentChecked() {
    if (this.selectOption && this.selectOption.toArray().length) {
      if (!this.optionHandled) {
        this.selectOption
          .toArray()
          .filter((e) =>
            e.nativeElement.children[0].classList.contains(
              'disabled_opacity-no-color'
            )
          )
          .forEach((e) => {
            e.nativeElement.parentElement.classList.add('no-event');
          });
        this.optionHandled = true;
      }
    } else this.optionHandled = false;
  }
  //#endregion

  //#region -------UI Events
  // preselect = () => {
  //   if (this.property.options && this.property.options.length == 1) {
  //     this.value = this.property.options[0].value;
  //     (this.item as Item).reValidate();
  //     (this.item as Item).reCheckDirty();

  //     this.onChange.emit({ propertyName: this.name, previousValue: null, newValue: this.value, event: {}, item: this.item, property: this.property });
  //     this.property.changed.emit({ propertyName: this.name, previousValue: null, newValue: this.value, event: {}, item: this.item, property: this.property });
  //   }
  // }

  change(e) {
    if (e) {
      let optionData = this.options.find((o) => o.value == e.value).data;
      if (optionData.Disabled) return;
      let previousValue = this.data[this.name];
      //this.value = e.value;
      this.data[this.name] = optionData.Code;
      this.data.DepartmentLocation = optionData.FKey;
      this.item.reCheckDirty();
      if (this.property) {
        this.onChange.emit({
          propertyName: this.name,
          previousValue: previousValue,
          newValue: this.value,
          event: e,
          item: this.item,
          property: this.property,
        });
        this.property.changed.emit({
          propertyName: this.name,
          previousValue: previousValue,
          newValue: this.value,
          event: e,
          item: this.item,
          property: this.property,
        });
      } else if (this.column) {
        this.onChange.emit({
          propertyName: this.column.name,
          previousValue: previousValue,
          newValue: this.value,
          event: e,
          item: this.item,
          column: this.column,
        });
      }
    }
  }

  //#endregion

  //#region -------Private
  //#endregion
}
