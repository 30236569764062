<div class="row form-property">
  <div class="col-lg-12">
    <span
      class="p-float-label"
      [style.height]="
        property.textRows > 1
          ? ((property.textRows - 1) * 21 + 33).toString() + 'px'
          : 'auto'
      "
    >
      <div margin-left="8" *ngIf="!property.readonly" class="truncate">
        <small
          pTooltip="{{ itemValue }}"
          tooltipPosition="bottom"
          class="pointer italic"
          (click)="emails.toggle($event)"
        >
          {{ itemValue || addText }}
        </small>
      </div>
      <label class="gs-float-label" [ngClass]="{ 'error-color': notValid }">{{
        property.label
      }}</label>
      <span
        *ngIf="notValid"
        class="form-error"
        pTooltip="{{ property.validationMessage }}"
        tooltipPosition="bottom"
        style="width: 25px; height: 25px"
      >
        <i class="fas fa-info-circle fa-2x form-valid-icon error-color"></i>
      </span>
    </span>
  </div>
</div>

<p-overlayPanel
  (onHide)="hide()"
  [showCloseIcon]="true"
  #emails
  [style]="{ 'min-width': '230px' }"
>
  <div style="max-height: 30vh; overflow-y: auto">
    <form formGroup="form">
      <div
        [margin-left]="8"
        [margin-top]="8"
        *ngFor="let prop of form.controls.emails.controls; let i = index"
      >
        <input pInputText [formControl]="prop" type="text" />
        <span>
          <i class="pi pi-trash pointer" (click)="removeEmail(i)"></i>
        </span>
        <div *ngIf="prop.errors?.email">
          <small class="text-danger">Email is not in good format</small>
        </div>
      </div>
    </form>
    <div>
      <small
        [margin-left]="8"
        style="color: var(--nav-main-background-color)"
        class="pointer"
        (click)="addEmail()"
      >
        + Add Email
      </small>
    </div>
    <div class="right" [margin-bottom]="8">
      <button [margin-top]="8" class="btn btn-primary" (click)="emails.hide()">
        Apply
      </button>
    </div>
  </div>
</p-overlayPanel>
