import { Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { from } from 'rxjs';
import { distinct, filter, map, mergeMap, toArray } from 'rxjs/operators';
import { CustomerClient } from 'src/app/client/customer.client';
import { DashboardClient } from 'src/app/client/dashboard.client';

@Component({
  selector: 'app-training-video',
  templateUrl: './training-video.component.html',
  styleUrls: ['./training-video.component.css'],
})
export class TrainingVideoComponent implements OnInit, OnDestroy {
  videos;
  allVideos;
  selected;

  @ViewChild('container') container;
  _iframe;

  constructor(
    private dashboardClient: DashboardClient,
    private renderer: Renderer2
  ) { }

    ngOnDestroy(): void {
  
    }

  ngOnInit(): void {
    from(this.dashboardClient.getTrainingVideoAll())
      .pipe(
        map((data) => {
          return data.result.map((x) => {
            return {
              name: x.Title,
              src: `https://player.vimeo.com/video/${x.VideoId}?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479`,
              selected: false,
            };
          });
        })
      )
      .subscribe((data) => {
        this.videos = this.allVideos = data.filter((v, i, arr) => {
          return arr.find((x) => x.src === v.src) === v;
        });
      });
  }

  filter(event) {
    if (event?.target?.value)
      this.videos = this.allVideos.filter(
        (x) =>
          x.name.toLowerCase().indexOf(event.target.value.toLowerCase()) != -1
      );
    else this.videos = this.allVideos;
  }

  showVideo(video) {
    this.selected = video;
    this.videos.forEach((v) => (v.selected = false));
    this.selected.selected = true;
    if (this._iframe)
      this.renderer.removeChild(this.container.nativeElement, this._iframe);
    setTimeout(() => {
      this.appendIframe(video);
    }, 100);
  }
  newTab() {
    window.open(this.selected.src);
  }

  appendIframe(video) {
    const script = this.renderer.createElement('script');
    this.renderer.setAttribute(
      script,
      'src',
      'https://player.vimeo.com/api/player.js'
    );

    this._iframe = this.renderer.createElement('iframe');
    this.renderer.setAttribute(this._iframe, 'src', video.src);
    this.renderer.setAttribute(this._iframe, 'frameborder', '0');
    this.renderer.setAttribute(
      this._iframe,
      'allow',
      'autoplay; fullscreen; picture-in-picture'
    );

    this.renderer.setAttribute(this._iframe, 'title', video.name);
    this.renderer.addClass(this._iframe, 'video-frame');
    this.renderer.appendChild(this.container.nativeElement, this._iframe);

    this.renderer.appendChild(this._iframe, script);
  }
}
