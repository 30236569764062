import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core'
import { FormProperty } from '../entity/form/formProperty';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PropertyTypes } from '../entity/enums'

@Component({
  selector: 'property-form',
  templateUrl: './gs.property.form.html'
})
export class GSPropertyForm implements OnInit, OnDestroy {
  //#region -------Properties
  @Input() property: FormProperty;
  @Input() data: any;

  @Output() onSubmit = new EventEmitter<any>();
  @Output() onChange = new EventEmitter<any>();
  @Output() onClick = new EventEmitter<any>();
  @Output() onSelect = new EventEmitter<any>();
  @Output() onDropdown = new EventEmitter<any>();
  @Output() onComplete = new EventEmitter<any>();

  PropertyTypes = PropertyTypes;
  //#endregion

  //#region -------Constructor
  //#endregion

  //#region -------NG Events
  ngOnInit() {
  }

  ngOnDestroy() {
  }
  //#endregion

  //#region -------UI Events
  onPropertyChanged(e, propertyName) {
    this.onChange.emit({ propertyName: propertyName, value: e })
  }
  onPropertySelect(e, propertyName) {
    this.onSelect.emit({ propertyName: propertyName, value: e })
  }
  onPropertyDropdown(e, propertyName) {
    this.onDropdown.emit({ propertyName: propertyName, value: e })
  }
  onPropertyComplete(e, propertyName) {
    this.onComplete.emit({ propertyName: propertyName, value: e })
  }
  onPropertyClick(e, propertyName) {
    this.onClick.emit({ propertyName: propertyName, value: e })
  }
  //#endregion



  //#region -------Private
  //#endregion
}
