import { WebStorageUtility } from '../utils/tools';
import { Subscriber } from 'rxjs';

/*PROPERTY*/
export function DataValue(name?: string) {
    return (target: Object, propertyName: string): void => {
        Object.defineProperty(target, propertyName, {
            get: function (this) {
                return this.data[name];
            },
            set: function (this, value: any) {
                this.data[name] = value;
                if(this.status)
                    this.status = 2;
            },
        });
    }
}

export function LocalStorage(key?: string) {
    return WebStorage(localStorage, key);
}

export function SessionStorage(key?: string) {
    return WebStorage(sessionStorage, key);
}

export let WebStorage = (webStorage: Storage, key: string) => {
    return (target: Object, propertyName: string): void => {
        key = key || propertyName;

        let storageKey = WebStorageUtility.generateStorageKey(key);
        let storedValue = WebStorageUtility.get(webStorage, key);

        Object.defineProperty(target, propertyName, {
            get: function () {
                return WebStorageUtility.get(webStorage, key);
            },
            set: function (value: any) {
                if (!this._webstorage) {
                    // first setter handle
                    if (storedValue === null) {
                        // if no value in localStorage, set it to initializer
                        WebStorageUtility.set(webStorage, key, value);
                        storedValue = value;
                    }
                }
                else {
                    WebStorageUtility.set(webStorage, key, value);
                    storedValue = value;
                }
            },
        });
    }
}

/*CLASS*/ //auto unsubscribe from all subscrible properties
export function AutoUnsub(obs$ = []) {
    return function (constructor: any) {
        const orig = constructor.prototype.ngOnDestroy
        constructor.prototype.ngOnDestroy = function () {
            for (const prop in this) {
                const property = this[prop]
                if (typeof property.unsubscribe === "function" && !obs$.includes(property))
                    obs$.push(property)
            }
            for (const ob$ of obs$) {
                ob$.unsubscribe()
            }
            orig.apply()
        }
    }
}

/*FUNCTION*///memoize function execution (if same function was called with same parameters return existing value)
export function Memo() {
    return function (target: any, key: any, descriptor: any) {
        const oldFunc = descriptor.value
        const newFunc = memoize(oldFunc)
        descriptor.value = function () {
            return newFunc.apply(this, arguments)
        }
  }
  let a = 1;
}

/*FUNCTION*///calculate time of function execution
export function Time(target, name, descriptor) {
    const orig = descriptor.value
    descriptor.value = function (...args) {
        const start = performance.now()
        orig.apply(this, args)
        const stop = performance.now()
        console.log(`Metrics stats:`, (stop - start).toFixed(2))
    }
}

/*FUNCTION*///delay time of function execution for ms(seconds)
export function Delay(ms) {
    return function (target: any, key: any, descriptor: any) {
        const oldFunc = descriptor.value
        const newFunc = _delay(oldFunc, ms)
        descriptor.value = function () {
            return (<any>newFunc).apply(this, arguments)
        }
    }
}

// creating the decorator DestroySubscribers
export function DestroySubscribers() {
    return function (target: any) {
        // decorating the function ngOnDestroy
        target.prototype.ngOnDestroy = ngOnDestroyDecorator(target.prototype.ngOnDestroy);
        // decorator function
        function ngOnDestroyDecorator(f) {
            return function () {
                // saving the result of ngOnDestroy performance to the variable superData 
                let superData = f ? f.apply(this, arguments) : null;
                // unsubscribing
                for (let subscriberKey in this.subscribers) {
                    let subscriber = this.subscribers[subscriberKey];
                    if (subscriber instanceof Subscriber) {
                        subscriber.unsubscribe();
                    }
                }
                // returning the result of ngOnDestroy performance
                return superData;
            }
        }
        // returning the decorated class
        return target;
    }
}


function _delay(fn, ms) {
    (<any>_delay).inProgress === undefined ? (<any>_delay).inProgress = false : null
    if (!(<any>_delay).inProgress) {
        (<any>_delay).inProgress = true
        fn.apply()
        const timeout = setTimeout(() => {
            (<any>_delay).inProgress = false
            clearTimeout(timeout)
        }, ms)
    }
}

function memoize(fn: any) {
    return function () {
        var args =
            Array.prototype.slice.call(arguments)
        fn.cache = fn.cache || {};
        return fn.cache[args] ? fn.cache[args] : (fn.cache[args] = fn.apply(this, args))
    }
}
