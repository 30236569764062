export enum WorkflowExecutionStatuses {
  Intitiated = 1,
  Pending = 2,
  Executing = 3,
  Finished = 4,
  Failed = 5,
  Aborted = 6,
  Warning = 7
}

export enum WorkflowExecutionTypes {
  Scheduled = 1,
  Manual = 2,
  Invoke = 3,
  Admin = 4,
  ManualGeneric = 5
}

export enum ClientTypes {
  Group = 'Company Group',
  Location = 'Customer',
}


export enum ExecutionStatuses {
  Failed = 0,
  Success = 1,
  Warning = 2
}
