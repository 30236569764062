import { EventEmitter } from '@angular/core';
import { PropertyTypes } from '../enums';
import { FormProperty } from './formProperty';
import { FormTextboxProperty } from './formTextbox.property';
import { GsEmailControlComponent } from '../../controls/gs.email.control/gs.email.control.component';

export class FormEmailProperty extends FormProperty {
  type = PropertyTypes.Email;
  component = GsEmailControlComponent;
  properties: FormTextboxProperty[];
  changed = new EventEmitter<any>();

  constructor(name?: string, label?: string) {
    super(name, label);
  }
}
