import { FormProperty } from './formProperty';
import { PropertyTypes } from '../enum/propertyTypes';
import { GSInfoLabelControl } from '../../controls/gsControls';

export class FormInfoLabelProperty extends FormProperty {
    type = PropertyTypes.InfoLabel;
    component = GSInfoLabelControl;
  constructor(name: string, label: string, row?: number, column?: number,
    rowspan?: number, columnspan?: number, validate?: boolean, public color?, public fontSize?: string, public pureText?: boolean, public lineHeight?: string) {
        super(name, label, row , column ,
            rowspan, columnspan, validate);        
    }
}
