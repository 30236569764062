import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  IconsPipe,
  DateFormatPipe,
  GsFilterPipe,
  GsUIStatePipe,
  SafePipe,
} from '../../app/common/pipes/pipes';
import { PaddingDirective, MarginDirective } from './directives/directives';
import { GSForm } from '../common/forms/gs.form';
import { GSPropertyForm } from '../common/forms/gs.property.form';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AccordionModule } from 'primeng/accordion';
import { InputNumberModule } from 'primeng/inputnumber';
import { SelectButtonModule } from 'primeng/selectbutton';
import { PreventClick } from './directives/preventClick';
import { GSToolTip } from './directives/tooltip';
import { ValidDirective } from './directives/valid';
import { ClearInput } from './directives/clearOnInput';
import { GsTranslatePipe } from './pipes/gsTranslate.pipe';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableControl } from './component/table.control';
import { TableRowControl } from './component/tableRow.control';
import { CardModule } from 'primeng/card';
import { TooltipModule } from 'primeng/tooltip';
import { ScrollerModule } from 'primeng/scroller';
import { TimeFormatUSPipe } from './pipes/timeFormatUS.pipe';
import {
  GSTextboxControl,
  GSAutocompleteControl,
  GSDropdownControl,
  GSTPasswordControl,
  GSInputSwitchControl,
  GSTimeControl,
  GSRadioButtonControl,
  GSCalendarRangeControl,
  GSMultiSelectControl,
} from './controls/gsControls';
import { GSCalendarControl } from './controls/gs.calendar.control';
import { GSInfoLabelControl } from './controls/gs.infoLabel.control';
import { GSInputNumberControl } from './controls/gs.inputNumber.control';
import { GSInputDecimalControl } from './controls/gs.inputDecimal.control';
import { GSTimeEmployeeDropdownControl } from './controls/gs.timeEmployee.control';
import { GSButtonControl } from './controls/gs.button.control';
import { ButtonBarControl } from '../menuItems/customerWizard/controls/buttonBar.control';
import { StepHistoryLogDialog } from '../menuItems/customerWizard/dialogs/stepHistoryLog.dialog';
import { CalendarFromToComponent } from './controls/gs.calendar-from-to/gs.calendar-from-to.component';
import { DateComparePipe } from './pipes/dateCompare.pipe';

import { GSPlaceholder } from './directives/placeholder';
import { ChangeDirective } from './directives/change';
import { PhoneMaskDirective } from './directives/phoneMask';
import { ChangePasswordDialog } from '../menuItems/security/dialogs/changePassword.dialog';
import { ManageAgreementDialog } from '../menuItems/security/dialogs/manageAgreement.dialog';
import { GSPhoneNumberControl } from './controls/gs.phoneNumber.control';

import { DecimalDirective } from './directives/decimal';
import { GSLocationDropdownControl } from './controls/gs.locationDropdown.control';
import { GSLocationMultiselectControl } from './controls/gs.locationMultiselect.control';
import { MenuModule } from 'primeng/menu';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenuItem, SharedModule } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SpinnerModule } from 'primeng/spinner';
import { ChartModule } from 'primeng/chart';
import { ProgressBarModule } from 'primeng/progressbar';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ListboxModule } from 'primeng/listbox';
import { FieldsetModule } from 'primeng/fieldset';
import { ImageModule } from 'primeng/image';

//reporting
import { ChangeLogFilterControl } from '../menuItems/reporting/controls/changeLogFilter.control';
import { DateStringPipe } from './pipes/dateString.pipe';
import { MonthlyOverviewComponent } from '../menuItems/dashboard/components/monthlyOverview.component';
import { GSMotnhCalendarControl } from './component/monthCalendar.component';
import { GSMonthCalendarEventControl } from './component/monthCalendarEvent.component';
import { CalculatedDataDetailsControl } from '../menuItems/dashboard/controls/calculatedDataDetails.control';
import { SpinnerComponent } from './component/spinner.component';
import { GSDialogComponent } from './component/gsDialog.component';
import { GSDepartmentDropdownControl } from './controls/gs.departmentDropdown.control';
import { HelperDireciteve } from './directives/helper';
import { UserManualComponent } from './component/user-manual/user-manual.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { GSPETemplateItemControl } from './controls/gs.peTemplateItem.control';
import { GsDropDownValuePipe } from './pipes/dropdownValue.pipe';
import { GSCompanyGroupDropdownControl } from './controls/gs.companyGroupDropdown.control';
import { AmPmTimePipe } from './pipes/am-pm-time.pipe';
import { GSMaskInputControl } from './controls/gs.maskInput.control';

import { DragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { TimeToStringPipe } from './pipes/timeToString.pipe';
import { PaginatorModule } from 'primeng/paginator';
import { PickListModule } from 'primeng/picklist';
import { VideoTrainingDirective } from './video-training.directive';
import { PageTrainingVideoPipe } from './page-training-video.pipe';
import { TrainingVideoComponent } from './training-video/training-video.component';
import { StorageToItemsPipe, ToItemPipe } from './pipes/storage-to-items.pipe';
import { TableColumnsPipe } from './pipes/table-columns.pipe';
import { GsWorkflowRelativeComponent } from './controls/gs.workflow-relative/gs.workflow-relative.component';
import { GsScheduledValueControlTsComponent } from './controls/gs.scheduled-value.control.ts/gs.scheduled-value.control.ts.component';
import { ItemParameterPipe } from './pipes/item-parameter.pipe';
import { GsEmailControlComponent } from './controls/gs.email.control/gs.email.control.component';
import { WFLogDetailsControl } from './controls/gs.wfLogDetails.control'; 

@NgModule({
  exports: [
    FormsModule,
    ReactiveFormsModule,
    IconsPipe,
    GsFilterPipe,
    DateFormatPipe,
    DateStringPipe,
    DateComparePipe,
    TimeFormatUSPipe,
    GsTranslatePipe,
    GsUIStatePipe,
    GsDropDownValuePipe,
    PaddingDirective,
    MarginDirective,
    TableModule,
    MultiSelectModule,
    TabViewModule,
    AutoCompleteModule,
    TableControl,
    TableRowControl,
    CheckboxModule,
    ConfirmDialogModule,
    AccordionModule,
    InputSwitchModule,
    SpinnerModule,
    ChartModule,
    InputNumberModule,
    PreventClick,
    GSToolTip,
    ValidDirective,
    ClearInput,
    ChangeDirective,
    PhoneMaskDirective,
    DecimalDirective,
    HelperDireciteve,
    GSTextboxControl,
    GSMaskInputControl,
    GSPhoneNumberControl,
    GSPETemplateItemControl,
    GSAutocompleteControl,
    GSDropdownControl,
    GSTPasswordControl,
    GSInputSwitchControl,
    GSLocationDropdownControl,
    GSCompanyGroupDropdownControl,
    GSDepartmentDropdownControl,
    GSLocationMultiselectControl,
    GSTimeControl,
    GSRadioButtonControl,
    GSCalendarRangeControl,
    GSMultiSelectControl,
    GSForm,
    GSPropertyForm,
    GSCalendarControl,
    GSInfoLabelControl,
    GSInputNumberControl,
    GSInputDecimalControl,
    GSTimeEmployeeDropdownControl,
    GSButtonControl,
    ProgressBarModule,
    ButtonModule,
    DropdownModule,
    OverlayPanelModule,
    InputTextModule,
    PasswordModule,
    ToastModule,
    SharedModule,
    FormsModule,
    CardModule,
    TooltipModule,
    RadioButtonModule,
    RatingModule,
    DialogModule,
    CalendarModule,
    SelectButtonModule,
    FileUploadModule,
    InputMaskModule,
    ProgressSpinnerModule,
    ButtonBarControl,
    StepHistoryLogDialog,
    CalendarFromToComponent,
    GSPlaceholder,
    ChangePasswordDialog,
    ManageAgreementDialog,
    ChangeLogFilterControl,
    DecimalPipe,
    MenuModule,
    TabMenuModule,
    ListboxModule,
    FieldsetModule,
    GSMotnhCalendarControl,
    GSMonthCalendarEventControl,
    CalculatedDataDetailsControl,
    SpinnerComponent,
    GSDialogComponent,
    UserManualComponent,
    ImageModule,
    DragDropModule,
    ScrollerModule,
    TimeToStringPipe,
    VideoTrainingDirective,
    PageTrainingVideoPipe,
    TrainingVideoComponent,
    SafePipe,
    StorageToItemsPipe,
    ToItemPipe,
    TableColumnsPipe,
    ItemParameterPipe,
    WFLogDetailsControl
  ],
  providers: [],
  declarations: [
    IconsPipe,
    GsFilterPipe,
    DateFormatPipe,
    TimeFormatUSPipe,
    DateStringPipe,
    DateComparePipe,
    GsTranslatePipe,
    GsUIStatePipe,
    GsDropDownValuePipe,
    PaddingDirective,
    MarginDirective,
    TableControl,
    TableRowControl,
    PreventClick,
    GSToolTip,
    ValidDirective,
    ClearInput,
    ChangeDirective,
    PhoneMaskDirective,
    DecimalDirective,
    HelperDireciteve,
    GSTextboxControl,
    GSMaskInputControl,
    GSPhoneNumberControl,
    GSPETemplateItemControl,
    GSAutocompleteControl,
    GSDropdownControl,
    GSTPasswordControl,
    GSInputSwitchControl,
    GSLocationDropdownControl,
    GSCompanyGroupDropdownControl,
    GSDepartmentDropdownControl,
    GSLocationMultiselectControl,
    GSTimeControl,
    GSRadioButtonControl,
    GSCalendarRangeControl,
    GSMultiSelectControl,
    GSForm,
    GSPropertyForm,
    GSCalendarControl,
    GSInfoLabelControl,
    GSInputNumberControl,
    GSInputDecimalControl,
    GSTimeEmployeeDropdownControl,
    GSButtonControl,
    ButtonBarControl,
    StepHistoryLogDialog,
    CalendarFromToComponent,
    GSPlaceholder,
    ChangePasswordDialog,
    ManageAgreementDialog,
    ChangeLogFilterControl,
    GSMotnhCalendarControl,
    GSMonthCalendarEventControl,
    CalculatedDataDetailsControl,
    SpinnerComponent,
    GSDialogComponent,
    UserManualComponent,
    AmPmTimePipe,
    TimeToStringPipe,
    VideoTrainingDirective,
    PageTrainingVideoPipe,
    TrainingVideoComponent,
    SafePipe,
    StorageToItemsPipe,
    ToItemPipe,
    TableColumnsPipe,
    GsWorkflowRelativeComponent,
    GsScheduledValueControlTsComponent,
    ItemParameterPipe,
    GsEmailControlComponent,    
    WFLogDetailsControl
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputNumberModule,
    TableModule,
    TabViewModule,
    AutoCompleteModule,
    CheckboxModule,
    ProgressBarModule,
    ChartModule,
    ButtonModule,
    DropdownModule,
    OverlayPanelModule,
    InputTextModule,
    PasswordModule,
    ToastModule,
    SharedModule,
    FormsModule,
    InputSwitchModule,
    MultiSelectModule,
    SpinnerModule,
    CardModule,
    TooltipModule,
    RadioButtonModule,
    RatingModule,
    DialogModule,
    CalendarModule,
    SelectButtonModule,
    FileUploadModule,
    InputMaskModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
    AccordionModule,
    MenuModule,
    TabMenuModule,
    ListboxModule,
    FieldsetModule,
    PdfViewerModule,
    ImageModule,
    DragDropModule,
    PaginatorModule,
    PickListModule,
  ],
})
export class CommonGratModule {}
