<div *ngIf="item" class="row form-property">
  <div
    class="col-lg-{{tooltip?11:12}}"
    [ngClass]="{'no-padding-right':tooltip}"
  >
    <span class="p-float-label">
      <p-multiSelect
        [appendTo]="'body'"
        id="{{name}}"
        [options]="options"
        (onChange)="change($event)"
        [ngModel]="value"
        [readonly]="readonly"
        [defaultLabel]="property.defaultLabel"
        [ngClass]="{'disabled':readonly, 'property-invalid':notValid}"
        class="disabled ui-inputwrapper-focus ui-inputwrapper-focus"
        [style]="{'width': '100%'}"
      >
      </p-multiSelect>
      <label class="gs-float-label" [ngClass]="{'error-color':notValid}">{{label}}</label>
      <span
        *ngIf="notValid"
        class="form-error"
        pTooltip="{{property.validationMessage}}"
        tooltipPosition="top"
      >
        <i
          class="fas fa-info-circle fa-2x form-valid-icon with-icon error-color"
        ></i>
      </span>
    </span>
  </div>
  <div *ngIf="tooltip" class="col-lg-1 property-tooltip">
    <span pTooltip="{{property.tooltip}}">
      <i class="fas fa-info-circle master-color"></i>
    </span>
  </div>
</div>
