import { Directive, ElementRef, HostListener, Input, Renderer2, Output, EventEmitter } from '@angular/core';


@Directive({
  selector: '[clear-input]'
})
export class ClearInput {
  @Input() ngModel;
  @Output() ngModelChange =new EventEmitter<any>();

  removeElement;

  constructor(private element: ElementRef, private renderer: Renderer2) {
  }

  @HostListener('focus', ['$event'])
  focus(e) {
    this.createElement();
    e.stopPropagation();
  }

  @HostListener('keyup', ['$event'])
  change(e) {
    this.createElement();
    e.stopPropagation();
  }

  @HostListener('focusout', ['$event'])
  focusOut(e) {
    setTimeout(() => {
      if (this.removeElement) {
        this.element.nativeElement.parentNode.removeChild(this.removeElement);
        this.removeElement = undefined;
      }
    }, 100);
  }

  click(e) {
    this.ngModel = undefined;
    this.ngModelChange.emit(this.ngModel);
    this.element.nativeElement.focus();
    e.stopPropagation();
  }

  private createElement() {
    if (!this.removeElement && this.ngModel != undefined) {
      this.removeElement = document.createElement('span');
      this.removeElement.innerText = 'x';
      this.removeElement.addEventListener('click', this.click.bind(this))
      this.renderer.setStyle(this.removeElement, 'position', 'relative');
      this.renderer.setStyle(this.removeElement, 'right', '20px');
      this.element.nativeElement.parentNode.appendChild(this.removeElement)
    }
  }
  
}
