export enum ValidationTypes {
  equal = 1,
  greaterThan = 2,
  greaterThanOrEqual = 3,
  lessThan = 4,
  lessThanOrEqual = 5,
  uniqueNotEmpty = 6,
  peReportTemplateValidation = 7,
  fromReferenceOrNullExcludingSelf = 8,
  commissionSalesTrack = 9,
  SSN_4existing_or_9new = 10,
  DwollaFundingSource_AccountNumber = 11,
  DwollaFundingSource_RoutingNumber = 12,
  requiredIfRelatedValueIs = 13,
  required = 14,
  WFMaxAttempts = 15,
  WFWaitBetweenAttempts = 16,
  WFScheduleFrequency = 17,
  WFScheduleFrequencyValue = 18
}
