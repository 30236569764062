<div class="row form-property">
  <div
    class="col-lg-{{tooltip?11:12}}"
    [ngClass]="{'no-padding-right':tooltip}"
  >
    <ng-container *ngIf="!leftLabel">
      <span class="p-float-label">
        <p-calendar
          *ngIf="!property.rowspan"
          id="{{name}}"
          [appendTo]="'body'"
          class="ui-inputwrapper-focus ui-inputwrapper-focus"
          (ngModelChange)="change($event)"
          [(ngModel)]="date"
          [showIcon]="property.showIcon"
          [disabled]="property.readOnly"
                     [disabledDates]="property.disabledDates"
          [style]="{'width': '95%'}"
          [inputStyleClass]="readonly?'disabled':''"
          [inline]="property.inline"
          [minDate]="property.minDate"
          [maxDate]="property.maxDate"
          [view]="property.calendarView ? property.calendarView : 'date'"
          [dateFormat]="property.dateFormat ? property.dateFormat : 'mm/dd/yy'"
          [yearNavigator]="property.yearNavigator ? property.yearNavigator : false"
          [yearRange]="property.yearRange ? property.yearRange : null"
          [readonlyInput]="property.readonlyInput ? property.readonlyInput : null"
        >
        </p-calendar>
        <label class="gs-float-label" [ngClass]="{'error-color':notValid}">{{label}}</label>
        <!--<span *ngIf="notValid" class="form-error" pTooltip="{{property.validationMessage}}">
        <i class="fas fa-info-circle fa-2x form-valid-icon error-color"></i>
      </span>-->
      </span>
    </ng-container>
    <ng-container *ngIf="leftLabel">
      <label class="master-font" [ngClass]="{'error-color':notValid}"
        >{{label}}</label
      >
      <span class="float-right">
        <p-calendar
          *ngIf="!property.rowspan"
          id="{{name}}"
          class="ui-inputwrapper-focus ui-inputwrapper-focus"
          (ngModelChange)="change($event)"
          [(ngModel)]="date"
          [showIcon]="property.showIcon"
          [disabled]="property.readOnly"
          [style]="{'width': '95%'}"
          [inline]="property.inline"
        ></p-calendar>
      </span>
    </ng-container>
  </div>
  <div *ngIf="tooltip" class="col-lg-1 property-tooltip">
    <span pTooltip="{{property.tooltip}}">
      <i class="fas fa-info-circle master-color"></i>
    </span>
  </div>
</div>
