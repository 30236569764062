export enum HelpPath {
  Adjusting_an_Employees_Job= 1,
  Adjusting_an_Employees_Time= 2,
  Adjusting_distribution_on_individual_level= 3,
  Adjusting_final_calculated_data= 4,
  Canceling_Marketplace_Subscription= 5,
  Changing_my_Details= 6,
  Changing_Password= 7,
  Closing_a_day= 8,
  Creating_new_managers_account= 9,
  Explanation_of_each_Column____in_the_Calculated_data_Tab_= 10,
  Generate_Export_a_Time_Attendance_Report_= 11,
  Instalation_Manual= 12,
  Loading_a_Pay_Card_with_Gratuity_Solutions= 13,
  Loading_an_Audit_Report= 14,
  Managers_Account_Roles_Explanations = 15,
  PayDay_Portal_Alarm_function= 16,
  PayDayPortal_Mobile_App= 17,
  Payment_reporting = 18,
  Registering_an_employee= 19,
  Requesting_support= 21,
  Resetting_password= 22,
  Scheduling_Training = 23,
  Shift_Adjustments__on_the_POS_Data_page = 24,
  Synchronizing_PayDay_portal_with_POS_system = 25,
  Teaming_up_the_Employees = 26,
  USBANK_CARD__activation_process = 27,
  Welcome_kit= 28,
}
