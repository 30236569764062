export enum TrainingVideos {
  DailyStatsAndReports = 1,
  SoftShift = 2,
  ApproveAndCloseDate = 3,
  POSData = 4,
  AddTimeCard = 5,
  CalculatedData = 6,
  DistributionOverride = 7,
  ReviewChangeLogs = 8,
  Alarms = 9,
  CommonAlarms = 10,
  Sync = 11,
  OpenAndEditDate = 12,
  DistributionRules = 13,
  RegisterNewEmployee = 14,
  //ActivatePayCard = 15,
  ViewTimeData = 16,
  //MapEmployee = 17,
  //PayEmployee = 18,
  //MarkAsPaid = 19,
  SeeManagerRoles = 20,
  DistributionOverrideForEmployee = 21,

  //SyncPayroll = 24,
  DisplayData = 25,
  NavigationMenu = 26,
  GeneratePayroll = 27,
  SubmitSupportTicket = 28,
  FindHelpDocument = 29,
  ScheduleTraining = 30,
  AddNewManager = 31,
  ChangeAccountDetailsAndPassword = 32,
  //MapJobCodes = 33,
}
