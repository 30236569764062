import { FormProperty } from './formProperty';
import { PropertyTypes } from '../enum/propertyTypes';
import { EventEmitter } from '@angular/core';
import { GSTimeControl } from '../../controls/gsControls';

export class FormTimeSpanProperty extends FormProperty {
  type = PropertyTypes.TimeSpan;
  changed = new EventEmitter<any>();
  keydown = new EventEmitter<any>();
  onpaste = new EventEmitter<any>();
  component = GSTimeControl;

  constructor(name: string, label: string, row?: number, column?: number,
        rowspan?: number, columnspan?: number, validate?: boolean) {
        super(name, label, row , column ,
          rowspan, columnspan, validate);
  }
}
