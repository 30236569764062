import { Component, OnInit, OnDestroy, Inject, Optional, Injectable, ChangeDetectorRef } from '@angular/core'
import { GSBaseControl } from './gs.base.control';
import { FormButtonProperty } from '../entity/form/formButton.property';
import { Item } from '../entity/entities';

@Component({
  selector: 'gs-button',
  templateUrl: './gs.button.control.html'
})
@Injectable()
export class GSButtonControl extends GSBaseControl implements OnInit, OnDestroy {
  //#region -------Properties
  //#endregion

  //#region -------Constructor
  //#endregion
  //#region -------NG Eents
  ngOnInit() {
  }

  ngOnDestroy() {
  }
  //#endregion

  //#region -------Constructor
  constructor(@Optional() prop: FormButtonProperty, @Optional() item: Item, private changeDr: ChangeDetectorRef) {
    super(changeDr);
        this.property = prop;
        this.item = item;
    }
  //#endregion

  //#region -------UI Events
  click(e) {
      this.onClick.emit(e);
      this.property.click.emit({ propertyName: this.name, event: e });    
  }

  //#endregion

  //#region -------Private
  //#endregion
}
