<p-dropdown
  [filter]="column.dropdownSearch"
  filterBy="label"
  *ngIf="column?.showNull || item?.data[column?.name] || item?.data[column?.name]==0"
  class="table-control-middle"
  [ngClass]="{'disabled':readonly, 'dropdown-property-invalid':notValid}"
  [appendTo]="'body'"
  [baseZIndex]="11000"
  [ngModel]="item.data[column.name]"
  [options]="column.options"
  style="width: 100%; text-align: center; margin-top: 5px"
  [itemSize]="31"
  (ngModelChange)="change($event)"
  [placeholder]="column.placeholder"
>
  <ng-template let-slctd pTemplate="selectedItem">
    <span style="vertical-align: initial">{{slctd?.data?.Name}}</span>
  </ng-template>
  <ng-template let-opt pTemplate="item">
    <div #selectOption class="row" [margin-left]="10" [margin-right]="5">
      <div
        class="lightborder ui-helper-clearfix col-lg-10 col-sm-10 col-md-10"
        [ngClass]="{'disabled_opacity-no-color no-event':opt?.data?.IsPaid}"
        style="position: relative; border-bottom: 1px solid lightgrey"
      >
        <span class="text-l">{{opt?.data?.Name}}</span>
      </div>
      <div
        *ngIf="opt?.data?.IsPaid"
        gsTooltip="{{'dashboard.time.employeePaid' | gstranslate}}"
        [type]="'error'"
        class="ui-helper-clearfix col-lg-2 col-sm-2 col-md-2 pointer-events-auto"
      >
        <i
          class="fas error-color"
          [ngClass]="{'fa-exclamation-triangle':opt?.data?.IsPaid}"
        ></i>
      </div>
    </div>
  </ng-template>
</p-dropdown>
