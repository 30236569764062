<div class="display-flex">
  <div style="width: 200px; z-index: 1">
    <gs-dropdown
      *ngIf="dropdownProperty"
      [item]="item"
      [property]="dropdownProperty"
    ></gs-dropdown>
  </div>
  <div style="width: 100px">
    <gs-input-number
      *ngIf="numberProperty && item.data[dropdownProperty.name] != 'W'"
      [item]="item"
      [property]="numberProperty"
      [hideLabel]="true"
    ></gs-input-number>
    <gs-dropdown
      *ngIf="dropdownProperty2 && item.data[dropdownProperty.name] == 'W'"
      [item]="item"
      [property]="dropdownProperty2"
      [hideLabel]="true"
    ></gs-dropdown>
  </div>
</div>
