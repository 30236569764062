<div *ngIf="item" class="row form-property">
  <div
    class="col-lg-{{tooltip?11:12}}"
    [ngClass]="{'no-padding-right':tooltip}"
  >
    <span class="p-float-label">
      <p-multiSelect
        [appendTo]="'body'"
        id="{{name}}"
        [options]="options"
        (onChange)="change($event)"
        [ngModel]="value"
        [readonly]="readonly"
        [ngClass]="{'disabled':readonly, 'multiselect-property-invalid':notValid}"
        class="disabled ui-inputwrapper-focus ui-inputwrapper-focus"
        [style]="{'width': '100%'}"
        [panelStyle]="{'max-height': '400px','min-width': '400px'}"
        [defaultLabel]="property.defaultLabel"
        [virtualScroll]="true"
        [virtualScrollItemSize]="80"
      >
        <ng-template let-selected pTemplate="value">
          <span style="vertical-align: middle"
            >{{selected?selected.data.Name:''}}</span
          >
        </ng-template>
        <ng-template let-opt pTemplate="item">
          <span
            #selectOption
            [margin-left]="10"
            [margin-right]="5"
            style="width: 90%; border-bottom: 1px solid lightgrey"
          >
            <span
              class="lightborder ui-helper-clearfix col-lg-11 col-sm-11 col-md-11"
              [ngClass]="{'disabled_opacity-no-color no-event':opt?.data?.BlockedReason || opt?.data?.Disabled}"
              style="position: relative"
            >
              <span class="text-l bold">{{opt?.data?.Name}}</span>
              <br />
              <span
                *ngIf="opt?.data?.CustomerAddress"
                class="text-s"
                style="padding-left: 15px"
              >
                {{opt?.data?.CustomerAddress?.StreetNumber}}
                {{opt?.data?.CustomerAddress?.Street}}
              </span>
              <br />
              <span
                *ngIf="opt?.data?.CustomerAddress"
                class="text-s"
                style="padding-left: 15px"
              >
                {{opt?.data?.CustomerAddress?.Zip}}
                {{opt?.data?.CustomerAddress?.State}}
              </span>
            </span>
            <span
              *ngIf="opt?.data?.BlockedReason || opt?.data?.Disabled"
              gsTooltip="{{opt?.data?.BlockedReason?opt?.data?.BlockedReason:(opt?.data?.Disabled ? ('general.notConfigLocation' | gstranslate):'')}}"
              [type]="'error'"
              style="position: absolute; top: 25px; right: 25px"
              class="ui-helper-clearfix col-lg-1 col-sm-1 col-md-1 pointer-events-auto"
              [ngClass]="{'no-event':opt.data.BlockedReason || opt.data.Disabled}"
              (click)="$event.stopPropagation()"
            >
              <i
                class="fas fa-2x error-color"
                [ngClass]="{'fa-eye-slash':opt?.data?.BlockedReason,'fa-exclamation-triangle':!opt?.data?.BlockedReason && opt?.data?.Disabled}"
              ></i>
            </span>
          </span>
        </ng-template>
      </p-multiSelect>
      <label class="gs-float-label" [ngClass]="{'error-color':notValid}"
        >{{label}}</label
      >
      <span
        *ngIf="notValid"
        class="form-error"
        pTooltip="{{property.validationMessage}}"
        tooltipPosition="top"
      >
        <i
          class="fas fa-info-circle fa-2x form-valid-icon with-icon error-color"
        ></i>
      </span>
    </span>
  </div>
  <div *ngIf="tooltip" class="col-lg-1 property-tooltip">
    <span pTooltip="{{property.tooltip}}">
      <i class="fas fa-info-circle master-color"></i>
    </span>
  </div>
</div>
