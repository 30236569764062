import { Injectable, Component } from '@angular/core';
//import { AppConfig } from "../../app.config";
import { CacheService } from 'ng2-cache';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseClient } from '../common/client/base.client';
import { AppConfig } from '../app.config';
import { SvcClients } from '../common/client/SvcClients';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SecurityClient extends BaseClient {
  constructor() {
    super();
  }

  login(username: string, password: string, message: string) {
    return this.post(
      SvcClients.AdminSvc,
      'PayDayAdminPortal/authenticate',
      { username: username, password: password },
      message
    );
  }

  exchangeToken(oneTimeCode: string) {
    return this.post(SvcClients.AdminSvc, 'Saml/ExchangeToken', {
      Code: oneTimeCode,
    });
  }

  menu(message?: string) {
    return this.get(SvcClients.AdminSvc, 'PayDayAdminPortal/menu', message);
  }

  signUp(signUpModel: any, message: string) {
    return this.post(
      SvcClients.AdminSvc,
      'v2/RegisterAccount',
      signUpModel,
      message
    );
  }

  changePassword(password: string, newPassword: string, message: string) {
    return this.post(
      SvcClients.AdminSvc,
      'Account/changePassword',
      { password: password, newPassword: newPassword },
      message
    );
  }

  acknowledge() {
    return this.put(
      SvcClients.AdminSvc,
      'UserAgreement/acknowledge',
      null,
      null
    );
  }

  getAccountDetails(message: string, withCustomers: boolean, userCode?) {
    return this.get(
      SvcClients.AdminSvc,
      `G5AccountDetails/GetAccountDetails?userCode=${userCode}&withCustomers=${withCustomers}`,
      message
    );
  }

  getAccountDetailsWithActiveCustomers(
    message: string,
    withCustomers: boolean,
    userCode?
  ) {
    return this.get(
      SvcClients.AdminSvc,
      `G5AccountDetails/GetAccountDetailsWithActiveCustomers?userCode=${userCode}&withCustomers=${withCustomers}`,
      message
    );
  }

  setAccountDetails(message: string, model: any) {
    return this.post(
      SvcClients.AdminSvc,
      'G5AccountDetails/UpdateAccountDetails',
      model,
      message
    );
  }

  captchaVerify(message: string, captchaToken: any) {
    return this.get(
      SvcClients.AdminSvc,
      `Account/CaptchaVerify?captchaToken=${captchaToken}`,
      message
    );
  }

  sendForgotPasswordInquiry(
    message: string,
    captchaToken: string,
    emailOrPhone: string,
    username: string
  ) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPAccount/ForgotPassword?captchaToken=${captchaToken}`,
      { PhoneOrEmal: emailOrPhone, UserName: username },
      message
    );
  }

  resetPassword(message: string, verificationCode: string, password: string) {
    return this.post(
      SvcClients.AdminSvc,
      'Account/ResetPassword',
      { ResetCode: verificationCode, Password: password },
      message
    );
  }

  transferAccountOwnership(message: string, model: any) {
    return this.post(
      SvcClients.AdminSvc,
      `G5AccountDetails/TransferOwnership`,
      model,
      message
    );
  }

  getMobileButtons(message: string, model: any) {
    return this.post(
      SvcClients.AdminSvc,
      `Customer/MobileButtons`,
      model,
      message
    );
  }

  showHelp(key, customerCode) {
    if (!customerCode)
      return this.get(SvcClients.AdminSvc, `PDPHelp/GetHelpPage/?key=${key}`);
    else
      return this.get(
        SvcClients.AdminSvc,
        `PDPHelp/GetHelpPage/?key=${key}&customerCode=${customerCode}`
      );
  }

  sendChatGPT(model) {
    return this.post(
      SvcClients.ResourceSvc,
      `GratChat/ChatCompletetion`,
      model,
      null
    );
  }
}
