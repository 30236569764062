import { Pipe, PipeTransform } from '@angular/core';
import { MenuModel } from '../entity/enum/menuModel';
import { TranslateService } from '@ngx-translate/core';
import { Session } from '../entity/entities';
@Pipe({
    name: 'gsuistate'
})
export class GsUIStatePipe implements PipeTransform {

  constructor( ) {
  
  }
  transform(value: string, ...args: any) {
    if (args && args.length) {
      //set
      if (!Session.uiStates.items.keys.some(k => k == value))
        Session.uiStates.setItem(args[0], value);
      else
        Session.uiStates.items.get(value).data = args[0];
    }
    else if (Session.uiStates.items.keys.some(k => k == value)) {
      //get
      return Session.uiStates.items.get(value).data;
    }
  }
}
