import { TableTypes } from '../entity/enum/tableTypes';
import { TableColumn } from '../entity/table/tableColumn';
import { ColumnTypes, PropertyTypes } from '../entity/enums';
import { Column, EventCollection } from '../entity/entities';
import { PageObejctHelper } from '../helpers/pageObject.helper';
import { FilterTypes } from '../entity/enum/filterTypes';
import { ValidationTypes } from '../entity/enum/validationTypes';
import { FormLocationDropdownProperty } from '../entity/form/formLocationDropdown.property';
import { regExLib } from '../regEx/regExLib';
import { breakTextIntoLines } from 'pdf-lib';
import { WorkflowExecutionTypes } from '../entity/enum/workflowExecutionStatuses';
import { TrainingVideos } from '../entity/enum/trainingVideos';

export class TableBuilder {

  public static build(tableType: TableTypes) {
    let columns: TableColumn[] = [];
    switch (tableType) {
      case TableTypes.CustomerList:
        columns = TableBuilder.getCustomerList();
        break;
      case TableTypes.DataSourceConfiguration:
        columns = TableBuilder.getDataSourceConfiguration();
        break;
      case TableTypes.BusinessLocalization_everyDay:
        columns = TableBuilder.getBusinessLocalizationEveryDay();
        break;
      case TableTypes.BusinessLocalization_byDay:
        columns = TableBuilder.getBusinessLocalizationByDay();
        break;
      case TableTypes.TenderMapping:
        columns = TableBuilder.getTenderMapping();
        break;
      case TableTypes.JobConfiguration:
        columns = TableBuilder.getJobConfiguration();
        break;
      case TableTypes.JobElectionJobs:
        columns = TableBuilder.getJobElectionJobs();
        break;
      case TableTypes.Disitribution:
        columns = TableBuilder.getDistributions();
        break;
      case TableTypes.Disitribution_Exceptions:
        columns = TableBuilder.getDistributions_exceptions();
        break;
      case TableTypes.Security_Function:
        columns = TableBuilder.getSecurityFunctions();
        break;
      case TableTypes.Dashboard_Notifiation:
        columns = TableBuilder.getDashboardNotification();
        break;
      case TableTypes.Dashboard_Time_TimeCard:
        columns = TableBuilder.getDashboardTime_TimeCard();
        break;
      case TableTypes.Dashboard_Time_Breaks:
        columns = TableBuilder.getDashboardTime_Breaks();
        break;
      case TableTypes.Dashboard_Time_Shifts:
        columns = TableBuilder.getDashboardTime_Shifts();
        break;
      case TableTypes.Dashboard_Time_TimeAdd:
        columns = TableBuilder.getDashboardTime_TimeAdd();
        break;
      case TableTypes.ControlPanel_ManagerAccounts:
        columns = TableBuilder.getControlPanel_ManagerAccounts();
        break;
      case TableTypes.ControlPannel_Employee_Mapping:
        columns = TableBuilder.getControlPanel_EmployeeMapping();
        break;
      case TableTypes.ControlPannel_Employee_ETimeMapping:
        columns = TableBuilder.getControlPanel_EmployeeETimeMapping();
        break;
      case TableTypes.ControlPannel_Job_Payroll_Mapping:
        columns = TableBuilder.getControlPanel_JobPayrollMapping();
        break;
      case TableTypes.ControlPannel_Job_ETimeMapping:
        columns = TableBuilder.getControlPanel_JobETimeMapping();
        break;
      case TableTypes.LoadCardReport_Details:
        columns = TableBuilder.getLoadCardReport_Details();
        break;
      case TableTypes.EmployeeProfile_ProfileDetails:
        columns = TableBuilder.getEmployeeProfile_ProfileDetails();
        break;
      case TableTypes.CalculatedData_DistributionOverride:
        columns = TableBuilder.getCalculatedData_DistributionOverride();
        break;
      case TableTypes.EmployeeProfile_ProfileMappings:
        columns = TableBuilder.getEmployeeProfile_ProfileMappings();
        break;
      case TableTypes.Dashboard_Payroll_Periods:
        columns = TableBuilder.getDashboardPayrollPeriods();
        break;
      case TableTypes.ControlPanel_Withhold:
        columns = TableBuilder.getControlPanel_Withhold();
        break;
      case TableTypes.ControlPanel_WithholdConfiguration:
        columns = TableBuilder.getControlPanel_WithholdConfiguration();
        break;
      case TableTypes.Dashboard_MonthlyOverviewTipShare:
        columns = TableBuilder.getDashboard_MonthlyOverviewTipShare();
        break;
      case TableTypes.Dashboard_POS_Distribution:
        columns = TableBuilder.getDashboard_posDistribution();
        break;
      case TableTypes.Dashboard_PayMe:
        columns = TableBuilder.getDashboard_PayMe();
        break;
      case TableTypes.ControlPanel_PayMePreferences:
        columns = TableBuilder.getControlPanel_PayMePreferences();
        break;
      case TableTypes.Dashboard_BulkApproveAndClose:
        columns = TableBuilder.getDashboard_BulkApproveAndClose();
        break;
      case TableTypes.Dashboard_TransactionsWithEndDate:
        columns = TableBuilder.getDashboard_TransactionsWithEndDate();
        break;
      case TableTypes.Dashboard_TransactionsWithEndDateEdit:
        columns = TableBuilder.getDashboard_TransactionsWithEndDateEdit();
        break;
      case TableTypes.Security_Alarms:
        columns = TableBuilder.getSecurityAlarms();
        break;
      case TableTypes.Dashboard_TED_PosData:
        columns = TableBuilder.getDashboard_TED_PosData();
        break;
      case TableTypes.Dashboard_TED_Transactions:
        columns = TableBuilder.getDashboard_TED_Transactions();
        break;
      case TableTypes.Security_UserManual:
        columns = TableBuilder.getSecurity_userManuals();
        break;
      case TableTypes.Security_AutoTimecardJobs:
        columns = TableBuilder.getSecurity_AutoTimecardJobs();
        break;
      case TableTypes.Security_AutoTimecardRule:
        columns = TableBuilder.getSecurity_AutoTimecardRule();
        break;
      case TableTypes.Security_AutoTimecardRuleDependencies:
        columns = TableBuilder.getSecurity_AutoTimecardRuleDependencies();
        break;
      case TableTypes.Security_PEExportTemplateColumns:
        columns = TableBuilder.getSecurity_PEExportTemplateColumns();
        break;
      case TableTypes.PayrollExportTemplateCfgFormulasColumns:
        columns = TableBuilder.getPayrollExportTemplateCfgFormulasColumns();
        break;
      case TableTypes.Dashboard_Payroll_Manual_Changes:
        columns = TableBuilder.getPayrollExportManualChanges();
        break;
      case TableTypes.ControlPanel_TimeAndAttendance:
        columns = TableBuilder.getControlPanelTimeAndAttendance();
        break;
      case TableTypes.Paycard:
        columns = TableBuilder.getPaycard();
        break;
      case TableTypes.CommissionSalesCategoryTracks:
        columns = TableBuilder.getCommissionSalesCategoryTracks();
        break;
      case TableTypes.Security_AutoTimecardVirtualEmployees:
        columns = TableBuilder.getSecurity_AutotimecardVirtualEmployees();
        break;
      case TableTypes.PaymentIntegrations_VisaChoiceApplications:
        columns =
          TableBuilder.getPaymentIntegrations_VisaChoiceApplicationsList();
        break;
      case TableTypes.PaymentIntegrations_VisaChoiceApplicationOwners:
        columns = TableBuilder.getPaymentIntegrations_VisaChoiceOwners();
        break;
      case TableTypes.PaymentIntegrations_VisaChoiceApplicationAdditionalDocs:
        columns =
          TableBuilder.getPaymentIntegrations_VisaChoiceAdditionalDocuments();
        break;
      case TableTypes.TabapayReconciliationReport:
        columns = TableBuilder.getTabapayReconciliationReport();
        break;
      case TableTypes.TabapayReconciliationReport_Details:
        columns = TableBuilder.getTabapayReconciliationReport_Details();
        break;
      case TableTypes.Quickbooks_Overview:
        columns = TableBuilder.getQuickbooks_Overview();
        break;
      case TableTypes.Quickbooks_Details:
        columns = TableBuilder.getQuickbooks_Details();
        break;
      case TableTypes.Dashboard_CheckAdjustments:
        columns = TableBuilder.getDashboard_CheckAdjustments();
        break;
      case TableTypes.Dashboard_CheckAdjustmentsUpdated:
        columns = TableBuilder.getDashboard_CheckAdjustmentsUpdated();
        break;
      case TableTypes.CompanyGroup_Overview:
        columns = TableBuilder.getCompanyGroups_Overview();
        break;
      case TableTypes.Security_AutoTransfer:
        columns = TableBuilder.getAutoTransferConfigOverview();
        break;
      case TableTypes.Reporting_Analytics:
        columns = TableBuilder.getReportingAnalyticCheck();
        break;
      case TableTypes.Reporting_AnalyticsDetails:
        columns = TableBuilder.getReportingAnalyticDetailsCheck();
        break;
      case TableTypes.Reporting_AnalyticsRowDetails:
        columns = TableBuilder.getReportingAnalyticRowDetailsCheck();
        break;
      case TableTypes.DwollaBenefitialOwnersList:
        columns = TableBuilder.getDwollaBenefitialOwnersList();
        break;
      case TableTypes.DwollaCustomerDocuments:
        columns = TableBuilder.getDwollaCustomerDocuments();
        break;
      case TableTypes.DwollaFundingSources:
        columns = TableBuilder.getDwollaFundingSources();
        break;
      case TableTypes.TabapayReconciliation_Import:
        columns = TableBuilder.getTabapayReconciliationImport();
        break;
      case TableTypes.TabapayReconciliation_ProccessedImport:
        columns = TableBuilder.getTabapayReconciliationImportProccessed();
        break;
      case TableTypes.TabapayReconciliation_BankStatements:
        columns = TableBuilder.getTabapayReconciliationBankStatements();
        break;
      case TableTypes.TabapayReconciledTransactions:
        columns = TableBuilder.getTabapayReconciledTransactions();
        break;
      case TableTypes.TabapayReconciledTransactionsByCustomer:
        columns = TableBuilder.getTabapayReconciledTransactionsByCustomer();
        break;
      case TableTypes.TabapayUnreconciledTransactions:
        columns = TableBuilder.getTabapayUnreconciledTransactions();
        break;
      case TableTypes.DwollaFundingSourcesLocations:
        columns = TableBuilder.getDwollaFundingSourceLocations();
        break;
      case TableTypes.CompanyGroup_CustomerAdditionalParams:
        columns = TableBuilder.getCompanyGroup_CustomerAdditionalParams();
        break;
      case TableTypes.VisaChoiceApplications:
        columns = TableBuilder.getVisaChoiceApplications();
        break;
      case TableTypes.VisaChoiceApplications_CustomerMapping:
        columns = TableBuilder.getVisaChoiceApplications_CustomerMapping();
        break;
      case TableTypes.PlaidOriginators:
        columns = TableBuilder.getPlaidOriginators();
        break;
      case TableTypes.PlaidFundingSources:
        columns = TableBuilder.getPlaidFundingSources();
        break;
      case TableTypes.PlaidFundingSourceMappings:
        columns = TableBuilder.getPlaidFundingSourceMappings();
        break;
      case TableTypes.Reporting_DataExport:
        columns = TableBuilder.getReportDataExport();
        break;
      case TableTypes.Wizard_DataExport:
        columns = TableBuilder.getWizardDataExport();
        break;
      case TableTypes.Wizard_DataExport_Connector:
        columns = TableBuilder.getWizardDataExportConnector();
        break;
      case TableTypes.Wizard_DataExport_Log:
        columns = TableBuilder.getDataExportLog();
        break;
      case TableTypes.Security_Vendor:
        columns = TableBuilder.getVendor();
        break;
      case TableTypes.Security_Vendor_Credentials:
        columns = TableBuilder.getVendorCredentials();
        break;
      case TableTypes.Security_Vendor_Credentials_Scopes:
        columns = TableBuilder.getVendorCredentialsScopes();
        break;
      case TableTypes.EmployeeProfile_DataExportRestrictions:
        columns = TableBuilder.getEmployeeProfile_DataExportRestrictions();
        break;
      case TableTypes.PaymentIntegrations_PlaidOriginators:
        columns = TableBuilder.getPaymentIntegrations_PlaidOriginators();
        break;
      case TableTypes.PaymentIntegrations_PlaidDebitTransactions:
        columns = TableBuilder.getPaymentIntegrations_PlaidDebitTransactions();
        break;
      case TableTypes.PaymentIntegrations_PlaidCreditTransactions:
        columns = TableBuilder.getPaymentIntegrations_PlaidCreditTransactions();
        break;
      case TableTypes.LegalDocuments:
        columns = TableBuilder.getLegalDocuments();
        break;
      case TableTypes.WorkflowsExecutionPlan:
        columns = TableBuilder.getWorkflowsExecutionPlan();
        break;
      case TableTypes.WorkflowsExecutionLog:
        columns = TableBuilder.getWorkflowsExecutionLog();
        break;
      case TableTypes.WorkflowsTaskExecutionLog:
        columns = TableBuilder.getWorkflowsTaskExecutionLog();
        break;
      case TableTypes.Workflow_Definitions:
        columns = TableBuilder.getWorkflowDefinitions();
        break;
      case TableTypes.Workflow_Definitions_Configuration:
        columns = TableBuilder.getWorkflowDefinitionsConfiguration();
        break;
      case TableTypes.Workflow_Definitions_Tasks:
        columns = TableBuilder.getWorkflowDefinitionsTasks();
        break;
      case TableTypes.PayCardNetworks:
        columns = TableBuilder.getPaycardNetworks();
        break;
      case TableTypes.Billing:
        columns = TableBuilder.getBilling();
        break;
      case TableTypes.WorkflowTaskLogDetails:
        columns = TableBuilder.getWorkflowsTaskLogDetails();
        break;
    }

    return columns;
  }

  private static getCustomerList() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'Code',
      'customerList.Code',
      ColumnTypes.Text,
      true,
      'startsWith',
      true,
      90
    );
    c1.align = 'left';
    c1.maxWidthPX = 90;
    c1.width = 90;

    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Name',
      'customerList.Name',
      ColumnTypes.Text,
      true,
      'contains',
      true,
      null,
      true
    );
    c2.align = 'left';
    columns.push(c2);

    let codeNameColumn: TableColumn = new TableColumn(
      'Group',
      'customerList.Group',
      ColumnTypes.Text,
      true,
      'contains',
      true,
      null,
      true
    );
    codeNameColumn.align = 'left';
    columns.push(codeNameColumn);

    let c13: TableColumn = new TableColumn(
      'POS',
      'customerList.POS',
      ColumnTypes.TextWithDropFilter,
      true,
      'equals',
      true,
      120,
      null,
      true,
      null,
      this.GetOptions()
    );
    c13.filterPlaceholder = '...';
    c13.showFilterClear = true;
    c13.align = 'left';
    c13.maxWidthPX = 120;
    columns.push(c13);

    let c3: TableColumn = new TableColumn(
      'TrialEnds',
      'customerList.TrialEnds',
      ColumnTypes.Date,
      true,
      'startsWith',
      true,
      100
    );
    c3.align = 'left';
    c3.maxWidthPX = 100;
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'CustomerCreated',
      'customerList.CustomerCreated',
      ColumnTypes.Date,
      true,
      null,
      true,
      100
    );
    c4.align = 'left';
    c4.maxWidthPX = 100;
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'WizardProgress',
      'customerList.WizardProgress',
      ColumnTypes.ProgressFraction,
      true,
      null,
      true,
      80,
      null,
      true
    );
    c5.maxWidthPX = 80;
    columns.push(c5);

    let c7: TableColumn = new TableColumn(
      'WizardFinished',
      'customerList.Finished',
      ColumnTypes.BoolSwitch,
      true,
      null,
      true,
      90,
      null,
      null,
      null
    );
    c7.maxWidthPX = 90;
    columns.push(c7);

    let c14: TableColumn = new TableColumn(
      'Send',
      'customerList.WelcomeKit',
      ColumnTypes.EditAction,
      false,
      null,
      false,
      60,
      null,
      false,
      'fas fa-paper-plane'
    );
    c14.tooltip = 'Send';
    c14.maxWidthPX = 60;
    c14.width = 60;
    columns.push(c14);

    let c8: TableColumn = new TableColumn(
      'SurveyProgress',
      'customerList.SurveyProgress',
      ColumnTypes.ProgressFractionWithAction,
      null,
      null,
      true,
      90,
      null,
      true,
      'fas fa-download'
    );
    c8.maxWidthPX = 90;
    columns.push(c8);

    let c9: TableColumn = new TableColumn(
      'SurveyActive',
      'customerList.SurveyActive',
      ColumnTypes.BoolSwitch,
      null,
      null,
      true,
      90,
      null,
      false,
      null
    );
    c9.maxWidthPX = 90;
    columns.push(c9);

    let c10: TableColumn = new TableColumn(
      'RollOut',
      'customerList.RolledOut',
      ColumnTypes.BoolTrafficlightYesNo,
      null,
      null,
      true,
      90
    );
    c10.maxWidthPX = 90;
    columns.push(c10);
    c10.align = 'center';

    let c11: TableColumn = new TableColumn(
      'Active',
      'customerList.Active',
      ColumnTypes.BoolSwitch,
      true,
      null,
      true,
      90,
      null,
      false
    );
    c11.maxWidthPX = 90;
    columns.push(c11);

    let c111: TableColumn = new TableColumn(
      'PETemplateValidationStatus',
      'customerList.PETemplateValidationStatus',
      ColumnTypes.IconSetDynamic,
      null,
      null,
      true,
      90
    );
    c111.maxWidthPX = 100;
    columns.push(c111);
    c111.align = 'center';

    let c12: TableColumn = new TableColumn(
      'Edit',
      'customerList.Edit',
      ColumnTypes.EditAction,
      false,
      null,
      false,
      60,
      null,
      false,
      'fas fa-edit'
    );
    c12.maxWidthPX = 60;
    c12.width = 60;
    c12.align = 'center';
    columns.push(c12);

    let c15: TableColumn = new TableColumn(
      'CreateNewCustomerFrom',
      'customerList.CreateCopy',
      ColumnTypes.EditAction,
      false,
      null,
      false,
      60,
      null,
      false,
      'fas fa-copy'
    );
    c15.tooltip = 'Create new customer from this one...';
    c15.align = 'center';
    c15.maxWidthPX = 60;
    c15.width = 60;
    columns.push(c15);

    return columns;
  }

  private static getBusinessLocalizationEveryDay() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'ID',
      'businessLocalization.ShiftID',
      ColumnTypes.Text,
      null,
      null,
      null,
      50
    );
    c1.align = 'left';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Name',
      'businessLocalization.ShiftName',
      ColumnTypes.TextInput,
      null,
      null,
      null,
      360
    );
    c2.align = 'left';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'StartTime',
      'businessLocalization.ShiftStartTime',
      ColumnTypes.TimeWithSpinners,
      null,
      null,
      null,
      140
    );
    c3.align = 'left';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'EndTIme',
      'businessLocalization.ShiftEndTime',
      ColumnTypes.TimeWithSpinners,
      null,
      null,
      null,
      140
    );
    c4.align = 'left';
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'Overlap',
      'businessLocalization.ShiftOverlap',
      ColumnTypes.Dropdown,
      null,
      null,
      null,
      70
    );
    c5.align = 'left';
    c5.options = [
      { label: '0', value: 0 },
      { label: '15', value: 15 },
      { label: '30', value: 30 },
      { label: '45', value: 45 },
      { label: '60', value: 60 },
      { label: '75', value: 75 },
      { label: '90', value: 90 },
      { label: '105', value: 105 },
      { label: '120', value: 120 },
      { label: '135', value: 135 },
      { label: '150', value: 150 },
      { label: '165', value: 165 },
      { label: '180', value: 180 },
    ];
    columns.push(c5);

    let c7: TableColumn = new TableColumn(
      'Remove',
      'businessLocalization.ShiftRemove',
      ColumnTypes.Checkbox,
      null,
      null,
      null,
      90
    );
    c7.enableReference = 'AllowRemove';
    columns.push(c7);

    return columns;
  }

  private static getBusinessLocalizationByDay() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'ID',
      'businessLocalization.ShiftID',
      ColumnTypes.Text,
      null,
      null,
      null,
      50
    );
    c1.align = 'left';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Name',
      'businessLocalization.ShiftName',
      ColumnTypes.Text,
      null,
      null,
      null,
      360
    );
    c2.align = 'left';
    c2.readonly = true;
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'StartTime',
      'businessLocalization.ShiftStartTime',
      ColumnTypes.TimeWithSpinners,
      null,
      null,
      null,
      140
    );
    c3.align = 'left';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'EndTIme',
      'businessLocalization.ShiftEndTime',
      ColumnTypes.TimeWithSpinners,
      null,
      null,
      null,
      140
    );
    c4.align = 'left';
    columns.push(c4);

    return columns;
  }

  private static getDataSourceConfiguration() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'SyncTimeType',
      'datasourceConfiguration.SyncTimeType',
      ColumnTypes.Text
    );
    c1.minWidthPX = 100;
    c1.align = 'left';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'SyncTimeLocal',
      'datasourceConfiguration.SyncTimeLocal',
      ColumnTypes.Text
    );
    c2.minWidthPX = 100;
    c2.align = 'left';
    columns.push(c2);

    let c21: TableColumn = new TableColumn(
      'SpecialDay',
      'datasourceConfiguration.SpecialDay',
      ColumnTypes.Text
    );
    c21.minWidthPX = 150;
    c21.align = 'left';
    columns.push(c21);

    let c22: TableColumn = new TableColumn(
      'ShiftOriginalID',
      'datasourceConfiguration.ShiftID',
      ColumnTypes.Text
    );
    c22.minWidthPX = 100;
    c22.align = 'left';
    columns.push(c22);

    let c3: TableColumn = new TableColumn(
      '',
      'Delete',
      ColumnTypes.DeleteAction,
      null,
      null,
      null,
      null,
      null,
      false,
      'fas fa-times'
    );
    //columns.push(c3);

    return columns;
  }

  private static getTenderMapping() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'Name',
      'tenderMapping.posMapping',
      ColumnTypes.Text
    );
    c1.align = 'left';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'TypeValue',
      'tenderMapping.gratShare',
      ColumnTypes.Dropdown
    );
    c2.align = 'left';
    c2.showNull = true;
    columns.push(c2);

    return columns;
  }

  private static getJobConfiguration() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'Name',
      'jobConfiguration.job',
      ColumnTypes.Text
    );
    c1.align = 'left';
    c1.minWidthPX = 160;
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'DepartmentId',
      'jobConfiguration.tempDept',
      ColumnTypes.Text
    );
    c2.align = 'left';
    c2.minWidthPX = 100;
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'SoftShift',
      'jobConfiguration.softShift',
      ColumnTypes.Dropdown
    );
    c3.align = 'left';
    c3.showNull = true;
    c3.minWidthPX = 100;
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'GenerateSales',
      'jobConfiguration.generateSales',
      ColumnTypes.BoolSwitch
    );
    c4.minWidthPX = 70;
    c4.align = 'center';
    columns.push(c4);

    let c16: TableColumn = new TableColumn(
      'CashTipsAssumption',
      'jobConfiguration.cashTipsAssumption',
      ColumnTypes.BoolSwitch
    );
    c16.minWidthPX = 70;
    c16.align = 'center';
    columns.push(c16);

    let c5: TableColumn = new TableColumn(
      'SelfCashOut',
      'jobConfiguration.sefCaheOut',
      ColumnTypes.BoolSwitch
    );
    c5.minWidthPX = 70;
    c5.align = 'center';
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'RetainAdditionalTips',
      'jobConfiguration.retainAdditionalTips',
      ColumnTypes.BoolSwitch
    );
    c6.minWidthPX = 70;
    c6.align = 'center';
    columns.push(c6);

    let c7: TableColumn = new TableColumn(
      'RetainCashTips',
      'jobConfiguration.retainCashTips',
      ColumnTypes.BoolSwitch
    );
    c7.minWidthPX = 70;
    c7.align = 'center';
    columns.push(c7);

    let c8: TableColumn = new TableColumn(
      'IsBanquet',
      'jobConfiguration.isBanquet',
      ColumnTypes.BoolSwitch
    );
    c8.align = 'center';
    c8.minWidthPX = 70;
    columns.push(c8);

    let c9: TableColumn = new TableColumn(
      'TeamNumber',
      'jobConfiguration.teamNo',
      ColumnTypes.Dropdown
    );
    c9.align = 'left';
    c9.showNull = true;
    c9.minWidthPX = 100;
    columns.push(c9);

    let c10: TableColumn = new TableColumn(
      'TeamAssistantID',
      'jobConfiguration.teamAssistant',
      ColumnTypes.Dropdown
    );
    c10.align = 'left';
    c10.minWidthPX = 100;
    c10.showNull = true;
    columns.push(c10);

    let c11: TableColumn = new TableColumn(
      'ChangeNotAllowed',
      'jobConfiguration.changeNotAllowed',
      ColumnTypes.BoolSwitch
    );
    c11.minWidthPX = 70;
    c11.align = 'center';
    columns.push(c11);

    let c12: TableColumn = new TableColumn(
      'MaxDailyWorkHours',
      'jobConfiguration.maxDailyWorkingHours',
      ColumnTypes.TimeWithSpinners
    );
    c12.align = 'left';
    c12.minutesFormat = true;
    c12.minWidthPX = 160;
    columns.push(c12);

    let c13: TableColumn = new TableColumn(
      'LoadCard',
      'jobConfiguration.loadCard',
      ColumnTypes.BoolSwitch
    );
    c13.minWidthPX = 70;
    c13.align = 'center';
    columns.push(c13);

    let c14: TableColumn = new TableColumn(
      'ChargeFee',
      'jobConfiguration.chargeFee',
      ColumnTypes.BoolSwitch
    );
    c14.minWidthPX = 70;
    c14.align = 'center';
    columns.push(c14);

    let c15: TableColumn = new TableColumn(
      'SalesGroupId',
      'jobConfiguration.salesGroup',
      ColumnTypes.Dropdown
    );
    c15.align = 'left';
    c15.minWidthPX = 160;
    c15.showNull = true;
    columns.push(c15);

    return columns;
  }

  private static getJobElectionJobs() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'Active',
      'jobElection.active',
      ColumnTypes.Checkbox,
      null,
      null,
      true,
      30
    );
    c1.width = 30;
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'JobName',
      'jobElection.jobName',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c2.align = 'left';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'Point',
      'jobElection.points',
      ColumnTypes.Currency,
      null,
      null,
      true,
      70
    );
    c3.align = 'right';
    c3.minFractionDigits = 2;
    c3.maxFractionDigits = 2;
    columns.push(c3);

    return columns;
  }

  private static getDistributions() {
    let columns: TableColumn[] = [];

    //let c7: TableColumn = new TableColumn(null, null, ColumnTypes.ExpandButton, null, null, null, 30);
    //c7.icon = 'fas fa-chevron-circle-right';
    //c7['identity'] = 'RecordID';
    //c7.expand = true;
    //columns.push(c7);

    let c1: TableColumn = new TableColumn(
      'ShiftID',
      'distribution.shifts',
      ColumnTypes.Dropdown,
      null,
      null,
      null
    );
    c1.align = 'left';
    c1.validate = true;
    c1.showNull = true;
    c1.width = 25;
    c1.widthPercentage = true;
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'ContributorGroupID',
      'distribution.contributorGroup',
      ColumnTypes.ItemDropdown,
      null,
      null,
      null
    );
    c2.align = 'left';
    c2.showNull = true;
    c2.validate = true;
    c2.width = 25;
    c2.widthPercentage = true;
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'RecipientGroupID',
      'distribution.receipentGroup',
      ColumnTypes.ItemDropdown,
      null,
      null,
      null
    );
    c3.align = 'left';
    c3.showNull = true;
    c3.validate = true;
    c3.width = 25;
    c3.widthPercentage = true;
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'FixAmount',
      'distribution.amountType',
      ColumnTypes.RadioButton,
      null,
      null,
      null
    );
    c4.width = 150;
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'Amount',
      'distribution.amount',
      ColumnTypes.Currency,
      null,
      null,
      null
    );
    c5.validate = true;
    c5.minFractionDigits = 2;
    c5.maxFractionDigits = 2;
    c5.align = 'right';
    c5.width = 150;
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'CalculationTypeID',
      'distribution.calculationType',
      ColumnTypes.ItemDropdown,
      null,
      null,
      null
    );
    c6.showNull = true;
    c6.validate = true;
    c6.width = 200;
    columns.push(c6);

    let c8: TableColumn = new TableColumn(
      'copy',
      'distribution.copy',
      ColumnTypes.Button,
      null,
      null,
      null,
      60
    );
    c8.icon = 'fas fa-copy';
    columns.push(c8);

    let c9: TableColumn = new TableColumn(
      'delete',
      'distribution.delete',
      ColumnTypes.DeleteAction,
      null,
      null,
      null,
      60
    );
    c9.icon = 'fas fa-times';
    columns.push(c9);

    return columns;
  }

  private static getDistributions_exceptions() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'GroupID',
      'distribution.applyToGroup',
      ColumnTypes.Dropdown,
      null,
      null,
      null,
      150
    );
    c1.align = 'left';
    c1.validate = true;
    c1.showNull = true;
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'NumberOfEmployees',
      'distribution.numberOfEployees',
      ColumnTypes.TextInput,
      null,
      null,
      null
    );
    c2.align = 'left';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'Amount',
      'distribution.amount',
      ColumnTypes.Currency,
      null,
      null,
      null
    );
    c3.align = 'right';
    c3.minFractionDigits = 2;
    c3.maxFractionDigits = 2;
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'delete',
      'distribution.delete',
      ColumnTypes.DeleteAction,
      null,
      null,
      null,
      30
    );
    c4.icon = 'fas fa-times';
    columns.push(c4);

    return columns;
  }

  private static getSecurityFunctions() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'Enabled',
      'security.function.function',
      ColumnTypes.Checkbox,
      null,
      null,
      null,
      350
    );
    c1.label = 'Function.Name';
    c1.align = 'left';
    c1.width = 40;
    c1.widthPercentage = true;
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Description',
      'security.function.description',
      ColumnTypes.Text,
      null,
      null,
      null
    );
    c2.align = 'left';
    columns.push(c2);

    return columns;
  }

  private static getDashboardNotification() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'BusinessDate',
      'dashboard.notification.day',
      ColumnTypes.DateTimeFormat,
      null,
      null,
      null
    );
    c1.align = 'left';
    c1.dateFormat = 'EEEE, MMMM d, y';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Employee_FullName',
      'dashboard.notification.Employee',
      ColumnTypes.Text,
      null,
      null,
      null
    );
    c2.align = 'left';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'ShiftName',
      'dashboard.notification.Shift',
      ColumnTypes.Text,
      null,
      null,
      null
    );
    c3.align = 'left';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'TipsScam',
      'dashboard.notification.alarmType',
      ColumnTypes.Text,
      null,
      null,
      null
    );
    c4.align = 'left';
    c4.class = 'bold';
    c4.trainingVideo = TrainingVideos.CommonAlarms;
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'Amount',
      'dashboard.notification.amount',
      ColumnTypes.Text,
      null,
      null,
      null
    );
    c5.align = 'right';
    c5.class = 'bold';
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'Description',
      'dashboard.notification.alarmDetail',
      ColumnTypes.Text,
      null,
      null,
      null
    );
    c6.align = 'left';
    columns.push(c6);

    let c7: TableColumn = new TableColumn(
      'DateApproved',
      'dashboard.notification.dateApproved',
      ColumnTypes.DateTimeFormat,
      null,
      null,
      null
    );
    c7.align = 'left';
    columns.push(c7);

    let c8: TableColumn = new TableColumn(
      'UserApproved',
      'dashboard.notification.userApproved',
      ColumnTypes.Text,
      null,
      null,
      null
    );
    c8.align = 'left';
    columns.push(c8);

    let c9: TableColumn = new TableColumn(
      'info',
      'dashboard.notification.action',
      ColumnTypes.ButtonText,
      null,
      null,
      null
    );
    c9.align = 'center';
    columns.push(c9);

    return columns;
  }

  private static getDashboardTime_TimeCard() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'Job_ID',
      'dashboard.timecard.position',
      ColumnTypes.Dropdown,
      null,
      null,
      null,
      200
    );
    c1.validate = true;
    c1.showNull = true;
    c1.dropdownSearch = true;
    PageObejctHelper.getDropdownOptions('dashboard_time_timecard_job').then(
      (res) => {
        c1.options = res;
      }
    );
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'ClockIn',
      'dashboard.timecard.clockIn',
      ColumnTypes.DateTime,
      null,
      null,
      null,
      160
    );
    c2.validate = true;
    c2.hoursFormat = 12;
    c2.readonly = false;
    c2.align = 'left';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'ClockOut',
      'dashboard.timecard.clockOut',
      ColumnTypes.DateTime,
      null,
      null,
      null,
      160
    );
    c3.validate = true;
    c3.hoursFormat = 12;
    c3.readonly = false;
    c3.align = 'left';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'RegHours',
      'dashboard.timecard.regHours',
      ColumnTypes.TimeSpan,
      null,
      null,
      null,
      70
    );
    c4.readonly = true;
    c4.align = 'right';
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'OvtHours',
      'dashboard.timecard.ovtHours',
      ColumnTypes.TimeSpan,
      null,
      null,
      null,
      70
    );
    c5.readonly = true;
    c5.align = 'right';
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'DblHours',
      'dashboard.timecard.doubleHours',
      ColumnTypes.TimeSpan,
      null,
      null,
      null,
      70
    );
    c6.readonly = true;
    c6.align = 'right';
    columns.push(c6);

    let c7: TableColumn = new TableColumn(
      'SpcHours',
      'dashboard.timecard.specHours',
      ColumnTypes.TimeSpan,
      null,
      null,
      null,
      70
    );
    c7.readonly = true;
    c7.align = 'right';
    columns.push(c7);

    let c8: TableColumn = new TableColumn(
      'TotalHours',
      'dashboard.timecard.totalHours',
      ColumnTypes.TimeSpan,
      null,
      null,
      null,
      70
    );
    c8.readonly = true;
    c8.align = 'right';
    columns.push(c8);

    let c9: TableColumn = new TableColumn(
      'RegRate',
      'dashboard.timecard.regRate',
      ColumnTypes.Currency,
      null,
      null,
      null,
      60
    );
    c9.readonly = false;
    c9.align = 'right';
    c9.minFractionDigits = 2;
    c9.maxFractionDigits = 2;
    columns.push(c9);

    let c10: TableColumn = new TableColumn(
      'OvtRate',
      'dashboard.timecard.ovtRate',
      ColumnTypes.Currency,
      null,
      null,
      null,
      60
    );
    c10.readonly = false;
    c10.align = 'right';
    c10.minFractionDigits = 2;
    c10.maxFractionDigits = 2;
    columns.push(c10);

    let c91: TableColumn = new TableColumn(
      'DblRate',
      'dashboard.timecard.doubleRate',
      ColumnTypes.Currency,
      null,
      null,
      null,
      60
    );
    c91.readonly = false;
    c91.align = 'right';
    c91.minFractionDigits = 2;
    c91.maxFractionDigits = 2;
    columns.push(c91);

    let c92: TableColumn = new TableColumn(
      'SpcRate',
      'dashboard.timecard.spcRate',
      ColumnTypes.Currency,
      null,
      null,
      null,
      60
    );
    c92.readonly = false;
    c92.align = 'right';
    c92.minFractionDigits = 2;
    c92.maxFractionDigits = 2;
    columns.push(c92);

    let c11: TableColumn = new TableColumn(
      'RegPay',
      'dashboard.timecard.regPay',
      ColumnTypes.Currency,
      null,
      null,
      null,
      70
    );
    c11.readonly = true;
    c11.align = 'right';
    c11.minFractionDigits = 2;
    c11.maxFractionDigits = 2;
    columns.push(c11);

    let c12: TableColumn = new TableColumn(
      'OvtPay',
      'dashboard.timecard.ovtPay',
      ColumnTypes.Currency,
      null,
      null,
      null,
      70
    );
    c12.readonly = true;
    c12.align = 'right';
    c12.minFractionDigits = 2;
    c12.maxFractionDigits = 2;
    columns.push(c12);

    let c13: TableColumn = new TableColumn(
      'DblPay',
      'dashboard.timecard.doublePay',
      ColumnTypes.Currency,
      null,
      null,
      null,
      70
    );
    c13.readonly = true;
    c13.align = 'right';
    c13.minFractionDigits = 2;
    c13.maxFractionDigits = 2;
    columns.push(c13);

    let c14: TableColumn = new TableColumn(
      'SpcPay',
      'dashboard.timecard.specPay',
      ColumnTypes.Currency,
      null,
      null,
      null,
      70
    );
    c14.readonly = true;
    c14.align = 'right';
    c14.minFractionDigits = 2;
    c14.maxFractionDigits = 2;
    columns.push(c14);

    let c15: TableColumn = new TableColumn(
      'TotalPay',
      'dashboard.timecard.totalPay',
      ColumnTypes.Currency,
      null,
      null,
      null,
      70
    );
    c15.readonly = true;
    c15.align = 'right';
    c15.minFractionDigits = 2;
    c15.maxFractionDigits = 2;
    columns.push(c15);

    return columns;
  }

  private static getDashboardTime_TimeAdd() {
    let columns: TableColumn[] = [];

    let c0: TableColumn = new TableColumn(
      'Employee_ID',
      'dashboard.timecard.employee',
      ColumnTypes.TimeEmployeeDropdown,
      null,
      null,
      null,
      320
    );
    c0.validate = true;
    c0.showNull = true;
    c0.dropdownSearch = true;
    PageObejctHelper.getDropdownOptions('dashboard_time_employee').then(
      (res) => {
        c0.options = res;
      }
    );
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'Job_ID',
      'dashboard.timecard.position',
      ColumnTypes.Dropdown,
      null,
      null,
      null,
      220
    );
    c1.validate = true;
    c1.showNull = true;
    c1.dropdownSearch = true;
    PageObejctHelper.getDropdownOptions('dashboard_time_timecard_job').then(
      (res) => {
        c1.options = res;
      }
    );
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'ClockIn',
      'dashboard.timecard.clockIn',
      ColumnTypes.DateTime,
      null,
      null,
      null,
      160
    );
    c2.validate = true;
    c2.readonly = false;
    c2.closeOnSelect = false;
    c2.hoursFormat = 12;
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'ClockOut',
      'dashboard.timecard.clockOut',
      ColumnTypes.DateTime,
      null,
      null,
      null,
      160
    );
    c3.validate = true;
    c3.readonly = false;
    c3.closeOnSelect = false;
    c3.hoursFormat = 12;
    columns.push(c3);

    let c9: TableColumn = new TableColumn(
      'RegRate',
      'dashboard.timecard.regRate',
      ColumnTypes.NumberInput,
      null,
      null,
      null,
      80
    );
    c9.readonly = false;
    c9.align = 'right';
    columns.push(c9);

    let c10: TableColumn = new TableColumn(
      'OvtRate',
      'dashboard.timecard.ovtRate',
      ColumnTypes.NumberInput,
      null,
      null,
      null,
      80
    );
    c10.readonly = false;
    c10.align = 'right';
    columns.push(c10);

    let c91: TableColumn = new TableColumn(
      'DblRate',
      'dashboard.timecard.doubleRate',
      ColumnTypes.NumberInput,
      null,
      null,
      null,
      80
    );
    c91.readonly = false;
    c91.align = 'right';
    columns.push(c91);

    let c92: TableColumn = new TableColumn(
      'SpcRate',
      'dashboard.timecard.spcRate',
      ColumnTypes.NumberInput,
      null,
      null,
      null,
      80
    );
    c92.readonly = false;
    c92.align = 'right';
    columns.push(c92);

    return columns;
  }

  private static getDashboardTime_Breaks() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'IsPayed',
      'dashboard.timecard.paid',
      ColumnTypes.BoolSwitch,
      null,
      null,
      false,
      130,
      null,
      null
    );
    columns.push(c1);

    let c4: TableColumn = new TableColumn(
      'Job_ID',
      'dashboard.timecard.position',
      ColumnTypes.Dropdown,
      null,
      null,
      null,
      220
    );
    c4.validate = true;
    c4.showNull = true;
    c4.dropdownSearch = true;
    PageObejctHelper.getDropdownOptions('dashboard_time_timecard_job').then(
      (res) => {
        c4.options = res;
      }
    );
    columns.push(c4);

    let c2: TableColumn = new TableColumn(
      'StartTime',
      'dashboard.timecard.start',
      ColumnTypes.DateTime,
      null,
      null,
      null,
      160
    );
    c2.validate = true;
    c2.align = 'left';
    c2.hoursFormat = 12;
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'EndTime',
      'dashboard.timecard.end',
      ColumnTypes.DateTime,
      null,
      null,
      null,
      160
    );
    c3.validate = true;
    c3.align = 'left';
    c3.hoursFormat = 12;
    columns.push(c3);

    return columns;
  }

  private static getDashboardTime_Shifts() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'ShiftName',
      'dashboard.timecard.shift',
      ColumnTypes.Text,
      null,
      null,
      null,
      250
    );
    c1.align = 'left';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'JobTitle',
      'dashboard.timecard.position',
      ColumnTypes.Text,
      null,
      null,
      null,
      250
    );
    c2.align = 'left';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'ClockIn',
      'dashboard.timecard.clockIn',
      ColumnTypes.TimeWithSpinners,
      null,
      null,
      null,
      200
    );
    c3.hoursFormat = 12;
    c3.align = 'left';
    c3.showAsString = true;
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'ClockOut',
      'dashboard.timecard.clockOut',
      ColumnTypes.TimeWithSpinners,
      null,
      null,
      null,
      200
    );
    c4.hoursFormat = 12;
    c4.align = 'left';
    c4.showAsString = true;
    columns.push(c4);

    return columns;
  }

  private static getControlPanel_ManagerAccounts() {
    let columns: TableColumn[] = [];

    //let c0: TableColumn = new TableColumn('Customer', 'controlPanel.location', ColumnTypes.CustomerTextMultiSelectFilter, null, null, null, 200);
    //c0.align = 'left';
    //c0.sort = true;
    //columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'Name',
      'controlPanel.name',
      ColumnTypes.Text,
      null,
      null,
      null,
      200
    );
    c1.align = 'left';
    c1.sort = true;
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Phone',
      'controlPanel.phone',
      ColumnTypes.Text,
      null,
      null,
      null,
      100
    );
    c2.align = 'left';
    c2.sort = true;
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'Email',
      'controlPanel.email',
      ColumnTypes.Text,
      null,
      null,
      null,
      200
    );
    c3.align = 'left';
    c3.sort = true;
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'Role',
      'controlPanel.role',
      ColumnTypes.Text,
      null,
      null,
      null,
      250
    );
    c4.align = 'left';
    c4.sort = true;
    c4.trainingVideo = TrainingVideos.SeeManagerRoles;
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'StatusLabel',
      'controlPanel.status',
      ColumnTypes.Text,
      null,
      null,
      null,
      270,
      null,
      true,
      null
      //,[{ label: 'Active or unfinished registration', value: false }
      //  , { label: 'All', value: true }]
    );
    //c5.hideClearFilter = true;
    c5.align = 'left';
    c5.sort = true;
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'Action',
      'controlPanel.action',
      ColumnTypes.EditActionText,
      false,
      null,
      false,
      200,
      null,
      false
    );
    columns.push(c6);

    let c7: TableColumn = new TableColumn(
      'Details',
      'controlPanel.details',
      ColumnTypes.EditAction,
      false,
      null,
      false,
      100,
      null,
      false,
      'fas fa-chart-bar'
    );
    columns.push(c7);

    return columns;
  }

  private static getLoadCardReport_Details() {
    let columns: TableColumn[] = [];

    let c0: TableColumn = new TableColumn(
      'BusinessDate',
      'reporting.loadCard.businessDate',
      ColumnTypes.Date,
      null,
      null,
      null
    );
    c0.align = 'left';
    c0.sort = true;
    c0.minWidthPX = 200;
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'Amount',
      'reporting.loadCard.amount',
      ColumnTypes.Text,
      null,
      null,
      null,
      150
    );
    c1.align = 'right';
    c1.sort = true;
    columns.push(c1);

    return columns;
  }

  private static getCalculatedData_DistributionOverride() {
    let columns: TableColumn[] = [];

    let c0: TableColumn = new TableColumn(
      'Percentage',
      'dashboard.calculatedData.distributionOverride.percentage',
      ColumnTypes.Currency,
      null,
      null,
      null,
      100
    );
    c0.align = 'right';
    c0.minFractionDigits = 2;
    c0.maxFractionDigits = 2;
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'TipPoolGrTo_Name',
      'dashboard.calculatedData.distributionOverride.tipOutTo',
      ColumnTypes.Text,
      null,
      null,
      null
    );
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Rollback',
      'dashboard.calculatedData.distributionOverride.reset',
      ColumnTypes.Checkbox
    );
    c2.enableReference = 'Exception';
    columns.push(c2);

    return columns;
  }

  private static getEmployeeProfile_ProfileMappings() {
    let columns: TableColumn[] = [];

    let c0: TableColumn = new TableColumn(
      'Title',
      '',
      ColumnTypes.Text,
      null,
      null,
      null
    );
    c0.align = 'left';
    c0.minWidthPX = 200;
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'Value',
      '',
      ColumnTypes.Text,
      null,
      null,
      null
    );
    c1.align = 'left';
    c1.minWidthPX = 200;
    columns.push(c1);

    return columns;
  }

  private static getEmployeeProfile_ProfileDetails() {
    let columns: TableColumn[] = [];

    let c0: TableColumn = new TableColumn(
      'TitleValue',
      '',
      ColumnTypes.Text,
      null,
      null,
      null
    );
    c0.align = 'left';
    c0.minWidthPX = 200;
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'Value',
      '',
      ColumnTypes.Text,
      null,
      null,
      null
    );
    c1.align = 'left';
    c1.minWidthPX = 200;
    columns.push(c1);

    return columns;
  }

  private static getDashboardPayrollPeriods() {
    let columns: TableColumn[] = [];

    let c0: TableColumn = new TableColumn(
      'DateFrom',
      'dashboard.payroll.period.dateFrom',
      ColumnTypes.Date,
      null,
      null,
      null,
      100
    );
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'DateTo',
      'dashboard.payroll.period.dateTo',
      ColumnTypes.Date,
      null,
      null,
      null,
      100
    );
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Status',
      'dashboard.payroll.period.status',
      ColumnTypes.Text,
      null,
      null,
      null
    );
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'Lock',
      '',
      ColumnTypes.ButtonText,
      null,
      null,
      null,
      60
    );
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'Delete',
      '',
      ColumnTypes.ButtonText,
      null,
      null,
      null,
      60
    );
    columns.push(c4);

    return columns;
  }

  private static getControlPanel_EmployeeMapping() {
    let columns: TableColumn[] = [];

    let c3: TableColumn = new TableColumn(
      'PayrollId',
      'controlPanel.employeeMapping.payroll.PayrollId',
      ColumnTypes.Text,
      null,
      null,
      null,
      150
    );
    c3.align = 'left';
    columns.push(c3);

    let c0: TableColumn = new TableColumn(
      'PosEmployeeName',
      'controlPanel.employeeMapping.payroll.posEmploye',
      ColumnTypes.Text,
      null,
      null,
      null,
      300
    );
    c0.align = 'left';
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'MappedWorkerId',
      'controlPanel.employeeMapping.payroll.payrollEmployee',
      ColumnTypes.Dropdown,
      null,
      null,
      null,
      300
    );
    c1.showNull = true;
    c1.dropdownSearch = true;
    c1['virtualScroll'] = true;
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Ignore',
      'controlPanel.employeeMapping.payroll.ignore',
      ColumnTypes.Checkbox,
      null,
      null,
      null,
      100
    );
    columns.push(c2);

    return columns;
  }

  private static getControlPanel_EmployeeETimeMapping() {
    let columns: TableColumn[] = [];

    let c0: TableColumn = new TableColumn(
      'PosEmployeeName',
      'controlPanel.employeeMapping.payroll.posEmploye',
      ColumnTypes.Text,
      null,
      null,
      null,
      150
    );
    c0.align = 'left';
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'MappedWorkerId',
      'controlPanel.employeeMapping.payroll.payrollEmployee',
      ColumnTypes.Dropdown,
      null,
      null,
      null,
      300
    );
    c1.showNull = true;
    c1.dropdownSearch = true;
    c1['virtualScroll'] = true;
    columns.push(c1);

    return columns;
  }

  private static getControlPanel_JobPayrollMapping() {
    let columns: TableColumn[] = [];

    let c0: TableColumn = new TableColumn(
      'PosJobTitle',
      'controlPanel.jobMapping.payroll.posJob',
      ColumnTypes.Text,
      null,
      null,
      null,
      300
    );
    c0.align = 'left';
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'MappedJobId',
      'controlPanel.jobMapping.payroll.payrollJob',
      ColumnTypes.Dropdown,
      null,
      null,
      null,
      300
    );
    c1.showNull = true;
    c1.dropdownSearch = true;
    c1['virtualScroll'] = true;
    columns.push(c1);

    return columns;
  }

  private static getControlPanel_JobETimeMapping() {
    let columns: TableColumn[] = [];

    let c0: TableColumn = new TableColumn(
      'PosJobTitle',
      'controlPanel.jobMapping.payroll.posJob',
      ColumnTypes.Text,
      null,
      null,
      null,
      300
    );
    c0.align = 'left';
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'MappedJobId',
      'controlPanel.jobMapping.payroll.payrollJob',
      ColumnTypes.Dropdown,
      null,
      null,
      null,
      300
    );
    c1.showNull = true;
    c1.dropdownSearch = true;
    c1['virtualScroll'] = true;
    columns.push(c1);

    return columns;
  }

  private static getControlPanel_Withhold() {
    let columns: TableColumn[] = [];

    let c0: TableColumn = new TableColumn(
      'Employee_ID',
      'controlPanel.withhold.id',
      ColumnTypes.Text,
      null,
      null,
      null,
      150
    );
    c0.align = 'left';
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'Employee_FullName',
      'controlPanel.withhold.name',
      ColumnTypes.Text,
      null,
      null,
      true,
      250
    );
    c1.align = 'left';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Withhold_MonthTotal',
      'controlPanel.withhold.withholdPerMonth',
      ColumnTypes.Currency,
      null,
      null,
      true,
      150
    );
    c2.align = 'right';
    c2.maxFractionDigits = 2;
    c2.minFractionDigits = 2;
    c2.readonly = true;
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'Withhold_RatePerDayTotal',
      'controlPanel.withhold.ratePerDay',
      ColumnTypes.Currency,
      null,
      null,
      true,
      150
    );
    c3.align = 'right';
    c3.maxFractionDigits = 2;
    c3.minFractionDigits = 2;
    c3.readonly = true;
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'Withhold_StatusFlag',
      'controlPanel.withhold.status',
      ColumnTypes.IconSet,
      null,
      null,
      true,
      100
    );
    c4.iconValueSet.push({
      value: 0,
      icon: 'fas fa-stop-circle fa-2x',
      color: 'crimson',
    });
    c4.iconValueSet.push({
      value: 1,
      icon: 'fas fa-exclamation-triangle fa-2x',
      color: 'darkorange',
    });
    c4.iconValueSet.push({
      value: 2,
      icon: 'fas fa-play-circle fa-2x',
      color: 'var(--text-nav-color-selected: #1AAE61)',
    });
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'Withhold_StatusDescription',
      'controlPanel.withhold.statusDescription',
      ColumnTypes.Text,
      null,
      null,
      true,
      200
    );
    c5.align = 'left';
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'More',
      'general.details',
      ColumnTypes.EditAction,
      false,
      null,
      false,
      100,
      null,
      false,
      'fas fa-cogs'
    );
    columns.push(c6);

    return columns;
  }

  private static getControlPanel_WithholdConfiguration() {
    let columns: TableColumn[] = [];

    let c0: TableColumn = new TableColumn(
      'WithholdType_PK',
      'controlPanel.withhold.type',
      ColumnTypes.Dropdown,
      null,
      null,
      null,
      250
    );
    c0.align = 'left';
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'Withhold_Amount',
      'controlPanel.withhold.amount',
      ColumnTypes.Currency,
      null,
      null,
      true,
      150
    );
    c1.align = 'right';
    c1.maxFractionDigits = 2;
    c1.minFractionDigits = 2;
    c1.validate = true;
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Withhold_Rate',
      'controlPanel.withhold.rate',
      ColumnTypes.Currency,
      null,
      null,
      true,
      150
    );
    c2.align = 'right';
    c2.maxFractionDigits = 2;
    c2.minFractionDigits = 2;
    c2.validate = true;
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'DateFrom',
      'controlPanel.withhold.activateFrom',
      ColumnTypes.DateTime,
      null,
      null,
      true,
      100
    );
    c3.align = 'left';
    c3.dateOnly = true;
    c3.validate = true;
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'Dateto',
      'controlPanel.withhold.stopOn',
      ColumnTypes.DateTime,
      null,
      null,
      true,
      100
    );
    c4.align = 'left';
    c4.dateOnly = true;
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'Withhold_StatusDescription',
      'controlPanel.withhold.status',
      ColumnTypes.IconSet,
      null,
      null,
      true,
      100
    );
    c5.iconValueSet.push({
      value: 'Stopped',
      icon: 'fas fa-stop-circle fa-2x',
      color: 'crimson',
    });
    c5.iconValueSet.push({
      value: 'Running',
      icon: 'fas fa-play-circle fa-2x',
      color: 'var(--text-nav-color-selected)',
    });
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'CanDelete',
      '',
      ColumnTypes.BoolShowAction,
      null,
      null,
      null,
      60
    );
    c6.icon = 'fas fa-times';
    c6.color = 'crimson';
    columns.push(c6);

    return columns;
  }

  private static getDashboard_posDistribution() {
    let columns: TableColumn[] = [];

    let c0: TableColumn = new TableColumn(
      'PG_From',
      'dashboard.pos.distribution.tipOutFrom',
      ColumnTypes.Text,
      null,
      null,
      null,
      150
    );
    c0.align = 'left';
    c0.width = 250;
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'PG_To',
      'dashboard.pos.distribution.tipOutTo',
      ColumnTypes.Text,
      null,
      null,
      null,
      150
    );
    c1.width = 250;
    c1.align = 'left';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Percentage',
      'dashboard.pos.distribution.perOrAmount',
      ColumnTypes.Text,
      null,
      null,
      null
    );
    c2.width = 50;
    c2.align = 'center';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'PercentageFrom',
      'dashboard.pos.distribution.percentageFrom',
      ColumnTypes.Text,
      null,
      null,
      null
    );
    c3.width = 150;
    c3.align = 'center';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'PG_To_Split',
      'dashboard.pos.distribution.recPoolGroup',
      ColumnTypes.Text,
      null,
      null,
      null,
      150
    );
    c4.width = 150;
    c4.align = 'center';
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'CurrentTipOut',
      'dashboard.pos.distribution.currentTipOut',
      ColumnTypes.Text,
      null,
      null,
      null
    );
    c5.align = 'center';
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'TipAmount',
      'dashboard.pos.distribution.tipAmount',
      ColumnTypes.NumberInput,
      null,
      null,
      null
    );
    c6.align = 'right';
    columns.push(c6);

    let c7: TableColumn = new TableColumn(
      'TipAmountIsPercentage',
      'dashboard.pos.distribution.tipAmountIsPercentage',
      ColumnTypes.Checkbox,
      null,
      null,
      null
    );
    columns.push(c7);

    let c8: TableColumn = new TableColumn(
      'Rollback',
      'dashboard.pos.distribution.undo',
      ColumnTypes.Checkbox,
      null,
      null,
      null
    );
    columns.push(c8);

    return columns;
  }

  private static getDashboard_PayMe() {
    let columns: TableColumn[] = [];

    let c0: TableColumn = new TableColumn(
      'CustomerName',
      'dashboard.payMe.location',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    c0.align = 'left';
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'BusinessDate',
      'dashboard.payMe.date',
      ColumnTypes.Date,
      null,
      null,
      true,
      100
    );
    c1.align = 'left';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'AvailableAmount',
      'dashboard.payMe.availableAmount',
      ColumnTypes.Currency,
      null,
      null,
      true,
      170
    );
    c2.align = 'right';
    c2.maxFractionDigits = 2;
    c2.minFractionDigits = 2;
    c2.validate = true;
    c2.readonly = true;
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'Amount',
      'dashboard.payMe.amountToLoad',
      ColumnTypes.Currency,
      null,
      null,
      true,
      170
    );
    c3.align = 'right';
    c3.maxFractionDigits = 2;
    c3.minFractionDigits = 2;
    c3.validate = true;
    c3.validationReference = 'AvailableAmount';
    c3.validationTypeForReference = ValidationTypes.lessThanOrEqual;
    c3.disabledParameterReference = 'Disabled';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'Selected',
      'dashboard.payMe.load',
      ColumnTypes.BoolSwitch,
      null,
      null,
      true,
      120
    );
    c4.align = 'center';
    columns.push(c4);

    return columns;
  }

  private static getControlPanel_PayMePreferences() {
    let columns: TableColumn[] = [];

    let c0: TableColumn = new TableColumn(
      'CustomerName',
      'controlPanel.payMePreferences.location',
      ColumnTypes.Text,
      null,
      null,
      true,
      250
    );
    c0.align = 'left';
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'Percentage',
      'controlPanel.payMePreferences.loadToCard',
      ColumnTypes.Dropdown,
      null,
      null,
      true,
      150
    );
    c1.align = 'center';
    c1.options = [];
    c1.options.push({ label: '5%', value: 5 });
    c1.options.push({ label: '10%', value: 10 });
    c1.options.push({ label: '15%', value: 15 });
    c1.options.push({ label: '20%', value: 20 });
    c1.options.push({ label: '25%', value: 25 });
    c1.options.push({ label: '30%', value: 30 });
    c1.options.push({ label: '35%', value: 35 });
    c1.options.push({ label: '40%', value: 40 });
    c1.options.push({ label: '45%', value: 45 });
    c1.options.push({ label: '50%', value: 50 });
    c1.options.push({ label: '55%', value: 55 });
    c1.options.push({ label: '60%', value: 60 });
    c1.options.push({ label: '65%', value: 65 });
    c1.options.push({ label: '70%', value: 70 });
    c1.options.push({ label: '75%', value: 75 });
    c1.options.push({ label: '80%', value: 80 });
    c1.options.push({ label: '85%', value: 85 });
    c1.options.push({ label: '90%', value: 90 });
    c1.options.push({ label: '95%', value: 95 });
    c1.options.push({ label: '100%', value: 100 });
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      '',
      '',
      ColumnTypes.Button,
      null,
      null,
      false,
      150
    );
    c2.icon = 'fas fa-save';
    c2.disabledParameterReference = 'Disabled';
    c2.align = 'center';
    columns.push(c2);

    return columns;
  }

  private static getDashboard_MonthlyOverviewTipShare() {
    let columns: TableColumn[] = [];

    let c0: TableColumn = new TableColumn(
      'EmployeeName',
      'dashboard.monthlyOverview.shareWith',
      ColumnTypes.Text,
      null,
      null,
      true,
      250
    );
    c0.align = 'left';
    c0.readonly = true;
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'Amount',
      'dashboard.monthlyOverview.amount',
      ColumnTypes.Currency,
      null,
      null,
      true,
      150
    );
    c1.align = 'right';
    c1.maxFractionDigits = 2;
    c1.minFractionDigits = 2;
    c1.validate = true;
    columns.push(c1);

    return columns;
  }

  private static getDashboard_BulkApproveAndClose() {
    let columns: TableColumn[] = [];

    let c2: TableColumn = new TableColumn(
      'StatusFlag',
      '',
      ColumnTypes.IconSetDynamic,
      null,
      null,
      true,
      100
    );
    c2.iconValueSet.push({
      value: 0,
      icon: 'fas fa-stop-circle fa-2x',
      color: 'crimson',
    });
    c2.iconValueSet.push({
      value: 1,
      icon: 'fas fa-exclamation-triangle fa-2x',
      color: 'darkorange',
    });
    c2.iconValueSet.push({
      value: 2,
      icon: 'fas fa-play-circle fa-2x',
      color: 'var(--text-nav-color-selected: #1AAE61)',
    });
    columns.push(c2);

    let c0: TableColumn = new TableColumn(
      'Date',
      'dashboard.bulkApproveAndClose.date',
      ColumnTypes.Date,
      null,
      null,
      false,
      150
    );
    c0.align = 'left';
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'StatusText',
      'dashboard.bulkApproveAndClose.processingStatus',
      ColumnTypes.Text,
      null,
      null,
      false,
      150
    );
    c1.align = 'left';
    columns.push(c1);

    let c3: TableColumn = new TableColumn(
      'Message',
      'dashboard.bulkApproveAndClose.message',
      ColumnTypes.Text,
      null,
      null,
      false,
      250
    );
    c3.align = 'left';
    columns.push(c3);

    return columns;
  }

  private static getDashboard_TransactionsWithEndDate() {
    let columns: TableColumn[] = [];

    let c0: TableColumn = new TableColumn(
      'repTrans_CustomerName',
      'dashboard.transactionsWithEndDate.customerName',
      ColumnTypes.Text,
      null,
      null,
      false,
      200
    );
    c0.align = 'left';
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'repTrans_BusinessDate',
      'dashboard.transactionsWithEndDate.businessDate',
      ColumnTypes.Date,
      null,
      null,
      false,
      150
    );
    c1.align = 'left';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'repTran_employeeID',
      'dashboard.transactionsWithEndDate.employeeID',
      ColumnTypes.Text,
      null,
      null,
      false,
      150
    );
    c2.align = 'left';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'rep_name',
      'dashboard.transactionsWithEndDate.employeeName',
      ColumnTypes.Text,
      null,
      null,
      false,
      250
    );
    c3.align = 'left';
    columns.push(c3);

    let c31: TableColumn = new TableColumn(
      'repTran_accountNumber',
      'dashboard.transactionsWithEndDate.accountNumber',
      ColumnTypes.Text,
      null,
      null,
      false,
      180
    );
    c31.align = 'right';
    columns.push(c31);

    //let c4: TableColumn = new TableColumn('repTran_shiftname', 'dashboard.transactionsWithEndDate.shift', ColumnTypes.Text, null, null, false, 200);
    //c4.align = 'left';
    //columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'repTrans_cctip',
      'dashboard.transactionsWithEndDate.initialAmount',
      ColumnTypes.Currency,
      null,
      null,
      false,
      120
    );
    c5.align = 'right';
    c5.minFractionDigits = 2;
    c5.maxFractionDigits = 2;
    c5.readonly = true;
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'repTran_availableamount',
      'dashboard.transactionsWithEndDate.usedAmount',
      ColumnTypes.Currency,
      null,
      null,
      false,
      120
    );
    c6.align = 'right';
    c6.minFractionDigits = 2;
    c6.maxFractionDigits = 2;
    c6.readonly = true;
    columns.push(c6);

    let c7: TableColumn = new TableColumn(
      'repTrans_closedate',
      'dashboard.transactionsWithEndDate.closingDate',
      ColumnTypes.Date,
      null,
      null,
      false,
      120
    );
    c7.align = 'right';
    columns.push(c7);

    let c8: TableColumn = new TableColumn(
      'Details',
      'dashboard.transactionsWithEndDate.details_',
      ColumnTypes.EditAction,
      false,
      null,
      false,
      100,
      null,
      false,
      'fas fa-chart-bar'
    );
    columns.push(c8);

    return columns;
  }

  private static getDashboard_TransactionsWithEndDateEdit() {
    let columns: TableColumn[] = [];

    let c0: TableColumn = new TableColumn(
      'repTrans_CustomerName',
      'dashboard.transactionsWithEndDate.customerName',
      ColumnTypes.Text,
      null,
      null,
      false,
      200
    );
    c0.align = 'left';
    c0.readonly = true;
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'repTrans_BusinessDate',
      'dashboard.transactionsWithEndDate.businessDate',
      ColumnTypes.Date,
      null,
      null,
      false,
      150
    );
    c1.align = 'left';
    c1.readonly = true;
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'rep_name',
      'dashboard.transactionsWithEndDate.employeeName',
      ColumnTypes.Text,
      null,
      null,
      false,
      250
    );
    c2.align = 'left';
    c2.readonly = true;
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'repTran_shiftname',
      'dashboard.transactionsWithEndDate.shift',
      ColumnTypes.Text,
      null,
      null,
      false,
      200
    );
    c3.align = 'left';
    c3.readonly = true;
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'repTrans_amount',
      'dashboard.transactionsWithEndDate.initialAmount',
      ColumnTypes.Currency,
      null,
      null,
      false,
      120
    );
    c4.align = 'right';
    c4.minFractionDigits = 2;
    c4.maxFractionDigits = 2;
    c4.validate = true;
    c4.validationReference = 'repTran_availableamount';
    c4.validationTypeForReference = ValidationTypes.greaterThanOrEqual;
    //c4.readonly = true;
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'repTran_availableamount',
      'dashboard.transactionsWithEndDate.usedAmount',
      ColumnTypes.Currency,
      null,
      null,
      false,
      120
    );
    c5.align = 'right';
    c5.minFractionDigits = 2;
    c5.maxFractionDigits = 2;
    c5.readonly = true;
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'repTrans_closedate',
      'dashboard.transactionsWithEndDate.closingDate',
      ColumnTypes.DateTime,
      null,
      null,
      false,
      120
    );
    c6.align = 'right';
    c6.dateOnly = true;
    c6.readonly = false;
    c6.disabled = false;
    c6.closeOnSelect = true;
    let date = new Date();
    c6.minDate = new Date(date.setDate(date.getDate() + 1));
    columns.push(c6);

    return columns;
  }

  private static getSecurityAlarms() {
    let columns: TableColumn[] = [];

    let c0: TableColumn = new TableColumn(
      'DateFrom',
      'security.alarms.validFrom',
      ColumnTypes.DateTime,
      null,
      null,
      null
    );
    c0.align = 'left';
    c0.readonly = true;
    c0.dateOnly = true;
    c0.width = 110;
    c0.dateFormat = 'M d, yy';
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'AlarmType',
      'security.alarms.alarm',
      ColumnTypes.Dropdown,
      null,
      null,
      false,
      100
    );
    c1.align = 'center';
    c1.width = 200;
    c1.showNull = true;
    c1.disabledParameterReference = 'Disabled';
    columns.push(c1);

    let c3: TableColumn = new TableColumn(
      'Description',
      'security.alarms.description',
      ColumnTypes.Text,
      null,
      null,
      false,
      300
    );
    c3.align = 'left';
    c3.class = 'truncate';

    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'Value',
      'security.alarms.value',
      ColumnTypes.NumberInput,
      null,
      null,
      false
    );
    c4.align = 'right';
    c4.width = 80;
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'delete',
      'distribution.delete',
      ColumnTypes.DeleteAction,
      null,
      null,
      null
    );
    c5.icon = 'fas fa-times';
    c5.width = 60;
    columns.push(c5);

    return columns;
  }

  private static getDashboard_TED_PosData() {
    let columns: TableColumn[] = [];
    let c1: TableColumn = new TableColumn(
      'repTrans_jobtitleoriginalid',
      'dashboard.transactionsWithEndDate.details.jobPosition',
      ColumnTypes.Dropdown,
      null,
      null,
      false,
      400
    );
    c1.align = 'left';
    c1.width = 200;
    c1.showNull = true;
    c1.disabledParameterReference = 'JobPositionReadonly';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'repTrans_cctip',
      'dashboard.transactionsWithEndDate.details.cctip',
      ColumnTypes.Currency,
      null,
      null,
      false,
      200
    );
    c2.align = 'right';
    c2.minFractionDigits = 2;
    c2.maxFractionDigits = 2;
    c2.readonly = false;
    c2.disabledParameterReference = 'AmountReadonly';
    c2.validate = true;
    c2.validationRegEx = regExLib.regex_NoZero;
    columns.push(c2);

    let c5: TableColumn = new TableColumn(
      'repTrans_note',
      'dashboard.transactionsWithEndDate.details.note',
      ColumnTypes.Text,
      null,
      null,
      false,
      400
    );
    c5.align = 'left';
    c5.width = 200;

    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'repTrans_changeUser',
      'dashboard.transactionsWithEndDate.details.changeUser',
      ColumnTypes.Text,
      null,
      null,
      false,
      400
    );
    c6.align = 'left';
    c6.width = 200;

    columns.push(c6);

    let c3: TableColumn = new TableColumn(
      '',
      '' /*'dashboard.transactionsWithEndDate.details.delete'*/,
      ColumnTypes.DeleteAction,
      null,
      null,
      null,
      null,
      null,
      false,
      'fas fa-times'
    );
    c3.visibleParameterReference = 'DeleteVisible';
    columns.push(c3);

    return columns;
  }

  private static getDashboard_TED_Transactions() {
    let columns: TableColumn[] = [];
    let c1: TableColumn = new TableColumn(
      'Type',
      'dashboard.transactionsWithEndDate.details.transactionType',
      ColumnTypes.Text,
      null,
      null,
      false,
      180
    );
    c1.align = 'left';
    c1.width = 200;
    c1.showNull = true;
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Amount',
      'dashboard.transactionsWithEndDate.details.amount',
      ColumnTypes.Currency,
      null,
      null,
      false,
      200
    );
    c2.minFractionDigits = 2;
    c2.maxFractionDigits = 2;
    c2.readonly = true;

    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'Description',
      'dashboard.transactionsWithEndDate.details.description',
      ColumnTypes.Text,
      null,
      null,
      false,
      500
    );
    c3.align = 'left';
    columns.push(c3);

    return columns;
  }

  private static getSecurity_userManuals() {
    let columns: TableColumn[] = [];
    let c1: TableColumn = new TableColumn(
      'Name',
      'security.userManual.name',
      ColumnTypes.TextInput,
      null,
      null,
      false,
      180
    );
    c1.align = 'left';
    c1.autoWidth = true;
    c1.showNull = true;
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Menu',
      'security.userManual.menu',
      ColumnTypes.Dropdown,
      null,
      null,
      false,
      200
    );
    c2.showNull = true;
    c2.validate = true;
    c2.width = 140;
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'Page',
      'security.userManual.page',
      ColumnTypes.ItemDropdown,
      null,
      null,
      false,
      200
    );
    c3.showNull = true;
    c3.dropdownSearch = true;
    c3.width = 140;
    c3.validate = true;
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'PageObject',
      'security.userManual.pageObject',
      ColumnTypes.ItemDropdown,
      null,
      null,
      false,
      200
    );
    c4.showNull = true;
    c4.dropdownSearch = true;
    c4.width = 140;
    columns.push(c4);

    let c8: TableColumn = new TableColumn(
      'Visibility',
      'security.userManual.visibility',
      ColumnTypes.Dropdown,
      null,
      null,
      false,
      200
    );
    c8.width = 150;
    columns.push(c8);

    // let c4:TableColumn = new TableColumn('IsDefault','security.userManual.default',ColumnTypes.BoolSwitch,null,null,null,30);
    // c4.align = 'center';
    // c4.width=80;
    // columns.push(c4);

    // let c5:TableColumn = new TableColumn('IsDefaultForCompanyGroup','security.userManual.defaultForCompany',ColumnTypes.BoolSwitch,null,null,null,30);
    // c5.align = 'center';
    // c5.width=120;
    // columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'Customize',
      'security.userManual.customize',
      ColumnTypes.Button,
      null,
      null,
      null,
      30
    );
    c6.align = 'center';
    c6.width = 80;
    c6.icon = 'fas fa-edit';
    columns.push(c6);

    let c7: TableColumn = new TableColumn(
      'Remove',
      'general.remove',
      ColumnTypes.Button,
      null,
      null,
      null,
      30
    );
    c7.align = 'center';
    c7.width = 80;
    c7.icon = 'fas fa-times';
    columns.push(c7);

    return columns;
  }

  private static getSecurity_AutoTimecardJobs() {
    let columns: TableColumn[] = [];
    let c1: TableColumn = new TableColumn(
      'JobName',
      'security.autoTimecardJobs.title',
      ColumnTypes.TextInput,
      null,
      null,
      false,
      180
    );
    c1.align = 'left';
    c1.validate = true;
    //c1.validationTypeForReference = ValidationTypes.uniqueNotEmpty;
    //c1.validationReference = 'JobName';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'OriginalID',
      'security.autoTimecardJobs.id',
      ColumnTypes.Text,
      null,
      null,
      false,
      180
    );
    c2.align = 'left';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'TempDept',
      'security.autoTimecardJobs.tempDept',
      ColumnTypes.TextInput,
      null,
      null,
      false,
      180
    );
    c3.align = 'left';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'MaxWorkTimePerDay',
      'security.autoTimecardJobs.maxWorkTimePerDay',
      ColumnTypes.NumberInput,
      null,
      null,
      false,
      90
    );
    c4.align = 'right';
    c4.width = 90;
    c4.validate = true;
    columns.push(c4);

    return columns;
  }

  private static getSecurity_AutoTimecardRule() {
    let columns: TableColumn[] = [];
    let c1: TableColumn = new TableColumn(
      'JobID',
      'security.autoTimecardRule.job',
      ColumnTypes.Dropdown,
      null,
      null,
      false,
      180
    );
    c1.align = 'left';
    c1.options = [];
    c1.placeholder = '...';
    c1.validateAllowNull = false;
    c1.validate = true;
    c1.showNull = true;
    c1.dropdownSearch = true;
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'EmployeeID',
      'security.autoTimecardRule.employee',
      ColumnTypes.Dropdown,
      null,
      null,
      false,
      180
    );
    c2.align = 'left';
    c2.showNull = true;
    c2.validateAllowNull = false;
    c2.validate = true;
    c2.options = [];
    c2.placeholder = '...';
    c2.validationTypeForReference = ValidationTypes.uniqueNotEmpty;
    c2.validationReference = 'EmployeeID';
    c2.dropdownSearch = true;
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'Delete',
      'Delete',
      ColumnTypes.DeleteAction,
      null,
      null,
      null,
      null,
      null,
      false,
      'fas fa-times'
    );
    columns.push(c3);

    return columns;
  }

  private static getSecurity_AutoTimecardRuleDependencies() {
    let columns: TableColumn[] = [];
    let c1: TableColumn = new TableColumn(
      'JobID',
      'security.autoTimecardRule.job',
      ColumnTypes.Dropdown,
      null,
      null,
      false,
      180
    );
    c1.align = 'left';
    c1.options = [];
    c1.placeholder = '...';
    c1.validateAllowNull = false;
    c1.validate = true;
    c1.showNull = true;
    c1.validationTypeForReference = ValidationTypes.uniqueNotEmpty;
    c1.validationReference = 'JobID';
    columns.push(c1);

    let c3: TableColumn = new TableColumn(
      'Delete',
      'Delete',
      ColumnTypes.DeleteAction,
      null,
      null,
      null,
      null,
      null,
      false,
      'fas fa-times'
    );
    columns.push(c3);

    return columns;
  }

  private static getSecurity_PEExportTemplateColumns() {
    let columns: TableColumn[] = [];
    let c1: TableColumn = new TableColumn(
      'Index',
      'security.peExportTemplate.index',
      ColumnTypes.NumberInput,
      null,
      null
    );
    c1.align = 'right';
    c1.validateAllowNull = false;
    c1.validate = true;
    c1.validationTypeForReference = ValidationTypes.uniqueNotEmpty;
    c1.validationReference = 'Index';
    c1.maxWidthPX = 90;
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Title_UI',
      'security.peExportTemplate.titleUI',
      ColumnTypes.TextInput,
      null,
      null
    );
    c2.align = 'left';
    c2.validateAllowNull = false;
    c2.validate = true;
    c2.validationTypeForReference = ValidationTypes.uniqueNotEmpty;
    c2.validationReference = 'Title_UI';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'Title_Export',
      'security.peExportTemplate.titleExport',
      ColumnTypes.TextInput,
      null,
      null
    );
    c3.align = 'left';
    c3.validateAllowNull = false;
    c3.validate = true;
    c3.validationTypeForReference = ValidationTypes.uniqueNotEmpty;
    c3.validationReference = 'Title_Export';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'Fk_PayrollConfigDataTypeField',
      'security.peExportTemplate.dataTypeField',
      ColumnTypes.Dropdown,
      null,
      null,
      false
    );
    c4.align = 'left';
    c4.options = [];
    c4.placeholder = '...';
    c4.validateAllowNull = false;
    c4.validate = true;
    c4.showNull = true;
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'Lenght',
      'security.peExportTemplate.length',
      ColumnTypes.NumberInput,
      null,
      null
    );
    c5.align = 'right';
    c5.validate = true;
    c5.maxWidthPX = 90;
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'ExcludeFromExport',
      'security.peExportTemplate.excludeFromExport',
      ColumnTypes.BoolSwitch,
      null,
      null
    );
    c6.align = 'center';
    //c6.validate = true;
    columns.push(c6);

    let c7: TableColumn = new TableColumn(
      'Visible',
      'security.peExportTemplate.visible',
      ColumnTypes.BoolSwitch,
      null,
      null
    );
    c7.align = 'center';
    //c6.validate = true;
    columns.push(c7);

    let c9: TableColumn = new TableColumn(
      'RoundToWholeNumber',
      'security.peExportTemplate.roundToWholeNumber',
      ColumnTypes.BoolSwitch,
      null,
      null
    );
    c9.align = 'center';
    c9.disabledParameterReference = 'disabledRoundToWholeNumber';
    columns.push(c9);

    let c10: TableColumn = new TableColumn(
      'DoNotShowZero',
      'security.peExportTemplate.doNotShowZero',
      ColumnTypes.BoolSwitch,
      null,
      null
    );
    c10.align = 'center';
    c10.disabledParameterReference = 'disabledDoNotShowZero';
    columns.push(c10);

    let c11: TableColumn = new TableColumn(
      'ZeroOrEmptyColumnIndexReference',
      'security.peExportTemplate.zeroOrEmptyColumnIndexReference',
      ColumnTypes.NumberInput,
      null,
      null
    );
    c11.validate = true;
    c11.validateAllowNull = true;
    c11.validationTypeForReference =
      ValidationTypes.fromReferenceOrNullExcludingSelf;
    c11.validationReference = 'Index';
    c11.align = 'right';
    c11.maxWidthPX = 90;

    columns.push(c11);

    let c8: TableColumn = new TableColumn(
      'Delete',
      'Delete',
      ColumnTypes.DeleteAction,
      null,
      null,
      null,
      null,
      null,
      false,
      'fas fa-times'
    );
    columns.push(c8);

    return columns;
  }

  static getPayrollExportTemplateCfgFormulasColumns() {
    let columns: TableColumn[] = [];
    let c1: TableColumn = new TableColumn(
      'Code',
      'security.peExportTemplate.code',
      ColumnTypes.Text,
      null,
      null
    );
    c1.align = 'right';
    c1.maxWidthPX = 90;
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Name',
      'security.peExportTemplate.fieldName',
      ColumnTypes.Text,
      null,
      null
    );
    c2.align = 'left';
    c2.maxWidthPX = 300;
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'Formula',
      'security.peExportTemplate.formula',
      ColumnTypes.Text,
      null,
      null
    );
    c3.align = 'left';
    columns.push(c3);

    return columns;
  }

  private static getAdditionalColums(pageObjectCode: string) {
    let tableColumns: TableColumn[] = [];
    switch (pageObjectCode) {
      case 'dashtab_transaction':
        tableColumns.push(
          new TableColumn(
            'More',
            'general.seeMore',
            ColumnTypes.EditAction,
            false,
            null,
            false,
            100,
            null,
            false,
            'fas fa-chart-bar'
          )
        );

        break;
      case 'dashtab_time':
        tableColumns.push(
          new TableColumn(
            'More',
            'general.details',
            ColumnTypes.EditAction,
            false,
            null,
            false,
            100,
            null,
            false,
            'fas fa-chart-bar'
          )
        );
        break;
      case 'dashtab_breakdown':
        tableColumns.push(
          new TableColumn(
            'More',
            'general.seeMore',
            ColumnTypes.EditAction,
            false,
            null,
            false,
            90,
            null,
            false,
            'fas fa-chart-bar'
          )
        );
        tableColumns.push(
          new TableColumn(
            'Details',
            'general.details',
            ColumnTypes.EditAction,
            false,
            null,
            false,
            75,
            null,
            false,
            'fas fa-info-circle'
          )
        );
        break;

      case 'dashtab_commission':
        tableColumns.push(
          new TableColumn(
            'More',
            'general.seeMore',
            ColumnTypes.EditAction,
            false,
            null,
            false,
            100,
            null,
            false,
            'fas fa-chart-bar'
          )
        );
        break;
      case 'set_EmplProfile':
        tableColumns.push(
          new TableColumn(
            'More',
            'general.details',
            ColumnTypes.EditAction,
            false,
            null,
            false,
            100,
            null,
            false,
            'fas fa-cogs'
          )
        );

        let c1: TableColumn = new TableColumn(
          'RemoveAdd',
          'general.addremove',
          ColumnTypes.ButtonText,
          false,
          null,
          false,
          100,
          null,
          false
        );
        c1.selectRow = true;
        c1.label = 'add';
        c1.align = 'center';
        tableColumns.push(c1);

        break;
      default:
        break;
    }
    return tableColumns;
  }

  private static getPayrollExportManualChanges() {
    let columns: TableColumn[] = [];
    let c1: TableColumn = new TableColumn(
      'Column',
      'dashboard.payroll.manualChange.column',
      ColumnTypes.Text,
      null,
      null,
      false
    );
    c1.align = 'left';
    c1.showNull = true;
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'OldValue',
      'dashboard.payroll.manualChange.oldValue',
      ColumnTypes.Text,
      null,
      null,
      false
    );
    c2.align = 'center';
    c2.showNull = true;
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'NewValue',
      'dashboard.payroll.manualChange.newValue',
      ColumnTypes.Text,
      null,
      null,
      false
    );
    c3.align = 'center';
    c3.showNull = true;
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'DateOfChange',
      'dashboard.payroll.manualChange.date',
      ColumnTypes.DateTimeString,
      null,
      null,
      false
    );
    c4.align = 'center';
    c4.showNull = true;
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'User',
      'dashboard.payroll.manualChange.user',
      ColumnTypes.Text,
      null,
      null,
      false
    );
    c5.align = 'center';
    c5.showNull = true;
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'Note',
      'dashboard.payroll.manualChange.note',
      ColumnTypes.Text,
      null,
      null,
      false
    );
    c6.align = 'center';
    c6.showNull = true;
    columns.push(c6);

    return columns;
  }

  private static getControlPanelTimeAndAttendance() {
    let columns: TableColumn[] = [];
    let c1: TableColumn = new TableColumn(
      'JobTitle',
      'controlPanel.wageCalculation.job',
      ColumnTypes.Text,
      null,
      null,
      false
    );
    c1.align = 'left';
    c1.showNull = true;
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'TempDept',
      'controlPanel.wageCalculation.tempDept',
      ColumnTypes.Text,
      null,
      null,
      false
    );
    c2.align = 'center';
    c2.showNull = true;
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'RegularRate',
      'controlPanel.wageCalculation.baseRate',
      ColumnTypes.NumberInput,
      null,
      null,
      false
    );
    c3.align = 'right';
    c3.showNull = true;
    c3.disabled = false;
    c3.maxWidthPX = 80;
    columns.push(c3);

    return columns;
  }

  private static getPaycard() {
    let columns: TableColumn[] = [];
    let c1: TableColumn = new TableColumn(
      'CardProvider',
      'payCard.cardProvider',
      ColumnTypes.Dropdown,
      null,
      null,
      false
    );
    c1.align = 'left';
    c1.placeholder = '...';
    c1.validateAllowNull = false;
    c1.validate = true;
    c1.showNull = true;
    c1.validationTypeForReference = ValidationTypes.uniqueNotEmpty;
    c1.validationReference = 'CardProvider';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      '',
      'Delete',
      ColumnTypes.DeleteAction,
      null,
      null,
      null,
      null,
      null,
      false,
      'fas fa-times'
    );
    columns.push(c2);

    return columns;
  }

  private static getCommissionSalesCategoryTracks() {
    let columns: TableColumn[] = [];
    let c1: TableColumn = new TableColumn(
      'Track_Number',
      'trackingGroups.trackNumber',
      ColumnTypes.Dropdown,
      null,
      null,
      false
    );
    c1.width = 300;
    c1.dropdownSearch = true;
    c1.align = 'left';
    c1.placeholder = '...';
    c1.validateAllowNull = false;
    c1.validate = true;
    c1.showNull = true;
    c1.validationTypeForReference = ValidationTypes.commissionSalesTrack;
    c1.validationReference = 'TrackType';
    c1.sort = true;
    columns.push(c1);

    let c3: TableColumn = new TableColumn(
      'TrackType',
      'trackingGroups.trackType',
      ColumnTypes.Dropdown,
      null,
      null,
      false
    );
    c3.width = 300;
    //c3.dropdownSearch = true;
    c3.align = 'left';
    c3.placeholder = '...';
    c3.validateAllowNull = false;
    c3.validate = true;
    c3.showNull = true;
    c3.sort = true;
    c3.validationTypeForReference = ValidationTypes.commissionSalesTrack;
    c3.validationReference = 'Track_Number';
    columns.push(c3);

    let c2: TableColumn = new TableColumn(
      'Delete',
      'Delete',
      ColumnTypes.DeleteAction,
      null,
      null,
      null,
      null,
      null,
      false,
      'fas fa-times'
    );
    columns.push(c2);

    return columns;
  }

  private static getSecurity_AutotimecardVirtualEmployees() {
    let columns: TableColumn[] = [];
    let c1: TableColumn = new TableColumn(
      'FirstName',
      'security.autoTimecardVirtualEmployees.firstName',
      ColumnTypes.TextInput,
      null,
      null,
      false,
      180
    );
    c1.align = 'left';
    c1.validate = true;
    //c1.validationTypeForReference = ValidationTypes.uniqueNotEmpty;
    //c1.validationReference = 'JobName';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'LastName',
      'security.autoTimecardVirtualEmployees.lastName',
      ColumnTypes.TextInput,
      null,
      null,
      false,
      180
    );
    c2.align = 'left';
    c2.validate = true;
    //c1.validationTypeForReference = ValidationTypes.uniqueNotEmpty;
    //c1.validationReference = 'JobName';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'OriginalID',
      'security.autoTimecardVirtualEmployees.id',
      ColumnTypes.Text,
      null,
      null,
      false,
      180
    );
    c3.align = 'left';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'Active',
      'security.autoTimecardVirtualEmployees.active',
      ColumnTypes.BoolSwitch,
      null,
      null,
      false,
      90
    );
    c4.align = 'center';
    c4.validate = true;
    columns.push(c4);

    return columns;
  }

  private static getPaymentIntegrations_VisaChoiceApplicationsList() {
    let columns: TableColumn[] = [];
    let c1: TableColumn = new TableColumn(
      'SubclientId',
      'paymentIntegrations.subclientId',
      ColumnTypes.Text,
      null,
      null,
      true,
      120
    );
    columns.push(c1);

    let c4: TableColumn = new TableColumn(
      'Name',
      'paymentIntegrations.name',
      ColumnTypes.Text,
      null,
      null,
      true,
      400
    );
    columns.push(c4);

    let c41: TableColumn = new TableColumn(
      'CompanyCode',
      'Company group code',
      ColumnTypes.Text,
      null,
      null,
      true,
      200
    );
    columns.push(c41);

    let c42: TableColumn = new TableColumn(
      'CompanyGroupName',
      'Company group name',
      ColumnTypes.Text,
      null,
      null,
      true,
      400
    );
    columns.push(c42);

    let c2: TableColumn = new TableColumn(
      'Date',
      'paymentIntegrations.date',
      ColumnTypes.Date,
      null,
      null,
      true,
      150
    );
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'activeStatusTitle',
      'paymentIntegrations.status',
      ColumnTypes.Text,
      null,
      null,
      true,
      200
    );
    columns.push(c3);

    return columns;
  }

  private static getPaymentIntegrations_VisaChoiceOwners() {
    let columns: TableColumn[] = [];
    let c1: TableColumn = new TableColumn(
      'Owner',
      'paymentIntegrations.ownerName',
      ColumnTypes.Text,
      null,
      null,
      true,
      300
    );
    c1.sort = false;
    columns.push(c1);

    let c4: TableColumn = new TableColumn(
      'OwnershipPercentage',
      'paymentIntegrations.ownership',
      ColumnTypes.Text,
      null,
      null,
      true,
      100
    );
    c4.sort = false;
    columns.push(c4);

    let c2: TableColumn = new TableColumn(
      'DOB',
      'paymentIntegrations.dob',
      ColumnTypes.Date,
      null,
      null,
      true,
      100
    );
    c2.sort = false;
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'Address',
      'paymentIntegrations.address',
      ColumnTypes.Text,
      null,
      null,
      true,
      300
    );
    c3.sort = false;
    columns.push(c3);

    let c6: TableColumn = new TableColumn(
      'OwnerSSN',
      'paymentIntegrations.ssn',
      ColumnTypes.Text,
      null,
      null,
      true,
      50
    );
    c6.sort = false;
    columns.push(c6);

    let c5: TableColumn = new TableColumn(
      'Arrests',
      'paymentIntegrations.arrests',
      ColumnTypes.Text,
      null,
      null,
      true,
      50
    );
    c5.sort = false;
    columns.push(c5);

    return columns;
  }

  private static getPaymentIntegrations_VisaChoiceAdditionalDocuments() {
    let columns: TableColumn[] = [];
    let c1: TableColumn = new TableColumn(
      'DocumentTypeName',
      'paymentIntegrations.type',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    c1.sort = false;
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Filename',
      'paymentIntegrations.filename',
      ColumnTypes.Text,
      null,
      null,
      true,
      300
    );
    c2.sort = false;
    columns.push(c2);

    let c4: TableColumn = new TableColumn(
      'Download',
      'general.download',
      ColumnTypes.Button,
      null,
      null,
      true,
      50
    );
    c4.icon = 'fas fa-download';
    c4.sort = false;
    columns.push(c4);

    let c3: TableColumn = new TableColumn(
      'Delete',
      'general.delete',
      ColumnTypes.DeleteAction,
      null,
      null,
      true,
      50
    );
    c3.icon = 'fas fa-times';
    c3.sort = false;
    columns.push(c3);

    return columns;
  }

  public static getTabapayReconciliationReport() {
    let columns: TableColumn[] = [];
    let c0: TableColumn = new TableColumn(
      'CompanyGroup',
      'Company group',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'CustomerName',
      'Location',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'LoadDate',
      'Load date',
      ColumnTypes.Date,
      null,
      null,
      true,
      150
    );
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'BusinessDate',
      'Business date',
      ColumnTypes.Date,
      null,
      null,
      true,
      150
    );
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'EmployeeFullName',
      'Employee',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'Amount',
      'Amount',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'LoadInitiatedAt',
      'Initiated at',
      ColumnTypes.DateTimeFormat,
      null,
      null,
      true,
      150
    );
    c6.dateFormat = 'MM/dd/yyyy HH:mm:ss';
    columns.push(c6);

    let c7: TableColumn = new TableColumn(
      'Status',
      'Status',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c7);

    let c8: TableColumn = new TableColumn(
      'TimeZoneTitle',
      'Time zone',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c8);

    let c9: TableColumn = new TableColumn(
      'tpTabaPayFee',
      'TabaPay fee',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c9);

    let c10: TableColumn = new TableColumn(
      'tpNetworkFee',
      'Network fee',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c10);

    let c11: TableColumn = new TableColumn(
      'tpInterchange',
      'Interchange',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c11);

    let c12: TableColumn = new TableColumn(
      'tpConvenienceFee',
      'Convenience fee',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c12);

    let c13: TableColumn = new TableColumn(
      'tpFilename',
      'Filename',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    c13.visible = true;
    columns.push(c13);

    let c14: TableColumn = new TableColumn(
      'tpReferenceId',
      'ReferenceId',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    c14.visible = true;
    columns.push(c14);

    let c15: TableColumn = new TableColumn(
      'tpTransactionAmount',
      'tp - Transaction amount',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    c15.visible = false;
    columns.push(c15);

    let c16: TableColumn = new TableColumn(
      'tpSettledAmount',
      'Settled amount',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    c16.visible = true;
    columns.push(c16);

    let c17: TableColumn = new TableColumn(
      'TabaPayFee',
      'tp - TabaPay fee',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    c17.visible = false;
    columns.push(c17);

    let c18: TableColumn = new TableColumn(
      'NetworkFee',
      'tp - Network fee',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    c18.visible = false;
    columns.push(c18);

    let c19: TableColumn = new TableColumn(
      'Interchange',
      'tp - Interchange',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    c19.visible = false;
    columns.push(c19);

    let c20: TableColumn = new TableColumn(
      'ConvenienceFee',
      'tp - Convenience fee',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    c20.visible = false;
    columns.push(c20);

    let c21: TableColumn = new TableColumn(
      'SettlementDate',
      'Settlement date',
      ColumnTypes.DateTimeFormat,
      null,
      null,
      true,
      150
    );
    c21.dateFormat = 'MM/dd/yyyy HH:mm:ss';
    columns.push(c21);

    return columns;
  }

  public static getTabapayReconciliationReport_Details() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'tpFilename',
      'reportingadmin.tabapay.filename',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'tpReferenceId',
      'reportingadmin.tabapay.referenceId',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'tpTransactionAmount',
      'reportingadmin.tabapay.transactionAmount',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'tpSettledAmount',
      'reportingadmin.tabapay.settledAmount',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c4);

    let c9: TableColumn = new TableColumn(
      'tpTabaPayFee',
      'reportingadmin.tabapay.tabapayFee',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c9);

    let c10: TableColumn = new TableColumn(
      'tpNetworkFee',
      'reportingadmin.tabapay.networkFee',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c10);

    let c11: TableColumn = new TableColumn(
      'tpInterchange',
      'reportingadmin.tabapay.interchange',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c11);

    let c12: TableColumn = new TableColumn(
      'tpConvenienceFee',
      'reportingadmin.tabapay.convenienceFee',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c12);

    return columns;
  }

  public static getQuickbooks_Overview() {
    let columns: TableColumn[] = [];
    let c0: TableColumn = new TableColumn(
      'CustomerName',
      'quickbooks.customerName',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'CustomerCode',
      'quickbooks.customerCode',
      ColumnTypes.Text,
      null,
      null,
      true,
      100
    );
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'DateFrom',
      'quickbooks.dateFrom',
      ColumnTypes.DateTimeFormat,
      null,
      null,
      true,
      100
    );
    c2.dateFormat = 'MM/dd/yyyy';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'DateTo',
      'quickbooks.dateTo',
      ColumnTypes.DateTimeFormat,
      null,
      null,
      true,
      100
    );
    c3.dateFormat = 'MM/dd/yyyy';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'Price',
      'quickbooks.price',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'PricingCategoryTitle',
      'quickbooks.pricingCategory',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'Description',
      'quickbooks.description',
      ColumnTypes.Text,
      null,
      null,
      true,
      250
    );
    columns.push(c6);

    let c7: TableColumn = new TableColumn(
      'Subtotal',
      'quickbooks.subtotal',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c7);

    let c8: TableColumn = new TableColumn(
      'AdminFee',
      'quickbooks.adminFee',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c8);

    let c9: TableColumn = new TableColumn(
      'OriginalAmount',
      'quickbooks.originalAmount',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c9);

    let c10: TableColumn = new TableColumn(
      'Amount',
      'quickbooks.amount',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c10);

    let c11: TableColumn = new TableColumn(
      'Balance',
      'quickbooks.balance',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c11);

    let c12: TableColumn = new TableColumn(
      'QBInvoiceNumber',
      'quickbooks.qbInvoiceNumber',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c12);

    let c13: TableColumn = new TableColumn(
      'ProcessingStatusTitle',
      'quickbooks.processingStatus',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c13);

    let c14: TableColumn = new TableColumn(
      'Note',
      'quickbooks.note',
      ColumnTypes.Text,
      null,
      null,
      true,
      250
    );
    columns.push(c14);

    let c15: TableColumn = new TableColumn(
      'QBStatusTitle',
      'quickbooks.qbStatus',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c15);

    let c16: TableColumn = new TableColumn(
      'Process',
      'quickbooks.process',
      ColumnTypes.ButtonText,
      null,
      null,
      true,
      100
    );
    columns.push(c16);

    return columns;
  }

  public static getQuickbooks_Details() {
    let columns: TableColumn[] = [];
    let c0: TableColumn = new TableColumn(
      'CustomerName',
      'quickbooks.customerName',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'CustomerCode',
      'quickbooks.customerCode',
      ColumnTypes.Text,
      null,
      null,
      true,
      100
    );
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'DateFrom',
      'quickbooks.dateFrom',
      ColumnTypes.DateTimeFormat,
      null,
      null,
      true,
      100
    );
    c2.dateFormat = 'MM/dd/yyyy';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'DateTo',
      'quickbooks.dateTo',
      ColumnTypes.DateTimeFormat,
      null,
      null,
      true,
      100
    );
    c3.dateFormat = 'MM/dd/yyyy';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'Price',
      'quickbooks.price',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'PricingCategoryTitle',
      'quickbooks.pricingCategory',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'Description',
      'quickbooks.description',
      ColumnTypes.Text,
      null,
      null,
      true,
      250
    );
    columns.push(c6);

    let c7: TableColumn = new TableColumn(
      'Subtotal',
      'quickbooks.subtotal',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c7);

    let c8: TableColumn = new TableColumn(
      'AdminFee',
      'quickbooks.adminFee',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c8);

    let c9: TableColumn = new TableColumn(
      'OriginalAmount',
      'quickbooks.originalAmount',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c9);

    let c10: TableColumn = new TableColumn(
      'Amount',
      'quickbooks.amount',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c10);

    let c11: TableColumn = new TableColumn(
      'Balance',
      'quickbooks.balance',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c11);

    let c12: TableColumn = new TableColumn(
      'QBInvoiceNumber',
      'quickbooks.qbInvoiceNumber',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c12);

    let c13: TableColumn = new TableColumn(
      'ProcessingStatusTitle',
      'quickbooks.processingStatus',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c13);

    let c14: TableColumn = new TableColumn(
      'Note',
      'quickbooks.note',
      ColumnTypes.Text,
      null,
      null,
      true,
      250
    );
    columns.push(c14);

    let c15: TableColumn = new TableColumn(
      'QBStatusTitle',
      'quickbooks.qbStatus',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c15);

    return columns;
  }

  public static getDashboard_CheckAdjustments() {
    let columns: TableColumn[] = [];
    let c100: TableColumn = new TableColumn(
      'Edit',
      'dashboard.checkAdjustments.edit',
      ColumnTypes.EditAction,
      null,
      null,
      true,
      50
    );
    c100.icon = 'fas fa-pencil-alt';
    columns.push(c100);

    let c101: TableColumn = new TableColumn(
      'Undo',
      'dashboard.checkAdjustments.undo',
      ColumnTypes.EditAction,
      null,
      null,
      true,
      50
    );
    c101.icon = 'fas fa-undo';
    columns.push(c101);

    let c103: TableColumn = new TableColumn(
      'Check_ID',
      'dashboard.checkAdjustments.checkId',
      ColumnTypes.Text,
      null,
      null,
      true,
      250
    );
    columns.push(c103);

    let c102: TableColumn = new TableColumn(
      'EmployeeID',
      'dashboard.checkAdjustments.employee',
      ColumnTypes.Dropdown,
      null,
      null,
      true,
      250
    );
    columns.push(c102);

    let c0: TableColumn = new TableColumn(
      'OpenTime',
      'dashboard.checkAdjustments.openTime',
      ColumnTypes.DateTimeString,
      null,
      null,
      true,
      150
    );
    c0.hoursFormat = 12;
    c0.dateFormat = 'MM/dd/yyyy'; // HH:mm:ss';
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'CloseTime',
      'dashboard.checkAdjustments.closeTime',
      ColumnTypes.DateTimeString,
      null,
      null,
      true,
      150
    );
    c1.hoursFormat = 12;
    c1.dateFormat = 'MM/dd/yyyy'; // HH:mm:ss';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'CC_Tips',
      'dashboard.checkAdjustments.ccTips',
      ColumnTypes.Currency,
      null,
      null,
      false,
      100
    );
    c2.readonly = true;
    c2.align = 'right';
    c2.minFractionDigits = 2;
    c2.maxFractionDigits = 2;
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'CC_Tips_Fee',
      'dashboard.checkAdjustments.ccTipsFee',
      ColumnTypes.Currency,
      null,
      null,
      false,
      100
    );
    c3.readonly = true;
    c3.align = 'right';
    c3.minFractionDigits = 2;
    c3.maxFractionDigits = 2;
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'CC_Sales',
      'dashboard.checkAdjustments.ccSales',
      ColumnTypes.Currency,
      null,
      null,
      true,
      100
    );
    c4.readonly = true;
    c4.align = 'right';
    c4.minFractionDigits = 2;
    c4.maxFractionDigits = 2;
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'Cash_Sales',
      'dashboard.checkAdjustments.cashSales',
      ColumnTypes.Currency,
      null,
      null,
      true,
      100
    );
    c5.readonly = true;
    c5.align = 'right';
    c5.minFractionDigits = 2;
    c5.maxFractionDigits = 2;
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'Cash_Tips',
      'dashboard.checkAdjustments.cashTips',
      ColumnTypes.Currency,
      null,
      null,
      true,
      100
    );
    c6.readonly = true;
    c6.align = 'right';
    c6.minFractionDigits = 2;
    c6.maxFractionDigits = 2;
    columns.push(c6);

    let c7: TableColumn = new TableColumn(
      'Indirect_Tips',
      'dashboard.checkAdjustments.indirectTips',
      ColumnTypes.Currency,
      null,
      null,
      true,
      100
    );
    c7.readonly = true;
    c7.align = 'right';
    c7.minFractionDigits = 2;
    c7.maxFractionDigits = 2;
    columns.push(c7);

    let c8: TableColumn = new TableColumn(
      'Net_Sales',
      'dashboard.checkAdjustments.netSales',
      ColumnTypes.Currency,
      null,
      null,
      true,
      100
    );
    c8.readonly = true;
    c8.align = 'right';
    c8.minFractionDigits = 2;
    c8.maxFractionDigits = 2;
    columns.push(c8);

    let c9: TableColumn = new TableColumn(
      'CC_ServiceCharge',
      'dashboard.checkAdjustments.ccServiceCharge',
      ColumnTypes.Currency,
      null,
      null,
      true,
      100
    );
    c9.readonly = true;
    c9.align = 'right';
    c9.minFractionDigits = 2;
    c9.maxFractionDigits = 2;
    columns.push(c9);

    let c10: TableColumn = new TableColumn(
      'CC_ServiceCharge_Fee',
      'dashboard.checkAdjustments.ccServiceChargeFee',
      ColumnTypes.Currency,
      null,
      null,
      true,
      100
    );
    c10.readonly = true;
    c10.align = 'right';
    c10.minFractionDigits = 2;
    c10.maxFractionDigits = 2;
    columns.push(c10);

    let c11: TableColumn = new TableColumn(
      'Cash_ServiceCharge',
      'dashboard.checkAdjustments.cashServiceCharge',
      ColumnTypes.Currency,
      null,
      null,
      true,
      100
    );
    c11.readonly = true;
    c11.align = 'right';
    c11.minFractionDigits = 2;
    c11.maxFractionDigits = 2;
    columns.push(c11);

    let c12: TableColumn = new TableColumn(
      'Wine_Sales',
      'dashboard.checkAdjustments.wineSales',
      ColumnTypes.Currency,
      null,
      null,
      true,
      100
    );
    c12.readonly = true;
    c12.align = 'right';
    c12.minFractionDigits = 2;
    c12.maxFractionDigits = 2;
    columns.push(c12);

    let c13: TableColumn = new TableColumn(
      'Food_Sales',
      'dashboard.checkAdjustments.foodSales',
      ColumnTypes.Currency,
      null,
      null,
      true,
      100
    );
    c13.readonly = true;
    c13.align = 'right';
    c13.minFractionDigits = 2;
    c13.maxFractionDigits = 2;
    columns.push(c13);

    let c14: TableColumn = new TableColumn(
      'Liquer_Sales',
      'dashboard.checkAdjustments.liquerSales',
      ColumnTypes.Currency,
      null,
      null,
      true,
      100
    );
    c14.readonly = true;
    c14.align = 'right';
    c14.minFractionDigits = 2;
    c14.maxFractionDigits = 2;
    columns.push(c14);

    let c15: TableColumn = new TableColumn(
      'SalesCat_4',
      'dashboard.checkAdjustments.salesCat4',
      ColumnTypes.Currency,
      null,
      null,
      true,
      100
    );
    c15.readonly = true;
    c15.align = 'right';
    c15.minFractionDigits = 2;
    c15.maxFractionDigits = 2;
    columns.push(c15);

    let c16: TableColumn = new TableColumn(
      'SalesCat_5',
      'dashboard.checkAdjustments.salesCat5',
      ColumnTypes.Currency,
      null,
      null,
      true,
      100
    );
    c16.readonly = true;
    c16.align = 'right';
    c16.minFractionDigits = 2;
    c16.maxFractionDigits = 2;
    columns.push(c16);

    let c17: TableColumn = new TableColumn(
      'SalesCat_6',
      'dashboard.checkAdjustments.salesCat6',
      ColumnTypes.Currency,
      null,
      null,
      true,
      100
    );
    c17.readonly = true;
    c17.align = 'right';
    c17.minFractionDigits = 2;
    c17.maxFractionDigits = 2;
    columns.push(c17);

    let c18: TableColumn = new TableColumn(
      'SalesCat_7',
      'dashboard.checkAdjustments.salesCat7',
      ColumnTypes.Currency,
      null,
      null,
      true,
      100
    );
    c18.readonly = true;
    c18.align = 'right';
    c18.minFractionDigits = 2;
    c18.maxFractionDigits = 2;
    columns.push(c18);

    let c19: TableColumn = new TableColumn(
      'Commission_Sales',
      'dashboard.checkAdjustments.commissionSales',
      ColumnTypes.Currency,
      null,
      null,
      true,
      100
    );
    c19.readonly = true;
    c19.align = 'right';
    c19.minFractionDigits = 2;
    c19.maxFractionDigits = 2;
    columns.push(c19);

    let c20: TableColumn = new TableColumn(
      'Commission_Sales_2',
      'dashboard.checkAdjustments.commissionSales2',
      ColumnTypes.Currency,
      null,
      null,
      true,
      100
    );
    c20.readonly = true;
    c20.align = 'right';
    c20.minFractionDigits = 2;
    c20.maxFractionDigits = 2;
    columns.push(c20);

    let c21: TableColumn = new TableColumn(
      'Commission_Sales_3',
      'dashboard.checkAdjustments.commissionSales3',
      ColumnTypes.Currency,
      null,
      null,
      true,
      100
    );
    c21.readonly = true;
    c21.align = 'right';
    c21.minFractionDigits = 2;
    c21.maxFractionDigits = 2;
    columns.push(c21);

    return columns;
  }

  public static getDashboard_CheckAdjustmentsUpdated() {
    let columns: TableColumn[] = [];

    let c00: TableColumn = new TableColumn(
      'AutoTransfer',
      'dashboard.checkAdjustments.autoTransfer',
      ColumnTypes.IconSet,
      null,
      null,
      false,
      60
    );
    c00.iconValueSet.push({
      value: true,
      icon: 'fas fa-cogs',
      color: 'dimgray',
    });
    c00.iconValueSet.push({
      value: false,
      icon: '',
      color: '',
    });
    columns.push(c00);

    let c102: TableColumn = new TableColumn(
      'EmployeeID',
      'dashboard.checkAdjustments.employee',
      ColumnTypes.Dropdown,
      null,
      null,
      true,
      250
    );
    columns.push(c102);

    let c0: TableColumn = new TableColumn(
      'OpenTime',
      'dashboard.checkAdjustments.openTime',
      ColumnTypes.DateTimeString,
      null,
      null,
      true,
      150
    );
    c0.hoursFormat = 12;
    c0.dateFormat = 'MM/dd/yyyy'; // HH:mm:ss';
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'CloseTime',
      'dashboard.checkAdjustments.closeTime',
      ColumnTypes.DateTimeString,
      null,
      null,
      true,
      150
    );
    c1.hoursFormat = 12;
    c1.dateFormat = 'MM/dd/yyyy'; // HH:mm:ss';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'CC_Tips',
      'dashboard.checkAdjustments.ccTips',
      ColumnTypes.Currency,
      null,
      null,
      false,
      50
    );
    c2.align = 'right';
    c2.maxFractionDigits = 2;
    c2.minFractionDigits = 2;
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'CC_Tips_Fee',
      'dashboard.checkAdjustments.ccTipsFee',
      ColumnTypes.Currency,
      null,
      null,
      false,
      50
    );
    c3.align = 'right';
    c3.maxFractionDigits = 2;
    c3.minFractionDigits = 2;
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'CC_Sales',
      'dashboard.checkAdjustments.ccSales',
      ColumnTypes.Currency,
      null,
      null,
      false,
      50
    );
    c4.align = 'right';
    c4.maxFractionDigits = 2;
    c4.minFractionDigits = 2;
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'Cash_Sales',
      'dashboard.checkAdjustments.cashSales',
      ColumnTypes.Currency,
      null,
      null,
      false,
      50
    );
    c5.align = 'right';
    c5.maxFractionDigits = 2;
    c5.minFractionDigits = 2;
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'Cash_Tips',
      'dashboard.checkAdjustments.cashTips',
      ColumnTypes.Currency,
      null,
      null,
      false,
      50
    );
    c6.align = 'right';
    c6.maxFractionDigits = 2;
    c6.minFractionDigits = 2;
    columns.push(c6);

    let c7: TableColumn = new TableColumn(
      'Indirect_Tips',
      'dashboard.checkAdjustments.indirectTips',
      ColumnTypes.Currency,
      null,
      null,
      false,
      50
    );
    c7.align = 'right';
    c7.maxFractionDigits = 2;
    c7.minFractionDigits = 2;
    columns.push(c7);

    let c8: TableColumn = new TableColumn(
      'Net_Sales',
      'dashboard.checkAdjustments.netSales',
      ColumnTypes.Currency,
      null,
      null,
      false,
      50
    );
    c8.align = 'right';
    c8.maxFractionDigits = 2;
    c8.minFractionDigits = 2;
    columns.push(c8);

    let c9: TableColumn = new TableColumn(
      'CC_ServiceCharge',
      'dashboard.checkAdjustments.ccServiceCharge',
      ColumnTypes.Currency,
      null,
      null,
      false,
      50
    );
    c9.align = 'right';
    c9.maxFractionDigits = 2;
    c9.minFractionDigits = 2;
    columns.push(c9);

    let c10: TableColumn = new TableColumn(
      'CC_ServiceCharge_Fee',
      'dashboard.checkAdjustments.ccServiceChargeFee',
      ColumnTypes.Currency,
      null,
      null,
      false,
      50
    );
    c10.align = 'right';
    c10.maxFractionDigits = 2;
    c10.minFractionDigits = 2;
    columns.push(c10);

    let c11: TableColumn = new TableColumn(
      'Cash_ServiceCharge',
      'dashboard.checkAdjustments.cashServiceCharge',
      ColumnTypes.Currency,
      null,
      null,
      false,
      50
    );
    c11.align = 'right';
    c11.maxFractionDigits = 2;
    c11.minFractionDigits = 2;
    columns.push(c11);

    let c12: TableColumn = new TableColumn(
      'Wine_Sales',
      'dashboard.checkAdjustments.wineSales',
      ColumnTypes.Currency,
      null,
      null,
      false,
      50
    );
    c12.align = 'right';
    c12.maxFractionDigits = 2;
    c12.minFractionDigits = 2;
    columns.push(c12);

    let c13: TableColumn = new TableColumn(
      'Food_Sales',
      'dashboard.checkAdjustments.foodSales',
      ColumnTypes.Currency,
      null,
      null,
      false,
      50
    );
    c13.align = 'right';
    c13.maxFractionDigits = 2;
    c13.minFractionDigits = 2;
    columns.push(c13);

    let c14: TableColumn = new TableColumn(
      'Liquer_Sales',
      'dashboard.checkAdjustments.liquerSales',
      ColumnTypes.Currency,
      null,
      null,
      false,
      50
    );
    c14.align = 'right';
    c14.maxFractionDigits = 2;
    c14.minFractionDigits = 2;
    columns.push(c14);

    let c15: TableColumn = new TableColumn(
      'SalesCat_4',
      'dashboard.checkAdjustments.salesCat4',
      ColumnTypes.Currency,
      null,
      null,
      false,
      50
    );
    c15.align = 'right';
    c15.maxFractionDigits = 2;
    c15.minFractionDigits = 2;
    columns.push(c15);

    let c16: TableColumn = new TableColumn(
      'SalesCat_5',
      'dashboard.checkAdjustments.salesCat5',
      ColumnTypes.Currency,
      null,
      null,
      false,
      50
    );
    c16.align = 'right';
    c16.maxFractionDigits = 2;
    c16.minFractionDigits = 2;
    columns.push(c16);

    let c17: TableColumn = new TableColumn(
      'SalesCat_6',
      'dashboard.checkAdjustments.salesCat6',
      ColumnTypes.Currency,
      null,
      null,
      false,
      50
    );
    c17.align = 'right';
    c17.maxFractionDigits = 2;
    c17.minFractionDigits = 2;
    columns.push(c17);

    let c18: TableColumn = new TableColumn(
      'SalesCat_7',
      'dashboard.checkAdjustments.salesCat7',
      ColumnTypes.Currency,
      null,
      null,
      false,
      50
    );
    c18.align = 'right';
    c18.maxFractionDigits = 2;
    c18.minFractionDigits = 2;
    columns.push(c18);

    let c19: TableColumn = new TableColumn(
      'Commission_Sales',
      'dashboard.checkAdjustments.commissionSales',
      ColumnTypes.Currency,
      null,
      null,
      false,
      50
    );
    c19.align = 'right';
    c19.maxFractionDigits = 2;
    c19.minFractionDigits = 2;
    columns.push(c19);

    let c20: TableColumn = new TableColumn(
      'Commission_Sales_2',
      'dashboard.checkAdjustments.commissionSales2',
      ColumnTypes.Currency,
      null,
      null,
      false,
      50
    );
    c20.align = 'right';
    c20.maxFractionDigits = 2;
    c20.minFractionDigits = 2;
    columns.push(c20);

    let c21: TableColumn = new TableColumn(
      'Commission_Sales_3',
      'dashboard.checkAdjustments.commissionSales3',
      ColumnTypes.Currency,
      null,
      null,
      false,
      50
    );
    c21.align = 'right';
    c21.maxFractionDigits = 2;
    c21.minFractionDigits = 2;
    columns.push(c21);

    return columns;
  }

  public static getCompanyGroups_Overview() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'Code',
      'companyGroups.code',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Name',
      'companyGroups.name',
      ColumnTypes.Text,
      null,
      null,
      true,
      300
    );
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'AccountID',
      'companyGroups.accountID',
      ColumnTypes.Text,
      null,
      null,
      true,
      200
    );
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'ImplementationTypeTitle',
      'companyGroups.implementationType',
      ColumnTypes.Text,
      null,
      null,
      true,
      300
    );
    columns.push(c4);

    return columns;
  }

  public static getAutoTransferConfigOverview() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'JobTitleFrom',
      'security.autoTransfer.jobFrom',
      ColumnTypes.Dropdown,
      null,
      null,
      false,
      180
    );
    c1.align = 'left';
    c1.options = [];
    c1.placeholder = '...';
    c1.validateAllowNull = false;
    c1.validate = true;
    c1.showNull = true;
    c1.dropdownSearch = true;
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'OrderMode',
      'security.autoTransfer.orderMode',
      ColumnTypes.NumberInput,
      null,
      null,
      false,
      30
    );
    c2.width = 60;
    c2.maxWidthPX = 70;
    c2.align = 'right';
    c2.minFractionDigits = 0;
    c2.maxFractionDigits = 0;
    c2.validate = false;
    //c2.validateAllowNull = false;
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'JobTitleTo',
      'security.autoTransfer.jobTo',
      ColumnTypes.Dropdown,
      null,
      null,
      false,
      180
    );
    c3.align = 'left';
    c3.options = [];
    c3.placeholder = '...';
    c3.validateAllowNull = false;
    c3.validate = true;
    c3.showNull = true;
    c3.dropdownSearch = true;
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'Priority',
      'security.autoTransfer.priority',
      ColumnTypes.NumberInput,
      null,
      null,
      false,
      30
    );
    c4.width = 60;
    c4.maxWidthPX = 70;
    c4.align = 'right';
    c4.minFractionDigits = 0;
    c4.maxFractionDigits = 0;
    c4.validate = true;
    c4.validateAllowNull = false;
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'Delete',
      'Delete',
      ColumnTypes.DeleteAction,
      null,
      null,
      null,
      null,
      null,
      false,
      'fas fa-times'
    );
    columns.push(c5);

    return columns;
  }

  public static getReportingAnalyticCheck() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'CheckId',
      'reporting.analytic.checkId',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c1.order = 0;
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'OpenTime',
      'reporting.analytic.openTime',
      ColumnTypes.TimeWithSpinners,
      null,
      null,
      true
    );
    c2.hoursFormat = 12;
    c2.align = 'left';
    c2.showAsString = true;
    c2.readonly = true;
    c2.order = 1;
    columns.push(c2);

    let c21: TableColumn = new TableColumn(
      'CloseTime',
      'reporting.analytic.closeTime',
      ColumnTypes.TimeWithSpinners,
      null,
      null,
      true
    );
    c21.hoursFormat = 12;
    c21.align = 'left';
    c21.showAsString = true;
    c21.readonly = true;
    c21.order = 2;
    columns.push(c21);

    let c10: TableColumn = new TableColumn(
      'AditionalTips',
      'reporting.analytic.aditionalTips',
      ColumnTypes.Currency,
      null,
      null,
      true
    );
    c10.minFractionDigits = 2;
    c10.maxFractionDigits = 2;
    c10.readonly = true;
    c10.order = 10;
    columns.push(c10);

    let c11: TableColumn = new TableColumn(
      'CCSvc',
      'reporting.analytic.CCSvc',
      ColumnTypes.Currency,
      null,
      null,
      true
    );
    c11.minFractionDigits = 2;
    c11.maxFractionDigits = 2;
    c11.readonly = true;
    c11.order = 11;
    columns.push(c11);

    let c12: TableColumn = new TableColumn(
      'CCTransFee',
      'reporting.analytic.CCTransFee',
      ColumnTypes.Currency,
      null,
      null,
      true
    );
    c12.order = 12;
    c12.minFractionDigits = 2;
    c12.maxFractionDigits = 2;
    c12.readonly = true;
    columns.push(c12);

    let c13: TableColumn = new TableColumn(
      'CashSVC',
      'reporting.analytic.CashSVC',
      ColumnTypes.Currency,
      null,
      null,
      true
    );
    c13.order = 13;
    c13.minFractionDigits = 2;
    c13.maxFractionDigits = 2;
    c13.readonly = true;
    columns.push(c13);

    let c14: TableColumn = new TableColumn(
      'Details',
      'controlPanel.details',
      ColumnTypes.EditAction,
      false,
      null,
      false,
      100,
      null,
      false,
      'fas fa-chart-bar'
    );
    c14.order = 14;
    columns.push(c14);

    return columns;
  }

  public static getReportingAnalyticDetailsCheck() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'EmployeeName',
      'reporting.analytic.details.employee',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c1.order = 1;
    columns.push(c1);

    let c11: TableColumn = new TableColumn(
      'JobName',
      'reporting.analytic.details.job',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c11.order = 1;
    columns.push(c11);

    let c2: TableColumn = new TableColumn(
      'TimeIn',
      'reporting.analytic.details.timeIn',
      ColumnTypes.TimeWithSpinners,
      null,
      null,
      true
    );
    c2.hoursFormat = 12;
    c2.align = 'left';
    c2.showAsString = true;
    c2.readonly = true;
    c2.order = 2;
    columns.push(c2);

    let c21: TableColumn = new TableColumn(
      'TimeOut',
      'reporting.analytic.details.timeOut',
      ColumnTypes.TimeWithSpinners,
      null,
      null,
      true
    );
    c21.hoursFormat = 12;
    c21.align = 'left';
    c21.showAsString = true;
    c21.readonly = true;
    c21.order = 2;
    columns.push(c21);

    let c4: TableColumn = new TableColumn(
      'TimeOnCheckString',
      'reporting.analytic.details.timeOnCheck',
      ColumnTypes.Time,
      null,
      null,
      true
    );
    c4.align = 'center';
    c4.readonly = true;
    c4.order = 2;
    columns.push(c4);

    let c13: TableColumn = new TableColumn(
      'EarnedAmount',
      'reporting.analytic.details.earnedAmount',
      ColumnTypes.Currency,
      null,
      null,
      true
    );
    c13.order = 13;
    c13.minFractionDigits = 4;
    c13.maxFractionDigits = 4;
    c13.readonly = true;
    columns.push(c13);

    return columns;
  }

  //getReportingAnalyticRowDetailsCheck
  public static getReportingAnalyticRowDetailsCheck() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'Description',
      'reporting.analytic.details.description',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c1.order = 1;
    columns.push(c1);

    let c13: TableColumn = new TableColumn(
      'Amount',
      'reporting.analytic.details.amount',
      ColumnTypes.Currency,
      null,
      null,
      true
    );
    c13.order = 13;
    c13.minFractionDigits = 4;
    c13.maxFractionDigits = 4;
    c13.readonly = true;
    columns.push(c13);

    return columns;
  }

  public static getDwollaBenefitialOwnersList() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'FirstName',
      'controlPanel.dwolla.firstName',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    c1.align = 'left';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'LastName',
      'controlPanel.dwolla.lastName',
      ColumnTypes.Text,
      null,
      null,
      true,
      150
    );
    c2.align = 'left';
    columns.push(c2);

    return columns;
  }

  public static getDwollaCustomerDocuments() {
    let columns: TableColumn[] = [];

    let c3: TableColumn = new TableColumn(
      'Type',
      'controlPanel.dwolla.type',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c3.align = 'left';
    columns.push(c3);

    let c2: TableColumn = new TableColumn(
      'Status',
      'controlPanel.dwolla.documentStatus',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c2.align = 'left';
    columns.push(c2);

    let c4: TableColumn = new TableColumn(
      'Created',
      'controlPanel.dwolla.uploadedOn',
      ColumnTypes.DateTimeString,
      null,
      null,
      true
    );
    c4.align = 'right';
    columns.push(c4);

    let c1: TableColumn = new TableColumn(
      'DwId',
      'controlPanel.dwolla.dwollaRefId',
      ColumnTypes.Text,
      null,
      null,
      false
    );
    c1.align = 'left';
    columns.push(c1);
    return columns;
  }

  public static getDwollaFundingSources() {
    let columns: TableColumn[] = [];

    let c0: TableColumn = new TableColumn(
      'Delete',
      'Delete',
      ColumnTypes.Button,
      null,
      null,
      true
    );
    c0.align = 'center';
    c0.icon = 'fas fa-times';
    c0.class = 'color-erorr';
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'Name',
      'controlPanel.dwolla.accountName',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c1.align = 'left';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'BankAccountType',
      'controlPanel.dwolla.accountType',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c2.align = 'left';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'BankName',
      'controlPanel.dwolla.bankName',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c3.align = 'left';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'Type',
      'controlPanel.dwolla.type',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c4.align = 'left';
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'Created',
      'controlPanel.dwolla.created',
      ColumnTypes.Date,
      null,
      null,
      true
    );
    c5.align = 'right';
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'GsStatus',
      'controlPanel.dwolla.documentStatus',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c6.align = 'left';
    columns.push(c6);

    let c7: TableColumn = new TableColumn(
      'AcountNumber',
      'controlPanel.dwolla.accountNumber',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c7.align = 'left';
    columns.push(c7);

    let c8: TableColumn = new TableColumn(
      'RoutingNumber',
      'controlPanel.dwolla.routingNumber',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c8.align = 'left';
    columns.push(c8);

    let c9: TableColumn = new TableColumn(
      'Verify',
      'Verify',
      ColumnTypes.ButtonText,
      null,
      null,
      true
    );
    c9.align = 'center';
    columns.push(c9);

    return columns;
  }

  public static getTabapayReconciliationImport() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'AccountDesignator',
      'Account Designator',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c1.align = 'left';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'PostedDate',
      'Posted Date',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c2.align = 'left';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'SerialNumber',
      'Serial Number',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c3.align = 'left';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'Description',
      'Description',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c4.align = 'left';
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'Amount',
      'Amount',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c5.align = 'left';
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'CRDR',
      'CR DR',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c6.align = 'left';
    columns.push(c6);

    return columns;
  }

  public static getTabapayReconciliationImportProccessed() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'PostedDate',
      'Posted date',
      ColumnTypes.Date,
      null,
      null,
      true
    );
    c1.align = 'left';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Description',
      'Description',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c2.align = 'left';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'MID',
      'MID',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c3.align = 'left';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'Amount',
      'Amount',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c4.align = 'left';
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'AmountReconciled',
      'Amount reconciled',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c5.align = 'left';
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'Processed',
      'Processed',
      ColumnTypes.BoolSwitch,
      null,
      null,
      true
    );
    c6.align = 'center';
    c6.disabled = true;
    c6.readonly = true;
    columns.push(c6);

    let c8: TableColumn = new TableColumn(
      'Reconciled',
      'Reconciled',
      ColumnTypes.BoolSwitch,
      null,
      null,
      true
    );
    c8.align = 'center';
    c8.disabled = true;
    c8.readonly = true;
    columns.push(c8);

    let c7: TableColumn = new TableColumn(
      'Reprocess',
      'Reprocess',
      ColumnTypes.ButtonText,
      null,
      null,
      true
    );
    c7.readonly = false;
    c7.disabled = false;
    c7.align = 'center';
    columns.push(c7);

    return columns;
  }

  public static getTabapayReconciliationBankStatements() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'PostedDate',
      'Posted date',
      ColumnTypes.Date,
      null,
      null,
      true
    );
    c1.align = 'left';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Description',
      'Description',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c2.align = 'left';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'MID',
      'MID',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c3.align = 'left';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'Amount',
      'Amount',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c4.align = 'left';
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'AmountReconciled',
      'Amount reconciled',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c5.align = 'left';
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'Reconciled',
      'Reconciled',
      ColumnTypes.BoolSwitch,
      null,
      null,
      true
    );
    c6.align = 'left';
    c6.disabled = true;
    c6.readonly = true;
    columns.push(c6);

    let c7: TableColumn = new TableColumn(
      'Reprocess',
      'Reprocess',
      ColumnTypes.ButtonText,
      null,
      null,
      true
    );
    c7.readonly = false;
    c7.disabled = false;
    c7.align = 'center';
    columns.push(c7);

    return columns;
  }

  public static getTabapayReconciledTransactions() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'MID',
      'MID',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c1.align = 'left';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Merchant',
      'Merchant',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c2.align = 'left';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'CustomerCode',
      'CustomerCode',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c3.align = 'left';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'Customer',
      'Customer',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c4.align = 'left';
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'Employee',
      'Employee',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c5.align = 'left';
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'EmployeeId',
      'Employee Id',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c6.align = 'left';
    columns.push(c6);

    let c7: TableColumn = new TableColumn(
      'ProcessedDateUTC',
      'Processed date UTC',
      ColumnTypes.DateTimeFormat,
      null,
      null,
      true
    );
    c7.align = 'left';
    c7.dateFormat = 'MM/dd/yyyy';
    columns.push(c7);

    let c8: TableColumn = new TableColumn(
      'SettlementDate',
      'Settlement date',
      ColumnTypes.DateTimeFormat,
      null,
      null,
      true
    );
    c8.align = 'left';
    c8.dateFormat = 'MM/dd/yyyy';
    columns.push(c8);

    let c9: TableColumn = new TableColumn(
      'TransactionStatus',
      'Transaction status',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c9.align = 'left';
    columns.push(c9);

    let c10: TableColumn = new TableColumn(
      'SettledAmount',
      'Settled amount',
      ColumnTypes.Number,
      null,
      null,
      true
    );
    c10.align = 'right';
    columns.push(c10);

    let c11: TableColumn = new TableColumn(
      'TransactionAmount',
      'Transaction amount',
      ColumnTypes.Number,
      null,
      null,
      true
    );
    c11.align = 'right';
    columns.push(c11);

    let c111: TableColumn = new TableColumn(
      'TPFee',
      'TP fee',
      ColumnTypes.Number,
      null,
      null,
      true
    );
    c111.align = 'right';
    columns.push(c111);

    let c12: TableColumn = new TableColumn(
      'ConvenienceFee',
      'Convenience fee',
      ColumnTypes.Number,
      null,
      null,
      true
    );
    c12.align = 'right';
    columns.push(c12);

    let c13: TableColumn = new TableColumn(
      'Fees',
      'Total fees',
      ColumnTypes.Number,
      null,
      null,
      true
    );
    c13.align = 'right';
    columns.push(c13);

    let c14: TableColumn = new TableColumn(
      'GSProfit',
      'GS profit',
      ColumnTypes.Number,
      null,
      null,
      true
    );
    c14.align = 'right';
    columns.push(c14);

    let c15: TableColumn = new TableColumn(
      'ProcessedDatePT',
      'Processed date PT',
      ColumnTypes.DateTimeFormat,
      null,
      null,
      true
    );
    c15.dateFormat = 'MM/dd/yyyy';
    c15.align = 'right';
    columns.push(c15);

    let c16: TableColumn = new TableColumn(
      'CutOffDate',
      'Cut off date',
      ColumnTypes.DateTimeFormat,
      null,
      null,
      true
    );
    c16.dateFormat = 'MM/dd/yyyy';
    c16.align = 'right';
    columns.push(c16);

    return columns;
  }

  public static getTabapayReconciledTransactionsByCustomer() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'MID',
      'MID',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c1.align = 'left';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Merchant',
      'Merchant',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c2.align = 'left';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'CustomerCode',
      'CustomerCode',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c3.align = 'left';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'Customer',
      'Customer',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c4.align = 'left';
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'SettledAmount',
      'Settled amount',
      ColumnTypes.Number,
      null,
      null,
      true
    );
    c5.align = 'right';
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'TransactionAmount',
      'Transaction amount',
      ColumnTypes.Number,
      null,
      null,
      true
    );
    c6.align = 'right';
    columns.push(c6);

    let c61: TableColumn = new TableColumn(
      'TPFees',
      'TP fees',
      ColumnTypes.Number,
      null,
      null,
      true
    );
    c61.align = 'right';
    columns.push(c61);

    let c7: TableColumn = new TableColumn(
      'ConvenienceFee',
      'Convenience fee',
      ColumnTypes.Number,
      null,
      null,
      true
    );
    c7.align = 'right';
    columns.push(c7);

    let c8: TableColumn = new TableColumn(
      'Fees',
      'Total fees',
      ColumnTypes.Number,
      null,
      null,
      true
    );
    c8.align = 'right';
    columns.push(c8);

    let c9: TableColumn = new TableColumn(
      'GSProfit',
      'GS Profit',
      ColumnTypes.Number,
      null,
      null,
      true
    );
    c9.align = 'right';
    columns.push(c9);

    return columns;
  }

  public static getTabapayUnreconciledTransactions() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'MID',
      'MID',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c1.align = 'left';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Merchant',
      'Merchant',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c2.align = 'left';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'CustomerCode',
      'CustomerCode',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c3.align = 'left';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'Customer',
      'Customer',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c4.align = 'left';
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'Employee',
      'Employee',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c5.align = 'left';
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'EmployeeId',
      'Employee Id',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c6.align = 'left';
    columns.push(c6);

    let c7: TableColumn = new TableColumn(
      'ProcessedDateUTC',
      'Processed date UTC',
      ColumnTypes.DateTimeFormat,
      null,
      null,
      true
    );
    c7.align = 'left';
    c7.dateFormat = 'MM/dd/yyyy';
    columns.push(c7);

    let c8: TableColumn = new TableColumn(
      'SettlementDate',
      'Settlement date',
      ColumnTypes.DateTimeFormat,
      null,
      null,
      true
    );
    c8.align = 'left';
    c8.dateFormat = 'MM/dd/yyyy';
    columns.push(c8);

    let c9: TableColumn = new TableColumn(
      'TransactionStatus',
      'Transaction status',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c9.align = 'left';
    columns.push(c9);

    let c10: TableColumn = new TableColumn(
      'SettledAmount',
      'Settled amount',
      ColumnTypes.Number,
      null,
      null,
      true
    );
    c10.align = 'right';
    columns.push(c10);

    let c11: TableColumn = new TableColumn(
      'TransactionAmount',
      'Transaction amount',
      ColumnTypes.Number,
      null,
      null,
      true
    );
    c11.align = 'right';
    columns.push(c11);

    let c111: TableColumn = new TableColumn(
      'TPFee',
      'TP fee',
      ColumnTypes.Number,
      null,
      null,
      true
    );
    c111.align = 'right';
    columns.push(c111);

    let c12: TableColumn = new TableColumn(
      'ConvenienceFee',
      'Convenience fee',
      ColumnTypes.Number,
      null,
      null,
      true
    );
    c12.align = 'right';
    columns.push(c12);

    let c13: TableColumn = new TableColumn(
      'Fees',
      'Total fees',
      ColumnTypes.Number,
      null,
      null,
      true
    );
    c13.align = 'right';
    columns.push(c13);

    let c14: TableColumn = new TableColumn(
      'GSProfit',
      'GS profit',
      ColumnTypes.Number,
      null,
      null,
      true
    );
    c14.align = 'right';
    columns.push(c14);

    let c15: TableColumn = new TableColumn(
      'ProcessedDatePT',
      'Processed date PT',
      ColumnTypes.DateTimeFormat,
      null,
      null,
      true
    );
    c15.dateFormat = 'MM/dd/yyyy';
    c15.align = 'right';
    columns.push(c15);

    let c16: TableColumn = new TableColumn(
      'CutOffDate',
      'Cut off date',
      ColumnTypes.DateTimeFormat,
      null,
      null,
      true
    );
    c16.dateFormat = 'MM/dd/yyyy';
    c16.align = 'right';
    columns.push(c16);

    return columns;
  }

  public static getDwollaFundingSourceLocations() {
    let columns: TableColumn[] = [];

    let c0: TableColumn = new TableColumn(
      'Delete',
      'Delete',
      ColumnTypes.Button,
      null,
      null,
      true
    );
    c0.align = 'center';
    c0.icon = 'fas fa-times';
    c0.class = 'color-erorr';
    c0.width = 100;
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'CustomerCode',
      'Customer code',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c1.width = 200;
    c1.align = 'center';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Name',
      'Name',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c2.align = 'left';
    columns.push(c2);

    return columns;
  }

  public static getCompanyGroup_CustomerAdditionalParams() {
    let columns: TableColumn[] = [];

    let c2: TableColumn = new TableColumn(
      'Title',
      '',
      ColumnTypes.Text,
      null,
      null,
      true,
      300
    );
    c2.align = 'left';
    columns.push(c2);

    return columns;
  }

  public static getVisaChoiceApplications() {
    let columns: TableColumn[] = [];

    let c0: TableColumn = new TableColumn(
      'SubclientId',
      'Subclient ID',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c0.align = 'left';
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'Name',
      'Business name',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c1.align = 'left';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Ein',
      'Ein',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c2.align = 'left';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'Aba',
      'Account number',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c3.align = 'left';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'Rtn',
      'Routing number',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c4.align = 'left';
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'Date',
      'Created',
      ColumnTypes.DateTimeFormat,
      null,
      null,
      true
    );
    c5.dateFormat = 'MM/dd/yyyy';
    c5.align = 'left';
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'activeStatusTitle',
      'Status',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c6.align = 'left';
    columns.push(c6);

    let c7: TableColumn = new TableColumn(
      'Delete',
      'Delete',
      ColumnTypes.ButtonText,
      null,
      null,
      false
    );
    c7.align = 'center';
    columns.push(c7);

    return columns;
  }

  public static getVisaChoiceApplications_CustomerMapping() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'Delete',
      '',
      ColumnTypes.Button,
      null,
      null,
      true
    );
    c1.align = 'center';
    c1.icon = 'fas fa-times';
    c1.class = 'color-erorr';
    c1.width = 100;
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'CustomerCode',
      'Customer code',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c2.width = 150;
    c2.align = 'left';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'CustomerName',
      'Name',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c3.align = 'left';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'LoadCardAllowedFrom',
      'Load card allowed from',
      ColumnTypes.DateTimeFormat,
      null,
      null,
      true
    );
    c4.dateFormat = 'MM/dd/yyyy';
    c4.align = 'left';
    columns.push(c4);

    return columns;
  }

  public static getPlaidOriginators() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'CompanyName',
      'Company name',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c1.align = 'left';
    columns.push(c1);

    return columns;
  }

  public static getPlaidFundingSources() {
    let columns: TableColumn[] = [];

    let c0: TableColumn = new TableColumn(
      'PlaidCustomerName',
      'Originator',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c0.align = 'left';
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'Name',
      'Name',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c1.align = 'left';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Type',
      'Type',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c2.align = 'left';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'Subtype',
      'Subtype',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c3.align = 'left';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'Mask',
      'Mask',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c4.align = 'left';
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'VerificationStatus',
      'Verification status',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c5.align = 'left';
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'D_Inserted',
      'Created',
      ColumnTypes.DateTimeFormat,
      null,
      null,
      true
    );
    c6.align = 'left';
    c6.dateFormat = 'MM/dd/yyyy';
    columns.push(c6);

    return columns;
  }

  public static getPlaidFundingSourceMappings() {
    let columns: TableColumn[] = [];

    let c0: TableColumn = new TableColumn(
      'Delete',
      '',
      ColumnTypes.Button,
      null,
      null,
      true
    );
    c0.align = 'center';
    c0.icon = 'fas fa-times';
    c0.class = 'color-erorr';
    c0.width = 100;
    columns.push(c0);

    let c1: TableColumn = new TableColumn(
      'CustomerCode',
      'Code',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c1.align = 'left';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'CustomerName',
      'Customer name',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c2.align = 'left';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'LoadCardAllowedFrom',
      'Load card allowed from',
      ColumnTypes.DateTimeFormat,
      null,
      null,
      true
    );
    c3.dateFormat = 'MM/dd/yyyy';
    c3.align = 'left';
    columns.push(c3);

    return columns;
  }

  public static getReportDataExport() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'ClientName',
      'Name',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c1.align = 'left';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'DataExport',
      'Data export name',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c2.align = 'left';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'DateFrom',
      'Date From',
      ColumnTypes.Date,
      null,
      null,
      true
    );
    c3.align = 'left';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'DateTo',
      'Date To',
      ColumnTypes.Date,
      null,
      null,
      true
    );
    c4.align = 'left';
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'GeneratedAt',
      'Generated At',
      ColumnTypes.DateTimeString,
      null,
      null,
      true
    );
    c5.align = 'left';
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'Sent',
      'Sent',
      ColumnTypes.BoolTrafficlightYesNo,
      null,
      null,
      true
    );
    c6.align = 'center';
    columns.push(c6);

    let c7: TableColumn = new TableColumn(
      'SentAt',
      'Sent At',
      ColumnTypes.DateTimeString,
      null,
      null,
      true
    );
    c7.align = 'left';
    columns.push(c7);

    let c9: TableColumn = new TableColumn(
      'info',
      'Info',
      ColumnTypes.Button,
      null,
      null,
      null
    );
    c9.icon = 'fas fa-info-circle';
    c9.align = 'center';
    columns.push(c9);

    return columns;
  }

  public static getWizardDataExport() {
    let columns: TableColumn[] = [];

    TableBuilder.addColumn(columns, {
      name: 'Name',
      desc: 'Name',
      type: ColumnTypes.Text,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'ClientName',
      desc: 'DESCRIPTION',
      type: ColumnTypes.Text,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'DataExportName',
      desc: 'Export type',
      type: ColumnTypes.Text,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'GSClientTypeName',
      desc: 'Client type',
      type: ColumnTypes.Text,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'GSClientConnectorName',
      desc: 'Connector',
      type: ColumnTypes.Text,
      align: 'left',
    });

    TableBuilder.addColumn(columns, {
      name: 'LastExported',
      desc: 'last export',
      type: ColumnTypes.DateTimeString,
      align: 'left',
      dateFormat: 'MM/dd/yyyy HH:mm:ss',
    });
    TableBuilder.addColumn(columns, {
      name: 'Log',
      desc: 'Log',
      type: ColumnTypes.Button,
      align: 'center',
      icon: 'fas fa-info-circle',
    });
    TableBuilder.addColumn(columns, {
      name: 'Details',
      desc: 'Details',
      type: ColumnTypes.Button,
      align: 'center',
      icon: 'fas fa-info-circle',
    });
    TableBuilder.addColumn(columns, {
      name: 'Remove',
      desc: 'Remove',
      type: ColumnTypes.Button,
      align: 'center',
      icon: 'fas fa-times',
    });

    return columns;
  }

  public static addColumn(columns, props) {
    let c1: TableColumn = new TableColumn(
      props.name,
      props.desc,
      props.type,
      null,
      null,
      true
    );
    c1.align = props.align ?? 'left';
    if (props.icon) c1.icon = props.icon;
    if (props.class) c1.class = props.class;
    if (props.dateFormat) c1.dateFormat = props.dateFormat;
    if (props.options) c1.options = props.options;
    if (props.allowNull) c1.showNull = props.allowNull;
    if (props.width) c1.width = props.width;
    if (props.validate) c1.validate = props.validate;
    if (props.related) c1.related = props.related;
    if (props.tooltip) c1.tooltip = props.tooltip;
    if (props.readonly) c1.readonly = props.readonly;
    if (props.validateAllowNull) c1.validateAllowNull = props.validateAllowNull;
    if (props.validationTypeForReference)
      c1.validationTypeForReference = props.validationTypeForReference;
    if (props.validationReference)
      c1.validationReference = props.validationReference;
    if (props.requestedValue) c1.requestedValue = props.requestedValue;
    if (props.disabledParameterReference)
      c1.disabledParameterReference = props.disabledParameterReference;
    if (props.validationRegEx) c1.validationRegEx = props.validationRegEx;
    columns.push(c1);
  }

  public static getWizardDataExportConnector() {
    let columns: TableColumn[] = [];
    TableBuilder.addColumn(columns, {
      name: 'Name',
      desc: 'DESCRIPTION',
      type: ColumnTypes.Text,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'ConnectorTypeName',
      desc: 'Type',
      type: ColumnTypes.Text,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'GSClientTypeName',
      desc: 'Client type',
      type: ColumnTypes.Text,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'ClientName',
      desc: 'Client code - name',
      type: ColumnTypes.Text,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'ConnectorCredentialsTypeName',
      desc: 'Credential types',
      type: ColumnTypes.Text,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'Details',
      desc: 'Details',
      type: ColumnTypes.Button,
      align: 'center',
      icon: 'fas fa-info-circle',
    });
    TableBuilder.addColumn(columns, {
      name: 'Remove',
      desc: 'Remove',
      type: ColumnTypes.Button,
      align: 'center',
      icon: 'fas fa-times',
    });

    return columns;
  }

  public static getDataExportLog() {
    let columns: TableColumn[] = [];
    TableBuilder.addColumn(columns, {
      name: 'DataExport',
      desc: 'Data export',
      type: ColumnTypes.Text,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'ClientType',
      desc: 'Client Type',
      type: ColumnTypes.Text,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'ClientName',
      desc: 'Client code - name',
      type: ColumnTypes.Text,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'DateFrom',
      desc: 'Period from',
      type: ColumnTypes.DateTimeString,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'DateTo',
      desc: 'Period to',
      type: ColumnTypes.DateTimeString,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'GeneratedS3FileNameButton',
      desc: 'Generated s3 payload',
      type: ColumnTypes.Button,
      align: 'center',
      icon: 'fas fa-download',
    });

    TableBuilder.addColumn(columns, {
      name: 'S3FileNameButton',
      desc: 'Converted s3 payload',
      type: ColumnTypes.Button,
      align: 'center',
      icon: 'fas fa-download',
    });
    TableBuilder.addColumn(columns, {
      name: 'GeneratedAt',
      desc: 'Created at',
      type: ColumnTypes.DateTimeString,
      align: 'center',
    });
    TableBuilder.addColumn(columns, {
      name: 'GeneratedAtLocal',
      desc: 'Created at (Local time)',
      type: ColumnTypes.DateTimeString,
      align: 'center',
    });
    TableBuilder.addColumn(columns, {
      name: 'SentAt',
      desc: 'Sent at',
      type: ColumnTypes.DateTimeString,
      align: 'center',
    });
    TableBuilder.addColumn(columns, {
      name: 'SentAtLocal',
      desc: 'Sent at (Local time)',
      type: ColumnTypes.DateTimeString,
      align: 'center',
    });
    TableBuilder.addColumn(columns, {
      name: 'Deleted',
      desc: 'Deleted',
      type: ColumnTypes.BoolTrafficlightYesNo,
      align: 'center'
    });
    
    return columns;
  }

  public static getVendor() {
    let columns: TableColumn[] = [];
    TableBuilder.addColumn(columns, {
      name: 'Name',
      desc: 'Name',
      type: ColumnTypes.Text,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'Phone',
      desc: 'Phone',
      type: ColumnTypes.Text,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'Address',
      desc: 'Address',
      type: ColumnTypes.Text,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'Url',
      desc: 'Url',
      type: ColumnTypes.Text,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'Active',
      desc: 'Active',
      type: ColumnTypes.BoolSwitch,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'Details',
      desc: 'Details',
      type: ColumnTypes.Button,
      align: 'center',
      icon: 'fas fa-info',
    });

    return columns;
  }

  public static getVendorCredentials() {
    let columns: TableColumn[] = [];
    TableBuilder.addColumn(columns, {
      name: 'ClientID',
      desc: 'Client ID',
      type: ColumnTypes.Text,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'ClientSecret',
      desc: 'Client Secret',
      type: ColumnTypes.Text,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'Description',
      desc: 'Description',
      type: ColumnTypes.Text,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'CreatedAt',
      desc: 'Created At',
      type: ColumnTypes.DateTimeString,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'Active',
      desc: 'Active',
      type: ColumnTypes.BoolSwitch,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'Details',
      desc: 'Details',
      type: ColumnTypes.Button,
      align: 'center',
      icon: 'fas fa-info',
    });
    TableBuilder.addColumn(columns, {
      name: 'Remove',
      desc: 'Remove',
      type: ColumnTypes.Button,
      align: 'center',
      icon: 'fas fa-times',
    });

    return columns;
  }

  public static getVendorCredentialsScopes() {
    let columns: TableColumn[] = [];
    TableBuilder.addColumn(columns, {
      name: 'Scope',
      desc: 'Scope',
      type: ColumnTypes.TextInput,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'FK_ScopeType',
      desc: 'Scope Type',
      type: ColumnTypes.Dropdown,
      align: 'left',
    });
    TableBuilder.addColumn(columns, {
      name: 'Remove',
      desc: 'Remove',
      type: ColumnTypes.Button,
      align: 'center',
      icon: 'fas fa-times',
    });

    return columns;
  }

  public static getEmployeeProfile_DataExportRestrictions() {
    let columns: TableColumn[] = [];
    let c1: TableColumn = new TableColumn(
      'DataExportCode',
      'Export',
      ColumnTypes.Dropdown,
      null,
      null,
      false
    );
    c1.validate = true;
    c1.align = 'center';
    c1.showNull = true;
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'DateFrom',
      'Exclude starting from',
      ColumnTypes.DateTime,
      null,
      null,
      false
    );
    c2.dateOnly = true;
    c2.readonly = false;
    c2.validate = true;
    //c2.dateFormat = 'MM/dd/yyyy';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'Active',
      'Active',
      ColumnTypes.BoolSwitch,
      null,
      null,
      false
    );

    c3.align = 'left';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'User_FullName_Removed',
      'Last update',
      ColumnTypes.Text,
      null,
      null,
      false
    );
    c4.readonly = true;
    c4.align = 'left';
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'D_Removed',
      'Date',
      ColumnTypes.DateTimeFormat,
      null,
      null,
      false
    );
    c5.readonly = true;
    c5.align = 'center';
    c5.dateFormat = 'MM/dd/yyyy';
    columns.push(c5);

    return columns;
  }

  public static getPaymentIntegrations_PlaidOriginators() {
    let columns: TableColumn[] = [];
    let c1: TableColumn = new TableColumn(
      'GSGroupCode',
      'GS Group Code',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c1.align = 'center';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'GSGroupName',
      'GS Group Name',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c2.align = 'center';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'CompanyName',
      'Company Name',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c3.align = 'center';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'BankAccountMask',
      'Bank account mask',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c4.align = 'center';
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'BankAccountType',
      'Bank account type',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c5.align = 'center';
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'BankAccountName',
      'Bank account name',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c6.align = 'center';
    columns.push(c6);

    return columns;
  }

  public static getPaymentIntegrations_PlaidDebitTransactions() {
    let columns: TableColumn[] = [];
    let c1: TableColumn = new TableColumn(
      'TransactionDate',
      'Debit date',
      ColumnTypes.DateTimeFormat,
      null,
      null,
      true
    );
    c1.dateFormat = 'MM/dd/yyyy HH:mm:ss';
    c1.align = 'center';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Amount',
      'Debit Amount',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c2.align = 'right';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'TransferId',
      'Transfer Id',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c3.align = 'center';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'Status',
      'Status',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c4.align = 'center';
    columns.push(c4);

    return columns;
  }

  public static getPaymentIntegrations_PlaidCreditTransactions() {
    let columns: TableColumn[] = [];
    let c1: TableColumn = new TableColumn(
      'TransactionDate',
      'Credit date',
      ColumnTypes.DateTimeFormat,
      null,
      null,
      true
    );
    c1.dateFormat = 'MM/dd/yyyy HH:mm:ss';
    c1.align = 'center';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Amount',
      'Credit Amount',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c2.align = 'right';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'TransferId',
      'Transfer Id',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c3.align = 'center';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'Status',
      'Status',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c4.align = 'center';
    columns.push(c4);

    return columns;
  }

  public static getLegalDocuments() {
    let columns: TableColumn[] = [];

    let c2: TableColumn = new TableColumn(
      'Caption',
      'Name',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c2.align = 'left';
    columns.push(c2);

    TableBuilder.addColumn(columns, {
      name: 'View',
      desc: 'View',
      type: ColumnTypes.Button,
      align: 'center',
      icon: 'fas fa-eye',
    });

    TableBuilder.addColumn(columns, {
      name: 'Print',
      desc: 'Print',
      type: ColumnTypes.Button,
      align: 'center',
      icon: 'fas fa-print',
    });

    return columns;
  }

  public static getWorkflowsExecutionPlan() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'UTCTime',
      'UTC Time',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c1.align = 'center';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'TimeZone',
      'Time Zone',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c2.align = 'center';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'LocalTime',
      'Local Time',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c3.align = 'center';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'ClientType',
      'Client Type',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c4.align = 'center';
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'WFDescription',
      'WF Description',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c5.align = 'center';
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'ScheduleFrequency',
      'Schedule Frequency',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c6.align = 'center';
    columns.push(c6);

    return columns;
  }

  public static getWorkflowsExecutionLog() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'Code',
      'Code',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c1.align = 'center';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Description',
      'Description',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c2.align = 'center';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'WorkflowClientExecutionType',
      'Execution Type',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c3.align = 'center';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'ScheduledStartAt',
      'Scheduled Start At',
      ColumnTypes.DateTimeString,
      null,
      null,
      true
    );
    c4.align = 'center';
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'StartAt',
      'Started At UTC',
      ColumnTypes.DateTimeString,
      null,
      null,
      true
    );
    c5.align = 'center';
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'ExecutionStatus',
      'Execution Status',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c6.align = 'center';
    columns.push(c6);

    let c7: TableColumn = new TableColumn(
      'CreatedAt',
      'Status Time',
      ColumnTypes.DateTimeString,
      null,
      null,
      true
    );
    c7.align = 'center';
    columns.push(c7);

    let c8: TableColumn = new TableColumn(
      'AttemptsDone',
      'Attempt',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c8.align = 'center';
    columns.push(c8);

    let c9: TableColumn = new TableColumn(
      'Message',
      'Message',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c8.align = 'center';
    columns.push(c9);

    TableBuilder.addColumn(columns, {
      name: 'ExecutedBy',
      desc: 'Executed by',
      type: ColumnTypes.Text,
      align: 'center',
    });

    TableBuilder.addColumn(columns, {
      name: 'Rerun',
      desc: 'Re-run',
      type: ColumnTypes.Button,
      align: 'center',
      icon: 'fas fa-play-circle',
      visible: false,
    });

    return columns;
  }

  public static getWorkflowsTaskExecutionLog() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'Sequence',
      'Sequence',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c1.align = 'center';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Description',
      'Description',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c2.align = 'center';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'CreatedAt',
      'Started at UTC',
      ColumnTypes.DateTimeString,
      null,
      null,
      true
    );
    c3.align = 'center';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'FK_WorkflowStatus',
      'Execution Status',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c4.align = 'center';
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'StatusTime',
      'Status Time',
      ColumnTypes.DateTimeString,
      null,
      null,
      true
    );
    c5.align = 'center';
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'Message',
      'Message',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c6.align = 'center';
    columns.push(c6);

    TableBuilder.addColumn(columns, {
      name: 'DetailedMessage',
      desc: 'Stack trace',
      type: ColumnTypes.Modal,
      align: 'center',
    });
    

    TableBuilder.addColumn(columns, {
      name: 'ExecutedBy',
      desc: 'Executed by',
      type: ColumnTypes.Text,
      align: 'center',
    });

    TableBuilder.addColumn(columns, {
      name: 'Details',
      desc: 'Details',
      type: ColumnTypes.DynamicModal,
      align: 'center',
    });

    TableBuilder.addColumn(columns, {
      name: 'ReRunTask',
      desc: 'Re-run',
      type: ColumnTypes.Button,
      align: 'center',
      icon: 'fas fa-play-circle',
      visible: false,
    });

    return columns;
  }

  public static getWorkflowDefinitions() {
    let columns: TableColumn[] = [];

    TableBuilder.addColumn(columns, {
      name: 'Code',
      desc: 'Code',
      type: ColumnTypes.Text,
      align: 'center',
    });

    TableBuilder.addColumn(columns, {
      name: 'ClientType',
      desc: 'Client Type',
      type: ColumnTypes.Text,
      align: 'center',
    });

    TableBuilder.addColumn(columns, {
      name: 'Description',
      desc: 'Description',
      type: ColumnTypes.Text,
      align: 'left',
    });

    let c4: TableColumn = new TableColumn(
      'Active',
      'Active',
      ColumnTypes.BoolSwitch,
      false,
      null,
      true,
      90,
      null,
      false
    );
    c4.maxWidthPX = 90;
    c4.disabledParameterReference = 'workflowDef_active';
    columns.push(c4);

    TableBuilder.addColumn(columns, {
      name: 'ParentWFCode',
      desc: 'Parent WF Code',
      type: ColumnTypes.Text,
      align: 'center',
    });

    TableBuilder.addColumn(columns, {
      name: 'ParentWFClientType',
      desc: 'Parent WF Client type',
      align: 'center',
    });

    TableBuilder.addColumn(columns, {
      name: 'LastRun',
      desc: 'Last run',
      type: ColumnTypes.DateTimeString,
      align: 'center',
    });

    TableBuilder.addColumn(columns, {
      name: 'Details',
      desc: 'Details',
      type: ColumnTypes.Button,
      align: 'center',
      icon: 'fas fa-info',
      disabledParameterReference: 'workflowDef_details',
    });

    TableBuilder.addColumn(columns, {
      name: 'Delete',
      desc: 'Delete',
      type: ColumnTypes.Button,
      align: 'center',
      icon: 'fas fa-times',
    });

    return columns;
  }

  public static getWorkflowDefinitionsConfiguration() {
    let columns: TableColumn[] = [];

    TableBuilder.addColumn(columns, {
      name: 'FK_WorkflowClientExecutionType',
      desc: 'Execution Type',
      type: ColumnTypes.Dropdown,
      align: 'center',
      allowNull: true,
      validate: true,
      validateAllowNull: false,
      requestedValue: WorkflowExecutionTypes.Manual,
      validationReference: 'FunctionType',
      validationTypeForReference: ValidationTypes.requiredIfRelatedValueIs,
    });

    TableBuilder.addColumn(columns, {
      name: 'FunctionType',
      desc: 'Function Type',
      type: ColumnTypes.Dropdown,
      align: 'center',
      allowNull: true,
      validate: true,
      validationReference: 'FK_WorkflowClientExecutionType',
      validationTypeForReference: ValidationTypes.required,
      requestedValue: WorkflowExecutionTypes.Manual,
    });

    TableBuilder.addColumn(columns, {
      name: 'FK_TimeZone',
      desc: 'Time Zone',
      type: ColumnTypes.Dropdown,
      align: 'center',
      allowNull: true,
      validate: true,
    });

    TableBuilder.addColumn(columns, {
      name: 'DesiredLocalTime',
      desc: 'Desired local time',
      type: ColumnTypes.TimeWithSpinners,
      align: 'center',
    });

    TableBuilder.addColumn(columns, {
      name: 'AvailableLocalTime',
      desc: 'Available local time',
      type: ColumnTypes.TimeUSFormat,
      align: 'center',
    });

    TableBuilder.addColumn(columns, {
      name: 'AvailableUTCTime',
      desc: 'Available UTC time',
      type: ColumnTypes.TimeUSFormat,
      align: 'center',
    });

    TableBuilder.addColumn(columns, {
      name: 'MaxAttempts',
      desc: 'Max Attempts',
      type: ColumnTypes.NumberInput,
      align: 'center',
      validate: true,
      //validationRegEx: regExLib.regex_GreaterThanZero,
      validationReference: 'WaitBetweenAttempts',
      validationTypeForReference: ValidationTypes.WFMaxAttempts,
    });

    TableBuilder.addColumn(columns, {
      name: 'WaitBetweenAttempts',
      desc: 'Wait between attempts (seconds)',
      type: ColumnTypes.NumberInput,
      align: 'center',
      validate: true,
      //validationRegEx: regExLib.regex_GreaterThanZero
      validationReference: 'WaitBetweenAttempts',
      validationTypeForReference: ValidationTypes.WFWaitBetweenAttempts,
    });

    TableBuilder.addColumn(columns, {
      name: 'FK_ScheduleFrequency',
      desc: 'Scheduled Frequency',
      type: ColumnTypes.Dropdown,
      align: 'center',
      allowNull: true,
      validate: true,
      validationReference: 'ScheduleFrequencyValue',
      validationTypeForReference: ValidationTypes.WFScheduleFrequency,
    });

    TableBuilder.addColumn(columns, {
      name: 'ScheduleFrequencyValue',
      desc: 'Scheduled Value',
      type: ColumnTypes.ScheduledValue,
      align: 'center',
      allowNull: true,
      related: 'FK_ScheduleFrequency',
      tooltip:
        'Enter number of weeks, and day of week when to execute workflow',
      validate: true,
      validationReference: 'FK_ScheduleFrequency',
      validationTypeForReference: ValidationTypes.WFScheduleFrequencyValue,
    });

    TableBuilder.addColumn(columns, {
      name: 'Active',
      desc: 'Active',
      type: ColumnTypes.BoolSwitch,
      align: 'center',
    });

    // TableBuilder.addColumn(columns, {
    //   name: 'Delete',
    //   desc: 'Delete',
    //   type: ColumnTypes.DeleteAction,
    //   align: 'center',
    //   icon: 'fas fa-times',
    // });

    TableBuilder.addColumn(columns, {
      name: 'Run',
      desc: 'Run',
      type: ColumnTypes.Button,
      align: 'center',
      icon: 'fas fa-play-circle',
    });

    TableBuilder.addColumn(columns, {
      name: 'Processing',
      desc: '',
      type: ColumnTypes.Button,
      align: 'center',
      icon: 'fa fa-spinner fa-spin',
    });

    TableBuilder.addColumn(columns, {
      name: 'Delete',
      desc: 'Delete',
      type: ColumnTypes.Button,
      align: 'center',
      icon: 'fas fa-times',
    });

    return columns;
  }

  public static getWorkflowDefinitionsTasks() {
    let columns: TableColumn[] = [];

    TableBuilder.addColumn(columns, {
      name: 'Sequence',
      desc: 'Order',
      type: ColumnTypes.TextInput,
      align: 'center',
      width: 80,
      validate: true,
    });

    TableBuilder.addColumn(columns, {
      name: 'FK_WorkflowTask',
      desc: 'Task Name',
      type: ColumnTypes.Dropdown,
      align: 'center',
      allowNull: true,
    });

    TableBuilder.addColumn(columns, {
      name: 'Active',
      desc: 'Active',
      type: ColumnTypes.BoolSwitch,
      align: 'center',
    });

    return columns;
  }

  public static getPaycardNetworks() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'NetworkName',
      'Network',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c1.readonly = true;
    c1.align = 'center';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'Rails',
      'Rails',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c2.readonly = true;
    c2.align = 'center';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'Fee',
      'Fee',
      ColumnTypes.NumberInput,
      null,
      null,
      true
    );
    c3.align = 'center';
    c3.disabledParameterReference = 'FeeDisabled';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'FeePayer',
      'FeePayer',
      ColumnTypes.Dropdown,
      null,
      null,
      true
    );
    c4.options = [];
    c4.options.push({ label: 'No fees', value: 0, isDisabled: true });
    c4.options.push({ label: 'Employee pays', value: 1, isDisabled: false });
    c4.options.push({ label: 'Employer pays', value: 2, isDisabled: false });
    c4.disabledParameterReference = 'FeePayerDisabled';
    c4.align = 'center';
    columns.push(c4);
    return columns;
  }

  public static getBilling() {
    let columns: TableColumn[] = [];

    let c1: TableColumn = new TableColumn(
      'Location',
      'Location Name',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c1.readonly = true;
    c1.align = 'center';
    columns.push(c1);

    let c2: TableColumn = new TableColumn(
      'MonthOfInvoice',
      'Month of invoice',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c2.readonly = true;
    c2.align = 'center';
    columns.push(c2);

    let c3: TableColumn = new TableColumn(
      'InvoiceNumber',
      'Invoice number',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c3.align = 'center';
    columns.push(c3);

    let c4: TableColumn = new TableColumn(
      'Amount',
      'Amount',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c4.align = 'center';
    columns.push(c4);

    let c5: TableColumn = new TableColumn(
      'Balance',
      'Balance',
      ColumnTypes.Text,
      null,
      null,
      true
    );
    c5.align = 'center';
    columns.push(c5);

    let c6: TableColumn = new TableColumn(
      'OverdueDays',
      'Overdue days',
      ColumnTypes.Number,
      null,
      null,
      true
    );
    c6.align = 'center';
    columns.push(c6);

    let c7: TableColumn = new TableColumn(
      'Link',
      'Link for payment',
      ColumnTypes.TextLink,
      null,
      null,
      true
    );
    c7.align = 'center';
    columns.push(c7);
    return columns;
  }

  public static getWorkflowsTaskLogDetails() {
    let columns: TableColumn[] = [];

    TableBuilder.addColumn(columns, {
      name: 'FK_WorkflowStatus',
      desc: 'Execution status',
      type: ColumnTypes.Text,
      align: 'center',
    });

    TableBuilder.addColumn(columns, {
      name: 'Action',
      desc: 'Action',
      type: ColumnTypes.Text,
      align: 'center',
    });

    TableBuilder.addColumn(columns, {
      name: 'UserFrendlyMessage',
      desc: 'Message',
      type: ColumnTypes.Text,
      align: 'center',
    });

    TableBuilder.addColumn(columns, {
      name: 'Message',
      desc: 'Details',
      type: ColumnTypes.Modal,
      align: 'center',
    });

    return columns;
  }


  public static buildTableColumnsFromModel(
    columns: EventCollection<Column>,
    pageObjectCode: string,
    width?: number
  ) {
    let tableColumns: TableColumn[] = [];

    columns.values
      .filter(
        (column) =>
          column.data.IsVisible && column.data.PageObjectLayout.DefaultVisible
      )
      .sort(
        (a, b) => a.data.PageObjectLayout.Order - b.data.PageObjectLayout.Order
      )
      .forEach((column) => {
        let tableColumn = new TableColumn(
          column.code,
          column.title,
          this.dataTypeToColumnType(
            column.data.DataType,
            column.data.PageObjectLayout
              ? column.data.PageObjectLayout.PropertyType
              : null
          ),
          null,
          null,
          true,
          width
            ? width
            : column.data.PageObjectLayout && column.data.PageObjectLayout.Width
            ? column.data.PageObjectLayout.Width
            : undefined,
          null,
          null,
          column.data.PageObjectLayout && column.data.PageObjectLayout.Icon
            ? column.data.PageObjectLayout.Icon
            : undefined
        );
        if (tableColumn.type == ColumnTypes.Currency) {
          tableColumn.minFractionDigits = tableColumn.minFractionDigits ?? 2;
          tableColumn.maxFractionDigits = tableColumn.maxFractionDigits ?? 2;
        }
        tableColumn.readonly = true; //set readonly by default, mybe this should be defined in pageobjectlayout
        if (tableColumn.type == ColumnTypes.Button) {
          tableColumn.disabled = column.data.IsDisabled;
          tableColumn.readonly = false;
        }
        tableColumn.align =
          column.data.PageObjectLayout && column.data.PageObjectLayout.Align
            ? column.data.PageObjectLayout &&
              column.data.PageObjectLayout.Align == -1
              ? 'left'
              : 'right'
            : 'center';
        tableColumns.push(tableColumn);
      });

    tableColumns = tableColumns.concat(
      TableBuilder.getAdditionalColums(pageObjectCode)
    );

    return tableColumns;
  }

  public static buildTableColumnsFromModelWithAdjustment(
    columns: EventCollection<Column>,
    pageObjectCode: string,
    width?: number,
    readonly?,
    withDisabled?
  ) {
    let tableColumns: TableColumn[] = [];

    columns.values
      .filter(
        (column) =>
          (!column.data.IsDisabled || withDisabled) &&
          column.data.IsVisible &&
          column.data.IsVisibleOnEdit
      )
      .sort(
        (a, b) => a.data.PageObjectLayout.Order - b.data.PageObjectLayout.Order
      )
      .forEach((column) => {
        let tableColumn = new TableColumn(
          column.code,
          column.title,
          this.dataTypeToColumnType(
            column.data.DataType,
            column.data.PageObjectLayout
              ? column.data.PageObjectLayout.PropertyType
              : null
          ),
          null,
          null,
          true,
          width
            ? width
            : column.data.PageObjectLayout && column.data.PageObjectLayout.Width
            ? column.data.PageObjectLayout.Width
            : undefined,
          null,
          null,
          column.data.PageObjectLayout && column.data.PageObjectLayout.Icon
            ? column.data.PageObjectLayout.Icon
            : undefined
        );
        if (tableColumn.type == ColumnTypes.Currency) {
          tableColumn.minFractionDigits = tableColumn.minFractionDigits ?? 2;
          tableColumn.maxFractionDigits = tableColumn.maxFractionDigits ?? 2;
        }
        tableColumn.readonly = readonly; //set readonly by default, maybe this should be defined in pageobjectlayout
        tableColumn.align =
          column.data.PageObjectLayout && column.data.PageObjectLayout.Align
            ? column.data.PageObjectLayout &&
              column.data.PageObjectLayout.Align == -1
              ? 'left'
              : 'right'
            : 'center';
        tableColumns.push(tableColumn);
      });

    return tableColumns;
  }

  private static dataTypeToColumnType(dataType, propertyType) {
    switch (dataType) {
      case 1:
        return ColumnTypes.Text;
      case 2:
        return ColumnTypes.Number;
      case 3:
        return ColumnTypes.Currency;
      case 4:
        if (propertyType && propertyType == PropertyTypes.DateTimeString)
          return ColumnTypes.DateTimeString;
        else return ColumnTypes.Date;
      case 5:
        if (propertyType && propertyType == PropertyTypes.TimeUSFormat)
          return ColumnTypes.TimeUSFormat;
        else return ColumnTypes.TimeWithSpinners;
      case 6:
        return ColumnTypes.TimeSpan;
      case 7:
        return ColumnTypes.ButtonText;
      case 8:
        return ColumnTypes.TimeChangeLog;
      case 9:
        return ColumnTypes.Icon;
      case 13:

      default:
        if (propertyType == PropertyTypes.Button) return ColumnTypes.Button;
        if (propertyType == PropertyTypes.BoolShowAction)
          return ColumnTypes.BoolShowAction;
        if (propertyType == PropertyTypes.InputSwitch)
          return ColumnTypes.BoolSwitch;
        return ColumnTypes.Text;
    }
  }

  private static GetOptions() {
    var toRet = [
      { label: 'N/A', value: 'N/A' },
      { label: 'ALOHA', value: 'ALOHA' },
      { label: 'MICROS', value: 'MICROS' },
      { label: 'POSITOUCH', value: 'POSITOUCH' },
      { label: 'RM', value: 'RM' },
      { label: 'FOCUS', value: 'FOCUS' },
      { label: 'ALDELO', value: 'ALDELO' },
      { label: 'REVEL', value: 'REVEL' },
      { label: 'DIGITALDINING', value: 'DIGITALDINING' },
      { label: 'TOAST', value: 'TOAST' },
      { label: 'BREADCRUMB', value: 'BREADCRUMB' },
      { label: 'NCR', value: 'NCR' },
      { label: 'NEC', value: 'NEC' },
      { label: 'BE', value: 'BE' },
      { label: 'BRINKERALOHA', value: 'BRINKERALOHA' },
      { label: 'SULTIMATE', value: 'SULTIMATE' },
      { label: 'BRINK', value: 'BRINK' },
      { label: 'GDA', value: 'GDA' },
      { label: 'NORTHSTAR', value: 'NORTHSTAR' },
      { label: 'EMAGINE', value: 'EMAGINE' },
      { label: 'HMS', value: 'HMS' },
      { label: 'ROSNET', value: 'ROSNET' },
      { label: 'SQUARE', value: 'SQUARE' },
      { label: 'CLOVER', value: 'CLOVER' },
      { label: "LINGA", value: "LINGA" },
      { label: "HEARTLAND", value: "HEARTLAND" },
      { label: "HMSDirectSync", value: "HMSDirectSync" },
      { label: "CTUIT", value: "CTUIT" },
      { label: "AGILYSYS", value: "AGILYSYS" },
      { label: "Squirrel", value: "Squirrel" },
      { label: "SIMPHONY", value: "SIMPHONY" },
      { label: "SIMPHONYEXPORT", value: "SIMPHONYEXPORT" },
      { label: "BrinkerWebhook", value: "BrinkerWebhook" },
      { label: "NCRAPI", value: "NCRAPI" },
      { label: "HMSQSR", value: "HMSQSR" },
      { label: "SilverWare", value: "SilverWare" }
    ];

    return toRet.sort((a, b) => a.label.localeCompare(b.label));
  }
}
