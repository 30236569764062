import { FormProperty } from './formProperty';
import { PropertyTypes } from '../enum/propertyTypes';
import { EventEmitter } from '@angular/core';
import { GSLocationDropdownControl } from '../../controls/gs.locationDropdown.control';

export class FormLocationDropdownProperty extends FormProperty {
  get options() {
    return this._options;
  }
  set options(value) {
    this._options = value;
    this.optionsSet.next();
  }
  private _options?: any[];
  source: any;
  type = PropertyTypes.LocationDropdown;
  component = GSLocationDropdownControl;
  changed = new EventEmitter<any>();
  optionsSet = new EventEmitter<void>();
  defaultLabel = 'Choose';
  preselectFirst = false;
  showClear = false;

  constructor(
    name: string,
    label: string,
    options?: [],
    row?: number,
    column?: number,
    rowspan?: number,
    columnspan?: number,
    valid?: boolean
  ) {
    super(name, label, row, column, rowspan, columnspan, valid);
    this.options = options ? options : this.options;
  }
}
