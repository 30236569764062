import { finalize, tap } from 'rxjs/operators';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Session } from '../entity/entities';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
  count = 0;
  ignoreList = [
    'PDAPGlobalConfiguration/getGlobalConfiguration'
  ];

  constructor(private spinner: NgxSpinnerService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.count++;
    //STRANGE< BUT IT WORKS LIKE THIS WITH SETTIMEOUT ON SUBSEQUENT CALLS. ONLY this.spinner.show() WONT WORK IF hide() IS CALLED PREVIOUSLY
      setTimeout(() => {
        /** spinner ends after API response */
        if (this.count > 0)
          Session.showSpinner = true && !Session.suppressSpinner;
        //this.spinner.show();
      }, 0);
    return next
      .handle(req)

      .pipe(
        tap(
          (event) => console.log(event),

          (error) => console.log(error)
        ),
        finalize(() => {
          this.count--;
          if (this.count == 0) Session.showSpinner = false;
          //this.spinner.hide();
        })
      );
  }
}
