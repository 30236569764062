<div class="row form-property calendar-from-to" (focus)="focus($event)">
  <div
    class="col-lg-{{ tooltip ? 11 : 12 }}"
    [ngClass]="{ 'no-padding-right': tooltip }"
  >
    <span class="p-float-label">
      <p-calendar
        styleClass="date-from"
        [hideOnDateTimeSelect]="false"
        #dateFromCalendar
        [(ngModel)]="dateFrom"
        (onClickOutside)="
          dateFromCalendar.overlayVisible = true;
          dateToCalendar.overlayVisible = true
        "
        (ngModelChange)="change($event)"
        [maxDate]="dateTo"
      >
        <ng-template pTemplate="header">
          <div class="calendar-from-to-header-text">
            <span>
              {{ dateFrom | date: "EEEE, MMMM d, y" }}
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="date" let-date>
          <div class="{{ date | dateCompare: dateFrom:dateTo:'from' }}">
            {{ date.day }}
          </div>
        </ng-template>
        <ng-template pTemplate="footer">
          <div class="calendar-from-to-footer right">
            <span>
              <p (click)="filter(f, $event)" *ngFor="let f of filtersLeft">
                {{ f.label }}
              </p>
            </span>
          </div>
        </ng-template>
      </p-calendar>
      <p-calendar
        styleClass="date-to"
        #dateToCalendar
        [hideOnDateTimeSelect]="false"
        [(ngModel)]="dateTo"
        (onClickOutside)="dateToCalendar.overlayVisible = true"
        [minDate]="dateFrom"
        (ngModelChange)="change($event)"
      >
        <ng-template pTemplate="date" let-date>
          <div class="{{ date | dateCompare: dateFrom:dateTo:'to' }}">
            {{ date.day }}
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <div class="calendar-from-to-header-text">
            <span class="arrow">
              <i class="pi pi-arrow-right"></i>
            </span>
            <span>
              {{ dateTo | date: "EEEE, MMMM d, y" }}
            </span>
          </div>
          <span class="calendar-from-to-header-close">x</span>
        </ng-template>

        <ng-template pTemplate="footer">
          <div class="calendar-from-to-footer left">
            <span>
              <p (click)="filter(f, $event)" *ngFor="let f of filtersRight">
                {{ f.label }}
              </p>
            </span>
          </div>
        </ng-template>
      </p-calendar>

      <i
        (click)="showCalendar($event)"
        class="pi pi-calendar calendar-icon"
      ></i>
      <input
        [(ngModel)]="inputValue"
        pInputText
        style="width: 100%; height: 41px"
        (click)="showCalendar($event)"
      />

      <label class="gs-float-label" [ngClass]="{ 'error-color': notValid }">{{
        label
      }}</label>
      <span
        *ngIf="notValid"
        class="form-error"
        pTooltip="{{ property.validationMessage }}"
        tooltipPosition="top"
      >
        <i
          style="margin-right: 15px"
          class="fas fa-info-circle fa-2x form-valid-icon with-icon error-color"
        ></i>
      </span>
    </span>
  </div>
  <div *ngIf="tooltip" class="col-lg-1 property-tooltip">
    <span pTooltip="{{ property.tooltip }}">
      <i class="fas fa-info-circle master-color"></i>
    </span>
  </div>
</div>
