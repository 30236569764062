import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Session, Item, UIElement } from '../../../common/entity/entities';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CustomerClient } from '../../../client/customer.client';

@Component({
  selector: 'button-bar',
  templateUrl: './buttonBar.control.html',
})
export class ButtonBarControl implements OnInit, OnDestroy {
  @Input() showPrev: boolean = true;
  @Input() showNext: boolean = true;
  @Input() showApply: boolean = true;
  @Input() showUndo: boolean = true;
  @Input('inUse') _inUse: boolean = true;
  @Input('locked') _locked: boolean = true;
  private _item: Item;
  @Input()
  set item(val) {
    this._item = val;
  }
  get item(): Item {
    return this._item;
  }
  @Output() apply = new EventEmitter<any>();
  @Output() undo = new EventEmitter<any>();
  @Output() previous = new EventEmitter<any>();
  @Output() next = new EventEmitter<any>();

  history: any = {};

  @ViewChild('historyLogComment') historyLogComment;

  get menuItem() {
    if (Session && Session.menu && Session.menu.menuItems)
      return Session.menu.menuItems.get('pdap_integration');
  }

  get items() {
    if (this.menuItem) return this.menuItem.items.values;
  }

  get selected() {
    if (this.menuItem) return this.menuItem.items.selected;
  }

  get integrationMenuItem() {
    return Session.menu.menuItems.get('pdap_integration');
  }

  get selectedPage() {
    return Session.menu.menuItems
      .get('pdap_integration')
      .pages.values.find((p) => p.selected);
  }

  get isFirstStep() {
    return (
      Session.menu.menuItems
        .get('pdap_integration')
        .pages.values.indexOf(this.selectedPage) <= 0
    );
  }

  get isLastStep() {
    return (
      Session.menu.menuItems
        .get('pdap_integration')
        .pages.values.indexOf(this.selectedPage) >=
      Session.menu.menuItems.get('pdap_integration').pages.values.length - 1
    );
  }

  get prevText() {
    let selectedPage = this.selectedPage;
    if (selectedPage)
      return this.integrationMenuItem.pages.values[
        this.integrationMenuItem.pages.values.indexOf(selectedPage) - 1
      ].title;
  }

  get nextText() {
    let selectedPage = this.selectedPage;
    if (selectedPage)
      return this.integrationMenuItem.pages.values[
        this.integrationMenuItem.pages.values.indexOf(selectedPage) + 1
      ].title;
  }

  get applyEnabled() {
    if (this.historyLogComment)
      return (
        (this.historyLogComment.overlayVisible && this.history.comment) ||
        (!this.historyLogComment.overlayVisible && this.item.isDirty)
      );
    else return false;
  }

  get inUse() {
    if (this._inUse && this.item) return this.item?.data?.InUse;
    return true;
  }

  get locked() {
    if (this._locked && this.item) return this.item?.data?.Locked;
    return true;
  }

  //#region -------Constructor
  constructor(
    private translate: TranslateService,
    private router: Router,
    private customerClient: CustomerClient
  ) {}
  //#endregion

  //#region -------NG Events
  ngOnInit() {
    ////get wizard dashboard if not already retrieved
    //if (this.selectedPage.code != 'pdap_integration_customer_wizarddash' && !this.selected.items.keys.some(k => k == 'pdap_integration_customer_wizarddash')) {
    //  this.customerClient.getCustomerWizardDashboard(this.translate.instant('wizardDashboard.get'), this.selected.id).then(data => {
    //    if (data) {
    //      let item = new Item(this.items, data);
    //      data.forEach(wd => {
    //        let wdItem = new Item(item.items, wd);
    //        item.items.add(wd.ID, wdItem);
    //      });
    //      this.selected.items.add('pdap_integration_customer_wizarddash', item);
    //    }
    //  });
    //}
  }

  ngOnDestroy() {}
  //#endregion

  //#region -------UI Events
  doApply(event) {
    let finished = this.item.data.Finished;
    if (finished && !this.historyLogComment.overlayVisible) {
      if (!this.historyLogComment.overlayVisible)
        this.historyLogComment.toggle(event);
    } else if (!finished || (finished && this.history.comment)) {
      if (this.historyLogComment.overlayVisible)
        this.historyLogComment.toggle(event);
      this.apply.emit({
        e: event,
        item: this.item,
        comment: this.history.comment,
      });
    }

    ////TODO: this is a bit dirty way to reference desired dashboard step...
    //  let loadData = this.selected.items.get('pdap_integration_customer_wizarddash');
    //  if (!loadData && this.selected.id) {
    //      this.customerClient.getCustomerWizardDashboard(null, this.selected.id).then(data => {
    //          if (data) {
    //              let item = new Item(this.items, data);
    //              data.forEach(wd => {
    //                  let wdItem = new Item(item.items, wd);
    //                  item.items.add(wd.ID, wdItem);
    //              });
    //              this.selected.items.add('pdap_integration_customer_wizarddash', item);
    //              let step = data[this.menuItem.pages.values.indexOf(this.selectedPage) - 2]
    //              this.applyChanges(step, event);
    //          }
    //      })
    //  } else {
    //      let step = this.selected.items.get('pdap_integration_customer_wizarddash').data[this.menuItem.pages.values.indexOf(this.selectedPage) - 2]
    //      this.applyChanges(step, event);
    //  }
  }

  doUndo(event) {
    this.undo.emit({ e: event, item: this.item });
  }

  doGoPrev(event) {
    let selectedPage = this.selectedPage;
    if (selectedPage)
      this.navigate(
        Session.menu.menuItems
          .get('pdap_integration')
          .pages.values.indexOf(selectedPage) - 1
      );
  }

  doGoNext(event) {
    let selectedPage = this.selectedPage;
    if (selectedPage)
      this.navigate(
        Session.menu.menuItems
          .get('pdap_integration')
          .pages.values.indexOf(selectedPage) + 1
      );
  }

  hideDialog(e) {
    this.history.comment = undefined;
  }

  //#endregion

  navigate(index) {
    if (this.integrationMenuItem.pages.values[index])
      this.router.navigate([
        `/${this.integrationMenuItem.pages.values[index].route}`,
      ]);
  }

  //  private applyChanges(finsihed, event) {
  //    if (finsihed && !this.historyLogComment.overlayVisible) {
  //        if (!this.historyLogComment.overlayVisible)
  //            this.historyLogComment.toggle(event);
  //    }
  //    else if (!finsihed || finsihed && this.history.comment) {
  //        this.historyLogComment.toggle(event);
  //        this.apply.emit({ e: event, item: this.item, comment: this.history.comment });
  //    }
  //}
}
