import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  Optional,
  Injectable,
  ChangeDetectorRef,
  HostListener,
} from '@angular/core';
import { GSBaseControl } from './gs.base.control';
import { FormProperty } from '../entity/form/formProperty';
import { Item } from '../entity/entities';
import { PropertyTypes } from '../entity/enums';
import { TableColumn } from '../entity/table/tableColumn';
import { TimeFormatUSPipe } from '../pipes/timeFormatUS.pipe';

@Component({
  selector: 'gs-time',
  templateUrl: './gs.time.control.html',
})
@Injectable()
export class GSTimeControl extends GSBaseControl implements OnInit, OnDestroy {
  @Input() minutesFormat = false;
  @Input() readOnly = false;
  @Input() inTable = false;

  @Input() item: any;
  @Input() column: TableColumn;

  PropertyTypes = PropertyTypes;
  @ViewChild('hoursSpinner') hoursSpinner;
  @ViewChild('minutesSpinner') minutesSpinner;

  //#region -------Properties
  _minutes: string;
  switchEnabled: boolean;
  get _minutesValue() {
    if (!this._minutes)
      if (this.data && this.data[this.name]) {
        this._minutes = (+this.data[this.name]
          .toString()
          .split(':')[1]).toString(); //this.data[this.name].toString().substring(3, 5);
      }
    return this._minutes;
  }
  get minutes() {
    return this._minutesValue;
  }

  _hours: string;
  get _hoursValue() {
    if (!this._hours)
      if (this.data && this.data[this.name])
        this._hours = (+this.data[this.name]
          .toString()
          .split(':')[0]).toString();
    return this._hours;
  }

  get hours() {
    return this._hoursValue;
  }

  get minutesFormatHours() {
    if (this.data)
      return Math.floor((this.data[this.name] ? this.data[this.name] : 0) / 60);
  }

  set minutesFormatHours(value) {
    if (this.data && !this.data[this.name]) {
      this.data[this.name] = 0;
    }
    this.data[this.name] = value * 60 + this.minutesFormatMinutes;
  }

  get minutesFormatMinutes() {
    if (this.data)
      return (this.data[this.name] ? this.data[this.name] : 0) % 60;
  }

  set minutesFormatMinutes(value) {
    if (this.data && !this.data[this.name]) {
      this.data[this.name] = 0;
    }
    this.data[this.name] = this.minutesFormatHours * 60 + value;
  }

  get hoursUS() {
    if (this.data && this.data[this.name]) {
      var dateUSFormat = this.timeUSpipe
        .transform(this.data[this.name])
        .toString();
      return (+dateUSFormat.split(':')[0]).toString();
    }
  }

  optionsAmPm = [
    { label: 'AM', value: 'AM' },
    { label: 'PM', value: 'PM' },
  ];
  AmPmvalue;
  //#endregionvalue

  //#region -------Constructor
  constructor(
    @Optional() prop: FormProperty,
    @Optional() item: Item,
    private changeDr: ChangeDetectorRef,
    private timeUSpipe: TimeFormatUSPipe
  ) {
    super(changeDr);
    this.property = prop;
    this.item = item;
  }
  //#endregion

  //#region -------NG Eents
  ngOnInit() {
    if (
      this.data &&
      (this.property.type == this.PropertyTypes.TimeUSFormat ||
        this.column == null ||
        (this.column != null && this.column.hoursFormat == 12))
    ) {
      if (parseInt(this.hours) >= 12) this.AmPmvalue = 'PM';
      else this.AmPmvalue = 'AM';
    }

    this.switchEnabled = this.data[this.name] != null;
  }

  ngOnDestroy() {}
  //#endregion

  //#region -------UI Events
  switchEnabledChange(e) {
    this.value = null;
    this.data[this.name] = null;
    this._hours = null;
    this._minutes = null;
    this.item.reCheckDirty();
    if (this.property && this.property.changed)
      this.property.changed.emit({
        propertyName: this.name,
        previousValue: undefined,
        newValue: this.value,
        event: null,
        item: this.item,
      });
  }

  changeHours(event) {
    let value = event?.value;
    if (!value) value = 0;
    if (this.data && !this.data[this.name]) {
      this.data[this.name] = '00:00' + (this.property.withSeconds ? ':00' : '');
    }
    this.data[this.name] = `${('00' + value).slice(-2).toString()}:${
      this.minutes ? ('00' + this.minutes).slice(-2) : '00'
    }`;
    this._hours = null;
    this.item.reCheckDirty();
  }

  formatHours(event, hours) {
    this.item.reCheckDirty();
    if (this.property && this.property.changed)
      this.property.changed.emit({
        propertyName: this.name,
        previousValue: undefined,
        newValue: this.value,
        event: event,
        item: this.item,
      });
    //this.validate();
    this.onChange.emit({
      propertyName: this.name,
      previousValue: undefined,
      newValue: this.value,
      event: event,
      item: this.item,
      property: this.property,
    });

    if (this.property.type == this.PropertyTypes.TimeUSFormat) {
      if (parseInt(this.hours) >= 12) this.AmPmvalue = 'PM';
      else this.AmPmvalue = 'AM';
    }
  }

  formatMinutes($event, minutes) {
    this.item.reCheckDirty();
    if (this.property && this.property.changed)
      this.property.changed.emit({
        propertyName: this.name,
        previousValue: undefined,
        newValue: this.value,
        event: event,
        item: this.item,
      });
    //this.validate();
    this.onChange.emit({
      property: this.property,
      propertyName: this.name,
      previousValue: undefined,
      newValue: this.value,
      event: $event,
      item: this.item,
    });
  }

  changeMinutes(event) {
    let value = event?.value;
    if (!value) value = 0;
    if (this.data && !this.data[this.name]) {
      this.data[this.name] = '00:00' + (this.property.withSeconds ? ':00' : '');
    }
    this.data[this.name] = `${this.hours}:${('00' + value)
      .slice(-2)
      .toString()}`;
    this._minutes = null;
    this.item.reCheckDirty();
  }

  minutesFormatMinutesChanged(event) {
    this.item.reCheckDirty();

    if (this.column)
      this.onChange.emit({
        originalEvent: event,
        row: this.item.data,
        column: this.column,
        columnName: this.name,
        selectedValue: this.value,
        item: this.item,
      });
  }

  minutesFormatHoursChanged(event) {
    this.item.reCheckDirty();
    if (this.column)
      this.onChange.emit({
        originalEvent: event,
        row: this.item.data,
        column: this.column,
        columnName: this.name,
        selectedValue: this.value,
        item: this.item,
      });
  }

  changeAmPm(event) {
    this.AmPmvalue = event.value;

    let value = parseInt(this.hours);
    if (!value) value = 12;
    if (this.AmPmvalue == 'PM' && value != 12) value = value + 12;
    if (value == 12 && this.AmPmvalue == 'AM') value = 0;

    if (this.data && !this.data[this.name]) {
      this.data[this.name] = '00:00' + (this.property.withSeconds ? ':00' : '');
    }
    this.data[this.name] = `${('00' + value).slice(-2).toString()}:${
      this.minutes ? ('00' + this.minutes).slice(-2) : '00'
    }`;
  }

  changeHoursUS(event) {
    let value = event?.value;
    if (!value) value = 12;
    if (this.AmPmvalue == 'PM' && value != 12) value = value + 12;
    if (value == 12 && this.AmPmvalue == 'AM') value = 0;

    if (this.data && !this.data[this.name]) {
      this.data[this.name] = '00:00' + (this.property.withSeconds ? ':00' : '');
    }
    this.data[this.name] = `${('00' + value).slice(-2).toString()}:${
      this.minutes ? ('00' + this.minutes).slice(-2) : '00'
    }`;

    this.item.reCheckDirty();
  }
  //#endregion

  //#region -------Private
  //#endregion
}
