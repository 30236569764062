import { FormProperty } from './formProperty';
import { PropertyTypes } from '../enum/propertyTypes';
import { EventEmitter, Injectable } from '@angular/core';
import { GSMaskInputControl } from '../../controls/gs.maskInput.control';

export class formMaskInputProperty extends FormProperty {
    type = PropertyTypes.MaskInput;
    component = GSMaskInputControl;
  changed = new EventEmitter<any>();
  keydown = new EventEmitter<any>();
  onpaste = new EventEmitter<any>();
  afterText;
  isMaskChanged: boolean = false;

  constructor(name: string, label: string, row?: number, column?: number,
    rowspan?: number, columnspan?: number, validate?: boolean, validationRegex?: RegExp, align?, public textRows?, public maxLen?, public mask?, public placeholder?) {
        super(name, label, row , column ,
          rowspan, columnspan, validate, validationRegex, align);

  }

}
