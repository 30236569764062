import { Injectable, inject } from '@angular/core';

import { tap } from 'rxjs/operators';
import { from } from 'rxjs';
import { SvcClients } from 'src/app/common/client/SvcClients';
import { BaseClient } from 'src/app/common/client/base.client';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WorkflowService extends BaseClient {
  constructor() {
    super();
  }

  getWorkflows(customerCode) {
    return from(
      this.get(
        SvcClients.AdminSvc,
        `PDAPWorkflows/GetWorkflows?customerCode=${customerCode}`
      )
    );
  }

  getWorkflowDefinitions(customerCode) {
    return from(
      this.get(
        SvcClients.AdminSvc,
        `PDAPWorkflows/GetWorkflowClientExecutionConfigurations?customerCode=${customerCode}`
      )
    );
  }

  getWorkflowDefinitionTasks(workflowClientId, customerCode, isManualGeneric , gsFunctionType) {
    return from(
      this.get(
        SvcClients.AdminSvc,
        `PDAPWorkflows/GetWorkflowTaskClients?workflowClientId=${workflowClientId}&customerCode=${customerCode}&isManualGeneric=${isManualGeneric}&gsFunctionType=${gsFunctionType}`
      )
    );
  }

  GetScheduleFrequencyType() {
    return from(
      this.get(SvcClients.AdminSvc, `PDAPWorkflows/GetScheduleFrequencyType`)
    );
  }

  GetWorkflowClientExecutionType() {
    return from(
      this.get(
        SvcClients.AdminSvc,
        `PDAPWorkflows/GetWorkflowClientExecutionType`
      )
    );
  }

  GetWorkflowTask() {
    return from(this.get(SvcClients.AdminSvc, `PDAPWorkflows/GetWorkflowTask`));
  }

  GetNewWorkflowTaskClient(workflowTaskId, clientCode, clientType) {
    return from(
      this.get(
        SvcClients.AdminSvc,
        `PDAPWorkflows/GetNewWorkflowTaskClient?workflowTaskId=${workflowTaskId}&clientCode=${clientCode}&clientType=${clientType}`
      )
    );
  }

  SetWorkflowDefinitions(customerCode, payload) {
    return from(
      this.post(
        SvcClients.AdminSvc,
        `PDAPWorkflows/SaveWorkflowConfiguration?customerCode=${customerCode}`,
        payload
      )
    );
  }

  executeWorkflow(workflowParams: any) {
    return from(
      this.post(
        SvcClients.AdminSvc,
        `PDAPWorkflows/ExecuteWorkflow`,
        workflowParams,
        undefined,
        undefined,
        true,
        null,
        false,
        workflowParams.processID
      )
    );
  }


}
