import { Component, OnInit, OnDestroy, Inject, Optional, Injectable, ChangeDetectorRef } from '@angular/core'
import { GSBaseControl } from './gs.base.control';
import { DatePipe } from '@angular/common';
import { FormDateProperty } from '../entity/form/formDate.property';
import { Item } from '../entity/entities';

@Component({
  selector: 'gs-calendar-range',
  templateUrl: './gs.calendarRange.control.html',
  providers: [DatePipe]
})
@Injectable()
export class GSCalendarRangeControl extends GSBaseControl implements OnInit, OnDestroy {
    //#region -------Properties
  _rangeDates: Date[];
  get rangeDates(){
    if(!this._rangeDates && this.value)
      return this.value
    return this._rangeDates;
  }

  set rangeDates(val){
    this._rangeDates = val;
  }
    //#endregion

  //#region -------Constructor
  constructor(@Inject(DatePipe) private datepipe: DatePipe, private changeDr: ChangeDetectorRef) {
    super(changeDr);
  }
  //#endregion

    //#region -------NG Eents
    ngOnInit() {
    }

    ngOnDestroy() {
    }
    //#endregion

    //#region -------UI Events
  change(e) {
    this.value = [];
    if (e && e.length > 0)
      e.forEach((v, i) => {
        if (v)
          this.value[i] = this.datepipe.transform(v, 'yyyy-MM-dd');
        else
          this.value[i] = null;
      });

    let previousValue = this.data[this.name];
    this.value = e;
    this.item.reCheckDirty();
    this.onChange.emit({ propertyName: this.name, newValue: this.value, event: e, item: this.item, property: this.property});
    this.property.changed.emit({ propertyName: this.name, previousValue: previousValue, newValue: this.value, event: e, item: this.item, property: this.property});

  }
    //#endregion

    //#region -------Private
    //#endregion
}
