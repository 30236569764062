import { Component, OnInit, OnDestroy, EventEmitter, Output, Input, Inject, Optional, Injectable, ChangeDetectorRef } from '@angular/core'
import { GSBaseControl } from './gs.base.control';
import { FormDropdownProperty } from '../entity/form/formDropdown.property';
import { Item } from '../entity/entities';

@Component({
  selector: 'gs-multiselect',
  templateUrl: './gs.multiSelect.control.html'
})
@Injectable()
export class GSMultiSelectControl extends GSBaseControl implements OnInit, OnDestroy {
  //#region -------Properties

  get options() { return this.property.options }
  //#endregion

  //#region -------Constructor
  constructor(@Optional() prop: FormDropdownProperty, @Optional() item: Item, private changeDr: ChangeDetectorRef) {
    super(changeDr);
        this.property = prop;
        this.item = item;
    }
  //#endregion

  //#region -------NG Eents
  ngOnInit() {
  }

  ngOnDestroy() {
  }
  //#endregion

  //#region -------UI Events

  change(e) {
    if (e) {
      let previousValue = this.data[this.name];
      this.value = e.value;
      this.item.reCheckDirty();
      this.onChange.emit({ propertyName: this.name, previousValue: previousValue, newValue: this.value, event: e, item: this.item, property: this.property});
      this.property.changed.emit({ propertyName: this.name, previousValue: previousValue, newValue: this.value, event: e, item: this.item, property: this.property });
    }
  }

  //#endregion

  //#region -------Private
  //#endregion
}
