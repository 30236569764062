<div>
  <ng-container *ngIf="showHeaderAndText">
    <p>{{'login.manageAgreementTextHeader'|gstranslate}}</p>
    <br /><br />
    <p>{{'login.manageAgreementText'|gstranslate}}</p>
  </ng-container>
  <!--<div *ngIf="innerHTML" [innerHTML]="innerHTML"></div>-->
  <iframe [src]="urlString" width="100%" height="500"></iframe>

</div>

