import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Optional,
  Injectable,
  ChangeDetectorRef,
  ViewChild,
  SimpleChanges,
} from '@angular/core';
import { GSBaseControl } from './gs.base.control';
import { FormDropdownProperty } from '../entity/form/formDropdown.property';
import { Item } from '../entity/entities';
import { TableColumn } from '../entity/table/tableColumn';
import { TranslateService } from '@ngx-translate/core';
import { TableBuilder } from '../builder/gs.table.builder';
import { TableTypes } from '../entity/enums';
import { CustomerClient } from '../../client/customer.client';
import { WorkflowExecutionStatuses } from '../entity/enum/workflowExecutionStatuses';

@Component({
  selector: 'gs-wf-log-details',
  templateUrl: './gs.wfLogDetails.control.html',
})
@Injectable()
export class WFLogDetailsControl
  extends GSBaseControl
  implements OnInit, OnDestroy {
  //#region -------Properties

  @Input() item: any;
  @Input() column: TableColumn;
  @Input() loadData: boolean;
  @ViewChild('wfLogDetailsTable') table;

  columns: TableColumn[];
  wfLogItem: Item;
  logDetailsCount;

  get placeholder() {
    if (this.column) {
      return this.column.placeholder;
    }
    return this.property.defaultLabel;
  }

  get pageSize() {
    return this.item?.parameters['wfLogDetails_pageSize'] || 10;
  }

  //#endregion

  //#region -------Constructor
  constructor(
    @Optional() prop: FormDropdownProperty,
    @Optional() item: Item,
    private changeDr: ChangeDetectorRef,
    private translate: TranslateService,
    private customerClient: CustomerClient
  ) {
    super(changeDr);
    this.property = prop;
    this.item = item;
  }
  //#endregion

  //#region -------NG Eents

  ngOnInit() {
    this.initiate();
    this.wfLogItem = new Item(null, []);
  }

  private initiate() {
    this.columns = TableBuilder.build(
      TableTypes.WorkflowTaskLogDetails
    );
  }

  ngOnDestroy() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.loadData) {
        this.getData();
    }
  }

  //#endregion

  //#region -------UI Events

  getData = () => {
    if (this.loadData && this.item.data?.WFTaskClientExecutionID) {
      this.customerClient
        .getWorkflowsTaskExecutionLogDetails(this.item.data.WFTaskClientExecutionID)
        .then((response) => {
          if (response?.result) {
            this.wfLogItem.items.clear();
            response?.result.forEach((d, i) => {
              d.FK_WorkflowStatus = WorkflowExecutionStatuses[d.FK_WorkflowStatus]
              this.wfLogItem.setItem(d, i);
            });
          }
        });
    }
  }

  pageSizeChange(e) {
    this.item.parameters['wfLogDetails_pageSize'] = e;
  }

  //#endregion

  //#region -------Private
  //#endregion
}
