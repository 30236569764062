import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as jspdf from 'jspdf';
import * as _ from 'lodash';
import html2canvas from 'html2canvas';

@Injectable({
  providedIn: 'root',
})
export class TableToExcelService {
  private EXCEL_TYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  private EXCEL_EXTENSION = '.xlsx';
  private CSV_EXTENSION = '.csv';

  constructor() {}

  public exportAsExcelFile(json: any[], excelFileName: string) {
    var reg = new RegExp(/^[0-9.,]+$/);
    json.forEach((row) => {
      Object.getOwnPropertyNames(row).forEach((prop) => {
        if (reg.test(row[prop]) && row[prop] instanceof String) {
          row[prop] = Number(row[prop].replace(',', ''));
        }
      });
    });
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAsCsvFile(json: any[], headers: any[], excelFileName: string) {
    let csv = this.ConvertToCSV(json, headers);

    this.saveAsCsvFile(csv, excelFileName);
  }

  private ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';
    for (let index in headerList) {
      row += headerList[index].description + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in headerList) {
        let head = headerList[index].name;
        let val = array[i][head] ? array[i][head].toString() : '';
        val.replace('"', '""');
        if (val.indexOf(',') > -1) val = val.replace(',', '');

        line += val + ',';
      }
      line = line.slice(0, -1);
      str += line + '\r\n';
    }
    return str;
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: this.EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + this.EXCEL_EXTENSION
    );
  }

  private saveAsCsvFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: this.EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + this.CSV_EXTENSION
    );
  }

  public getDataForExport(dataRowsFromTable, columnsFromTable) {
    return dataRowsFromTable.map((data) => {
      const newObj = {};
      columnsFromTable.map((column) => {
        newObj[column.description] = data[column.name];
      });
      return newObj;
    });
  }
}
