import { Component, OnInit, OnDestroy, Inject, Optional, Injectable, ChangeDetectorRef } from '@angular/core'
import { GSBaseControl } from './gs.base.control';
import { DatePipe } from '@angular/common';
import { FormInfoLabelProperty } from '../entity/form/formInfoLabel.property';
import { Item } from '../entity/entities';

@Component({
  selector: 'gs-info-label',
  templateUrl: './gs.infoLabel.control.html'
})
@Injectable()
export class GSInfoLabelControl extends GSBaseControl implements OnInit, OnDestroy {
    //#region -------Properties

    //#endregion

  //#region -------Constructor
  constructor(@Optional() prop: FormInfoLabelProperty, @Optional() item: Item, private changeDr: ChangeDetectorRef) {
    super(changeDr);
        this.property = prop;
        this.item = item;
    }
  //#endregion

    //#region -------NG Eents
    ngOnInit() {
    }

    ngOnDestroy() {
    }
    //#endregion

    //#region -------UI Events
    //#endregion

    //#region -------Private
    //#endregion
}
