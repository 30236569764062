import { FormProperty } from './formProperty';
import { PropertyTypes } from '../enum/propertyTypes';
import { EventEmitter, Injectable } from '@angular/core';
import { GSPhoneNumberControl } from '../../controls/gs.phoneNumber.control';

export class FormPhoneNumberProperty extends FormProperty {
    type = PropertyTypes.Textbox;
    component = GSPhoneNumberControl;
  changed = new EventEmitter<any>();
  keydown = new EventEmitter<any>();
  onpaste = new EventEmitter<any>();

  constructor(name: string, label: string, row?: number, column?: number,
        rowspan?: number, columnspan?: number, validate?: boolean) {
        super(name, label, row , column ,
            rowspan, columnspan, validate);        
  }

}
