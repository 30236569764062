import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Item } from '../../entity/entities';
import { TableColumn } from '../../entity/table/tableColumn';

@Component({
  selector: 'gs-scheduled-value',
  templateUrl: './gs.scheduled-value.control.ts.component.html',
  styleUrls: ['./gs.scheduled-value.control.ts.component.css'],
})
export class GsScheduledValueControlTsComponent implements OnChanges {
  @Input() item;
  @Input() readOnly;
  @Input('column') col;

  weekValue;
  dayValue;
  dayOptions = [
    { value: undefined, label: 'Please select day' },
    { value: '1', label: 'Monday' },
    { value: '2', label: 'Tuesday ' },
    { value: '3', label: 'Wednesday' },
    { value: '4', label: 'Thursday ' },
    { value: '5', label: 'Friday' },
    { value: '6', label: 'Saturday' },
    { value: '7', label: 'Sunday' },
  ];

  @Output() propertyChanged = new EventEmitter();
  dropdownCol = new TableColumn();
  numberCol = new TableColumn();

  constructor(@Optional() item: Item, private changeDr: ChangeDetectorRef) {
    this.item = item;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item) this.handleItemChange();
  }

  handleItemChange() {
    if (!this.item.data[this.col.name]) return;
    const values = this.item.data[this.col.name].split(':');
    if (values.length) {
      this.weekValue = values[0];
      this.dayValue = values[1];
    }
  }

  changed(e) {
    this.item.data[this.col.name] = `${this.weekValue ?? ''}:${
      this.dayValue ?? ''
    }`;
    this.propertyChanged.emit(e);
  }


  validField(item: Item, colName) {
    let validationInfo = item.validationInfo.find(
      (vi) => vi.fieldName == colName
    );
    if (validationInfo) return validationInfo.valid;

    return true;
  }
}
