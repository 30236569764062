import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { ReportingBaseComponent } from '../components/reportingBase.component';
import { Item, Session } from '../../../common/entity/entities';
import { FormBuilder } from '../../../common/builder/gs.form.builder';
import { FormTypes } from '../../../common/entity/enum/formTypes';
import { TranslateService } from '@ngx-translate/core';
import { FormDropdownProperty } from '../../../common/entity/form/formDropdown.property';
import { ReportingClient } from '../../../client/reporting.client';
import { InstallationComponent } from '../../customerWizard/components/installation.component';
import { FormButtonProperty } from '../../../common/entity/form/formButton.property';

@Component({
  selector: 'change-log-filter',
  templateUrl: './changeLogFilter.control.html',
})
export class ChangeLogFilterControl implements OnInit, OnDestroy {
  //#region -------Properties
  @Input() parent: Item;
  @Input() showSelectType: boolean = false;
  @Input() showRollbackButton: boolean = false;

  @Output() onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRollback: EventEmitter<any> = new EventEmitter<any>();
  itemName = 'changeLogFilter';

  get item() {
    return this.parent.items.get(this.itemName);
  }

  get loadButton(): FormButtonProperty {
    if (this.properties) return this.properties.find((p) => p.name == 'Load');
  }

  get rollbackButton(): FormButtonProperty {
    if (this.properties) return this.properties.find((p) => p.name == 'Rollback');
  }

  properties;
  //#endregion

  //#region -------Constructor
  constructor(
    private translate: TranslateService,
    private reportingClient: ReportingClient
  ) {}
  //#endregion

  //#region -------NG Events
  ngOnInit() {
    this.initiate();
  }

  ngOnDestroy() {}
  //#endregion

  //#region -------UI Events
  propertyChange(e) {
    this.loadButton.disabled = !this.item.isValid;
    this.rollbackButton.disabled = true;
    if (e.propertyName == 'Location') {
      this.item.data.CustomerIDs = [];
      e.newValue.forEach((val) => {
        let data = e.property.options.find((o) => o.value == val).data;
        this.item.data.CustomerIDs.push(data.ID);
      });
    }
  }

  load = (e) => {
    this.onLoad.emit(this.item.data);
    this.rollbackButton.disabled = !this.item.isValid;
  };

  rollback = (e) => {
    this.onRollback.emit(this.item.data);
  };
  //#endregion

  //#region -------Private
  initiate() {
    if (!this.parent.items.get(this.itemName)) this.setItem();
    this.properties = FormBuilder.build(
      FormTypes.ChangeLogFilter,
      this.translate
    );
    if (this.showSelectType) this.setTypeOptions();
    this.setLocationOptions();
    this.loadButton.click.subscribe(this.load);
    this.loadButton.disabled = !this.item.isValid;

    this.rollbackButton.click.subscribe(this.rollback);
    this.rollbackButton.disabled = true;
    this.rollbackButton.visible = this.showRollbackButton;
   
  }

  setItem() {
    let data = {
      Type: 0,
      DateRange: undefined,
      Location: undefined,
      CustomerIDs: undefined,
    };
    this.parent.setItem(data, this.itemName);
  }

  clear() {
    this.item.data = {
      Type: 0,
      DateRange: undefined,
      Location: undefined,
      CustomerIDs: undefined,
    };
  }

  setTypeOptions() {
    let prop: FormDropdownProperty = this.properties.find(
      (p) => p.name == 'Type'
    );
    prop.visible = true;
    prop.options = [];
    prop.options.push({ label: 'All', value: 0 });
    prop.options.push({ label: 'POS data changes', value: 1 });
    prop.options.push({ label: 'Calculated data changes', value: 2 });
  }

  setLocationOptions() {
    let prop: FormDropdownProperty = this.properties.find(
      (p) => p.name == 'Location'
    );
    this.reportingClient.getActiveCustomers().then((response) => {
      prop.options = [];
      if (
        response?.result.Customers &&
        response?.result.Customers instanceof Array
      ) {
        response?.result.Customers.forEach((val) => {
          prop.options.push({ label: val.Name, value: val.Code, data: val });
        });
      }

      this.loadButton.disabled = !this.item.isValid;
      this.rollbackButton.disabled = true;
    });
  }
  //#endregion
}
