import { Component, OnInit, OnDestroy, Inject, Optional, Injectable, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core'
import { GSBaseControl } from './gs.base.control';
import { Item } from '../entity/entities';
import { FormNumberProperty } from '../entity/form/formNumber.property';
import { FormNumberDecimalProperty } from '../entity/form/formNumberDecimal.property';
import { TableColumn } from '../entity/table/tableColumn';
import { isNumber } from 'util';

@Component({
  selector: 'gs-input-decimal',
  templateUrl: './gs.inputDecimal.control.html'
})
@Injectable()
export class GSInputDecimalControl extends GSBaseControl implements OnInit, OnDestroy {
  //#region -------Properties
  @Input() forceReadonly = false;
  @Input() column: TableColumn;
  @Input() item: any;
  public stringItem = { stringVal: '' };
  //#endregion

  //#region -------Constructor
  constructor(@Optional() prop: FormNumberDecimalProperty, @Optional() item: Item, private changeDr: ChangeDetectorRef) {
        super(changeDr);
        this.property = prop;
        this.item = item;
    }
  //#endregion

  //#region -------NG Eents
  ngOnInit() {
  }

  ngOnDestroy() {
  }
  //#endregion

  //#region -------UI Events
  change(e) {
    if (e || e == '') {
      let previousValue = this.data[this.name];
      //this.value = e; //value is set in decimal.ts directive!!!! here it would be set to string, not decimal!!!!!
      this.item.reCheckDirty();
      if (this.property) {
        this.onChange.emit({ propertyName: this.name, previousValue: previousValue, newValue: this.value, event: e, item: this.item, property: this.property });
        this.property.changed.emit({ propertyName: this.name, previousValue: previousValue, newValue: this.value, event: e, item: this.item, property: this.property });
      }
      else if (this.column && !isNaN(parseFloat(e)) ) {
        this.onChange.emit({ propertyName: this.column.name, previousValue: previousValue, newValue: this.value, event: e, item: this.item, column: this.column });
      }
    }
  }


  //#endregion

  //#region -------Private
  //#endregion
}
