import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { BrowserHelper } from '../utils/utils';
import { Session } from '../entity/entities';

@Pipe({ name: 'timeUS' })
export class TimeFormatUSPipe implements PipeTransform {
  transform(value, noDate?, hour12?): string {
    if (!value) return '';
    var date = '1970-01-01T';
    let time = '';
    if (noDate)
      return new Date(date + value + 'Z').toLocaleTimeString('en-US', {
        timeZone: 'UTC',
        hour12: hour12,
        hour: 'numeric',
        minute: 'numeric',
      });
    if (value.length > 8) {
      time = new Date(value + 'Z').toLocaleTimeString('en-US', {
        timeZone: 'UTC',
        hour12: true,
        hour: 'numeric',
        minute: 'numeric',
      });
    } else
      time = new Date(date + value + 'Z').toLocaleTimeString('en-US', {
        timeZone: 'UTC',
        hour12: true,
        hour: 'numeric',
        minute: 'numeric',
      });
    return time;
  }
}
