<div class="logo-spiner-container">
  <div class="logo-spiner" style="background-color:white; border-radius:50%">
    <img src="../../../assets/images/gs.logo.png" style="padding:15px" width="100" height="100" />
    <!--<div class="cycle-spiner">
      <div class="cycle" style="--start: 1;--duration: 5"></div>
      <div class="cycle" style="--start: 3;--duration: 4"></div>
      <div class="cycle" style="--start: 4;--duration: 3"></div>
      <div class="cycle" style="--start: 6;--duration: 2"></div>
    </div>-->
  </div>  
</div>

