import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Item } from '../entity/entities';
import { TableColumn } from '../entity/table/tableColumn';
import { ColumnTypes } from '../entity/enums';

@Component({
  selector: 'gs-table-data',
  templateUrl: './tableRow.control.html',
})
export class TableRowControl {
  @Input() item: Item;
  @Input() items;
  @Input() columns: Array<TableColumn>;
  @Input() col;
  @Input() readOnly = false;
  @Input() rowIndex;
  @Input() showSelectAll;
  @Input() expanded;
  @Input() wide = false; //defines will table be wide as parent element

  @Output() rowAction = new EventEmitter<any>();
  @Output() rowDataChanged = new EventEmitter<any>();
  @Output() selectRow = new EventEmitter<any>();
  @Output() selectCell = new EventEmitter<any>();

  Math = Math;
  ColumnTypes = ColumnTypes;
  showModal: boolean = false;

  handleChange($event, item, columnName: string, column: TableColumn) {
    if (
      column &&
      (column.type == ColumnTypes.DeleteAction ||
        column.type == ColumnTypes.EditAction ||
        column.type == ColumnTypes.Button ||
        column.type == ColumnTypes.EditActionText ||
        column.type == ColumnTypes.BoolShowAction)
    )
      this.rowAction.emit({
        originalEvent: $event,
        row: item.data,
        column: column,
        columnName: columnName,
        item: item,
      });
    else
      this.rowDataChanged.emit({
        originalEvent: $event,
        row: item ? item.data : undefined,
        column: column,
        columnName: columnName,
        item: item,
      });
  }

  handleRadioButtonChange(
    $event,
    item,
    columnName: string,
    column: TableColumn,
    value
  ) {
    this.rowDataChanged.emit({
      originalEvent: $event,
      row: item.data,
      column: column,
      columnName: columnName,
      selectedValue: value,
      item: item,
    });
  }

  onSelectRow(row) {
    this.selectRow.emit(row);
  }

  onSelectCell(event) {
    this.selectCell.emit(event);
  }

  validField(item: Item, colName) {
    let validationInfo = item.validationInfo.find(
      (vi) => vi.fieldName == colName
    );
    if (validationInfo) return validationInfo.valid;

    return true;
  }

  getValue(data, name) {
    if (name) {
      if (name.indexOf('.') == -1) return data[name];
      else {
        let props = name.split('.');
        let l = 0;
        let prop;
        while (props.length > l) {
          if (l == 0 && data[props[l]] == undefined) return;
          else prop = prop ? prop[props[l]] : data[props[l]];
          l++;
        }
        return prop;
      }
    }
  }

  getIcon(item, column) {
    let iconValuePair = column.iconValueSet.find(
      (iv) => iv.value == item.data[column.name]
    );
    if (iconValuePair)
      return column.iconValueSet.find(
        (iv) => iv.value == item.data[column.name]
      ).icon;
  }

  getIconColor(item, column) {
    let iconValuePair = column.iconValueSet.find(
      (iv) => iv.value == item.data[column.name]
    );
    if (iconValuePair)
      return column.iconValueSet.find(
        (iv) => iv.value == item.data[column.name]
      ).color;
  }

  isHidden(item, col) {
    return (
      item.parameters?.column &&
      item.parameters?.column[col.name] &&
      item.parameters?.column[col.name].hide
    );
  }

  showDialog($event, item, columnName: string, column: TableColumn) {
    this.showModal = true;
  }
}
