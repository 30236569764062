<div class="row form-property">
  <div
    class="col-lg-{{tooltip?11:12}}"
    [ngClass]="{'no-padding-right':tooltip}"
  >
    <span class="p-float-label">
      <p-autoComplete
        #dropdown
        id="{{name}}"
        [suggestions]="suggestions"
        (onSelect)="select($event)"
        [(ngModel)]="selectedText"
        [field]="field"
        [dropdown]="dropDown"
        [readonly]="readonly"
        [ngClass]="{'disabled':readonly, 'autocomplete-property-invalid':notValid}"
        [dropdownMode]="dropdownMode"
        [dropdownIcon]="dropdownIcon"
        [inputStyle]="{'width': 'inherit','margin-right': '-34px'}"
        [style]="{'width': '100%'}"
        (completeMethod)="complete($event)"
        (onBlur)="onBlur($event)"
        (ngModelChange)="changeAutocomplete($event)"
        (onDropdownClick)="dropdownClick($event)"
      >
      </p-autoComplete>
      <label class="gs-float-label" [ngClass]="{'error-color':notValid}"
        >{{label}}</label
      >
      <span
        *ngIf="notValid"
        class="form-error"
        pTooltip="{{property.validationMessage}}"
        tooltipPosition="top"
      >
        <i
          class="fas fa-info-circle fa-2x form-valid-icon with-icon error-color"
        ></i>
      </span>
    </span>
  </div>
  <div *ngIf="tooltip" class="col-lg-1 property-tooltip">
    <span pTooltip="{{property.tooltip}}">
      <i class="fas fa-info-circle master-color"></i>
    </span>
  </div>
</div>
