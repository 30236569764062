import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { Item, Session } from '../../../common/entity/entities';
import { DashboardClient } from '../../../client/dashboard.client';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { DatePipe } from '@angular/common';
import { TableToPDFService } from '../../../common/utils/tableToPdfService';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'calculated-data-details',
  templateUrl: './calculatedDataDetails.control.html',
  styleUrls: ['./calculatedDataDetails.control.css'],
})
export class CalculatedDataDetailsControl implements OnInit, OnDestroy {
  //#region -------Properties
  @Input() selectedItem: Item;
  @Input() customerCode;
  @Input() businessDate;
  @Input() businessDateTo;
  @Input() shift;
  @Input() employeeId;
  @Input() shiftId;
  @Input() shiftPK;
  @Input() jobId;
  @Input() jobPK;

  @ViewChild('infoTable') dtPrint;

  itemName = 'details';
  pdf;

  get item() {
    if (this.selectedItem.items.get(this.itemName))
      return this.selectedItem.items.get(this.itemName);
  }

  get tipsDetails() {
    return this.selectedItem?.items.get('tipsDetails');
  }

  get breakDownDetails() {
    return this.item?.items.selected?.items.values[0].items.values;
  }

  get breakDownDetails_sum() {
    return this.item?.items.selected?.data.OriginalAmount;
  }

  get breakDownDetailsTransactions() {
    return this.item?.items.selected?.items.values[0].items.selected?.items
      .values[0].items.values;
  }

  get windowSize() {
    return window?.innerWidth;
  }

  get tipsDetailsInfo() {
    const infos = this.tipsDetails?.data;
    if (!infos?.length) return [];
    return [...['Tip shares'], ...infos];
  }
  //#endregion

  //#region -------Constructor
  constructor(
    private dashboardClient: DashboardClient,
    private tableToPdfService: TableToPDFService,
    private render: Renderer2,
    private translte: TranslateService,
    private datepipe: DatePipe
  ) {}
  //#endregion

  //#region -------NG Events
  ngOnInit() {
    if (!this.businessDateTo) this.businessDateTo = this.businessDate;
    this.initiate();
  }

  ngOnDestroy() {}
  //#endregion

  //#region -------UI Events
  propertyChanged(e) {}

  waitForPrint = (callback, holder, type) => {
    if (!holder.dtPrint) {
      setTimeout(function () {
        window.requestAnimationFrame(function () {
          holder.waitForPrint(callback, holder, type);
        });
      }, 100);
    } else {
      callback(type);
    }
  };

  doPrint = (type) => {
    const printContent = this.dtPrint.nativeElement; //.cloneNode(true);
    const WindowPrt = window.open('', '_blank');

    WindowPrt.document.write(printContent.innerHTML);
    document.querySelectorAll('link, style').forEach((htmlElement) => {
      WindowPrt.document.head.appendChild(htmlElement.cloneNode(true));
    });
    setTimeout(() => {
      WindowPrt.document.close();
    }, 100);

    if (type == 'PRINT') {
      WindowPrt.focus();
      WindowPrt.print();
    } else {
      WindowPrt.onload = () => {
        this.tableToPdfService.saveToPdf(
          WindowPrt,
          WindowPrt.document.body,
          'export from grid'
        );
      };
    }
  };

  print() {
    const printContent = this.dtPrint.nativeElement.cloneNode(true);
    let divContinaer = this.render.createElement('div');
    let divInfoDate = this.render.createElement('div');
    let spanInfoDate = this.render.createElement('span');
    spanInfoDate.innerText = `${this.translte.instant(
      'dashboard.calculatedData.forPeriod'
    )} ${this.datepipe.transform(
      this.businessDate,
      'yyyy-MM-dd'
    )} to ${this.datepipe.transform(this.businessDateTo, 'yyyy-MM-dd')}`;
    this.render.appendChild(divInfoDate, spanInfoDate);

    let divInfoUser = this.render.createElement('div');
    let spanInfoUser = this.render.createElement('span');
    spanInfoUser.innerText = `${this.translte.instant(
      'dashboard.calculatedData.printBy'
    )} ${Session.userName}`;
    this.render.appendChild(divInfoUser, spanInfoUser);
    this.render.appendChild(divInfoUser, this.render.createElement('br'));
    this.render.appendChild(divInfoUser, this.render.createElement('br'));

    this.render.appendChild(divContinaer, divInfoDate);
    this.render.appendChild(divInfoUser, printContent);
    this.render.appendChild(divContinaer, divInfoUser);

    var blob = new Blob([divContinaer.innerHTML], { type: 'text/html' });
    const blobUrl = URL.createObjectURL(blob);

    const iframe = document.createElement('iframe');
    iframe.id = 'iframe';
    iframe.style.display = 'none';
    iframe.src = blobUrl;
    document.body.appendChild(iframe);
    setTimeout(() => {
      document.querySelectorAll('link, style').forEach((htmlElement) => {
        frames[0].document.head.appendChild(htmlElement.cloneNode(true));
      });
      iframe.focus();
      iframe.contentWindow.print();
      document.body.removeChild(iframe);
    }, 100);
  }

  //#endregion

  //#region -------Private
  initiate() {
    if (!this.item)
      this.dashboardClient
        .getEmployeeSharedDetailsByShift(
          this.customerCode,
          this.businessDate,
          this.businessDateTo,
          this.shiftPK,
          this.employeeId,
          this.jobPK
        )
        .then((response) => {
          if (response?.result.shiftDetail?.length) {
            this.selectedItem.setItem(response?.result.shiftDetail, 'details');
            response?.result.shiftDetail.forEach((val, i) => {
              this.item.setItem(val, i);
            });
          }
          if (response?.result.tipsDetails?.length) {
            this.selectedItem.setItem(
              response?.result.tipsDetails,
              'tipsDetails'
            );
          }
        });
  }

  seeBreakdown(breakDownItem) {
    if (!breakDownItem.items.get('details'))
      this.dashboardClient
        .getEmployeeSharedTipTransitionsForShift(
          this.customerCode,
          this.businessDate,
          this.businessDateTo,
          this.shiftId,
          this.employeeId,
          breakDownItem.data.GroupType,
          breakDownItem.data.TypeOfChange
        )
        .then((response) => {
          if (response?.result.length) {
            let detailsItem = breakDownItem.setItem(
              response?.result,
              'details'
            );
            response?.result.forEach((val, i) => {
              detailsItem.setItem(val, i);
            });
            breakDownItem.select();
          }
        });
    else breakDownItem.select();
  }

  transactionDetails(item) {
    if (!item.items.get('details'))
      this.dashboardClient
        .getEmployeeSharedTipTransitionsDetails(
          this.customerCode,
          this.businessDate,
          this.jobId,
          item.data.CheckId,
          this.item?.items.selected.data.GroupType,
          this.item?.items.selected.data.TypeOfChange
        )
        .then((response) => {
          if (response?.result.length) {
            let detailsItem = item.setItem(response?.result, 'details');
            response?.result.forEach((val, i) => {
              detailsItem.setItem(val, i);
            });
            item.select();
          }
        });
    else item.select();
  }
  //#endregion
}
