import { FormProperty } from './formProperty';
import { PropertyTypes } from '../enum/propertyTypes';
import { EventEmitter, Injectable } from '@angular/core';
import { GSAutocompleteControl } from '../../controls/gsControls';

export class FormAutocompleteProperty extends FormProperty {
  dropdown?: boolean = false;
  field?: string;
  dropdownMode?: string = 'current';
  dropdownIcon?: string = 'pi pi-caret-down';
  suggestions?: string[];
  source: any;
  type = PropertyTypes.Autocomplete;
  component = GSAutocompleteControl;
  dropdownClick = new EventEmitter<any>();
  complete = new EventEmitter<any>();
  select = new EventEmitter<any>();

  onBlur = new EventEmitter<any>();
  selectedTextPath: string;
  selectedText: string;
  setValueWhenTyping: boolean;

  constructor(name: string, label: string, suggestions?: string[], row?: number, column?: number,
    rowspan?: number, columnspan?: number, validate?: boolean, dropdown?: boolean, dropdownMode?: string,
    dropdownIcon?: string, field?: string, setValueWhenTyping?: boolean, selectedTextPath?: string) {
    super(name, label, row, column,
      rowspan, columnspan, validate);
    this.suggestions = suggestions ? suggestions : this.suggestions;
    this.dropdown = dropdown ? dropdown : this.dropdown;
    this.dropdownMode = dropdownMode ? dropdownMode : this.dropdownMode;
    this.dropdownIcon = dropdownIcon ? dropdownIcon : this.dropdownIcon;
    this.field = field ? field : undefined;
    this.setValueWhenTyping = setValueWhenTyping ? setValueWhenTyping : false;
    this.selectedTextPath = selectedTextPath;
  }
}
