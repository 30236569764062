<div class="row form-property">
  <div class="col-lg-{{tooltip?11:12}}"
       [ngClass]="{'no-padding-right':tooltip}">
    <span class="p-float-label"
          [style.height]="property.textRows>1? ((property.textRows-1)*21+33).toString()+'px' :'auto'">
      <p-inputMask id="{{name}}"
                   [ngStyle]="{'text-align': (property.align || property.align ==0) ? (property.align==0?'center':(property.align==1 ? 'right':'left')) :'left'}"
                   [ngModel]="value"
                   class="p-filled p-filled"
                   [readonly]="readonly"
                   [ngClass]="{'disabled':readonly,'property-invalid':notValid}"
                   (ngModelChange)="change($event)"
                   (keydown)="keydown($event)"
                   (paste)="onpaste($event)"
                   [mask]="property.mask"
                   [placeholder]="property.placeholder"
                   style="width: 100%"
                   [autocomplete]="property.autocompleteOff?'new':''"
                   [maxlength]="property.maxLen"></p-inputMask>

      <label class="gs-float-label" [ngClass]="{'error-color':notValid}">{{label}}</label>
      <span *ngIf="notValid"
            class="form-error"
            pTooltip="{{property.validationMessage}}">
        <i class="fas fa-info-circle fa-2x form-valid-icon error-color"></i>
      </span>
    </span>
  </div>
  <div *ngIf="tooltip" class="col-lg-1 property-tooltip">
    <span pTooltip="{{property.tooltip}}">
      <i class="fas fa-info-circle master-color"></i>
    </span>
  </div>
</div>
