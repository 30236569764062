<div *ngIf="form">
  <p>{{'login.changePasswordText'|gstranslate}}</p>
  <form [formGroup]="form" class="change-password">
    <div class="pass-container">
      <div class="transparentframe">
        <div style="margin-top: 20px">
          <ul class="list-unstyled">
            <li>
              <div class="ui-inputgroup">
                <span class="ui-inputgroup-addon form-input-icon"
                  ><i class="fas fa-key"></i
                ></span>
                <input
                  type="password"
                  [(ngModel)]="model.oldPassword"
                  formControlName="oldPassword"
                  pPassword
                  [showPassword]="model.showOld"
                  [feedback]="false"
                  placeholder="{{ 'login.oldPassword' | gstranslate }}"
                  class="form-input"
                  style="height: 50px"
                />
                <span
                  *ngIf="model.oldPassword"
                  (mousedown)="model.showOld=true"
                  (mouseup)="model.showOld=false"
                  class="change-pass-eye"
                >
                  <i class="fas fa-eye"></i>
                </span>
              </div>
              <div class="form-error">
                <div
                  id="oldPasswordValidation"
                  *ngIf="!form.controls['oldPassword'].valid && form.controls['oldPassword'].dirty"
                  translate
                >
                  {{ 'login.oldPasswordRequired' | gstranslate }}
                </div>
              </div>
            </li>
            <li>
              <div class="ui-inputgroup">
                <span class="ui-inputgroup-addon form-input-icon"
                  ><i class="fas fa-key"></i
                ></span>
                <input
                  type="password"
                  [(ngModel)]="model.newPassword"
                  formControlName="newPassword"
                  pPassword
                  [showPassword]="model.showNew"
                  [feedback]="true"
                  placeholder="{{ 'login.newPassword' | gstranslate }}"
                  class="form-input"
                  style="height: 50px"
                />
                <span
                  *ngIf="model.newPassword"
                  (mousedown)="model.showNew=true"
                  (mouseup)="model.showNew=false"
                  class="change-pass-eye"
                >
                  <i class="fas fa-eye"></i>
                </span>
              </div>
              <div class="form-error">
                <div
                  id="newPasswordValidation"
                  *ngIf="!form.controls['newPassword'].valid && form.controls['newPassword'].dirty"
                  translate
                >
                  {{ 'login.newPasswordRequired' | gstranslate }}
                </div>
              </div>
            </li>
            <li>
              <div class="ui-inputgroup">
                <span class="ui-inputgroup-addon form-input-icon"
                  ><i class="fas fa-key"></i
                ></span>
                <input
                  type="password"
                  [(ngModel)]="model.confirmPassword"
                  formControlName="confirmPassword"
                  [showPassword]="model.showConfirm"
                  pPassword
                  [feedback]="true"
                  placeholder="{{ 'login.confirmPassword' | gstranslate }}"
                  class="form-input"
                  style="height: 50px"
                />
                <span
                  *ngIf="model.confirmPassword"
                  (mousedown)="model.showConfirm=true"
                  (mouseup)="model.showConfirm=false"
                  class="change-pass-eye"
                >
                  <i class="fas fa-eye"></i>
                </span>
              </div>
              <div class="form-error">
                <div
                  id="confirmPasswordValidation"
                  *ngIf="!form.controls['confirmPassword'].valid && form.controls['confirmPassword'].dirty"
                  translate
                >
                  {{ 'login.confirmPasswordRequired' | gstranslate }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </form>
</div>
