import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
  Input,
  Inject,
  Optional,
  Injectable,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { GSBaseControl } from './gs.base.control';
import { FormAutocompleteProperty } from '../entity/form/formAutocomplete.property';
import { Item } from '../entity/entities';
import { ItemUtil } from '../utils/utils';

@Component({
  selector: 'gs-autocomplete',
  templateUrl: './gs.autocomplete.control.html',
})
@Injectable()
export class GSAutocompleteControl
  extends GSBaseControl
  implements OnInit, OnDestroy, AfterViewInit
{
  //#region -------Properties

  get suggestions() {
    return this.property.suggestions;
  }
  get dropDown() {
    return this.property.dropdown ? this.property.dropdown : false;
  }
  get dropdownMode() {
    return this.property.dropdownMode ? this.property.dropdownMode : 'current';
  }
  get dropdownIcon() {
    return this.property.dropdownIcon
      ? this.property.dropdownIcon
      : 'pi pi-caret-down';
  }
  get originalDropdownIcon() {
    return this.property.dropdownIcon
      ? this.property.dropdownIcon
      : 'pi pi-caret-down';
  }
  get field() {
    return this.property.field ? this.property.field : undefined;
  }

  get selectedText() {
    if (this.property.selectedTextPath)
      this.property.selectedText = ItemUtil.getPropertyData(
        this.item,
        this.property.selectedTextPath
      );

    return this.property.selectedText;
  }

  set selectedText(value) {
    this.property.selectedText = value;
  }

  @ViewChild('dropdown') dropdown;
  //#endregion

  //#region -------Constructor
  constructor(
    @Optional()
    @Inject(FormAutocompleteProperty)
    prop: FormAutocompleteProperty,
    @Optional() @Inject(Item) item: Item,
    private changeDr: ChangeDetectorRef,
    private element: ElementRef
  ) {
    super(changeDr);
    this.property = prop;
    this.item = item;
  }
  //#endregion

  //#region -------NG Eents
  ngOnInit() {
    //this.element
  }
  ngAfterViewInit(): void {
    //this.element

    const iconTag = this.element.nativeElement
      .getElementsByTagName('p-autocomplete')[0]
      .getElementsByClassName('p-button-icon pi pi-caret-down')[0];
    if (iconTag) iconTag.className = 'gs-autocomplete-icon';
  }

  ngOnDestroy() {}
  //#endregion

  //#region -------UI Events

  select(e) {
    //this.property.select.emit(e);
    if (!e || e == '') {
      this.property.complete.emit({
        value: e,
        property: this.property,
        item: this.item,
      });
      this.property.dropdownIcon = this.originalDropdownIcon;
    }
    //if (this.property.setValueWhenTyping)
   
      this.data[this.name] = e;
    this.item.reCheckDirty();
    this.onChange.emit({
      propertyName: this.name,
      newValue: this.value,
      event: e,
      item: this.item,
      property: this.property,
    });
  }

  complete(e) {
    this.property.complete.emit({
      value: e,
      property: this.property,
      item: this.item,
    });
  }

  dropdownClick(e) {
    let add = this.dropdownIcon == 'pi pi-caret-down' ? false : true;
    this.property.dropdownClick.emit({
      value: e,
      add: add,
      property: this.property,
      item: this.item,
    });
  }

  onBlur(e) {
    this.property.onBlur.emit({
      value: e,
      property: this.property,
      item: this.item,
    });
  }

  changeAutocomplete(e) {
    if (!e || e == '') {
      this.property.complete.emit({
        value: e,
        property: this.property,
        item: this.item,
      });
      this.property.dropdownIcon = this.originalDropdownIcon;
    }
    if (this.property.setValueWhenTyping) this.data[this.name] = e;
    this.item.reCheckDirty();
    this.onChange.emit({
      propertyName: this.name,
      newValue: this.value,
      event: e,
      item: this.item,
      property: this.property,
    });
  }

  //#endregion

  //#region -------Private
  //#endregion
}
