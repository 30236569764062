<div class="row form-property">
  <div class="col-lg-12" [ngClass]="{'no-padding-right':tooltip}">
    <p-button id="{{name}}"
              (onClick)="click($event)"
              [label]="label"
              [disabled]="property.disabled"
              [icon]="icon"
              [pTooltip]="tooltip"
              [styleClass]="property.styleClass"
              >
    </p-button>
  </div> 
</div>

