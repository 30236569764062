import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { BrowserHelper } from '../utils/utils';
import { Session } from '../entity/entities';

@Pipe({ name: 'dateCompare', pure: true })
export class DateComparePipe implements PipeTransform {
  transform(
    date: any,
    dateFrom: Date,
    dateTo: Date,
    type: 'to' | 'from'
  ): string | null {
    if (!date) return;
    const dateValue = new Date(date.year, date.month, date.day);
    if (dateFrom != dateTo) {
      if (
        type == 'to' &&
        dateValue.getDate() == dateFrom.getDate() &&
        dateValue.getMonth() == dateFrom.getMonth() &&
        dateValue.getFullYear() == dateFrom.getFullYear()
      )
        return 'calendar-range-from-to';
      else if (
        type == 'from' &&
        dateValue.getDate() == dateTo.getDate() &&
        dateValue.getMonth() == dateTo.getMonth() &&
        dateValue.getFullYear() == dateTo.getFullYear()
      )
        return 'calendar-range-from-to';
    }
    if (
      (dateValue.getDate() == dateFrom.getDate() &&
        dateValue.getMonth() == dateFrom.getMonth() &&
        dateValue.getFullYear() == dateFrom.getFullYear()) ||
      (dateValue.getDate() == dateTo.getDate() &&
        dateValue.getMonth() == dateTo.getMonth() &&
        dateValue.getFullYear() == dateTo.getFullYear())
    )
      return 'calendar-range-from-to-highlight';
    if (dateValue > dateFrom && dateValue < dateTo)
      return 'calendar-range-from-to';
  }
}
