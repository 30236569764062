import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
  Input,
  Inject,
  Optional,
  Injectable,
  QueryList,
  ElementRef,
  ViewChildren,
  ChangeDetectorRef,
} from '@angular/core';
import { GSBaseControl } from './gs.base.control';
import { FormDropdownProperty } from '../entity/form/formDropdown.property';
import { Item } from '../entity/entities';
import { FormLocationDropdownProperty } from '../entity/form/formLocationDropdown.property';
import { TableColumn } from '../entity/table/tableColumn';

@Component({
  selector: 'gs-time-employee-dropdown',
  templateUrl: './gs.timeEmployee.control.html',
})
@Injectable()
export class GSTimeEmployeeDropdownControl
  extends GSBaseControl
  implements OnInit, OnDestroy
{
  //#region -------Properties
  @Input() item: any;
  @Input() column: TableColumn;
  @Output() onChange = new EventEmitter<any>();
  get showClear() {
    return this.property.showClear;
  }
  get options() {
    return this.column.options;
  }
  @ViewChildren('selectOption') selectOption: QueryList<ElementRef>;
  optionHandled = false;
  //#endregion
  preselected = false;
  //#region -------Constructor
  constructor(
    @Optional() prop: FormDropdownProperty,
    @Optional() item: Item,
    private changeDr: ChangeDetectorRef
  ) {
    super(changeDr);
    this.property = prop;
    this.item = item;
  }
  //#endregion

  //#region -------NG Eents
  ngOnInit() {
    if (this.column.options) this.preselect();
  }

  ngOnDestroy() {}

  ngAfterContentChecked() {
    if (this.selectOption && this.selectOption.toArray().length) {
      if (!this.optionHandled) {
        this.selectOption
          .toArray()
          .filter((e) =>
            e.nativeElement.children[0].classList.contains(
              'disabled_opacity-no-color'
            )
          )
          .forEach((e) => {
            e.nativeElement.parentElement.classList.add('no-event');
          });
        this.optionHandled = true;
      }
    } else this.optionHandled = false;
  }
  //#endregion

  //#region -------UI Events
  preselect = () => {
    if (!this.preselected) {
      this.preselected = true;
      if (this.property.options) {
        let first = this.options.find(
          (l) => !l.data.BlockedReason && !l.data.Disabled
        );
        if (
          first &&
          (this.property.options.length == 1 || this.property.preselectFirst)
        ) {
          this.value = first.value;
          (this.item as Item).reValidate();
          (this.item as Item).reCheckDirty();

          this.onChange.emit({
            propertyName: this.name,
            previousValue: null,
            newValue: this.value,
            event: {},
            item: this.item,
            property: this.property,
          });
          this.property.changed.emit({
            propertyName: this.name,
            previousValue: null,
            newValue: this.value,
            event: {},
            item: this.item,
            property: this.property,
          });
        }
      }
    }
  };

  change(e) {
    if (e) {
      let val = this.value;
      if (
        this.options.find((o) => o.data?.[this.column.name] == e)?.data?.IsPaid
      ) {
        this.value = e;
        setTimeout(() => {
          this.value = val;
        }, 100);

        return;
      }
      let previousValue = this.data[this.name];
      this.value = e;
      this.item.reCheckDirty();
      this.onChange.emit({
        propertyName: this.name,
        previousValue: previousValue,
        newValue: this.value,
        event: e,
        item: this.item,
        property: this.property,
      });
      if (this.property)
        this.property.changed.emit({
          propertyName: this.name,
          previousValue: previousValue,
          newValue: this.value,
          event: e,
          item: this.item,
          property: this.property,
        });
    }
  }

  //#endregion

  //#region -------Private
  //#endregion
}
