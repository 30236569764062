import { Item } from 'src/app/common/entity/entities';
import { BaseComponent } from '../../../common/component/base.component';
import { FormProperty } from '../../../common/entity/form/formProperty';
import { Directive } from '@angular/core';

@Directive()
export class CustomerBaseComponent extends BaseComponent {
  public properties: FormProperty[] = [];
  public menuItemName = 'pdap_integration';

  //public validate() {
  //    if (this.properties)
  //        return !this.properties.some(p => !p.valid);
  //}
}
