import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[padding],[padding-right],[padding-left],[padding-top],[padding-bottom]'
})
export class PaddingDirective implements OnInit {
    @Input() padding;
    @Input('padding-right') right;
    @Input('padding-left') left;
    @Input('padding-top') top;
    @Input('padding-bottom') bottom;

    constructor(private el: ElementRef) {       
    }

    ngOnInit() {
        if (this.padding)
            this.el.nativeElement.style.padding = `${this.padding}px`;
        if (this.top)
            this.el.nativeElement.style.paddingTop = `${this.top}px`;
        if (this.right)
            this.el.nativeElement.style.paddingRight = `${this.right}px`;
        if (this.left)
            this.el.nativeElement.style.paddingLeft = `${this.left}px`;
        if (this.bottom)
            this.el.nativeElement.style.paddingBottom = `${this.bottom}px`;
    }
}
