<div (click)="eventClick($event)" style="margin-bottom: 5px; overflow-x: hidden">
  <ng-container [ngSwitch]="event.type">
    <ng-container *ngSwitchCase="CalendarEventTypes.EmployeeMonthOverview">
      <div class="row employee-month-overview-event-title">
        <div class="col-lg-9" style="padding-right:0px">
          <div class="employee-month-overview-event-title" style="padding:3px">
            <div>{{event.title}}</div>
            <div class="employee-month-overview-event-text">{{event.earned}}</div>
            <div class="employee-month-overview-event-text">{{event.available}}</div>
          </div>
        </div>
        <div class="col-lg-2" style="padding-left:8px">
          <div class="pointer employee-month-overview-event-action" style="margin-top: 5px; margin-left:2px" (click)="event.detailsAction(event)">
            <i class="fa fa-info-circle fa-2x"></i>
          </div>
          <div *ngIf="event.showTipShareOption" class="pointer employee-month-overview-event-action" (click)="event.tipShareAction(event)">
            <i class="fa fa-money fa-2x"></i>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>  
</div>

