import { FormProperty } from './formProperty';
import { PropertyTypes } from '../enum/propertyTypes';
import { GSCalendarRangeControl } from '../../controls/gsControls';
import { EventEmitter } from '@angular/core';

export class FormDateRangeProperty extends FormProperty {
  type = PropertyTypes.DateRange;
  component = GSCalendarRangeControl;
  public showIcon: boolean;
  public readOnly: boolean;
  public from: Date;
  public to: Date;
  public yearRange;
  changed = new EventEmitter<any>();

  constructor(name: string, label: string, showIcon: boolean, row?: number, column?: number,
    rowspan?: number, columnspan?: number, validate?: boolean, readOnly?: boolean, public monthOnly?: boolean) {
        super(name, label, row , column ,
          rowspan, columnspan, validate);
    this.showIcon = showIcon;
    this.readOnly = readOnly;
    this.yearRange = (new Date().getFullYear() - 100).toString() + ':' + new Date().getFullYear().toString();

    }
}
