import { Pipe, PipeTransform } from '@angular/core';
import { TableTypes } from '../entity/enums';
import { TableColumn } from '../entity/table/tableColumn';
import { TableBuilder } from '../builder/gs.table.builder';

@Pipe({
  name: 'tableColumns',
})
export class TableColumnsPipe implements PipeTransform {
  transform(type: TableTypes): TableColumn[] {
    if (!type) return null;
    return TableBuilder.build(type);
  }
}
