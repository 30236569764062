<div class="row form-property">
  <div
    class="col-lg-{{tooltip?11:12}}"
    [ngClass]="{'no-padding-right':tooltip}"
  >
    <span class="p-float-label">
      <input
        id="{{name}}"
        [ngModel]="value"
        class="p-filled"
        (ngModelChange)="change($event)"
        type="password"
        pPassword
        [readonly]="readonly"
        [ngClass]="{'disabled':readonly, 'property-invalid':notValid}"
        [showPassword]="showPassword"
        [feedback]="property.feedback"
        autocomplete="anyrandomstring"
        style="width: 100%"
        [autocomplete]="property.autocompleteOff?'new-password':''"
      />
      <label class="gs-float-label" [ngClass]="{'error-color':notValid}">{{label}}</label>
      <span
        *ngIf="!notValid"
        class="pointer"
        (click)="show()"
        style="position: absolute; margin-left: -23px; top: 5px"
      >
        <i
          class="fas"
          [ngClass]="{'fa-eye':!showPassword,'fa-eye-slash':showPassword}"
        ></i>
      </span>
      <span
        *ngIf="notValid"
        class="form-error"
        pTooltip="{{property.validationMessage}}"
      >
        <i class="fas fa-info-circle fa-2x form-valid-icon error-color"></i>
      </span>
    </span>
  </div>
  <div *ngIf="tooltip" class="col-lg-1 property-tooltip">
    <span pTooltip="{{property.tooltip}}">
      <i class="fas fa-info-circle master-color"></i>
    </span>
  </div>
</div>
