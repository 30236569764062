import { Directive, ViewContainerRef, Input } from '@angular/core'

@Directive({
    selector: '[placeholder]'
})
export class GSPlaceholder {
    @Input() reference: string;
    @Input() element: any;
    constructor(public viewContainerRef: ViewContainerRef) {
    }
}
