import { DatePipe } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Renderer } from 'html2canvas/dist/types/render/renderer';
import { GSBaseControl } from '../gs.base.control';

@Component({
  selector: 'gs-calendar-from-to',
  templateUrl: './gs.calendar-from-to.component.html',
  styleUrls: ['./gs.calendar-from-to.component.scss'],
  providers: [DatePipe],
})
export class CalendarFromToComponent
  extends GSBaseControl
  implements OnInit, OnDestroy
{
  dateFrom;
  dateTo;

  get inputValue() {
    return `${this.datepipe.transform(
      this.dateFrom,
      'yyyy/MM/dd'
    )} - ${this.datepipe.transform(this.dateTo, 'yyyy/MM/dd')}`;
  }

  set inputValue(value) {
    const dates = value.split('-');
    if (dates[0]) this.dateFrom = new Date(dates[0].trim());
    if (dates[1]) this.dateTo = new Date(dates[1].trim());
  }

  @ViewChild('dateToCalendar') dateToCalendar;
  @ViewChild('dateFromCalendar') dateFromCalendar;

  filters = [
    {
      label: this.translate.instant('general.calendarFilter.yesterday'),
      code: 'y',
    },
    {
      label: this.translate.instant('general.calendarFilter.today'),
      code: 't',
    },
    {
      label: this.translate.instant('general.calendarFilter.lastWeek'),
      code: 'lw',
    },
    {
      label: this.translate.instant('general.calendarFilter.thisWeek'),
      code: 'tw',
    },
    {
      label: this.translate.instant('general.calendarFilter.lastMonth'),
      code: 'lm',
    },
    {
      label: this.translate.instant('general.calendarFilter.thisMonth'),
      code: 'tm',
    },
  ];

  get filtersLeft() {
    return this.filters.slice(0, 3);
  }
  get filtersRight() {
    return this.filters.slice(3, 6);
  }

  //#region -------Constructor
  constructor(
    @Inject(DatePipe) private datepipe: DatePipe,
    private changeDr: ChangeDetectorRef,
    private translate: TranslateService,
    private element: ElementRef,
    private renderer: Renderer2
  ) {
    super(changeDr);
  }
  //#endregion

  ngOnInit(): void {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    if (this.value && this.value.length == 2) {
      this.dateFrom = this.value[0];
      this.dateTo = this.value[1];
    }
    this.dateFrom = this.dateFrom ? this.dateFrom : yesterday;
    this.dateTo = this.dateTo ? this.dateTo : yesterday;
    this.value = [];
    this.value.push(this.dateFrom);
    this.value.push(this.dateTo);
    this.item.reValidate();
  }

  ngOnDestroy() {}

  showCalendar(e) {
    if (!this.dateFromCalendar.overlayVisible) this.dateFromCalendar.toggle(e);
    if (!this.dateToCalendar.overlayVisible) this.dateToCalendar.toggle(e);
  }

  filter(filter, event) {
    const today = new Date();
    let m = today.getMonth();
    let y = today.getFullYear();

    switch (filter.code) {
      case 'y':
        const yesterday = new Date(<any>today - 1 * 24 * 60 * 60 * 1000);
        this.dateTo = yesterday;
        this.dateFrom = yesterday;
        break;
      case 't':
        this.dateFrom = today;
        this.dateTo = today;
        break;
      case 'lw':
        const sevenDayAgo = new Date(<any>today - 7 * 24 * 60 * 60 * 1000);
        this.dateFrom = this.getDay(sevenDayAgo, 1);
        this.dateTo = this.getDay(sevenDayAgo, 7);
        break;
      case 'tw':
        this.dateTo = today;
        this.dateFrom = this.getDay(this.dateTo, 1);
        break;
      case 'lm':
        m = m == 0 ? 11 : m;
        y = m == 0 ? y - 1 : y;
        this.dateFrom = new Date(y, m - 1, 1);
        this.dateTo = new Date(y, m, 0);
        break;
      case 'tm':
        this.dateTo = today;
        this.dateFrom = new Date(y, m, 1);
        break;
    }
    this.dateFromCalendar.updateUI();
    this.change(event);
  }

  getDay(d, dayOfWeek) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : dayOfWeek);
    return new Date(d.setDate(diff));
  }

  @HostListener('document:click', ['$event'])
  focus(event) {
    if (
      !this.element.nativeElement.contains(event.target) ||
      event.target.className.indexOf('calendar-from-to-header-close') != -1
    ) {
      if (
        !(
          event.target.className.indexOf('p-datepicker-month') != -1 ||
          event.target.className.indexOf('p-monthpicker-month') != -1 ||
          event.target.className.indexOf('p-datepicker-year') != -1 ||
          event.target.className.indexOf('p-yearpicker-year') != -1 ||
          event.target.className.indexOf('p-datepicker-prev-icon') != -1 ||
          event.target.className.indexOf('p-datepicker-next-icon') != -1
        )
      ) {
        if (this.dateFromCalendar) this.dateFromCalendar.hideOverlay();
        if (this.dateToCalendar) this.dateToCalendar.hideOverlay();
      }
    }
  }

  change(event) {
    this.value = [];
    let previousValue = this.data[this.name];
    this.value.push(this.dateFrom);
    this.value.push(this.dateTo);
    this.item.reCheckDirty();
    this.onChange.emit({
      propertyName: this.name,
      newValue: this.value,
      event: event,
      item: this.item,
      property: this.property,
    });
    this.property.changed.emit({
      propertyName: this.name,
      previousValue: previousValue,
      newValue: this.value,
      event: event,
      item: this.item,
      property: this.property,
    });
  }
}
