import { Directive, ElementRef, OnInit, Input, Renderer2, RendererStyleFlags2, HostListener} from '@angular/core'
import { Item } from '../entity/entities';

@Directive({
    selector:'[validate]'
})
export class ValidDirective implements OnInit {

    @Input() item: Item;
    @Input() name: string;
    @Input() collection: string;

    constructor(private element: ElementRef, private renderer: Renderer2) {

    }

    @HostListener('keyup', ['$event'])
    click(e) {
        this.validate();
    }

    ngOnInit() {
        this.validate();
    }

    private validate() {
        if (this.item && this.name) {
            if (this.collection && this.item.parameters.contains(this.collection) && this.item.parameters[this.collection].indexOf(this.name) > -1) {
                let notValid = this.item.data[this.name] == undefined || this.item.data[this.name]=='';
                if (notValid) {
                    const flags = RendererStyleFlags2.DashCase | RendererStyleFlags2.Important;
                    this.renderer.setStyle(this.element.nativeElement, 'border-color', 'red', flags);
                } else
                    this.renderer.setStyle(this.element.nativeElement, 'border-color', '#a6a6a6');
            }
        }       
    }
}
