import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  NgControl,
} from '@angular/forms';
import { AppConfig } from '../../app.config';
import { Router, ActivatedRoute } from '@angular/router';
import { SecurityClient } from '../../client/security.client';
import { DatePipe } from '@angular/common';
import { EmployeeIdentifierTypes } from '../../common/entity/enum/employeeIdentifierTypes';
import { SelectItem } from 'primeng/api';
import { regExLib } from '../../common/regEx/regExLib'

@Component({
  selector: 'sign-up',
  templateUrl: './signUp.component.html',
  providers: [
    //SecurityService, LocalizationService,
    AppConfig,
    UntypedFormBuilder,
    SecurityClient,
  ],
})
export class SignUpComponent {
  form: UntypedFormGroup;
  progress: boolean = false;
  confirmationDialogVisible = false;
  yearRange;

  firstName; // = new FormControl('', Validators.required);
  lastName; // = new FormControl('', Validators.required);
  user; // = new FormControl('', [Validators.required, Validators.pattern("[A-Za-z0-9_.@-]*$")]);
  password; // = new FormControl('', Validators.required);
  confirmPass; // = new FormControl('', this.checkPasswords);
  mobileNo; // = new FormControl('', this.validatePhone(this.form));
  dateOfBirth; // = new FormControl('', Validators.required);
  employeeIdentifier; //= new FormControl('', this.validateEmployeeID);
  employeeIdentifierType; // = new FormControl('', Validators.required);
  termsAndConditions;

  employeeIdentifierTypes: SelectItem[];
  EmployeeIdentifierTypesEnum = EmployeeIdentifierTypes;

  get SelectedEmployeeIdentifierType() {
    if (
      this.form &&
      this.form.get('employeeIdentifierType') &&
      this.form.get('employeeIdentifierType').value
    )
      return this.form.get('employeeIdentifierType').value;
  }

  constructor(
    public route: ActivatedRoute,
    public config: AppConfig,
    public securityClient: SecurityClient,
    public router: Router,
    formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    private datepipe: DatePipe
  ) {
    this.firstName = new UntypedFormControl('', Validators.required);
    this.lastName = new UntypedFormControl('', Validators.required);
    this.user = new UntypedFormControl('', [
      Validators.required,
      Validators.pattern('[A-Za-z0-9_.@-]*$'),
    ]);
    this.password = new UntypedFormControl('', this.validatePassword);
    this.confirmPass = new UntypedFormControl('', this.checkPasswords);
    this.mobileNo = new UntypedFormControl('', this.validatePhone);
    this.dateOfBirth = new UntypedFormControl('', Validators.required);
    this.employeeIdentifier = new UntypedFormControl('', this.validateEmployeeID);
    this.employeeIdentifierType = new UntypedFormControl('', Validators.required);
    this.termsAndConditions = new UntypedFormControl('', Validators.required);

    this.employeeIdentifierTypes = [
      { label: 'Phone number', value: EmployeeIdentifierTypes.PhoneNumber },
      { label: 'Employee ID', value: EmployeeIdentifierTypes.EmployeeID },
    ];

    this.yearRange =
      (new Date().getFullYear() - 100).toString() +
      ':' +
      new Date().getFullYear().toString();
    // BrowserStorage.useFor(this);
    this.form = formBuilder.group({
      firstName: this.firstName,
      lastName: this.lastName,
      user: this.user,
      password: this.password,
      confirmPass: this.confirmPass,
      mobileNo: this.mobileNo,
      dateOfBirth: this.dateOfBirth,
      employeeIdentifierType: this.employeeIdentifierType,
      employeeIdentifier: this.employeeIdentifier,
      termsAndConditions: this.termsAndConditions,
    });

    this.form
      .get('employeeIdentifierType')
      .setValue(EmployeeIdentifierTypes.EmployeeID);
  }

  signUp() {
    this.form.controls['confirmPass'].updateValueAndValidity();

    if (this.form.valid) {
      let data = {
        FirstName: this.firstName.value,
        LastName: this.lastName.value,
        UserName: this.user.value,
        Password: this.password.value,
        //Phone: this.SelectedEmployeeIdentifierType == this.EmployeeIdentifierTypesEnum.PhoneNumber ? this.mobileNo.value.toString().replace(/[^0-9]/g, '') : null,
        DateOfBirth: this.datepipe.transform(
          this.dateOfBirth.value,
          'yyyy-MM-dd'
        ),
        EmployeeIdentifier:
          this.SelectedEmployeeIdentifierType ==
          this.EmployeeIdentifierTypesEnum.EmployeeID
            ? this.employeeIdentifier.value
            : this.mobileNo.value.toString().replace(/[^0-9]/g, ''),
        EmployeeIdentifierType: this.employeeIdentifierType.value,
        AcceptTermsAndConditions: this.termsAndConditions.value,
      };

      this.securityClient
        .signUp(data, this.translate.instant('signUp.signUpInProgress'))
        .then(
          (response) => {
            this.confirmationDialogVisible = true;
          },
          (reject) => {}
        );
    }
  }

  validateEmployeeID = (group: UntypedFormGroup) => {
    let val = null;
    if (
      group.root.get('employeeIdentifierType') &&
      group.root.get('employeeIdentifierType').value ==
        EmployeeIdentifierTypes.EmployeeID
    ) {
      this.form.controls['mobileNo'].updateValueAndValidity();
      if (group.root.get('employeeIdentifier'))
        val = group.root.get('employeeIdentifier').value;

      if (val == null || val.toString().trim() == '') return { required: true };
    }

    return null;
  };

  validatePhone = (group: UntypedFormGroup) => {
    let val = null;
    if (
      group.root.get('employeeIdentifierType') &&
      group.root.get('employeeIdentifierType').value ==
        EmployeeIdentifierTypes.PhoneNumber
    ) {
      this.form.controls['employeeIdentifier'].updateValueAndValidity();
      if (group.root.get('mobileNo')) val = group.root.get('mobileNo').value;

      if (
        val == null ||
        val.toString().trim() == '' ||
        val.toString().length > 20
      )
        return { required: true };
    }

    return null;
  };

  checkPasswords(group: UntypedFormGroup) {
    // here we have the 'passwords' group
    let pass;
    let confirmPass;
    if (group.root.get('password')) pass = group.root.get('password').value;
    if (group.root.get('confirmPass'))
      confirmPass = group.root.get('confirmPass').value;

    return pass === confirmPass ? null : { notSame: true };
  }

  validatePassword(group: UntypedFormGroup) {
    let pass;
    if (group.root.get('password')) pass = group.root.get('password').value;
    var re = new RegExp(regExLib.regex_ComplexPassword12);
    var valid = re.test(pass);
    return pass == null ? { required: true } : (valid == true ? null : {valid: false});
  }

  closeConfirmationDialog() {
    this.confirmationDialogVisible = false;
    this.router.navigate(['/login']);
  }

  openTerms() {
    window.open(
      'https://policies.paydayportal.com/legal/Gratuity_Service_Agreement_with_Platform_Addendum.pdf',
      '_blank'
    );
  }
}
