import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import jsPDF from 'jspdf'
import * as _ from 'lodash';
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';

@Injectable({
  providedIn: 'root'
})
export class TableToPDFService {

  constructor() { }  

  public saveToPdf(window, elementId, name) {      
    var element = elementId;
    var opt = {
      margin: 0.15,
      filename: name,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
    };

    // New Promise-based usage:
    html2pdf().from(element).set(opt).save();
    window.close();
    //// Old monolithic-style usage:
    //html2pdf(element, opt);
   
    /////////////////////////////////////////////////////////////////////////////////////////////
    ////const element = document.getElementById(elementId);
    //html2canvas(elementId, { scrollY: -window.scrollY }).then(canvas => {
    //  // Few necessary setting options
    //  const imgWidth = 208;
    //  const pageHeight = 295;
    //  const imgHeight = (canvas.height * imgWidth) / canvas.width;
    //  const heightLeft = imgHeight;

    //  const contentDataURL = canvas.toDataURL('image/png');
    //  //window.close();
    //  const pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
    //  const position = 0;
    //  pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
    //  pdf.save(`${name}.pdf`); // Generated PDF
    //});
    /////////////////////////////////////////////////////////////////////////////////////////////
  }


  
}
