import { Component, OnInit, OnDestroy, Inject, Optional, Injectable, ChangeDetectorRef } from '@angular/core'
import { GSBaseControl } from './gs.base.control';
import { FormRadioButtonProperty } from '../entity/form/formRadioButton.property';
import { Item } from '../entity/entities';

@Component({
  selector: 'gs-radio-button',
  templateUrl: './gs.radioButton.control.html'
})
@Injectable()
export class GSRadioButtonControl extends GSBaseControl implements OnInit, OnDestroy {
  //#region -------Properties
  //#endregion

  //#region -------Constructor
  constructor(@Optional() prop: FormRadioButtonProperty, @Optional() item: Item, private changeDr: ChangeDetectorRef) {
    super(changeDr);
        this.property = prop;
        this.item = item;
    }
  //#endregion

  //#region -------NG Eents
  ngOnInit() {

  }

  ngOnDestroy() {
  }
  //#endregion

  //#region -------UI Events


  change(e) {
    let previousValue = this.data[this.name];
    this.data[this.name] = e.target.checked;
    this.value = e.target.checked;
    this.item.reCheckDirty();
    this.onChange.emit({ propertyName: this.name, previousValue: previousValue, newValue: this.value, event: e, item: this.item, property: this.property });
    this.property.changed.emit({ propertyName: this.name, previousValue: previousValue, newValue: this.value, event: e, item: this.item, property: this.property });

  }

  changeV(e) {
    let previousValue = this.data[this.name];
    this.data[this.name] = e;
    this.value = e;
    this.item.reCheckDirty();
    this.onChange.emit({ propertyName: this.name, previousValue: previousValue, newValue: this.value, event: e, item: this.item, property: this.property });
    this.property.changed.emit({ propertyName: this.name, previousValue: previousValue, newValue: this.value, event: e, item: this.item, property: this.property });

  }
  //#endregion

  //#region -------Private
  //#endregion
}
