import { Component, OnInit, OnDestroy, HostListener, ElementRef, AfterViewInit, OnChanges, AfterContentInit, AfterContentChecked, Input, Output, EventEmitter } from '@angular/core';
import { CustomerClient } from '../../../client/customer.client';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'step-history-log',
  templateUrl: './stepHistoryLog.dialog.html',
  providers: [CustomerClient]
})
export class StepHistoryLogDialog implements OnInit, OnDestroy {

  @Input() history: any = {};
  @Input() showApply = false;
  @Output() onApply = new EventEmitter<any>();
  constructor(private elRef: ElementRef) {

  }

  get applyDisabled() {
    return !this.history.comment;
  }

  //#region -------NG events

  ngOnInit() {
    this.elRef.nativeElement.addEventListener('mouseleave', this.hide);
    this.history.comment = undefined;
  }

  ngOnDestroy() {
    this.elRef.nativeElement.removeEventListener('mouseleave', this.hide);
  }

  //#endregion

  //#region -------UI Events

  apply($event) {
    this.onApply.emit($event);
  }




  //#endregion

  //#region -------Private
  hide(e) {
    //this.formData = { enrollmentID: undefined, group: undefined };
  }

  //#endregion





}
