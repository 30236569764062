import { FormProperty } from './formProperty';
import { PropertyTypes } from '../enum/propertyTypes';
import { GSTPasswordControl } from '../../controls/gsControls';
import { EventEmitter } from '@angular/core';

export class FormPasswordProperty extends FormProperty {
    type = PropertyTypes.Password;
    component = GSTPasswordControl;
  changed = new EventEmitter<any>();

    constructor(name: string, label: string, row?: number, column?: number,
      rowspan?: number, columnspan?: number, validate?: boolean, public validateEqualTo?: string, public validateEqualFrom?: string, public feedback?: boolean) {
        super(name, label, row , column ,
            rowspan, columnspan, validate);        
    }
}
