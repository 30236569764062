import { FormProperty } from './formProperty';
import { PropertyTypes } from '../enum/propertyTypes';
import { EventEmitter } from '@angular/core';
import { GSLocationMultiselectControl } from '../../controls/gs.locationMultiselect.control';

export class FormLocationMultiselectProperty extends FormProperty {
  get options() {
    return this._options;
  }
  set options(value) {
    this._options = value;
    this.optionsSet.next();
  }
  private _options?: any[];
  source: any;
  type = PropertyTypes.LocationMultiselect;
  component = GSLocationMultiselectControl;
  changed = new EventEmitter<any>();
  optionsSet = new EventEmitter<void>();
  selectedValue: any;
  defaultLabel = 'Choose';

  constructor(
    name: string,
    label: string,
    options?: [],
    row?: number,
    column?: number,
    rowspan?: number,
    columnspan?: number,
    validate?: boolean
  ) {
    super(name, label, row, column, rowspan, columnspan, validate);
    this.options = options ? options : this.options;
  }
}
