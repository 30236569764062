<header class="pdplg-header">
  <div class="pdplg-container">
    <div class="pdplg-logo pdplg-withbg">
      <img src="../assets/images/paydayportal-logo.svg" alt="logo" />
    </div>

    <div class="pdplg-navigation">
      <div class="pdplg-btn-group pdplg-TopRightBtn">
        <div class="pdplg-contactform">
          <form id="pdplg-contactusform" [formGroup]="form">
            <div class="pdplg-contact-field">
              <div class="pdplg-valid" id="divFirstName">
                <input class="pdplg-form-control"
                       id="user"
                       type="text"
                       placeholder="{{ 'login.Username' | translate }}"
                       formControlName="user"
                       (keydown.enter)="login($event)" />
              </div>
              <div class="pdplg-field-error">
                <div id="userValidation"
                     *ngIf="
                    !form.controls['user'].valid && form.controls['user'].dirty
                  "
                     translate>
                  login.UsernameRequired
                </div>
              </div>
            </div>
            <div class="pdplg-contact-field">
              <div class="pdplg-form-group valid" id="divFirstName">
                <input class="pdplg-form-control"
                       id="password"
                       type="password"
                       pPassword
                       [feedback]="false"
                       [showPassword]="showPassword"
                       placeholder="{{ 'login.Password' | translate }}"
                       formControlName="password"
                       (keydown.enter)="login($event)"
                       style="border-radius: 35px !important" />
                <span *ngIf="!showPassword"
                      class="pointer"
                      (click)="show()"
                      style="position: absolute; right: 20px; top: 13px">
                  <i class="fas fa-eye"></i>
                </span>
                <span *ngIf="showPassword"
                      class="pointer"
                      (click)="show()"
                      style="position: absolute; right: 20px; top: 13px">
                  <i class="fas fa-eye-slash"></i>
                </span>
              </div>
              <div class="pdplg-field-error">
                <div id="passwordValidation"
                     *ngIf="
                    !form.controls['password'].valid &&
                    form.controls['password'].dirty
                  "
                     translate>
                  login.PasswordRequired
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="pdplg-contactform pdplg-login-btn-container">
          <div [class.disabled_opacity-no-color]="!form.valid || loading">
            <a href="#" (click)="login($event)" class="pdplg-site-btn">
              {{
              "login.LogIn" | translate
              }}
            </a>
          </div>

          <div class="pdplg-forgotPass">
            <a href="*" (click)="forgotPasswordClick($event)">
              Forgot password?
            </a>
          </div>
        </div>
        <div class="sso-text">
          <span>
            {{ "login.ssoDescription" | translate }}
          </span>
        </div>
        <div class="pdplg-contactform pdplg-login-btn-container sso-container">
          <div [class.disabled_opacity-no-color]="loading">
            <a href="#"
               (click)="oktaLogin($event)"
               class="pdplg-site-btn-sso sso-login">{{ "login.sso" | translate }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="pdplg-logo-right">
      <a href="https://gratuitysolutions.com/index.html">
        <img src="../assets/images/gratuity_solutions_m.png"
             class="pdplg-logo_mobile" />
      </a>
    </div>
  </div>

  <p-dialog #changePass
            header="{{ dialogHeader }}"
            [(visible)]="showDialog"
            [modal]="true"
            [style]="{ width: dialogWidth }"
            [draggable]="false"
            [resizable]="false">
    <change-password *ngIf="changePaswordModel"
                     [model]="changePaswordModel"
                     [form]="dialogForm"
                     (onApply)="apply()"
                     (onCancel)="cancel()"></change-password>
    <manage-agreement *ngIf="agreementURl"
                      [agreementURl]="agreementURl"></manage-agreement>

    <p-footer>
      <button type="button"
              pButton
              icon="pi pi-check"
              *ngIf="dialogForm"
              [disabled]="!dialogForm.valid"
              (click)="apply()"
              label="{{ dialogApply }}"></button>
      <button type="button"
              pButton
              icon="pi pi-times"
              *ngIf="dialogForm"
              (click)="cancel()"
              label="{{ dialogCancel }}"
              class="ui-button-secondary"></button>
      <button type="button"
              pButton
              icon="pi pi-print"
              (click)="print()"
              *ngIf="agreementURl"
              label="{{ dialogCancel }}"
              class="ui-button-secondary"></button>
      <button type="button"
              pButton
              icon="pi pi-check"
              (click)="apply()"
              *ngIf="agreementURl"
              label="{{ dialogApply }}"></button>
    </p-footer>
  </p-dialog>

  <p-dialog *ngIf="showForgotPassDialog"
            class="forgotpass"
            #forgotPass
            header="FORGOT PASSWORD?"
            [(visible)]="showForgotPassDialog"
            [modal]="true"
            [style]="{ width: dialogWidth }"
            [draggable]="false"
            [resizable]="false">
    <div *ngIf="!forgotPassFinalVisible"
         [formGroup]="forgotPassForm"
         class="nsignup"
         style="margin-top: 20px">
      <ul class="list-unstyled">
        <li>
          <div class="p-inputgroup">
            <span style="display: grid"
                  class="p-inputgroup-addon form-input-icon">
              <div style="margin-top: -10px"><i class="fas fa-at"></i></div>
              <div style="margin-top: -10px"><i class="fas fa-mobile"></i></div>
            </span>
            <input id="emailOrPhone"
                   type="text"
                   pInputText
                   placeholder="{{ 'forgotPass.emailOrPhone' | translate }}"
                   formControlName="emailOrPhone"
                   class="form-input"
                   style="height: 50px" />
          </div>
          <div class="form-error">
            <div id="emailOrPhoneValidation"
                 *ngIf="
                !forgotPassForm.controls['emailOrPhone'].valid &&
                forgotPassForm.controls['emailOrPhone'].dirty
              "
                 translate>
              forgotPass.emailOrPhoneRequired
            </div>
          </div>
        </li>
        <li>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon form-input-icon">
              <i class="fas fa-user"></i>
            </span>
            <input id="username"
                   type="text"
                   placeholder="{{ 'forgotPass.username' | translate }}"
                   formControlName="username"
                   class="form-input"
                   style="height: 50px" />
          </div>
          <div class="form-error">
            <div id="usernameValidation"
                 *ngIf="
                !forgotPassForm.controls['username'].valid &&
                forgotPassForm.controls['username'].dirty
              "
                 translate>
              forgotPass.usernameRequired
            </div>
          </div>
        </li>
        <li>
          <div style="margin-bottom: -20px">Google Captcha loading...</div>
          <div class="p-inputgroup">
            <re-captcha (resolved)="resolved($event)"
                        [siteKey]="SITE_KEY"></re-captcha>
          </div>
        </li>
        <li class="p-inputgroup" style="margin-top: 30px">
          <button class="button"
                  pButton
                  label="{{ 'forgotPass.sendInquiry' | translate }}"
                  [disabled]="!forgotPassForm.valid || !captchaValid"
                  (click)="forgotPassRequest()"></button>
        </li>
      </ul>
    </div>
    <div *ngIf="forgotPassFinalVisible"
         [formGroup]="forgotPassFormFinal"
         class="nsignup"
         style="margin-top: 20px">
      <ul class="list-unstyled">
        <li>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon form-input-icon">
              <i class="fas fa-qrcode"></i>
            </span>
            <input autocomplete="new"
                   id="emailOrPhone"
                   type="text"
                   placeholder="{{ 'forgotPass.verificationCode' | translate }}"
                   formControlName="verificationCode"
                   class="form-input"
                   style="height: 50px" />
          </div>
          <div class="form-error">
            <div id="emailOrPhoneValidation"
                 *ngIf="
                !forgotPassFormFinal.controls['verificationCode'].valid &&
                forgotPassFormFinal.controls['verificationCode'].dirty
              "
                 translate>
              forgotPass.verificationCodeRequired
            </div>
          </div>
        </li>
        <li>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon form-input-icon">
              <i class="fas fa-key"></i>
            </span>
            <input autocomplete="new-password"
                   type="password"
                   [(ngModel)]="forgotPaswordFinalModel.newPassword"
                   formControlName="newPassword"
                   pPassword
                   [showPassword]="forgotPaswordFinalModel.showNew"
                   [feedback]="true"
                   placeholder="{{ 'login.newPassword' | gstranslate }}"
                   class="form-input"
                   style="
                height: 50px;
                width: 100%;
                border-right: 1px solid lightgray;
              " />
            <span *ngIf="forgotPaswordFinalModel.newPassword"
                  (mousedown)="forgotPaswordFinalModel.showNew = true"
                  (mouseup)="forgotPaswordFinalModel.showNew = false"
                  class="change-pass-eye">
              <i class="fas fa-eye"></i>
            </span>
          </div>
          <div class="form-error">
            <div id="newPasswordValidation"
                 *ngIf="
                !forgotPassFormFinal.controls['newPassword'].valid &&
                forgotPassFormFinal.controls['newPassword'].dirty
              "
                 translate>
              {{ "login.newComplexPasswordRequired" | gstranslate }}
            </div>
          </div>
        </li>
        <li>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon form-input-icon">
              <i class="fas fa-key"></i>
            </span>
            <input autocomplete="new-password"
                   type="password"
                   [(ngModel)]="forgotPaswordFinalModel.confirmPassword"
                   formControlName="confirmPassword"
                   [showPassword]="forgotPaswordFinalModel.showConfirm"
                   pPassword
                   [feedback]="false"
                   placeholder="{{ 'login.confirmPassword' | gstranslate }}"
                   class="form-input"
                   style="
                height: 50px;
                width: 100%;
                border-right: 1px solid lightgray;
              " />
            <span *ngIf="forgotPaswordFinalModel.confirmPassword"
                  (mousedown)="forgotPaswordFinalModel.showConfirm = true"
                  (mouseup)="forgotPaswordFinalModel.showConfirm = false"
                  class="change-pass-eye">
              <i class="fas fa-eye"></i>
            </span>
          </div>
          <div class="form-error">
            <div id="confirmPasswordValidation"
                 *ngIf="
                !forgotPassFormFinal.controls['confirmPassword'].valid &&
                (forgotPassFormFinal.controls['confirmPassword'].dirty ||
                  forgotPassFormFinal.controls['newPassword'].dirty)
              "
                 translate>
              {{ "login.confirmPasswordRequired" | gstranslate }}
            </div>
          </div>
        </li>

        <li class="p-inputgroup" style="margin-top: 30px">
          <button class="button"
                  pButton
                  label="{{ 'forgotPass.changePassword' | translate }}"
                  [disabled]="!forgotPassFormFinal.valid"
                  (click)="forgotPassRequestFinal()"></button>
        </li>
      </ul>
    </div>
  </p-dialog>

  <p-dialog [(visible)]="confirmationDialogVisible"
            [header]="confirmationHeader"
            modal="true"
            [closable]="true"
            class="signup">
    {{ confirmationMessage }}
    <p-footer>
      <button type="button"
              pButton
              icon="pi pi-check"
              (click)="closeConfirmationDialog()"
              label="{{ 'general.ok' | translate }}"></button>
    </p-footer>
  </p-dialog>

  <p-dialog [(visible)]="showIdentityProvider"
            [header]="'Enter identity provider code'"
            modal="true"
            [closable]="true"
            class="signup">
    <form *ngIf="identityProviderModel" [formGroup]="identityProviderForm" class="form-inputgroup">
      <input id="identityProviderCode"
             type="text"
             placeholder="Identity provider code"
             formControlName="identityProviderCode"
             class="form-input"
             style="height:50px;border-radius:35px;"
              [(ngModel)]="identityProviderModel.identityProviderCode"/>
     
    </form>
    <p-footer>
      <button *ngIf="identityProviderForm" type="button"
              pButton
              (click)="confirmIdentityProviderDialog()"
              label="Confirm"
              [disabled]="!identityProviderForm.valid"></button>
      <button *ngIf="identityProviderForm" type="button"
              pButton
              (click)="closeIdentityProviderDialog()"
              label="Cancel"></button>
    </p-footer>
  </p-dialog>
</header>

<section class="pdplg-banner-top-sec pdplg-mobLogin">
  <div class="pdplg-container">
    <div class="pdplg-home-bt">
      <a href="https://gratuitysolutions.com/"
        ><img src="../../../assets/images/home-icon.png"
      /></a>
    </div>
    <div class="pdplg-contactform">
      <form id="pdplg-contactusform" action="#" [formGroup]="form">
        <div class="pdplg-contact-field">
          <div class="pdplg-form-group valid" id="divFirstName">
            <input
              class="pdplg-form-control"
              id="user"
              type="text"
              placeholder="{{ 'login.Username' | translate }}"
              formControlName="user"
              (keydown.enter)="login($event)"
            />
          </div>
        </div>
        <div class="pdplg-contact-field">
          <div class="pdplg-form-group valid" id="divFirstName">
            <input
              class="pdplg-form-control"
              id="password"
              type="password"
              placeholder="{{ 'login.Password' | translate }}"
              formControlName="password"
              (keydown.enter)="login($event)"
            />
          </div>
        </div>
      </form>
    </div>
    <div class="pdplg-contactform">
      <div class="pdplg-btn-group pdplg-get-free-btn">
        <a href="#" (click)="login($event)" class="pdplg-site-btn">{{
          "login.LogIn" | translate
        }}</a>
      </div>
      <div class="pdplg-forgotPass">
        <a href="*" (click)="forgotPasswordClick($event)"> Forgot password? </a>
      </div>
    </div>
  </div>
  <div style="clear: both"></div>
</section>

<!--<embed [src]="contentURL"
       style="width:100%; height: calc(100vh - 120px)"
       onerror="alert('URL invalid !!');" />-->

<!--<BODY style="overflow:hidden; height:100%;">

  <object [data]="contentURL" type="text/html" style="float:left;width:230px;height:100%"></object>

</BODY>-->

<iframe
  class="pdplg-iframe"
  id="iframeURL"
  name="iframeURL"
  [src]="contentURL"
  allowtransparency="true"
  scrolling="yes"
  style="width: 100%; border: none; height: calc(100vh - 10px)"
></iframe>
