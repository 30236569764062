export enum WizardSteps {
    CompanyInfo = 1,
    BusinessLocalization = 2,
    Datasource = 3,
    InstalationPackage = 4,
    TimeProvider = 5,
    TendersMapping = 6,
    TrackingGroups = 7,
    JobElection = 8,
    Distribution = 9,
    JobConfiguration = 10,
    GlobalConfiguration = 11,
    PayCard = 12,
  PayRoll = 13,
  DataExport = 14,
    Workflows = 15,
    PricingAndBilling = 16
}
