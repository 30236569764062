import { FormProperty } from './formProperty';
import { PropertyTypes } from '../enum/propertyTypes';
import { EventEmitter, Injectable } from '@angular/core';
import { GSTextboxControl } from '../../controls/gsControls';

export class FormTextboxProperty extends FormProperty {
    type = PropertyTypes.Textbox;
    component = GSTextboxControl;
  changed = new EventEmitter<any>();
  keydown = new EventEmitter<any>();
  onpaste = new EventEmitter<any>();
  afterText;

  constructor(name: string, label: string, row?: number, column?: number,
      rowspan?: number, columnspan?: number, validate?: boolean, validationRegex?: RegExp, align?, public textRows?, public maxLen?) {
        super(name, label, row , column ,
          rowspan, columnspan, validate, validationRegex, align);

  }

}
