import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { BrowserHelper } from '../utils/utils';
import { Session } from '../entity/entities';

@Pipe({ name: 'timeToString' })
export class TimeToStringPipe implements PipeTransform {
  transform(value): string {
    if (!value) return '';
    const time = value.split(':');
    return `${time.length > 0 ? time[0] : ''}:${
      time.length > 1 ? time[1] : ''
    }`;
  }
}
