<div class="buttons-title-bar">
  <span *ngIf="!isFirstStep && showPrev" style="float: left">
    <button pButton class="prevnext"
            icon="fa fa-chevron-left"
            type="button"
            [label]="prevText"
            [disabled]="isFirstStep"
            (click)="doGoPrev($event)"></button>
  </span>
  <span>
    <button *ngIf="showApply && inUse && !locked" [margin-right]="8" pButton
            icon="fas fa-save"
            type="button"
            [disabled]="!applyEnabled || !item.isValid"
            label="{{'general.apply' | gstranslate }}"
            (click)="doApply($event)"></button>
    <button *ngIf="showUndo && inUse && !locked" class="undo" [margin-right]="8" pButton
            icon="fas fa-undo"
            [disabled]="!item.isDirty"
            type="button"
            label="{{'general.undo' | gstranslate }}"
            (click)="doUndo($event)"></button>
  </span>
  <span *ngIf="!isLastStep && showNext" style="float: right">
    <button pButton class="prevnext" iconPos="right"
            icon="fa fa-chevron-right"
            type="button"
            [label]="nextText"
            [disabled]="isLastStep"
            (click)="doGoNext($event)"></button>
  </span>

  <p-overlayPanel [appendTo]="'body'" [dismissable]="true" (onHide)="hideDialog($event)" [showCloseIcon]="true"  #historyLogComment>
    <step-history-log [history]="history"></step-history-log>
  </p-overlayPanel>

</div>
