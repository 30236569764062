export enum FormTypes {
  NewCustomer = 1,
  CustomerInfo = 2,
  BusinessLocalization = 3,
  DatasourceConfiguration = 4,
  DatasourceConfiguration_AdditionalSettings = 5,
  TimeProvider = 6,
  TenderMaping = 7,
  JobElection = 8,
  NewPool = 9,
  GlobalConfiguration = 10,
  PayCardCardProvider = 11,
  PayCardCardFlex = 12,
  PayCardGCC = 13,
  PayCardADP = 14,
  PayCardRapidCard = 15,
  PayCardMoneyNetwork = 16,
  PayCardUSBank = 17,
  PayCardNetSpend = 18,
  Distribution_Replacement_Groups = 19,
  Payroll = 20,
  PricingAndBilling = 21,
  Pricing = 22,
  BillingInfo = 23,
  BillingAdditionalInfo = 24,
  BillingInfoDetails = 25,
  NewLocation = 26,
  PayCardTabaPay = 27,
  AccountDetails = 28,
  ChangePassword = 29,
  RegisterManagerAccount = 30,
  ActivateManagerAccount = 31,
  ManagerAccountDetails = 32,
  ChangeLogFilter = 33,
  ManagersAccountsFilter = 34,
  RegisterTicket = 35,
  LoadCardReportFilter = 36,
  WithholdReportFilter = 37,
  ScheduleTraining = 38,
  EmployeeProfileFilter = 39,
  EmployeeProfileGratuityAccount = 40,
  EmployeeProfileCardSettings = 41,
  EmployeeProfileCardStatus = 42,
  ControlPanelWithholdFilter = 43,
  MonthlyOverviewFilter = 44,
  ControlPanel_DistributionRules = 45,
  EmployeeSummaryReportFilter = 46,
  Reporting_AuditDetails = 47,
  EmployeeMapping = 48,
  JobMapping = 49,
  TransactionsWithEndDateFilter = 50,
  Dashboard_CustomerTabLocations = 51,
  PayCardCardProvider_CardProperties = 52,
  Reportig_Payments = 53,
  PayCardDailyPay = 54,
  PayCardInPrepaid = 55,
  PayCardComData = 56,
  ControlPanel_VisaChoice = 57,
  Payroll_Automatic = 58,
  Reporting_Analytics_Totals = 59,
  GlobalConfiguration_Wage = 60,
  ControlPanel_VisaChoice_Merchant = 61,
  ControlPanel_VisaChoice_CustomerBankInfo = 62,
  ControlPanel_VisaChoice_Ownership = 63,
  ControlPanel_VisaChoice_AdditionalInfo = 64,
  ControlPanel_PlaidConfiguration = 65,
  EmployeeProfileCardAssigned = 66,
  CustomerDeactivation = 67,
  TrackingGroupsCommissionSales = 68,
  ControlPanel_CommissionDistributionRules = 69,
  CompanyGroupAddProperty = 72,
  ControlPanel_VisaChoice_ACH = 73,
  PayCardBranch = 74,
  TabapayReconciliationReportFilter = 75,
  QuickbooksFilter = 76,
  PayCardFakeCard = 77,
  CompanyGroup_Details = 78,
  CompanyGroup_Details2 = 79,
  CompanyGroup_Details3 = 80,
  Reporting_Analytics = 81,
  DwollaVerifiedCustomerFilter = 83,
  DwollaVerifiedCustomer = 84,
  DwollaVerifiedCustomerController = 85,
  DwollaVerifiedCustomerBenefitialOwner = 86,
  DwollaCustomerFundingSource = 87,
  DwollaVerifyMicrodeposit = 88,
  DwollaCustomerBankAccounts = 89,
  DwollaAssignLocations = 90,
  DwollaEmailVerification = 91,
  TabapayReconciliationBankStatementsFilter = 92,
  TabapayReconciledTransactionsFilter = 93,
  TabapayReconciledTransactionsByCustomerFilter = 94,
  CompanyGroupDwolla = 95,
  TabapayUnreconciledTransactionsFilter = 96,
  CustomerAdditionalParamsText = 97,
  CustomerAdditionalParamsDecimal = 98,
  CustomerAdditionalParamsDate = 99,
  CustomerAdditionalParamsPassword = 100,
  CompanyGroupCustomerAdditionalParams = 101,
  CompanyGroup_BasicDetails = 102,
  CompanyGroup_Datasource = 103,
  ControlPanel_VisaChoiceCustomerMapping = 104,
  Plaid_Originators = 105,
  Plaid_CustomerMappings = 106,
  DataExportReportFilter = 107,
  PayCardInstant = 108,
  Plaid_OriginatorsTransferVolume = 109,
  PayCardPlaid = 110,
  DataExportConnectorDetails = 111,
  DataExportConnectorDetails_Credentials = 112,
  DataExportDetails = 113,
  TAConfiguration = 114,
  Plaid_BankAccount = 115,
  Vendor_Details = 116,
  Vendor_Credentials_Details = 117,
  PaymentIntegrations_PlaidOriginatorsFilter = 118,
  PaymentIntegrations_PlaidDebitTrFilter = 119,
  PayCardNetworkFee = 120,
  EmployeeProfileCardAssignedActive = 121,
  PayCardTabaPayBank = 122,
  WorkflowExecutionLog = 123,
  Workflow_Definitions_Details = 124,
  Workflow_Definitions_Execution_Configuration = 125,
  Workflow_Task_Invocation_Details = 126,
  CompanyGroup_Settings = 127,
  EmployeeProfileCardAssignedEnableLoad = 128,
  GlobalConfiguration_ContinuesPayment = 129,
}
