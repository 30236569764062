import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { umask } from 'process';
import { SecurityClient } from 'src/app/client/security.client';
import { Item, Session } from '../../entity/entities';

@Component({
  selector: 'app-user-manual',
  templateUrl: './user-manual.component.html',
  styleUrls: ['./user-manual.component.css'],
})
export class UserManualComponent implements OnInit {
  @Input() showAll: boolean = false;
  @Input() isFromNavigation: boolean = false;
  helpSrc;
  userManuals;
  allUserManuals = new Array<{ name; key; selected }>();
  @ViewChild('downloadLink') downloadLink;

  constructor(private securityCli: SecurityClient) {}

  ngOnInit(): void {
    Session.menu.menuItems.values.forEach((menu) => {
      if (menu.data.UserManuals)
        menu.data.UserManuals.forEach((um) => {
          this.allUserManuals.push({
            name: um.Name,
            key: um.Key,
            selected: false,
          });
        });
    });
    this.userManuals = this.allUserManuals;
  }

  filter(event) {
    if (event?.target?.value)
      this.userManuals = this.allUserManuals.filter(
        (x) => x.name.toLowerCase().indexOf(event.target.value) != -1
      );
    else this.userManuals = this.allUserManuals;
  }

  showHelp(userManual) {
    this.userManuals.forEach(x=>x.selected = false);
    userManual.selected = true;
    this.securityCli.showHelp(userManual.key,null).then((response) => {
      this.helpSrc = `data:application/pdf;base64,${response.result}`;
    });
  }

  downloadFile(){
    this.downloadLink.nativeElement.href = this.helpSrc;
    this.downloadLink.nativeElement.download = `${this.userManuals.find(m=>m.selected).name}.pdf`;
    setTimeout(() => {
      this.downloadLink.nativeElement.click();
    }, 100);
  }

}
