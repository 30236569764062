import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HubConnection, MessageHeaders } from '@microsoft/signalr';
import { Session } from '../entity/entities';
import { HttpHeaders } from '@angular/common/http';
import { Subject, forkJoin, from, iif, of } from 'rxjs';
import { map, filter, catchError, tap, first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const SUBSCRIBE_METHOD = 'Subscribe';
const UNSUBSCRIBE_METHOD = 'Unsubscribe';
const KEEP_ALIVE_INTERVAL = 12 * 60 * 60;
@Injectable({
  providedIn: 'root',
})
export class BroadcastService {
  private _hubConnection: HubConnection | undefined;
  private subscribe$ = new Subject<any>();
  private tokenExpired$ = new Subject<any>();
  private _subscribedEvents = [];

  constructor() {}

  public init() {
    const token = Session.token;
    const clientId = Session.broadcastingClientId;
    const connectionAttempts = this.getConnectionAttempts();
    this._hubConnection = new signalR.HubConnectionBuilder()
      .withAutomaticReconnect(connectionAttempts) //keep connection live for 24h or until manually disconnect
      .withUrl(
        `${environment.broadcastingUrl2}/${environment.hubName}?token=${token}&clientId=${clientId}&portalCode=${environment.portalCode}`
      )
      .configureLogging(signalR.LogLevel.Information)
      .build();
    console.log(`[BroadcastService] Connect to hub`);
    this._hubConnection.on('Subscribe', (message) => {
      if (typeof message === 'string') message = JSON.parse(message);
      this.subscribe$.next(message);
    });
    this._hubConnection.on('connected', (message) => {
      console.log(message);
    });
    this._hubConnection.on('tokenExpired', (message) => {
      console.log(message);
      this.tokenExpired$.next(message);
    });
    return this._hubConnection
      .start()
      .catch((err) => console.error(err.toString()));
  }

  public disconnect() {
    if (!this._subscribedEvents.length) this._hubConnection.stop();
    else {
      const unsubscribe$ = [];
      this._subscribedEvents.forEach((x) => {
        unsubscribe$.push(this.unsubscribe(x));
      });
      forkJoin(unsubscribe$).subscribe(() => {
        this._hubConnection.stop();
      });
    }
  }

  public subscribe(data: {
    customerCode?;
    event?;
    requestId?;
    expiresInSeconds?;
  }) {
    console.log(
      `[BroadcastService] Subscribe to event for requestId: ${data.requestId} and event: ${data.event}`
    );
    this.registerEvent(data);
    this._hubConnection.invoke(
      SUBSCRIBE_METHOD,
      Session.broadcastingClientId,
      data.customerCode,
      data.event,
      data.requestId,
      data.expiresInSeconds ?? 300
    );
    return this.subscribe$.pipe(
      //create JSON object from message string
      map((mapData) => {
        if (typeof mapData === 'string') return JSON.parse(mapData);
        return mapData;
      }),
      filter((filter) => data.event == filter.NotificationEvent),
      catchError((error) => {
        console.log(
          `-----Subscribe to event failed for requestId: ${data.requestId} and event: ${data.event}`
        );
        return of(error);
      })
    );
  }

  public tokenExpired() {
    return this.tokenExpired$;
  }

  public unsubscribe(data: { event; requestId? }) {
    console.log(
      `[BroadcastService] Unsubscribe to event for requestId: ${data.requestId} and event: ${data.event}`
    );
    return from(
      this._hubConnection.invoke(UNSUBSCRIBE_METHOD, data.event, data.requestId)
    ).pipe(
      first(),
      tap(() => {
        this.removeEvent(data);
      })
    );
  }

  public subscribeOnMultipleEvents(
    events,
    data: {
      customerCode?;
      requestId?;
      expiresInSeconds?;
    }
  ) {
    events.forEach((event) => {
      this.registerEvent(event);
      this.subscribe({ ...data, event });
    });
    return this.subscribe$;
  }

  private getConnectionAttempts() {
    const connectionAttempts = [];
    let i = 0;
    while (i < KEEP_ALIVE_INTERVAL) {
      connectionAttempts.push(2000);
      i++;
    }
    return connectionAttempts;
  }

  private registerEvent(data) {
    if (!this._subscribedEvents.includes(data))
      this._subscribedEvents.push(data);
  }
  private removeEvent(data) {
    if (data === undefined) return;
    const i = this._subscribedEvents.findIndex(
      (x) => x.event === data.event && x.requestId == data.requestId
    );
    if (i > -1) this._subscribedEvents.splice(i, 1);
  }
}
