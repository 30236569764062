import { DashboardBaseComponent } from '../../../menuItems/dashboard/components/dashboardBase.component';

export enum TableTypes {
  CustomerList = 1,
  DataSourceConfiguration = 2,
  BusinessLocalization_everyDay = 3,
  BusinessLocalization_byDay = 4,
  TenderMapping = 5,
  JobConfiguration = 6,
  JobElectionJobs = 7,
  Disitribution = 8,
  Disitribution_Exceptions = 9,
  Security_Function = 10,
  Dashboard_Notifiation = 11,
  Dashboard_Time_TimeCard = 12,
  Dashboard_Time_Breaks = 13,
  Dashboard_Time_Shifts = 14,
  Dashboard_Time_TimeAdd = 15,
  ControlPanel_ManagerAccounts = 16,
  LoadCardReport_Details = 17,
  EmployeeProfile_ProfileDetails = 18,
  CalculatedData_DistributionOverride = 19,
  EmployeeProfile_ProfileMappings = 20,
  Dashboard_Payroll_Periods = 21,
  Dashboard_Payroll_Periods_Data = 22,
  ControlPannel_Employee_Mapping = 23,
  ControlPannel_Employee_ETimeMapping = 24,
  ControlPannel_Job_Payroll_Mapping = 25,
  ControlPannel_Job_ETimeMapping = 26,
  ControlPanel_Withhold = 27,
  ControlPanel_WithholdConfiguration = 28,
  Dashboard_POS_Distribution = 29,
  Dashboard_MonthlyOverviewTipShare = 30,
  Dashboard_PayMe = 31,
  ControlPanel_PayMePreferences = 32,
  Dashboard_BulkApproveAndClose = 33,
  Dashboard_TransactionsWithEndDate = 34,
  Dashboard_TransactionsWithEndDateEdit = 35,
  Security_Alarms = 36,
  Dashboard_TED_PosData = 37,
  Dashboard_TED_Transactions = 38,
  Security_UserManual = 39,
  Security_AutoTimecardJobs = 40,
  Security_AutoTimecardRule = 41,
  Security_AutoTimecardRuleDependencies = 42,
  Security_PEExportTemplateColumns = 43,
  PayrollExportTemplateCfgFormulasColumns = 44,
  Dashboard_Payroll_Manual_Changes = 45,
  ControlPanel_TimeAndAttendance = 46,
  Paycard = 47,
  Security_Alarms_DifferenceJobs = 48,
  CommissionSalesCategoryTracks = 49,
  Security_AutoTimecardVirtualEmployees = 50,
  PaymentIntegrations_VisaChoiceApplications = 51,
  PaymentIntegrations_VisaChoiceApplicationOwners = 52,
  PaymentIntegrations_VisaChoiceApplicationAdditionalDocs = 53,
  TabapayReconciliationReport = 54,
  TabapayReconciliationReport_Details = 55,
  Quickbooks_Overview = 56,
  Quickbooks_Details = 57,
  Dashboard_CheckAdjustments = 58,
  Dashboard_CheckAdjustmentsUpdated = 59,
  CompanyGroup_Overview = 60,
  Security_AutoTransfer = 61,
  Reporting_Analytics = 62,
  Reporting_AnalyticsDetails = 63,
  Reporting_AnalyticsRowDetails = 64,
  DwollaBenefitialOwnersList = 65,
  DwollaCustomerDocuments = 66,
  DwollaFundingSources = 67,
  TabapayReconciliation_Import = 68,
  TabapayReconciliation_ProccessedImport = 69,
  TabapayReconciliation_BankStatements = 70,
  TabapayReconciledTransactions = 71,
  TabapayReconciledTransactionsByCustomer = 72,
  TabapayUnreconciledTransactions = 73,
  DwollaFundingSourcesLocations = 74,
  CompanyGroup_CustomerAdditionalParams = 75,
  VisaChoiceApplications = 76,
  VisaChoiceApplications_CustomerMapping = 77,
  PlaidOriginators = 78,
  PlaidFundingSources = 79,
  PlaidFundingSourceMappings = 80,
  Reporting_DataExport = 81,
  Wizard_DataExport = 82,
  Wizard_DataExport_Connector = 83,
  Wizard_DataExport_Log = 84,
  Security_Vendor = 85,
  Security_Vendor_Credentials = 86,
  Security_Vendor_Credentials_Scopes = 87,
  EmployeeProfile_DataExportRestrictions = 88,
  PaymentIntegrations_PlaidOriginators = 89,
  PaymentIntegrations_PlaidDebitTransactions = 90,
  PaymentIntegrations_PlaidCreditTransactions = 91,
  LegalDocuments = 92,
  WorkflowsExecutionPlan = 93,
  WorkflowsExecutionLog = 94,
  WorkflowsTaskExecutionLog = 95,
  Workflow_Definitions = 96,
  Workflow_Definitions_Configuration = 967,
  Workflow_Definitions_Tasks = 98,
  PayCardNetworks = 99,
  Billing = 100,
  WorkflowTaskLogDetails = 101
}
