<div *ngIf="item" class="row form-property">
  <div class="col-lg-{{tooltip?11:12}}"
       [ngClass]="{'no-padding-right':tooltip}">
    <span class="p-float-label">
      <p-dropdown [appendTo]="'body'"
                  id="{{name}}"
                  [showClear]="showClear"
                  [options]="options"
                  (onChange)="change($event)"
                  [ngModel]="value"
                  [readonly]="readonly"
                  [ngClass]="{'disabled':readonly, 'dropdown-property-invalid':notValid}"
                  class="disabled ui-inputwrapper-focus ui-inputwrapper-focus"
                  [style]="{'width': '100%'}"
                  [panelStyle]="{'max-height': '400px','min-width': '400px'}"
                  [filter]="true"
                  [autoDisplayFirst]="false"
                  [placeholder]="property.defaultLabel">
        <ng-template let-slctd pTemplate="selectedItem">
          <span style="vertical-align: initial">{{slctd?slctd.data?.Name:''}}</span>
        </ng-template>
        <ng-template let-opt pTemplate="item">
          <div #selectOption class="row" [margin-left]="10" [margin-right]="5">
            <div class="lightborder ui-helper-clearfix col-lg-11 col-sm-11 col-md-11"
                 [ngClass]="{'disabled_opacity-no-color no-event':opt.data?.BlockedReason || opt.data?.Disabled}"
                 style="position: relative; border-bottom: 1px solid lightgrey">
              <span class="text-l bold">{{opt.data?.Name}}</span>             
            </div>         
          </div>
        </ng-template>
      </p-dropdown>
      <label class="gs-float-label" [ngClass]="{'error-color':notValid}">{{label}}</label>
      <span *ngIf="notValid"
            class="form-error"
            pTooltip="{{property.validationMessage}}"
            tooltipPosition="top">
        <i class="fas fa-info-circle fa-2x form-valid-icon with-icon error-color"></i>
      </span>
    </span>
  </div>
  <div *ngIf="tooltip" class="col-lg-1 property-tooltip">
    <span pTooltip="{{property.tooltip}}">
      <i class="fas fa-info-circle master-color"></i>
    </span>
  </div>
</div>
