import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gsDropDownValue',
  pure: false,
})
export class GsDropDownValuePipe implements PipeTransform {
  transform(options: any[], value: any): any {
    if (!options || !value) {
      return;
    }

    return options.find((x) => x.value == value).label;
  }
}
