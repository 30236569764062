import { Injectable } from '@angular/core';

@Injectable()
export class AppConfig {

    constructor() {

    }

}
