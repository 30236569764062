<div class="row form-property">
  <div
    class="col-lg-{{tooltip?11:12}}"
    [ngClass]="{'no-padding-right':tooltip}"
  >
    <span class="p-float-label">
      <p-calendar
        *ngIf="!property.rowspan"
        id="{{name}}"
        class="ui-inputwrapper-focus ui-inputwrapper-focus"
        (ngModelChange)="change($event)"
        [(ngModel)]="rangeDates"
        [showIcon]="property.showIcon"
        [disabled]="property.readOnly"
        [style]="{'width': '95%'}"
        selectionMode="range"
        [ngClass]="{'disabled':readonly, 'daterange-property-invalid':notValid}"
        view="{{property.monthOnly? 'month':'date'}}"
        [yearNavigator]="true"
        [yearRange]="property.yearRange"
      >
      </p-calendar>
      <label class="gs-float-label" [ngClass]="{'error-color':notValid}">{{label}}</label>
      <span
        *ngIf="notValid"
        class="form-error"
        pTooltip="{{property.validationMessage}}"
        tooltipPosition="top"
      >
        <i
          style="margin-right: 15px"
          class="fas fa-info-circle fa-2x form-valid-icon with-icon error-color"
        ></i>
      </span>
    </span>
  </div>
  <div *ngIf="tooltip" class="col-lg-1 property-tooltip">
    <span pTooltip="{{property.tooltip}}">
      <i class="fas fa-info-circle master-color"></i>
    </span>
  </div>
</div>
