<div class="display-flex">
  <div style="width: 50px">
    <input
      *ngIf="!readOnly && !col.readonly"
      [ngClass]="{
        'table-control-fill': col.minWidthPX === undefined,
        'table-control-inherit': col.minWidthPX != undefined,
           'property-invalid': !validField(item, col.name)
      }"
      [ngStyle]="{ 'text-align': col.align }"
      class="table-control p-inputtext"
      type="text"
      [disabled]="
        readOnly ||
        col.readonly ||
        (col.disabledParameterReference &&
          item.parameters &&
          item.parameters[col.disabledParameterReference])
      "
      [class.disabled]="
        readOnly ||
        col.readonly ||
        (col.disabledParameterReference &&
          item.parameters &&
          item.parameters[col.disabledParameterReference])
      "
      pInputText
      [(ngModel)]="weekValue"
      (ngModelChange)="changed($event)"
    />
  </div>
  <div style="width: 150px">
    <ng-container *ngIf="!readOnly && !col.readonly">
      <p-dropdown
        *ngIf="col.showNull || item.data[col.name] || item.data[col.name] == 0"
        [filter]="col.dropdownSearch"
        filterBy="label"
        class="table-control-middle"
        [appendTo]="'body'"
        [baseZIndex]="11000"
        [(ngModel)]="dayValue"
        [options]="dayOptions"
        [style]="{ width: ' 100%', 'text-align': 'center' }"
        [disabled]="
          readOnly ||
          col.readonly ||
          (col.disabledParameterReference &&
            item.parameters &&
            item.parameters[col.disabledParameterReference])
        "
        [class.disabled]="
          readOnly ||
          col.readonly ||
          (col.disabledParameterReference &&
            item.parameters &&
            item.parameters[col.disabledParameterReference])
        "
        [virtualScroll]="col.virtualScroll"
        [itemSize]="31"
        (ngModelChange)="changed($event)"
        [placeholder]="col.placeholder"
        optionDisabled="isDisabled"
      >
      </p-dropdown>
    </ng-container>
    <ng-container *ngIf="readOnly || col.readonly">
      {{ col.options | gsDropDownValue : item.data[col.name] }}
    </ng-container>
  </div>
  <div class="col-lg-1 property-tooltip">
    <span pTooltip="{{ col.tooltip }}">
      <i class="fas fa-info-circle master-color"></i>
    </span>
  </div>
</div>
