import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
} from '@angular/core';
import { GSBaseControl } from '../gs.base.control';
import { Item } from '../../entity/entities';
import { FormTextboxProperty } from '../../entity/form/formTextbox.property';
import { FormDropdownProperty } from '../../entity/form/formDropdown.property';

@Component({
  selector: 'app-gs-workflow-relative',
  templateUrl: './gs.workflow-relative.component.html',
  styleUrls: ['./gs.workflow-relative.component.css'],
})
export class GsWorkflowRelativeComponent implements OnInit {
  @Input() item;
  @Input() property;
  @Input() dropdownProperty;
  @Input() dropdownProperty2;
  @Input() numberProperty;

  constructor(@Optional() item: Item, private changeDr: ChangeDetectorRef) {
    this.item = item;
  }

  ngOnInit(): void {
    this.dropdownProperty.changed.subscribe(this.propertyChanged);
    this.numberProperty.changed.subscribe(this.propertyChanged);
    this.dropdownProperty2.changed.subscribe(this.propertyChanged);
  }

  propertyChanged = (val) => {
    const propertyName = val.propertyName.split('_wf_')[0];
    const previousValue = this.item.data[propertyName];
    let newValue;
    if (!previousValue || previousValue == '') newValue = ':';
    else newValue = previousValue;

    const subProp = val.propertyName.split('_wf_')[1];
    if (subProp == 'type') {
      //newValue = `${val.newValue}:${newValue.split(':')[1]}`;
      newValue = `${val.newValue}:${val.newValue == 'W' ? '' : 0}`;
      this.item.data[`${propertyName}_wf_value`] = val.newValue == 'W' ? '' : 0;
    } else if (subProp == 'value')
      newValue = `${newValue.split(':')[0]}:${val.newValue}`;
    this.item.data[propertyName] = newValue;

    this.property.changed.emit({
      propertyName,
      previousValue,
      newValue,
      event: val.event,
      item: this.item,
      property: val.property,
    });
  };
}
