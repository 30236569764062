export enum ColumnTypes {
  Text = 1,
  Image = 2,
  Currency = 3,
  Button = 4,
  BoolSwitch = 5,
  DateTime = 6,
  ProgressPercent = 7,
  Checkbox = 8,
  DeleteAction = 9,
  EditAction = 10,
  BoolTrafficlight = 11,
  StarsRating = 12,
  ProgressFraction = 13,
  ProgressFractionWithAction = 14,
  TextInput = 15,
  TimeWithSpinners = 16,
  Dropdown = 17,
  RadioButton = 19,
  BoolTrafficlightYesNo = 20,
  ExpandButton = 21,
  TextWithDropFilter = 22,
  ItemDropdown = 23,
  NumberInput = 24,
  Time = 25,
  Date = 26,
  TimeSpan = 27,
  DateTimeFormat = 29,
  CustomerTextMultiSelectFilter = 30,
  EditActionText = 31,
  DateTimeString = 32,
  BoolShowAction = 33,
  TeamUp = 34,
  ButtonText = 35,
  IconSet = 36,
  IconSetDynamic = 37,
  ParameterInput = 38,
  DepartmentDropdown = 39,
  Number = 40,
  TimeChangeLog = 41,
  PETemplateFieldTypeValue = 42,
  Icon = 43,
  TimeEmployeeDropdown = 44,
  TimeUSFormat = 45,
  ScheduledValue = 46,
  TextLink = 47,
  Modal = 48,
  DynamicModal = 49
}
