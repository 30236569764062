import { Component, OnInit, OnDestroy, Inject, Optional, Injectable, ChangeDetectorRef } from '@angular/core'
import { GSBaseControl } from './gs.base.control';
import { FormProperty } from '../entity/form/formProperty';
import { FormTextboxProperty } from '../entity/form/formTextbox.property';
import { Item } from '../entity/entities';
import { FormPhoneNumberProperty } from '../entity/form/formPhoneNumber.property';

@Component({
  selector: 'gs-phone-number',
  templateUrl: './gs.phoneNumber.control.html'
})
@Injectable()
export class GSPhoneNumberControl extends GSBaseControl implements OnInit, OnDestroy {
  //#region -------Properties
  public stringItem = { value: '' };
  //#endregion

  //#region -------Constructor
  constructor(@Optional() prop: FormPhoneNumberProperty, @Optional() item: Item, private changeDr: ChangeDetectorRef) {
    super(changeDr);
        this.property = prop;
        this.item = item;
    }
  //#endregion

  //#region -------NG Eents
  ngOnInit() {
  }

  ngOnDestroy() {
  }
  //#endregion

  //#region -------UI Events
  change(e) {
    if (e || e == '') {
      let previousValue = this.data[this.name];

      let len = e.replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '').replaceAll('-', '').length;

      if (len > 10)
        e = e.substr(0, e.length - (len - 10));

      //this.value = e;
      this.item.reCheckDirty();
      this.onChange.emit({ propertyName: this.name, previousValue: previousValue, newValue: this.value, event: e, item: this.item, property: this.property });
      this.property.changed.emit({ propertyName: this.name, previousValue: previousValue, newValue: this.value, event: e, item: this.item, property: this.property });
    }
  }

  keydown(e) {
    if (e.code == 'Backspace' && this.value.length == 2) {
      this.change('');
    }
    this.property.keydown.emit({ propertyName: this.name, event: e });
  }

  onpaste(e) {
    this.property.onpaste.emit({ propertyName: this.name, event: e });
  }
  //#endregion

  //#region -------Private
  //#endregion
}
