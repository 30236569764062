<div class="gs-form content-form" *ngIf="properties">
  <div class="row" *ngFor="let row of rows">
    <ng-container *ngFor="let property of row.properties">
      <ng-container *ngIf="property.visible">
        <div
          class="gs-form-property col-lg-{{property.columnspan}} col-md-{{property.columnspan}} col-sm-{{property.columnspan}}"
        >
          <div
            id="{{property.name}}"
            placeholder
            reference="{{property.name}}"
          ></div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div *ngIf="showSubmit" [margin-top]="20" class="col-lg-12 {{submitPostion}}">
    <button
      [margin-right]="8"
      pButton
      icon="{{submitIcon}}"
      type="button"
      (click)="submit($event)"
      [disabled]="disableSubmit? !isValid : false"
      label="{{submitLabel}}"
    ></button>
  </div>
</div>
<div id="none" placeholder reference="none"></div>
