<div [ngClass]="{'row': !column, 'form-property':!column}">
  <div
    class="{{tooltip && property?'col-lg-11':''}}"
    [ngClass]="{'no-padding-right':tooltip}"
  >
    <span class="p-float-label">
      <p-dropdown
        [appendTo]="'body'"
        id="{{name}}"
        [options]="options_1"
        (onChange)="changeFk_PayrollConfigFieldType($event)"
        [ngModel]="value.Fk_PayrollConfigFieldType"
        [readonly]="readonly"
        [ngClass]="{'disabled':readonly, 'dropdown-property-invalid':notValid_Fk_PayrollConfigFieldType}"
        class="disabled ui-inputwrapper-focus ui-inputwrapper-focus"
        [style]="{'width': '100%'}"
        [panelStyle]="{'max-height': '400px','min-width': '400px'}"
        [filter]="true"
        [autoDisplayFirst]="false"
        [placeholder]="placeholder"
      >
      </p-dropdown>
      <label [ngClass]="{'error-color':notValid_Fk_PayrollConfigFieldType}"
        >{{label}}</label
      >
      <span
        *ngIf="property && notValid_Fk_PayrollConfigFieldType"
        class="form-error"
        pTooltip="{{property.validationMessage}}"
        tooltipPosition="top"
      >
        <i
          class="fas fa-info-circle fa-2x form-valid-icon with-icon error-color"
        ></i>
      </span>
    </span>
    <span style="margin-top: 3px" class="p-float-label">
      <p-dropdown
        *ngIf="value.Fk_PayrollConfigFieldType!=6"
        [appendTo]="'body'"
        id="{{name}}"
        [options]="options_2_Filtered"
        (onChange)="changeValue($event)"
        [ngModel]="value.Value"
        [readonly]="readonly"
        [ngClass]="{'disabled':readonly, 'dropdown-property-invalid':notValid_Value}"
        class="disabled ui-inputwrapper-focus ui-inputwrapper-focus"
        [style]="{'width': '100%'}"
        [panelStyle]="{'max-height': '400px','min-width': '400px'}"
        [filter]="true"
        [autoDisplayFirst]="false"
        [placeholder]="placeholder"
      >
      </p-dropdown>
      <input
        *ngIf="value.Fk_PayrollConfigFieldType==6"
        id="{{name}}"
        [ngStyle]="{'text-align': 'left'}"
        [ngModel]="value.Value"
        class="p-filled p-filled"
        [readonly]="readonly"
        [ngClass]="{'disabled':readonly,'property-invalid':notValid_Value}"
        (ngModelChange)="changeValue($event)"
        pInputText
        style="width: 100%"
      />

      <label [ngClass]="{'error-color':notValid_Value}">{{label}}</label>
      <span
        *ngIf="property && notValid_Value"
        class="form-error"
        pTooltip="{{property.validationMessage}}"
        tooltipPosition="top"
      >
        <i
          class="fas fa-info-circle fa-2x form-valid-icon with-icon error-color"
        ></i>
      </span>
    </span>
  </div>
  <!--<div *ngIf="tooltip" class="col-lg-1 property-tooltip">
    <span pTooltip="{{property.tooltip}}">
      <i class="fas fa-info-circle master-color"></i>
    </span>
  </div>-->
</div>
