
import { Component, OnInit, Input, OnDestroy, EventEmitter, Output, AfterViewInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DecimalPipe, DatePipe } from '@angular/common';
import { CalendarEventTypes } from '../entity/enum/calendarEventTypes';


@Component({
  selector: 'gs-month-calendar-event',
  templateUrl: './monthCalendarEvent.component.html'
})
export class GSMonthCalendarEventControl implements OnInit, OnDestroy, AfterViewInit {

  @Input() event;
  @Input() date;
  @Output() click = new EventEmitter<any>();


  CalendarEventTypes = CalendarEventTypes;

  constructor(private translate: TranslateService, 
    private datePipe: DatePipe) {
   
  }

  ngOnDestroy(): void {

  }

  ngOnInit() {
   

  }

  ngAfterViewInit() {

  }


  eventClick($event) {
    //this.click.emit({ clickEvent: $event, event: this.event }); 
  }
  
}
