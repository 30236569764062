import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amPmTime',
})
export class AmPmTimePipe implements PipeTransform {
  transform(value: string): unknown {
    if (!value) return null;
    let hours = parseInt(value);
    hours = hours % 12;
    hours = hours ? hours : 12;
    return hours;
  }
}
