import { Component, OnInit, OnDestroy, Inject, Optional, Injectable, ChangeDetectorRef } from '@angular/core'
import { GSBaseControl } from './gs.base.control';
import { FormInputSwitchProperty } from '../entity/form/formInputSwitch.property';
import { Item } from '../entity/entities';

@Component({
  selector: 'gs-input-switch',
  templateUrl: './gs.inputSwitch.control.html'
})
@Injectable()
export class GSInputSwitchControl extends GSBaseControl implements OnInit, OnDestroy {
  //#region -------Properties
  //#endregion

  //#region -------Constructor
  constructor(@Optional() prop: FormInputSwitchProperty, @Optional() item: Item, private changeDr: ChangeDetectorRef) {
    super(changeDr);
        this.property = prop;
        this.item = item;
    }
  //#endregion

  //#region -------NG Eents
  ngOnInit() {
  }

  ngOnDestroy() {
  }
  //#endregion

  //#region -------UI Events
  change(e) {
    let previousValue = this.data[this.name];
    this.value = e;
    this.item.reCheckDirty();
    this.onChange.emit({ propertyName: this.name, previousValue: previousValue, newValue: this.value, event: e, item: this.item, property: this.property });
    if (this.property.changed)
      this.property.changed.emit({ propertyName: this.name, previousValue: previousValue, newValue: this.value, event: e, item: this.item, property: this.property });
  }
  //#endregion

  //#region -------Private
  //#endregion
}
