import { Injectable, Component } from '@angular/core';
//import { AppConfig } from "../../app.config";
import { CacheService } from 'ng2-cache';
import { BaseClient } from '../common/client/base.client';
import { AppConfig } from '../app.config';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { PagedResultRequest, Session } from '../common/entity/entities';
import { TranslateService } from '@ngx-translate/core';
import { SvcClients } from '../common/client/SvcClients';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ReportingClient extends BaseClient {
  constructor() {
    super();
  }

  //#region-------ChangeLog

  getActiveCustomers() {
    return new Promise<any>((resolve) => {
      if (Session.cache.get('Customers', 'All'))
        resolve(Session.cache.get('Customers', 'All'));
      else
        this.get(
          SvcClients.AdminSvc,
          `PayDayAdminPortal/getAllActiveCustomers`
        ).then((data) => {
          Session.cache.set('Customers', 'All', data);
          resolve(Session.cache.get('Customers', 'All'));
        });
    });
  }

  public getPosAndCalculatedData(value, message) {
    let data = {
      ChangeLogType: value.Type,
      DateFrom: this.datepipe.transform(value.DateRange[0], 'yyyy-MM-dd'),
      DateTo: value.DateRange[1]
        ? this.datepipe.transform(value.DateRange[1], 'yyyy-MM-dd')
        : this.datepipe.transform(value.DateRange[0], 'yyyy-MM-dd'),
      CustomerCodes: value.Location,
      CustomerIDs: value.CustomerIDs,
    };
    return this.post(
      SvcClients.ResourceSvc,
      `G5ReportingChangeLog/GetPosAndCaluclatedData?customerCode=undefined`,
      data,
      message
    );
  }

  public getDistributionChanges(value, message) {
    let data = {
      DateFrom: this.datepipe.transform(value.DateRange[0], 'yyyy-MM-dd'),
      DateTo: value.DateRange[1]
        ? this.datepipe.transform(value.DateRange[1], 'yyyy-MM-dd')
        : this.datepipe.transform(value.DateRange[0], 'yyyy-MM-dd'),
      CustomerCodes: value.Location,
      CustomerIDs: value.CustomerIDs,
    };
    return this.post(
      SvcClients.ResourceSvc,
      `G5ReportingChangeLog/GetDistributionOverrideLog?customerCode=undefined`,
      data,
      message
    );
  }

  public getTimeChangeLog(value, message) {
    let data = {
      DateFrom: this.datepipe.transform(value.DateRange[0], 'yyyy-MM-dd'),
      DateTo: value.DateRange[1]
        ? this.datepipe.transform(value.DateRange[1], 'yyyy-MM-dd')
        : this.datepipe.transform(value.DateRange[0], 'yyyy-MM-dd'),
      CustomerCodes: value.Location,
      CustomerIDs: value.CustomerIDs,
    };
    return this.post(
      SvcClients.ResourceSvc,
      `G5ReportingChangeLog/GetTimeChangeLog?customerCode=undefined`,
      data,
      message
    );
  }

  public getLoadCardReport(value, message) {
    let data = {
      DateFrom: this.datepipe.transform(value.DateRange[0], 'yyyy-MM-dd'),
      DateTo: value.DateRange[1]
        ? this.datepipe.transform(value.DateRange[1], 'yyyy-MM-dd')
        : this.datepipe.transform(value.DateRange[0], 'yyyy-MM-dd'),
      CustomerCodes: value.CustomerCodes,
      CustomerIDs: value.CustomerIDs,
    };
    return this.post(
      SvcClients.ResourceSvc,
      `G5ReportingLoadCard/multilocation?customerCode=undefined`,
      data,
      message
    );
  }

  public getDataExport(value, message) {
    let data = {
      DateFrom: this.datepipe.transform(value.DateRange[0], 'yyyy-MM-dd'),
      DateTo: value.DateRange[1]
        ? this.datepipe.transform(value.DateRange[1], 'yyyy-MM-dd')
        : this.datepipe.transform(value.DateRange[0], 'yyyy-MM-dd'),
      GSclientDataExportConfigurationId:
        value.GSclientDataExportConfigurationId,
    };

    return this.post(
      SvcClients.ResourceSvc,
      `G5ReportingDataExport/GetDataExportReport`,
      data,
      message
    );
  }

  public getDataExportConfiguration(codes?, message?) {
    const filter = { codes };

    return this.post(
      SvcClients.ResourceSvc,
      `G5ReportingDataExport/GetDataExportConfiguration`,
      filter
    );
  }

  public getDataExportPayloadFileData(logId, message?) {
    return this.get(
      SvcClients.ResourceSvc,
      `G5ReportingDataExport/GetDataExportPayloadFileData?gsClientDataExportLogId=${logId}`,
      message
    );
  }

  public getWithholdeport(value, message) {
    let data = {
      ByMonth: value.ByMonth,
      ByWithholdType: value.ByWithholdType,
      CustomerCodes: value.CustomerCodes,
      CustomerIDs: value.CustomerIDs,
      YearFrom: value.Period[0].getFullYear(),
      MonthFrom: value.Period[0].getMonth() + 1,
      YearTo: value.Period[1].getFullYear(),
      MonthTo: value.Period[1].getMonth() + 1,
    };
    return this.post(
      SvcClients.ResourceSvc,
      `G5ReportingWithholdReport/getreport?customerCode=undefined`,
      data,
      message
    );
  }

  public getLoadCardReportDetails(
    batchID: number,
    employeeID: number,
    customerCode: number,
    message
  ) {
    return this.get(
      SvcClients.ResourceSvc,
      `G5ReportingLoadCard/EmployeeDetails?batchID=${batchID}&employeeID=${employeeID}&customerCode=${customerCode}`,
      message
    );
  }

  public getEmployeeSummary(
    customerCodes: any,
    dateFrom,
    dateTo,
    byDay,
    byCustomer,
    byShift,
    byPosition,
    message
  ) {
    return this.post(
      SvcClients.ResourceSvc,
      `G5EmployeeSummary/GetReport?dateFrom=${dateFrom}&dateTo=${dateTo}&byDay=${byDay}&byCustomer=${byCustomer}&byShift=${byShift}&byPosition=${byPosition}`,
      customerCodes,
      message
    );
  }
  //#endregion

  //#region-------AUDIT DETAILS

  public getAuditDetails(message, customerCode, dateFrom, dateTo) {
    return this.get(
      SvcClients.ResourceSvc,
      `AuditDetails?dateFrom=${dateFrom}&dateTo=${dateTo}&customerCode=${customerCode}`,
      message
    );
  }

  //#endregion

  //#region -------PAYMENT
  public getPayment(message, customerCode, model: any) {
    return this.post(
      SvcClients.ResourceSvc,
      `PaymentReport/DataML?customerCode=${customerCode}`,
      model,
      message
    );
  }
  //#endregion

  //#region ------------ADMIN REPORTING
  public getTabapayReconciliationReport(value, message) {
    let data = {
      DateFrom: this.datepipe.transform(value.DateRange[0], 'yyyy-MM-dd'),
      DateTo: value.DateRange[1]
        ? this.datepipe.transform(value.DateRange[1], 'yyyy-MM-dd')
        : this.datepipe.transform(value.DateRange[0], 'yyyy-MM-dd'),
      CustomerCodes: value.CustomerCodes,
      CustomerIDs: value.CustomerIDs,
    };
    return this.post(
      SvcClients.AdminSvc,
      `PDAPReportingTabapayReconciliation/GetTabapayReconciliationReport?customerCode=undefined`,
      data,
      message
    );
  }
  //#endregion

  public getReportingAnalyticCheck(data) {
    return this.post(
      SvcClients.ResourceSvc,
      `G5ReportingAnalytic/GetReportingAnalyticCheck`,
      data
    );
  }

  public getReportingAnalyticCheckDetails(data) {
    return this.post(
      SvcClients.ResourceSvc,
      `G5ReportingAnalytic/GetReportingAnalyticCheckDetails`,
      data
    );
  }

  public getPoolTransactionAnalyticsDetails(data) {
    return this.post(
      SvcClients.ResourceSvc,
      `G5ReportingAnalytic/PoolTransactionAnalyticsDetails`,
      data
    );
  }
  public rollbackPOSDataChangelog(data, message) {
    return this.post(
      SvcClients.ResourceSvc,
      `G5ReportingChangeLog/RollbackDailyTransactionsLog`,
      data
    );
  }
}
