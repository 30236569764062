import { Pipe, PipeTransform } from '@angular/core';
import { Item } from '../entity/entities';

@Pipe({
  name: 'itemParameter',
  pure: false,
})
export class ItemParameterPipe implements PipeTransform {
  transform(parameters: any, parameterKeys: string[]): any {
    if (!parameters) return;
    let results = { ...parameters };
    parameterKeys.forEach((param) => {
      if (results && results[param] !== undefined) results = results[param];
      else results = undefined;
    });
    return results;
  }
}
