export enum PropertyTypes {
  Textbox = 1,
  Dropdown = 2,
  Textarea = 3,
  Checkbox = 4,
  Autocomplete = 5,
  Password = 6,
  InputSwitch = 7,
  Time = 8,
  RadioButton = 9,
  Date = 10,
  InfoLabel = 11,
  Number = 12,
  Button = 13,
  PhoneNumber = 14,
  TimeSpan = 15,
  LocationDropdown = 16,
  DateRange = 17,
  Multiselect = 18,
  LocationMultiselect = 19,
  BoolShowAction = 20,
  DateTimeString = 21,
  CalendarFromTo = 22,
  TimeUSFormat = 23,
  MaskInput = 24,
  WFRelativeDay = 25,
  Email = 25,
}
