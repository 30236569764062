
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html'
})
export class SpinnerComponent implements OnInit {

  @Input() message = '';
  numbers = [1, 2, 3, 4];
  Math = Math;

  constructor() { }

  ngOnInit() {
  }

  get number() {
    return this.numbers[Math.floor(Math.random() * this.numbers.length)];;
  }
}
