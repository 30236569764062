import { Directive, HostListener, Input, OnInit, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FormNumberDecimalProperty } from '../entity/form/formNumberDecimal.property';
import { Item } from '../entity/entities';
import { ItemUtil } from '../utils/utils';
import { TableColumn } from '../entity/table/tableColumn';

@Directive({
  selector: '[decimal]',
})
export class DecimalDirective implements OnInit, OnDestroy {
  private _property: FormNumberDecimalProperty;
  private _column: TableColumn;
  private _item: Item;
  private _stringItem;
  constructor(public ngControl: NgControl) { }
  @Input() get property() {
    return this._property;
  }
  set property(value) {
    this._property = value;
  }

  @Input() get column() {
    return this._column;
  }
  set column(value) {
    this._column = value;
  }

  @Input() get item() {
    return this._item;
  }
  set item(value) {
    this._item = value;
  }

  @Input() get stringItem() {
    return this._stringItem;
  }
  set stringItem(value) {
    this._stringItem= value;
  }

  private minFractionDigits: number;
  private maxFractionDigits: number;
  private name: string;


  ngOnInit() {

    if (this.property) {
      this.minFractionDigits = this.property.minFractionDigits;
      this.maxFractionDigits = this.property.maxFractionDigits;
      this.name = this.property.name;
    }
    else if (this.column) {
      this.minFractionDigits = this.column.minFractionDigits;
      this.maxFractionDigits = this.column.maxFractionDigits;
      this.name = this.column.name;
    }


    this.item.onRevertData.subscribe(this.onRevert, this);
    this.item.onReValidate.subscribe(this.onRevalidate, this);
    this.initiate();
  }

  ngOnDestroy() {
    if (this.item) {
      this.item.onRevertData.unsubscribeSubscriber(this);
    }
  }
  private onRevert = (element) => {
    this._stringItem.stringVal = '';
    this.initiate();
  }

  private onRevalidate = (element) => {
    if (!element.skip)
    this.initiate();
  }


  initiate() {
    let v = ItemUtil.getPropertyData(this.item, this.name);
    if (v != undefined && v != null && v.toString() != 'NaN') {
      let val = v.toString();

      if ((val.indexOf('.') == 0 || val.trim() == '') && this.minFractionDigits > 0)
        this._stringItem.stringVal = ('0.' + '0'.repeat(this.minFractionDigits));
      else if (val.indexOf('.') < 0 && this.minFractionDigits > 0)
        this._stringItem.stringVal = (val + '.' + '0'.repeat(this.minFractionDigits));
      else if (val.indexOf('.') > val.length - 1 - this.minFractionDigits)
        this._stringItem.stringVal = (val + '0'.repeat(this.minFractionDigits - (val.length - val.indexOf('.') - 1)));
      else if (val.indexOf('.') < val.length - 1 - this.maxFractionDigits)
        this._stringItem.stringVal = (val.substr(0, val.indexOf('.') + this.maxFractionDigits + 1));
      else
        this._stringItem.stringVal = val;
    }
    let float = parseFloat(this._stringItem.stringVal);
    ItemUtil.setPropertyData(this.item, this.name, float.toString() == 'NaN' && (v==undefined || v==null || v=="")? v: float);
    this.ngControl.valueAccessor.writeValue(this._stringItem.stringVal);
    this.item.reValidate(true);
  }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event);
  }

  @HostListener('focusout', ['$event'])
  onBlurEv(event) {
    this.onBlur(event);
  }

  onInputChange(event) {
    let newVal = this.format(event);
    if (event && event.startsWith('-'))
      newVal = '-' + newVal;
    let caretPosition = (this.ngControl.valueAccessor as any)._elementRef.nativeElement.selectionStart;

    //if (parseFloat(this._stringItem.stringVal) == 0 && caretPosition == 1 && newVal.indexOf('.')>-1) {
    //  newVal = newVal.substr(0, newVal.indexOf('.') - 1) + '.' + newVal.substr(newVal.indexOf('.') + 1, newVal.length - newVal.indexOf('.'));
    //}

    //if (newVal.indexOf('.') == 0 || newVal.trim() == '')
    //  newVal = '0.' + '0'.repeat(this.minFractionDigits)
    if (newVal.indexOf('.') > -1 && newVal.indexOf('.') < newVal.length - 1 - this.maxFractionDigits) {
      newVal = newVal.substr(0, newVal.indexOf('.') + 1 + this.maxFractionDigits);
    }
    this.ngControl.valueAccessor.writeValue(newVal);
    this._stringItem.stringVal = newVal;
    let float = parseFloat(this._stringItem.stringVal);
    ItemUtil.setPropertyData(this.item, this.name, float.toString() == 'NaN' ? null : float);
    this.ngControl.valueAccessor.writeValue(this._stringItem.stringVal);
    if (caretPosition) {
      if (caretPosition == 1 && this._stringItem.stringVal.indexOf('.') >1)
        caretPosition++;

      (this.ngControl.valueAccessor as any)._elementRef.nativeElement.setSelectionRange(caretPosition, caretPosition);
    }
  
    (this.ngControl.valueAccessor as any)._elementRef.nativeElement.focus()
    this.item.reValidate(true);
  }

  onBlur(event) {
    if ((event.target.value.indexOf('.') == 0 || event.target.value.trim() == '') && this.minFractionDigits > 0)
      this._stringItem.stringVal = '0.' + '0'.repeat(this.minFractionDigits);
    else if (event.target.value.indexOf('.') < 0 && this.minFractionDigits > 0)
      this._stringItem.stringVal = event.target.value + '.' + '0'.repeat(this.minFractionDigits)
    else if (event.target.value.indexOf('.') > event.target.value.length - 1 - this.minFractionDigits)
      this._stringItem.stringVal = event.target.value + '0'.repeat(this.minFractionDigits - (event.target.value.length - event.target.value.indexOf('.') - 1))
    else
      this._stringItem.stringVal = event.target.value.toString();
    let float = parseFloat(this._stringItem.stringVal);
    ItemUtil.setPropertyData(this.item, this.name, float.toString() == 'NaN' ? null : float);
   // ItemUtil.setPropertyData(this.item, this.name, parseFloat(this._stringItem.stringVal));
    this.ngControl.valueAccessor.writeValue(this._stringItem.stringVal);

    this.item.reValidate();
  }

  private format(str) {
    return str.replace(/[^\d.]/g, '').replace(/\.([.\d]+)$/, function (m, m1) {
      return '.' + m1.replace(/\./g, '')
    });
  }
}
