import { DOCUMENT } from '@angular/common';
import {
  Directive,
  Renderer2,
  OnInit,
  OnDestroy,
  ElementRef,
  Inject,
  Input,
  AfterViewChecked,
} from '@angular/core';

import { fromEvent, Subject, from } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';
import { DashboardClient } from '../client/dashboard.client';
import { Session } from './entity/entities';

@Directive({
  selector: '[appVideoTraining]',
})
export class VideoTrainingDirective implements OnInit, OnDestroy {
  @Input() type;
  @Input() isAbsolute = false;
  @Input() position: 'top' | 'bottom' | 'center' = 'top';
  @Input() size: 'normal' | 'small' = 'normal';
  @Input('position-right') positionRight = -5;
  @Input('position-top') positionTop;

  unsubscribe$ = new Subject<void>();
  private _container;
  private _iframe;
  private _icon;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private client: DashboardClient
  ) {}

  ngOnInit(): void {
    Session.showVideo$.pipe(takeUntil(this.unsubscribe$)).subscribe((show) => {
      if (this.type) {
        if (show) this.addIconEl();
        else this.removeIconEl();
      }
    });
  }

  addIconEl() {
    let parent;
    parent = this.el.nativeElement;
    if (this._icon) {
      this.renderer.appendChild(parent, this._icon);
      return;
    }
    this._icon = this.renderer.createElement('img');
    this.renderer.setAttribute(
      this._icon,
      'src',
      '../../../assets/icons/VIDEO_ICON.png'
    );
    this.renderer.addClass(this._icon, 'video-icon');
    if (this.size == 'small') {
      this.renderer.addClass(this._icon, 'video-icon-small');
    } else {
      this.renderer.addClass(this._icon, 'video-icon-normal');
    }
    if (this.isAbsolute) {
      this.renderer.addClass(this._icon, 'video-icon-absolute');
      this.renderer.setStyle(parent, 'position', 'relative');
      if (this.position == 'top')
        this.renderer.addClass(this._icon, 'video-icon-absolute-top');
      else if (this.position == 'bottom')
        this.renderer.addClass(this._icon, 'video-icon-absolute-bottom');
    }
    if (this.position == 'center')
      this.renderer.setStyle(parent, 'align-items', 'center');
    this.renderer.setStyle(this._icon, 'right', `${this.positionRight}px`);
    if (this.positionTop)
      this.renderer.setStyle(this._icon, 'top', `${this.positionTop}px`);

    fromEvent(this._icon, 'click')
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((event: any) => {
          event.stopPropagation();
          return from(this.client.getTrainingVideo(this.type));
        })
      )
      .subscribe((data) => {
        this.showVideo(data.result);
      });

    this.renderer.appendChild(parent, this._icon);
  }

  removeIconEl() {
    if (this._icon) this.renderer.removeChild(parent, this._icon);
  }

  showVideo(videoData: { Code; Title; Description; VideoId }) {
    this._container = this.renderer.createElement('div');
    this.renderer.addClass(this._container, 'video-container');
    const script = this.renderer.createElement('script');
    this.renderer.setAttribute(
      script,
      'src',
      'https://player.vimeo.com/api/player.js'
    );

    this._iframe = this.renderer.createElement('iframe');
    this.renderer.setAttribute(
      this._iframe,
      'src',
      `https://player.vimeo.com/video/${videoData.VideoId}?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479`
    );
    this.renderer.setAttribute(this._iframe, 'frameborder', '0');
    this.renderer.setAttribute(
      this._iframe,
      'allow',
      'autoplay; fullscreen; picture-in-picture'
    );

    this.renderer.setAttribute(this._iframe, 'title', videoData.Title);
    this.renderer.addClass(this._iframe, 'video-frame');
    this.renderer.appendChild(document.body, this._container);
    this.renderer.appendChild(this._container, this._iframe);

    this.renderer.appendChild(this._iframe, script);

    fromEvent(this._iframe, 'load')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.addClose();
      });
  }

  addClose() {
    const closeBtn = this.renderer.createElement('div');

    fromEvent(closeBtn, 'click')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.renderer.removeChild(document.body, this._container);
      });
    this.renderer.addClass(closeBtn, 'video-close');
    this.renderer.addClass(closeBtn, 'pi');
    this.renderer.addClass(closeBtn, 'pi-times');
    this.renderer.appendChild(this._container, closeBtn);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }
}
