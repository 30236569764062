import { FormProperty } from './formProperty';
import { PropertyTypes } from '../enum/propertyTypes';
import { GSCalendarRangeControl } from '../../controls/gsControls';
import { EventEmitter } from '@angular/core';
import { CalendarFromToComponent } from '../../controls/gs.calendar-from-to/gs.calendar-from-to.component';

export class FormCalendarFromToProperty extends FormProperty {
  type = PropertyTypes.CalendarFromTo;
  component = CalendarFromToComponent;
  public showIcon: boolean;
  public readOnly: boolean;
  public from: Date;
  public to: Date;
  public yearRange;
  changed = new EventEmitter<any>();

  constructor(
    name: string,
    label: string,
    showIcon: boolean,
    row?: number,
    column?: number,
    rowspan?: number,
    columnspan?: number,
    validate?: boolean,
    readOnly?: boolean,
    public monthOnly?: boolean
  ) {
    super(name, label, row, column, rowspan, columnspan, validate);
    this.showIcon = showIcon;
    this.readOnly = readOnly;
    this.yearRange =
      (new Date().getFullYear() - 100).toString() +
      ':' +
      new Date().getFullYear().toString();
  }
}
