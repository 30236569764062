<div
  style="
    height: 100%;
    width: 100%;
    min-height: 950px;
    background-color: black;
    background-image: url(../../../assets/images/background.png);
  "
>
  <div class="opacity-holder"></div>
</div>

<div class="nlogin heading">
  <div class="headingholder-title">
    <span class="headingholder1">Pay</span>
    <span class="headingholder2">Day</span>
    <span class="headingholder3">Portal</span>
    <span class="headingholder4">.com</span>
  </div>
  <div class="headingholder-logo">
    <span class="logo" translate>login.PoweredBy</span>
    <span> <img src="assets/images/logo.png" /></span>
  </div>
  <div class="rightsreserved">
    <span translate>login.Copyright</span>
  </div>
</div>

<form [formGroup]="form" (ngSubmit)="login()" class="nlogin">
  <div class="centralized">
    <div class="transparentframe">
      <div class="signin" translate>login.SignIn</div>
      <div class="registeredonly">
        ({{ "login.RegisteredOnly" | translate }})
      </div>
      <div style="margin-top: 20px">
        <ul class="list-unstyled">
          <li>
            <div class="ui-inputgroup">
              <span class="ui-inputgroup-addon form-input-icon"
                ><i class="far fa-user"></i
              ></span>
              <input
                id="user"
                type="text"
                pInputText
                placeholder="{{ 'login.Username' | translate }}"
                formControlName="user"
                class="form-input"
                style="height: 50px"
              />
            </div>
            <div class="form-error">
              <div
                id="userValidation"
                *ngIf="
                  !form.controls['user'].valid && form.controls['user'].dirty
                "
                translate
              >
                login.UsernameRequired
              </div>
            </div>
          </li>
          <li>
            <div class="ui-inputgroup">
              <span class="ui-inputgroup-addon form-input-icon"
                ><i class="fas fa-key"></i
              ></span>
              <input
                id="password"
                type="password"
                pPassword
                [feedback]="false"
                placeholder="{{ 'login.Password' | translate }}"
                formControlName="password"
                class="form-input"
                style="height: 50px"
              />
            </div>
            <div class="form-error">
              <div
                id="passwordValidation"
                *ngIf="
                  !form.controls['password'].valid &&
                  form.controls['password'].dirty
                "
                translate
              >
                login.PasswordRequired
              </div>
            </div>
          </li>
          <li class="ui-inputgroup">
            <button
              class="button"
              style="
                background-color: var(--text-nav-color-selected);
                border: none;
                color: white;
                text-decoration: none;
                text-transform: none;
                font-size: 17px;
                width: 326px;
                font-family: sans-serif;
                font-weight: normal;
                height: 50px;
                border-radius: 0 !important;
                text-align: left;
              "
              id="signin"
              pButton
              type="submit"
              label="{{ 'login.LogIn' | translate }}"
              [disabled]="!form.valid || progress"
            ></button>
          </li>
        </ul>
      </div>
      <div class="registeredonly">
        <a
          class="forgotpassword"
          style="text-transform: uppercase"
          href="#"
          translate
          >login.ForgotPassword</a
        >
      </div>
    </div>
  </div>
</form>
