<div *ngIf="item" class="row form-property">
  <div
    class="col-lg-{{tooltip?11:12}}"
    [ngClass]="{'no-padding-right':tooltip}"
  >
    <span class="p-float-label">
      <p-dropdown
        [appendTo]="'body'"
        id="{{name}}"
        [showClear]="showClear"
        [options]="options"
        (onChange)="change($event)"
        [ngModel]="value"
        [readonly]="readonly"
        [ngClass]="{'disabled':readonly, 'dropdown-property-invalid':notValid}"
        class="disabled ui-inputwrapper-focus ui-inputwrapper-focus"
        [style]="{'width': '100%'}"
        [panelStyle]="{'max-height': '400px','min-width': '400px'}"
        [filter]="true"
        [autoDisplayFirst]="false"
        [placeholder]="property.defaultLabel"
        [virtualScroll]="true"
        [virtualScrollItemSize]="30"
      >
        <ng-template let-slctd pTemplate="selectedItem">
          <span style="vertical-align: initial"
            >{{slctd?slctd.data.Name:''}}</span
          >
        </ng-template>
        <ng-template let-opt pTemplate="item">
          <div
            #selectOption
            [ngClass]="{'no-event':opt.data.BlockedReason || opt.data.Disabled}"
            class="row"
            [margin-left]="10"
            [margin-right]="5"
          >
            <div
              class="lightborder ui-helper-clearfix col-lg-11 col-sm-11 col-md-11"
              [ngClass]="{'disabled_opacity-no-color no-event':opt.data.BlockedReason || opt.data.Disabled}"
              style="position: relative; border-bottom: 1px solid lightgrey"
            >
              <span class="text-l bold">{{opt.data.Name}}</span>
              <br />
              <span *ngIf="opt.data.CustomerAddress" class="text-s">
                {{opt.data.CustomerAddress.StreetNumber}}
                {{opt.data.CustomerAddress.Street}}
                <br />
                {{opt.data.CustomerAddress.Zip}}
                {{opt.data.CustomerAddress.State}}
              </span>
            </div>
            <div
              *ngIf="opt.data.BlockedReason || opt.data.Disabled"
              gsTooltip="{{opt.data.BlockedReason?opt.data.BlockedReason:(opt.data.Disabled ? ('general.notConfigLocation' | gstranslate):'')}}"
              [type]="'error'"
              class="ui-helper-clearfix col-lg-1 col-sm-1 col-md-1 pointer-events-auto"
              [ngClass]="{'no-event':opt.data.BlockedReason || opt.data.Disabled}"
              (click)="$event.stopPropagation()"
            >
              <i
                class="fas fa-2x error-color"
                [ngClass]="{'fa-eye-slash':opt.data.BlockedReason,'fa-exclamation-triangle':!opt.data.BlockedReason && opt.data.Disabled}"
              ></i>
            </div>
          </div>
        </ng-template>
      </p-dropdown>
      <label class="gs-float-label" [ngClass]="{'error-color':notValid}"
        >{{label}}</label
      >
      <span
        *ngIf="notValid"
        class="form-error"
        pTooltip="{{property.validationMessage}}"
        tooltipPosition="top"
      >
        <i
          class="fas fa-info-circle fa-2x form-valid-icon with-icon error-color"
        ></i>
      </span>
    </span>
  </div>
  <div *ngIf="tooltip" class="col-lg-1 property-tooltip">
    <span pTooltip="{{property.tooltip}}">
      <i class="fas fa-info-circle master-color"></i>
    </span>
  </div>
</div>
