import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
    selector: '[gsTooltip]'
})
export class GSToolTip {
    constructor(private element: ElementRef, private renderer: Renderer2) {        
    }

    private _text;
    private container;
    private tooltipArrow;
    private tooltipTextContainer;
    private tooltipIcon;
    get showText() {
        return [undefined, ''].indexOf(this._text) == -1;
    }
    @Input('gsTooltip') set text(text: string) {
        this._text = text;        
    }

    @Input() type = 'info';//options: info, warning and error, info is default
  @Input() showOnClick = false;//by default tooltip will be hiddine on click
  @Input() innerHTML = false;

    @HostListener('mouseenter',['$event'])
    show(e) {
        if (this.showText && !this.container) {
            this.removeTooltips();
            this.container = document.createElement('div');

            this.tooltipArrow = document.createElement('div');
            this.tooltipTextContainer = document.createElement('div');            
            this.container.appendChild(this.tooltipArrow);
            
            if (window.innerWidth / 2 > e.clientX) {
                this.renderer.setStyle(this.tooltipArrow, 'left', `${8}px`)
                this.renderer.setStyle(this.container, 'left', `${e.clientX-13}px`)
            }
            else {
                this.renderer.setStyle(this.tooltipArrow, 'right', `${8}px`)
                this.renderer.setStyle(this.container, 'right', `${window.innerWidth - e.clientX-13}px`)
            }
            this.setType();
            this.setIcon();
            this.setText();            
            this.renderer.appendChild(document.body, this.container);
            this.getDirection(this.element.nativeElement, e);
                 
        }
        e.stopPropagation();
    }

    @HostListener('mouseleave', ['$event'])
    hide(e) {
        if (this.showText) {
            this.remove();
        }        
    }

    @HostListener('mouseup', ['$event'])
    click(e) {
        if (!this.showOnClick)
            this.remove();
    }

    private remove() {
        if (this.container) {
            this.renderer.removeChild(document.body, this.container);
            this.container = undefined;
        }
    }

    private removeTooltips() {
        for (var i = document.body.children.length-1; i > 0 ; i--) {
            if (document.body.children[i].className.indexOf('gs-tooltip') > -1)
                this.renderer.removeChild(document.body, document.body.children[i]);
        }
    }

    private getDirection(obj, ev) {
        var elemRectangle = obj.getBoundingClientRect();
        var t = elemRectangle.top,
            b = elemRectangle.bottom,
            l = elemRectangle.left,
            r = elemRectangle.right

        var topEdgeDist = Math.abs(t - ev.clientY);
        var bottomEdgeDist = Math.abs(b - ev.clientY);
        var min = Math.min(topEdgeDist, bottomEdgeDist);
        switch (min) {
            case topEdgeDist:
                this.renderer.addClass(this.tooltipArrow, 'gs-tooltip-arrow-down');
                this.renderer.setStyle(this.container, 'top', `${ev.clientY - elemRectangle.height}px`)
                break;
            case bottomEdgeDist:
                this.renderer.addClass(this.tooltipArrow, 'gs-tooltip-arrow-up');
                this.renderer.setStyle(this.container, 'top', `${ev.clientY + 10}px`)
                break;
        }
    }

    private setText() {
        this.renderer.addClass(this.tooltipTextContainer, 'gs-tooltip-container');   
      let tooltipText = document.createElement('span');
        tooltipText.innerHTML = this._text;
        this.tooltipTextContainer.appendChild(tooltipText);
        this.container.appendChild(this.tooltipTextContainer);
    }

    private setIcon() {
        let tooltipIconContainer = document.createElement('span');
        this.tooltipIcon = document.createElement('i');
        this.renderer.appendChild(tooltipIconContainer, this.tooltipIcon);
        this.renderer.addClass(this.tooltipIcon, 'fas');
        this.renderer.setStyle(tooltipIconContainer, 'padding-right', '5px');
        switch (this.type) {
            case "info":
                this.renderer.addClass(this.tooltipIcon,'fa-exclamation-circle');
                break;
            case "warning":
                this.renderer.addClass(this.tooltipIcon, 'fa-exclamation-circle');
                break;
            case "error":
                this.renderer.addClass(this.tooltipIcon, 'fa-exclamation-triangle');
                break;
        }
        this.renderer.appendChild(this.tooltipTextContainer, tooltipIconContainer);
    }

    private setType() {
        this.renderer.addClass(this.container, 'gs-tooltip');
        switch (this.type) {
            case "info":
                this.renderer.addClass(this.container, 'gs-tooltip-info');
                break;
            case "warning":
                this.renderer.addClass(this.container, 'gs-tooltip-warning');
                break;
            case "error":
                this.renderer.addClass(this.container, 'gs-tooltip-error');
                this.renderer.addClass(this.container, 'gs-tooltip-error');
                break;
        }
    }
}
