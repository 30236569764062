import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[margin],[margin-right],[margin-left],[margin-top],[margin-bottom]'
})
export class MarginDirective implements OnInit {
    @Input() margin;
    @Input('margin-right') right;
    @Input('margin-left') left;
    @Input('margin-top') top;
    @Input('margin-bottom') bottom;

    constructor(private el: ElementRef) {       
    }

    ngOnInit() {
        if (this.margin)
            this.el.nativeElement.style.margin = `${this.margin}px`;
        else if (this.top)
            this.el.nativeElement.style.marginTop = `${this.top}px`;
        else if (this.right)
            this.el.nativeElement.style.marginRight = `${this.right}px`;
        else if (this.left)
            this.el.nativeElement.style.marginLeft = `${this.left}px`;
        else if (this.bottom)
            this.el.nativeElement.style.marginBottom = `${this.bottom}px`;
    }
}
