import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  Injectable,
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormProperty } from '../entity/form/formProperty';
import { ItemUtil } from '../utils/utils';
import { TableColumn } from '../entity/table/tableColumn';

@Directive()
export class GSBaseControl implements OnInit, OnDestroy, AfterViewInit {
  //#region -------Properties
  public InputParams;
  @Input() item: any;
  _property;
  @Input()
  set property(val) {
    if (val) this._property = val;
  }
  get property() {
    return this._property;
  }
  @Input() column: TableColumn;
  @Input() hideLabel: boolean = false;

  @Output() onChange = new EventEmitter<any>();
  @Output() onClick = new EventEmitter<any>();

  get name() {
    return this.property ? this.property.name : this.column?.name;
  }
  get label() {
    return this.property ? this.property.label : null;
  }
  get readonly() {
    return this.property ? this.property.readonly : this.column?.readonly;
  }
  get tooltip() {
    return this.property ? this.property?.tooltip : null;
  }
  get icon() {
    return this.property ? this.property.icon : this.column?.icon;
  }

  get notValid() {
    if (this.item && this.property) {
      let validationInfo = this.item.validationInfo.find(
        (vi) => vi.fieldName == this.property.name
      );
      if (validationInfo) return !validationInfo.valid;
    } else if (this.item && this.column) {
      let validationInfo = this.item.validationInfo.find(
        (vi) => vi.fieldName == this.column.name
      );
      if (validationInfo) return !validationInfo.valid;
    }
    return false;
  }

  get value() {
    return ItemUtil.getPropertyData(this.item, this.name);
    //if (this.item) {
    //  return this.name.split('.').reduce(function (p, prop) {
    //    if (p)
    //      return p[prop];
    //  }, this.item.data);
    //}
  }

  set value(value) {
    ItemUtil.setPropertyData(this.item, this.name, value);
    //let name = this.name;
    //this.name.split('.').reduce(function (p, prop) {
    //    if (name.endsWith(prop))
    //        p[prop] = value;
    //        return p[prop];
    //}, this.item.data);
  }
  get leftLabel() {
    return this.property.leftLabel;
  }

  get rightLabel() {
    return this.property.rightLabel;
  }

  get data() {
    if (this.item) return this.item.data;
  }
  //#endregion

  //#region -------Constructor
  constructor(public cdr: ChangeDetectorRef) {}
  //#endregion

  //#region -------NG Eents
  ngOnInit() {}

  ngOnDestroy() {}
  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
  //#endregion

  //#region -------UI Events
  change(e) {
    this.value = e;
    this.item.reCheckDirty();
    this.onChange.emit(e);
  }
  //#endregion

  //#region -------Private
  //#endregion
}
