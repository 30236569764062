<div class="row form-property">
  <div
    class="col-lg-{{tooltip?11:12}}"
    [ngClass]="{'no-padding-right':tooltip}"
  >
    <span class="p-float-label">
      <p-inputNumber
        id="{{name}}"
        [ngStyle]="{'text-align': (property.align || property.align ==0) ? (property.align==0?'center':(property.align==1 ? 'right':'left')) :'left'}"
        [ngModel]="value"
        class="gs-control-inherit right ui-inputwrapper-focus ui-inputwrapper-focus ui-inputwrapper-filled"
        [ngClass]="{'disabled':readonly && !inTable}"
        (onInput)="change($event)"
        [mode]="property.mode"
        [minFractionDigits]="property.minFractionDigits"
        [maxFractionDigits]="property.maxFractionDigits"
        [min]="property.min"
        [max]="property.max"
        style="width: 100%"
        [readonly]="readonly"
        leadingZero="'allow'"
        [showButtons]="property.showButtons"
        [format]="property.formatNumber"
        [placeholder]="placeholder"
        [allowEmpty]="true"
      >
      </p-inputNumber>
      <label
        *ngIf="!hideLabel"
        class="gs-float-label"
        [ngClass]="{'error-color':notValid}"
        >{{label}}</label
      >
      <span
        *ngIf="notValid"
        class="form-error"
        pTooltip="{{property.validationMessage}}"
        tooltipPosition="bottom"
        style="width: 25px; height: 25px"
      >
        <i class="fas fa-info-circle fa-2x form-valid-icon error-color"></i>
      </span>
    </span>
  </div>
  <div *ngIf="tooltip" class="col-lg-1 property-tooltip">
    <span pTooltip="{{property.tooltip}}">
      <i class="fas fa-info-circle master-color"></i>
    </span>
  </div>
</div>
