import { Component, Inject, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  FormGroup,
  UntypedFormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { AppConfig } from '../../app.config';
import { Router, ActivatedRoute } from '@angular/router';
import { Session, Menu, MenuItem, Page } from '../../common/entity/entities';
import { LocalStorage } from '../../common/decorators/decorators';
import { BrowserStorage } from '../../common/utils/tools';
import { SecurityHelper } from './helper/security.helper';
import { SecurityClient } from '../../client/security.client';
import { BaseLoginComponent } from './baseLogin.component';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  providers: [
    //SecurityService, LocalizationService,
    AppConfig,
    UntypedFormBuilder,
    SecurityClient,
    //, Session, VisualTreeBuilder
  ],
})
export class LoginComponent extends BaseLoginComponent {
  constructor(
    public route: ActivatedRoute,
    public config: AppConfig,
    public securityClient: SecurityClient,
    public router: Router,
    formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    public renderer: Renderer2,
    @Inject(DOCUMENT) public _document: Document
  ) {
    super(
      route,
      config,
      securityClient,
      router,
      formBuilder,
      translate,
      renderer,
      _document
    );
    BrowserStorage.useFor(this);
    this.form = formBuilder.group({
      user: this.user,
      password: this.password,
    });
  }
}
