export class regExLib {
  public static regex_Email: RegExp = /^(?=.{6,50}$)([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,20}|[0-9]{1,3})(\]?)$/;
  public static regex_Password: RegExp = /^(?=.*?[a-z])(?=.*?[0-9]).{8,}/;
  public static regex_NoSpecialChars: RegExp = /^[A-Za-z0-9_.@-]*$/;
  public static regex_GreaterThanZero: RegExp = /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/;
  public static regex_NoZero: RegExp = /^-?(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/;
  public static regex_MaxLength_4 = /^\s*([^\s]\s *){0,4}$/;
  public static regex_OneToHundered = /^(100|[1-9][0-9]?)$/;
  public static regex_Year = /^(19|20)\d{2}$/;
  public static regex_10Digits = /^[0-9]{10}$/;
  public static regex_ComplexPassword12: RegExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_-])[A-Za-z\d@$!%*#?&]{12,}$/;
  public static regex_4Digits = /^[0-9]{4}$/;
  public static regex_6Digits = /^[0-9]{6}$/;
  public static regex_5Digits = /^[0-9]{5}$/;
  public static regex_9Digits = /^[0-9]{9}$/;
  public static regex_EIN = /^(?:(?=[\d-]{10}$)\d{2}-\d+)$/;
  public static regex_PositiveInteger = /^(?:\d+|null)$/;
  public static regex_MinChars(minChars: number) {
  return new RegExp('[0-9a-zA-Z]{' + minChars + ',}');
  }
  public static regex_RTN: RegExp = /^[0-9*]{9}$/;
  public static regex_SSN: RegExp = /^[0-9*\.\-\/]{9,11}$/;

  public static regex_Aba: RegExp = /^[0-9*]{4,20}$/;
} 
