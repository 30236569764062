import { Pipe, PipeTransform } from '@angular/core';
import { TrainingVideos } from './entity/enum/trainingVideos';

@Pipe({
  name: 'pageTrainingVideo',
})
export class PageTrainingVideoPipe implements PipeTransform {
  transform(page: any): unknown {
    switch (page.data.Code) {
      case 'cnt_TransactionChangeLog':
        return TrainingVideos.ReviewChangeLogs;
      case 'distributionrules':
        return TrainingVideos.DistributionRules;
      case 'set_EmplProfile':
        return TrainingVideos.RegisterNewEmployee;
      // case 'set_jobmappings':
      //   return TrainingVideos.MapEmployee;
      case 'set_frequentlyAskedQuestions':
        return TrainingVideos.SubmitSupportTicket;
      case 'set_ScheduleTraining':
        return TrainingVideos.ScheduleTraining;
      case 'rep_totals':
        return TrainingVideos.DailyStatsAndReports;
      case 'change_my_details':
        return TrainingVideos.ChangeAccountDetailsAndPassword;
      // case 'set_mappings':
      //   return TrainingVideos.MapJobCodes;
    }
  }
}
