import { FormProperty } from './formProperty';
import { PropertyTypes } from '../enum/propertyTypes';
import { GSInputSwitchControl } from '../../controls/gsControls';
import { EventEmitter } from '@angular/core';

export class FormInputSwitchProperty extends FormProperty {
  type = PropertyTypes.InputSwitch;
  component = GSInputSwitchControl;
  changed = new EventEmitter<any>();
  constructor(
    name: string,
    label: string,
    row?: number,
    column?: number,
    rowspan?: number,
    columnspan?: number,
    validate?: boolean
  ) {
    super(name, label, row, column, rowspan, columnspan, validate);
  }
}
