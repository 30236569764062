import { Component, OnInit, OnDestroy, Input, Inject, Optional, Injectable, ChangeDetectorRef } from '@angular/core'
import { GSBaseControl } from './gs.base.control';
import { FormPasswordProperty } from '../entity/form/formPassword.property';
import { Item } from '../entity/entities';

@Component({
    selector: 'gs-password',
    templateUrl: './gs.password.control.html'
})
@Injectable()
export class GSTPasswordControl extends GSBaseControl implements OnInit, OnDestroy {
    //#region -------Properties
    @Input() showPassword = false;
    //#endregion

  //#region -------Constructor
  constructor(@Optional() prop: FormPasswordProperty, @Optional() item: Item, private changeDr: ChangeDetectorRef) {
    super(changeDr);
        this.property = prop;
        this.item = item;
    }
  //#endregion

    //#region -------NG Eents
    ngOnInit() {
    }

    ngOnDestroy() {
    }
    //#endregion

    //#region -------UI Events
    show() {
        this.showPassword = !this.showPassword;
  }

  change(e) {
    if (e || e == '') {
      let previousValue = this.data[this.name];
      this.value = e;
      this.item.reCheckDirty();
      this.onChange.emit({ propertyName: this.name, previousValue: previousValue, newValue: this.value, event: e, item: this.item, property: this.property });
      this.property.changed.emit({ propertyName: this.name, previousValue: previousValue, newValue: this.value, event: e, item: this.item, property: this.property });
    }
  }
    //#endregion

    //#region -------Private
    //#endregion
}
