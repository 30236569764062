<div class="content-table">
  <p-table
    class="gs-table"
    *ngIf="!printLayout"
    #dt
    [value]="values"
    [frozenColumns]="frozenColumns"
    (sortFunction)="customSort($event)"
    [customSort]="!isLazyLoad"
    [lazy]="isLazyLoad"
    (onLazyLoad)="loadDataLazy($event)"
    [loading]="loading.value"
    [paginator]="showPaginator"
    [rows]="pageSize"
    [totalRecords]="totalResultsCnt_Calculated"
    [scrollable]="scrollable"
    [scrollHeight]="scrollHeight"
    (onRowSelect)="onSelectRow($event)"
    [selectionMode]="'single'"
    [tableStyleClass]="tableClass"
    dataKey="guid"
    currentPageReportTemplate="{{paginatorText}}"
    [showCurrentPageReport]="true"
    [autoLayout]="true"
    [virtualScroll]="virtualScroll"
    [virtualRowHeight]="virtualRowHeight"
    (onRowExpand)="onRowExpand($event)"
    (onRowCollapse)="onRowCollapse($event)"
    [globalFilterFields]="globalFilterFields"
    [sortField]="defaultSortField"
    [sortOrder]="sortOrder"
    [frozenWidth]="frozenWidth"
    [tableStyle]="tableStyle"
    [style]="style"
    (onPage)="onPage($event)"
    (onSort)="onSort($event)"
    [(first)]="firstRowIndex"
    [expandedRowKeys]="expandedRowKeys?expandedRowKeys:[]"
  >
    <ng-template pTemplate="caption">
      <ng-container
        *ngIf="globalFilterFields && globalFilterFields.length>0 || this.addRow.observers && this.addRow.observers.length && showAddRow || this.editTable.observers.length && showEditTable || showPrint || showSave"
      >
        <div style="min-height: 30px">
          <span
            *ngIf="globalFilterFields && globalFilterFields.length>0"
            style="float: left"
          >
            <i class="pi pi-search" style="margin: 4px 4px 0 0"></i>
            <input
              type="text"
              pInputText
              size="20"
              placeholder="Global Filter"
              (input)="setGlobalFilter($event.target.value, 'contains')"
              style="width: auto"
            />
            <ng-container *ngIf="gsfilter">
              <ng-container
                *ngTemplateOutlet="gsfilter; context {$implicit: items}"
              ></ng-container>
            </ng-container>
          </span>
          <span style="float: right">
            <button
              class="edit"
              *ngIf="showPrint"
              [disabled]="!items || !values || !values.length"
              style="margin-right: 10px"
              type="button"
              pButton
              icon="fas fa-print"
              (click)="printClk($event, 'PRINT')"
            ></button>
            <p-menu
              [appendTo]="'body'"
              #menu
              [popup]="true"
              [model]="saveAsMenuItems"
            ></p-menu>

            <button
              class="edit"
              *ngIf="showSave"
              [disabled]="!items || !values || !values.length"
              style="margin-right: 10px"
              type="button"
              pButton
              icon="fas fa-save"
              (click)="menu.toggle($event)"
            ></button>
            <div
              *ngIf="showAddRow"
              appVideoTraining
              [type]="addButtonTrainingVideo"
              [isAbsolute]="true"
              class="video-button-container"
            >
              <button
                class="edit"
                *ngIf="this.addRow.observers && this.addRow.observers.length && showAddRow"
                style="margin-right: 10px; margin-left: 40px"
                type="button"
                pButton
                icon="fas fa-plus"
                label="{{'general.add' | gstranslate}}"
                (click)="addRowClk($event)"
              ></button>
            </div>
            <button
              class="edit"
              *ngIf="this.editTable.observers && this.editTable.observers.length && showEditTable"
              [disabled]="!items || !values || !values.length"
              style="margin-right: 10px; margin-left: 40px"
              type="button"
              pButton
              icon="fas fa-edit"
              label="{{'general.edit' | gstranslate}}"
              (click)="editTableClk($event)"
            ></button>
          </span>
        </div>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="header">
      <ng-container *ngIf="!gsheader">
        <tr *ngIf="hasFilter && !hideHeaders">
          <th *ngIf="showSelectAll" style="width: 35px; flex: 0"></th>
          <th *ngIf="canExpand" style="width: 3em; flex: 0"></th>
          <th *ngIf="showSerialNumber" style="width: 3em; flex: 0"></th>
          <th *ngFor="let col of visibleColums" style="font-weight: 800">
            <div
              appVideoTraining
              [type]="col.trainingVideo"
              [isAbsolute]="true"
              [ngStyle]="{'text-align': col.align,'min-width': col.minWidthPX ? col.minWidthPX+'px' : 'auto', 'max-width': col.maxWidthPX ? (col.maxWidthPX+5) +'px' : 'auto', 'width': col.width ?   (col.widthPercentage?col.width+'%':(col.width+5) +'px') : 'inherit'}"
              style="vertical-align: middle"
            >
              <ng-container [ngSwitch]="col.type">
                <ng-container
                  *ngSwitchCase="ColumnTypes.Text"
                  style="text-align: left; vertical-align: middle"
                >
                  <input
                    *ngIf="col.filter"
                    id="{{ 'filter-' + col.name }}"
                    [(ngModel)]="filters[col.name].value"
                    (input)="dt.filter($event.target.value, col.name, col.filterType)"
                    pInputText
                    type="text"
                    style="width: inherit"
                    placeholder="..."
                    class="ui-column-filter"
                  />
                </ng-container>
                <ng-container
                  *ngSwitchCase="ColumnTypes.BoolSwitch"
                  style="text-align: center; vertical-align: middle"
                >
                  <p-dropdown
                    [appendTo]="'body'"
                    *ngIf="col.filter"
                    id="{{ 'filter-' + col.name }}"
                    [(ngModel)]="filters[col.name].value"
                    [options]="boolStatuses"
                    (onChange)="dt.filter($event.value, col.name, 'equals')"
                    styleClass="ui-column-filter"
                    [style]="{'min-width':'50px','width':'-webkit-fill-available'}"
                    placeholder="..."
                    [showClear]="true"
                  >
                    <ng-template let-item pTemplate="selectedItem">
                      <img
                        src="../../../assets/images/{{item.label}}.png"
                        style="width: 16px; vertical-align: middle"
                      />
                    </ng-template>
                    <ng-template let-option pTemplate="item">
                      <div
                        class="ui-helper-clearfix"
                        style="position: relative; height: 25px"
                      >
                        <img
                          src="../../../assets/images/{{option.label}}.png"
                          style="width: 16px; vertical-align: middle"
                        />
                      </div>
                    </ng-template>
                  </p-dropdown>
                </ng-container>
                <ng-container
                  *ngSwitchCase="ColumnTypes.TextWithDropFilter"
                  style="text-align: center; vertical-align: middle"
                >
                  <p-dropdown
                    [appendTo]="'body'"
                    *ngIf="col.filter"
                    id="{{ 'filter-' + col.name }}"
                    [(ngModel)]="filters[col.name].value"
                    [options]="col.options"
                    (onChange)="dt.filter($event.value, col.name, 'equals')"
                    styleClass="ui-column-filter"
                    [style]="{'min-width':'50px','width':'-webkit-fill-available'}"
                    [placeholder]="col.filterPlaceholder"
                    [showClear]="col.showFilterClear"
                  >
                  </p-dropdown>
                </ng-container>
                <ng-container
                  *ngSwitchCase="ColumnTypes.Checkbox"
                  style="text-align: center; vertical-align: middle"
                >
                  <p-dropdown
                    [appendTo]="'body'"
                    *ngIf="col.filter"
                    [(ngModel)]="filters[col.name].value"
                    id="{{ 'filter-' + col.name }}"
                    [options]="boolStatuses"
                    (onChange)="dt.filter($event.value, col.name, 'equals')"
                    styleClass="ui-column-filter"
                    [style]="{'min-width':'50px','width':'-webkit-fill-available'}"
                    placeholder="..."
                    [showClear]="true"
                  >
                    <ng-template let-item pTemplate="selectedItem">
                      <!--WON'T WORK WITH ICONS, SO WE PUT IMAGES-->
                      <img
                        src="../../../assets/images/{{item.label}}.png"
                        style="width: 16px; vertical-align: middle"
                      />
                    </ng-template>
                    <ng-template let-option pTemplate="item">
                      <!--WON'T WORK WITH ICONS, SO WE PUT IMAGES-->
                      <img
                        src="../../../assets/images/{{option.label}}.png"
                        style="width: 16px; vertical-align: middle"
                      />
                    </ng-template>
                  </p-dropdown>
                </ng-container>
                <ng-container
                  *ngSwitchCase="ColumnTypes.CustomerTextMultiSelectFilter"
                >
                  <span>
                    <button
                      *ngIf="showCustomerMultiselectApply"
                      type="button"
                      pButton
                      icon="pi pi-check"
                      (click)="dt.filter(col.multiSelectionFilterValue, col.name, 'equals');applyCustomerMultiselectFilter(col)"
                    ></button>

                    <p-multiSelect
                      [appendTo]="'body'"
                      *ngIf="col.options"
                      [options]="col.options"
                      [(ngModel)]="col.multiSelectionFilterValue"
                      (onPanelShow)="customerMultiselectFilterPanelShow(col)"
                      (onPanelHide)="customerMultiselectFilterPanelHide(col)"
                      (onChange)="customerMultiselectFilterChanged(col)"
                    >
                      <ng-template
                        let-selectedOptions
                        pTemplate="col.multiSelectionFilterValue"
                      >
                        <span *ngFor="let selectedOption of selectedOptions"
                          >{{selectedOption.data.Name}}</span
                        >
                      </ng-template>
                      <ng-template
                        class="lightborder"
                        let-opt
                        let-i="index"
                        pTemplate="item"
                      >
                        <div
                          class="lightborder ui-helper-clearfix"
                          style="
                            position: relative;
                            border-bottom: 1px solid lightgrey;
                          "
                        >
                          <span class="text-l bold">{{opt.data.Name}}</span>
                          <br />
                          <span *ngIf="opt.data.CustomerAddress" class="text-s">
                            {{opt.data.CustomerAddress.StreetNumber}}
                            {{opt.data.CustomerAddress.Street}}
                            <br />
                            {{opt.data.CustomerAddress.Zip}}
                            {{opt.data.CustomerAddress.State}}
                          </span>
                        </div>
                      </ng-template>
                    </p-multiSelect>
                  </span>
                </ng-container>
              </ng-container>
            </div>
          </th>
          <th *ngIf="!wide" style="width: 100%">
            <div style="width: auto"></div>
          </th>
        </tr>
        <tr *ngIf="!hideHeaders">
          <th *ngIf="showSelectAll" style="width: 35px; flex: 0">
            <p-checkbox
              class="darkborder"
              [ngModel]="areAllSelected"
              [binary]="true"
              (onChange)="handleChange($event.checked, null, 'selectAll', null)"
            ></p-checkbox>
          </th>
          <th *ngIf="canExpand" style="width: 3em; flex: 0"></th>
          <th *ngIf="showSerialNumber" style="width: 3em; flex: 0"></th>
          <th
            class="table-header-text"
            pSortableColumn="{{col.sort ? col.name : undefined}}"
            [ngClass]="{'no-wrap':headerNoWrap,'sticky-header':stickyHeader}"
            [ngStyle]="{'text-align': col.align, 'min-width': col.minWidthPX ?  col.minWidthPX +'px' : 'auto', 'max-width': col.maxWidthPX ? col.maxWidthPX+'px' : 'auto', 'top':stickyHeaderTop ? stickyHeaderTop : 'auto','width': col.width ?  (col.widthPercentage?col.width+'%':(col.width+5) +'px') : 'inherit'}"
            *ngFor="let col of visibleColums"
          >
            <span
              *ngIf="col.description"
              [ngStyle]="{'place-content':col.align,'float': col.align,'width': col.width ?   (col.widthPercentage?col.width+'%':(col.width+5) +'px') : 'inherit'}"
            >
              <span> {{col.description | gstranslate}} </span>
              <p-sortIcon *ngIf="col.sort" field="{{col.name}}"></p-sortIcon>
              <span
                *ngIf="col.trainingVideo"
                [type]="col.trainingVideo"
                appVideoTraining
                [size]="'small'"
                [position]="'center'"
              ></span>
            </span>
          </th>
          <th *ngIf="!wide" style="width: 100%">
            <div style="width: auto"></div>
          </th>
        </tr>
      </ng-container>
      <ng-container *ngIf="gsheader">
        <tr>
          <ng-container
            *ngTemplateOutlet="gsheader; context {$implicit: columns}"
          ></ng-container>
        </tr>
      </ng-container>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-item
      let-rowIndex="rowIndex"
      let-expanded="expanded"
    >
      <tr
        *ngIf="item.visible"
        class="pointer table-row-text  {{item.parameters.customRowClass? item.parameters.customRowClass:''}}"
        [id]="item.guid"
        [ngClass]="{'invalid-row':item.parameters.invalidMessage || item.parameters.invalid,'added-row':item.parameters.added, 'warning-row':item.parameters.warningMessage, 'selected-row':markSelected && (item.selected || item.parameters.selectedRow), 'row-expanded-parent':expanded}"
        style="height: 36px"
        gsTooltip="{{item.parameters.invalidMessage?item.parameters.invalidMessage:(item.parameters.warningMessage?item.parameters.warningMessage:(item.parameters.added?item.parameters.added:''))}}"
        [type]="item.parameters.invalidMessage?'error':(item.parameters.warningMessage?'warning':(item.parameters.added?'info':''))"
        (click)="onSelectRow(item)"
      >
        <td *ngIf="showSelectAll && items" style="width: 35px; flex: 0">
          <p-checkbox
            class="darkborder"
            [(ngModel)]="item.checked"
            [binary]="true"
            (ngModelChange)="handleChange($event, item, 'select', null)"
          ></p-checkbox>
        </td>
        <td *ngIf="canExpand" style="width: 3em; flex: 0">
          <a
            class="gs-table-expand"
            *ngIf="!customExpand || (item.parameters.column && item.parameters.column.Expand && !item.parameters.column.Expand.noExpand)"
            href="#"
            [pRowToggler]="item"
          >
            <i
              [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
            ></i>
          </a>
        </td>
        <td *ngIf="showSerialNumber && items" style="width: 35px; flex: 0">
          {{rowIndex+1}}
        </td>
        <td
          *ngFor="let col of visibleColums"
          class="{{col.class}}"
          [ngStyle]="{'text-align': col.align, 'min-width': col.minWidthPX ?  (col.minWidthPX+5) +'px' : 'auto', 'max-width': col.maxWidthPX ?  (col.maxWidthPX+5) +'px' : 'auto','width': col.autoWidth?'100%':(col.width ?  (col.widthPercentage?col.width+'%':(col.width+5) +'px') : 'inherit')}"
          style="vertical-align: middle"
        >
          <gs-table-data
            [items]="items"
            [item]="item"
            [columns]="columns"
            [col]="col"
            [rowIndex]="rowIndex"
            [showSelectAll]="showSelectAll"
            [wide]="wide"
            [readOnly]="readOnly"
            [expanded]="expanded"
            (rowAction)="onRowAction($event)"
            (rowDataChanged)="onRowDataChanged($event)"
            (selectRow)="onSelectRow($event)"
            (selectCell)="onSelectCell($event)"
            style="min-width: inherit; max-width: inherit; width: inherit"
          >
          </gs-table-data>
        </td>
        <td *ngIf="!wide" style="width: 100%">
          <div style="width: auto"></div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr *ngIf="footerItem && footerItem.data && values.length">
        <td *ngIf="showSelectAll" style="width: 35px; flex: 0"></td>
        <td *ngIf="canExpand" style="width: 3em; flex: 0"></td>
        <td
          *ngFor="let col of visibleColums"
          [ngStyle]="{'text-align': col.align, 'min-width': col.minWidthPX ?  (col.minWidthPX+5) +'px' : 'auto', 'max-width': col.maxWidthPX ?  (col.maxWidthPX+5) +'px' : 'auto'}"
          style="vertical-align: middle"
        >
          <ng-container
            *ngIf="footerItem.data && footerItem.data[col.name]!=null &&  footerItem.data[col.name]!=undefined"
            style="font-weight: bold"
          >
            <ng-container [ngSwitch]="col.type">
              <ng-container *ngSwitchCase="ColumnTypes.Currency">
                <gs-input-decimal
                  class="table-control-inherit table-footer"
                  [item]="footerItem"
                  [column]="col"
                  [forceReadonly]="true"
                >
                </gs-input-decimal>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <div>{{footerItem.data[col.name]}}</div>
              </ng-container>
            </ng-container>
          </ng-container>
        </td>
      </tr>
      <tr *ngIf="showPaginator && values?.length">
        <td [attr.colSpan]="'100%'">
          <ng-container>
            <div>
              <p-paginator
                #paginator
                (onPageChange)="onPage($event)"
                [rows]="pageSize?pageSize:10"
                [totalRecords]="totalResultsCnt_Calculated"
                [dropdownAppendTo]="'body'"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} - {last} of {totalRecords}"
                [rowsPerPageOptions]="rowsPerPageOptions"
              ></p-paginator>
            </div>
          </ng-container>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-row let-rowIndex="rowIndex">
      <tr *ngIf="gsExpand" class="row-expanded">
        <td attr.colspan="{{columns.length+1}}">
          <ng-container
            [ngTemplateOutlet]="gsExpand"
            [ngTemplateOutletContext]="{$implicit:row }"
          ></ng-container>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <!--PRINT LAYOUT!!!-->
  <div #dtPrintHolderNoDisplay style="display: none">
    <div #dtPrintHolder>
      <div style="margin: 0.4in">
        <div
          *ngIf="printSaveTitle && printSaveTitle.Text"
          class="table-header-text"
          style="margin-left: 10px"
        >
          {{printSaveTitle.Text}}
        </div>
        <p-table
          id="dtPrint"
          *ngIf="printItems && printItems.items && printItems.items.values.length"
          #dtPrint
          [value]="printItems.items.values"
          [responsive]="true"
          [columnResizeMode]="'fit'"
          [paginator]="false"
        >
          <ng-template pTemplate="header">
            <tr>
              <th
                [ngClass]="{' table-header-text ': !savePdf,' table-header-text-save-pdf ': savePdf}"
                pSortableColumn="{{col.sort ? col.name : undefined}}"
                [ngStyle]="{'text-align': col.align, 'max-width': col.maxWidthPX ? col.maxWidthPX+'px' : 'auto'}"
                style="text-transform: uppercase"
                *ngFor="let col of printColumns"
              >
                <span *ngIf="col.description">
                  {{col.description | gstranslate}}
                  <!--<p-sortIcon *ngIf="col.sort" field="{{col.name}}"></p-sortIcon>-->
                </span>
              </th>
              <th *ngIf="!wide">
                <div style="width: auto"></div>
              </th>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-item
            let-rowIndex="rowIndex"
            let-expanded="expanded"
          >
            <tr
              class="pointer"
              [ngClass]="{'invalid-row':item.parameters.invalidMessage || item.parameters.invalid,'added-row':item.parameters.added, 'warning-row':item.parameters.warningMessage,
            ' table-row-text ': !savePdf,' table-row-text-save-pdf ': savePdf,'row-expanded':expanded}"
              style="height: 36px"
              gsTooltip="{{item.parameters.invalidMessage?item.parameters.invalidMessage:(item.parameters.warningMessage?item.parameters.warningMessage:'')}}"
              [type]="item.parameters.invalidMessage?'error':(item.parameters.warningMessage?'warning':'')"
              (click)="onSelectRow(item.data)"
            >
              <td *ngIf="showSelectAll && items" style="width: 35px; flex: 0">
                <p-checkbox
                  class="darkborder"
                  [(ngModel)]="item.checked"
                  [binary]="true"
                  (ngModelChange)="handleChange($event, item, 'select', null)"
                ></p-checkbox>
              </td>
              <td
                *ngFor="let col of printColumns"
                [ngStyle]="{'text-align': col.align,  'max-width': col.maxWidthPX ?  (col.maxWidthPX+5) +'px' : 'auto'}"
                style="vertical-align: middle"
              >
                <div>{{item.data[col.name]}}</div>
              </td>
              <td *ngIf="!wide">
                <div style="width: auto"></div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr
              *ngIf="printFooterItem && printFooterItem.data && printItems.items.values.length"
              class="pointer"
              [ngClass]="{' table-row-text ': !savePdf,' table-row-text-save-pdf ': savePdf }"
            >
              <td
                *ngFor="let col of printColumns"
                [ngStyle]="{'text-align': col.align,  'max-width': col.maxWidthPX ?  (col.maxWidthPX+5) +'px' : 'auto'}"
                style="vertical-align: middle"
              >
                <div>{{printFooterItem.data[col.name]}}</div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
