<div class="row form-property">
  <div
    class="col-lg-{{tooltip?11:12}}"
    [ngClass]="{'no-padding-right':tooltip}"
    style="line-height: 33px"
  >
    <ng-container *ngIf="!leftLabel && !property.pureText">
      <span class="p-float-label" style="height: 33px">
        <label
          style="color: red"
          *ngIf="!property.rowspan"
          id="{{name}}"
          class="ui-inputwrapper-focus ui-inputwrapper-focus"
          >{{value}}</label
        >
        <label class="gs-float-label" [ngClass]="{'error-color':notValid}"
          >{{label}}</label
        >
        <!--<span *ngIf="notValid" class="form-error" pTooltip="{{property.validationMessage}}">
      <i class="fas fa-info-circle fa-2x form-valid-icon error-color"></i>
    </span>-->
      </span>
    </ng-container>
    <ng-container *ngIf="leftLabel && !property.pureText">
      <label class="master-font" [ngClass]="{'error-color':notValid}"
        >{{label}}</label
      >
      <span class="float-right">
        <label
          style="color: red"
          *ngIf="!property.rowspan"
          id="{{name}}"
          class="ui-inputwrapper-focus ui-inputwrapper-focus"
          >{{value}}</label
        >
      </span>
    </ng-container>
    <ng-container *ngIf="property.pureText">
      <div
        [style.color]="property.color"
        [style.font-size]="property.fontSize"
        [style.line-height]="property.lineHeight"
        id="{{name}}"
        [ngClass]="{'form-textarea':property.rowspan}"
      >
        {{label?label:value}}
      </div>
    </ng-container>
  </div>
  <div *ngIf="tooltip" class="col-lg-1 property-tooltip">
    <span pTooltip="{{property.tooltip}}">
      <i class="fas fa-info-circle master-color"></i>
    </span>
  </div>
</div>
