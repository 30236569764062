import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
  Input,
  Inject,
  Optional,
  Injectable,
  QueryList,
  ElementRef,
  ViewChildren,
  ChangeDetectorRef,
} from '@angular/core';
import { GSBaseControl } from './gs.base.control';
import { FormDropdownProperty } from '../entity/form/formDropdown.property';
import { Item } from '../entity/entities';
import { FormLocationDropdownProperty } from '../entity/form/formLocationDropdown.property';
import { ItemUtil } from '../utils/utils';
import { TableColumn } from '../entity/table/tableColumn';

@Component({
  selector: 'gs-pe-template-item',
  templateUrl: './gs.peTemplateItem.control.html',
})
@Injectable()
export class GSPETemplateItemControl
  extends GSBaseControl
  implements OnInit, OnDestroy
{
  //#region -------Properties

  @Input() item: any;
  @Input() column: TableColumn;

  get options_1() {
    if (this.column) {
      return this.column.options;
    }
    return this.property.options;
  }
  get options_2() {
    if (this.column) {
      return this.column.options_2;
    }
    return this.property.options;
  }
  get placeholder() {
    if (this.column) {
      return this.column.placeholder;
    }
    return this.property.defaultLabel;
  }

  get options_2_Filtered() {
    if (this.column) {
      if (!this.value.Fk_PayrollConfigFieldType) return [];
      return this.column.options_2.filter(
        (o2) =>
          o2.tableName ==
          this.options_1.find(
            (o1) => o1.value == this.value.Fk_PayrollConfigFieldType
          ).tableName
      );
    }
    return this.property.options;
  }

  get notValid_Fk_PayrollConfigFieldType() {
    let vi = this.item.validationInfo.find(
      (vi) => vi.fieldName == this.name + '_Fk_PayrollConfigFieldType'
    );
    if (vi) return !vi.valid;
    else return false;
  }

  get notValid_Value() {
    let vi = this.item.validationInfo.find(
      (vi) => vi.fieldName == this.name + '_Value'
    );
    if (vi) return !vi.valid;
    else return false;
  }

  //#endregion

  //#region -------Constructor
  constructor(
    @Optional() prop: FormDropdownProperty,
    @Optional() item: Item,
    private changeDr: ChangeDetectorRef
  ) {
    super(changeDr);
    this.property = prop;
    this.item = item;
  }
  //#endregion

  //#region -------NG Eents
  ngOnInit() {
    //(this.property as FormLocationDropdownProperty).optionsSet.subscribe(this.preselect);
    //if ((this.property as FormLocationDropdownProperty).options)
    //  this.preselect();
  }

  ngOnDestroy() {}

  ngAfterContentChecked() {
    //if (this.selectOption && this.selectOption.toArray().length) {
    //  if (!this.optionHandled) {
    //    this.selectOption.toArray().filter(e => e.nativeElement.children[0].classList.contains("disabled_opacity-no-color")).forEach(e => {
    //      e.nativeElement.parentElement.classList.add("no-event");
    //    })
    //    this.optionHandled = true;
    //  }
    //} else
    //  this.optionHandled = false;
  }
  //#endregion

  //#region -------UI Events
  preselect = () => {
    //if (!this.preselected) {
    //  this.preselected = true;
    //  if (this.property.options) {
    //    let first = this.options.find(l => !l.data.BlockedReason && !l.data.Disabled);
    //    if (first && (this.property.options.length == 1 || this.property.preselectFirst)) {
    //      this.value = first.value;
    //      (this.item as Item).reValidate();
    //      (this.item as Item).reCheckDirty();
    //      this.onChange.emit({ propertyName: this.name, previousValue: null, newValue: this.value, event: {}, item: this.item, property: this.property });
    //      this.property.changed.emit({ propertyName: this.name, previousValue: null, newValue: this.value, event: {}, item: this.item, property: this.property });
    //    }
    //  }
    //}
  };

  changeFk_PayrollConfigFieldType(e) {
    if (e) {
      //reset this.value.Value
      this.value.Value = null;

      let val = this.value.Fk_PayrollConfigFieldType;
      //if (this.options.find(o => o.data.Code == e.value).data.Disabled) {
      //  this.value = e.value;
      //  setTimeout(() => {
      //    this.value = val;
      //  }, 100);

      //  return;
      //}
      let previousValue = ItemUtil.getPropertyData(
        this.item,
        this.name + '.Fk_PayrollConfigFieldType'
      );
      this.value.Fk_PayrollConfigFieldType = e.value;
      this.item.reCheckDirty();
      this.onChange.emit({
        propertyName: this.name,
        previousValue: previousValue,
        newValue: this.value,
        event: e,
        item: this.item,
        property: this.property,
      });
      //this.property.changed.emit({ propertyName: this.name, previousValue: previousValue, newValue: this.value, event: e, item: this.item, property: this.property });
    }
  }

  changeValue(e) {
    if (e || (e != null && e != undefined && e.toString().trim() == '')) {
      let val = this.value.Value;
      //if (this.options.find(o => o.data.Code == e.value).data.Disabled) {
      //  this.value = e.value;
      //  setTimeout(() => {
      //    this.value = val;
      //  }, 100);

      //  return;
      //}
      let previousValue = ItemUtil.getPropertyData(
        this.item,
        this.name + '.Value'
      );
      this.value.Value =
        this.value.Fk_PayrollConfigFieldType != 6 ? e.value : e;
      this.item.reCheckDirty();
      this.onChange.emit({
        propertyName: this.name,
        previousValue: previousValue,
        newValue: this.value,
        event: e,
        item: this.item,
        property: this.property,
      });
      //this.property.changed.emit({ propertyName: this.name, previousValue: previousValue, newValue: this.value, event: e, item: this.item, property: this.property });
    }
  }

  //#endregion

  //#region -------Private
  //#endregion
}
