import { DashboardClient } from '../../client/dashboard.client';
import { CustomerClient } from '../../client/customer.client';
import { AppInjector } from '../../app.module';
import { Session } from '../entity/entities';
import { FinanceClient } from '../../client/finance.client';

export class PageObejctHelper {
  static dashboardClient;
  static customerClient;
  static financeClient;

  public static getDropdownOptions(pageObjectCode: string) {
    switch (pageObjectCode) {
      case 'trancol_job':
      case 'brkdowncol_job':
      case 'dashboard_time_timecard_job':
      case 'commcol_job':
        let date =
          Session.menu.menuItems.selected.items.selected.parameters
            .selectedDate;
        return new Promise<any>((resolve, reject) => {
          PageObejctHelper.getDashboarClient()
            .getAllCustomerJobs(
              Session.menu.menuItems.selected.items.selected.code
            )
            .then(
              (response) => {
                let options = [];
                options.push({ label: '', value: null });
                if (response?.result)
                  response?.result.forEach((o) =>
                    options.push({ label: o.Name, value: o.Job_ID, data: o })
                  );
                resolve(options);
              },
              (r) => {
                reject();
              }
            );
        });
      case 'dashboard_time_employee':
        return new Promise<any>((resolve, reject) => {
          PageObejctHelper.getDashboarClient()
            .getTimeCustomerEmployees(
              Session.menu.menuItems.selected.items.selected.code,
              Session.menu.menuItems.selected.items.selected.parameters
                ?.selectedDate
            )
            .then(
              (response) => {
                let options = [];
                options.push({ label: '', value: null });
                if (response?.result)
                  response?.result.forEach((o) =>
                    options.push({
                      label: o.Name,
                      value: o.Employee_ID,
                      data: o,
                    })
                  );
                resolve(options);
              },
              (r) => {
                reject();
              }
            );
        });
      case 'controlpanel_manageraccounts_locations':
      case 'reporting_loadCard_locations':
      case 'reporting_dataExport_locations':
      case 'reporting_withhold_locations':
      case 'help_scheduleTraining_locations':
      case 'controlPanel_employeeProfile_locations':
      case 'controlPanel_withhold_locations':
      case 'controlPanel_distribution_rules_locations':
      case 'reporting_employeeSummary_locations':
      case 'dashboard_transactionsWithEndDate_locations':
      case 'dashboard_daily_status':
      case 'set_visaChoice':
      case 'controlPanel_commission_distribution_rules_locations':
        return new Promise<any>((resolve, reject) => {
          PageObejctHelper.getDashboarClient()
            .getActiveCustomers()
            .then(
              (response) => {
                let page = Session.menu.menuItems.selected.pages.selected;
                PageObejctHelper.getDashboarClient()
                  .notConfiguredCustomers(page)
                  .then((notConfigured) => {
                    let options = [];

                    if (response?.result) {
                      let customers = JSON.parse(
                        JSON.stringify(response?.result.Customers)
                      );
                      customers
                        .filter(
                          (c) => notConfigured.result.indexOf(c.Code) != -1
                        )
                        .forEach((c) => {
                          c.Disabled = true;
                        });
                      customers.forEach((c) =>
                        options.push({ label: c.Name, value: c.Code, data: c })
                      );
                    }
                    resolve(options);
                  });
              },
              (r) => {
                reject();
              }
            );
        });
        break;
      case 'dwolla_gratuityLocationsFundingSource':
      case 'dwolla_gratuityLocations':
        return new Promise<any>((resolve, reject) => {
          PageObejctHelper.getDashboarClient()
            .getActiveCustomers()
            .then(
              (response) => {
                let page = Session.menu.menuItems.selected.pages.selected;
                //PageObejctHelper.getDashboarClient()
                //  .notConfiguredCustomers(page)
                //  .then((notConfigured) => {
                let options = [];

                if (response?.result) {
                  let customers = JSON.parse(
                    JSON.stringify(response?.result.Customers)
                  );
                  //customers
                  //  .filter((c) => notConfigured.result.indexOf(c.Code) != -1)
                  //  .forEach((c) => {
                  //    c.Disabled = true;
                  //  });
                  customers.forEach((c) =>
                    options.push({ label: c.Name, value: c.Code, data: c })
                  );
                }
                resolve(options);
                // });
              },
              (r) => {
                reject();
              }
            );
        });
        break;
      case 'reportingadmin_tabapay_companyGroups':
        return new Promise<any>((resolve, reject) => {
          PageObejctHelper.getCustomerClient()
            .getTabapayCompanyGroups(null)
            .then(
              (response) => {
                let options = [];

                if (response?.result) {
                  let groups = JSON.parse(JSON.stringify(response?.result));
                  options.push({
                    label: 'Select company group',
                    value: null,
                    data: { Name: 'Select company group', Code: null },
                  });
                  groups.forEach((c) =>
                    options.push({ label: c.Name, value: c.Code, data: c })
                  );
                }
                resolve(options);
              },
              (r) => {
                reject();
              }
            );
        });
        break;
      case 'reportingadmin_tabapay_customers':
        return new Promise<any>((resolve, reject) => {
          PageObejctHelper.getCustomerClient()
            .getTabapayCustomersByCompanyGroupCode(null, null)
            .then(
              (response) => {
                let options = [];

                if (response?.result) {
                  let groups = JSON.parse(JSON.stringify(response?.result));
                  groups.forEach((c) =>
                    options.push({ label: c.Code + ' - ' + c.Name, value: c.Code, data: c })
                  );
                }
                resolve(options);
              },
              (r) => {
                reject();
              }
            );
        });
        break;
      case 'companygroup_details_implementationtypes':
        return new Promise<any>((resolve, reject) => {
          PageObejctHelper.getCustomerClient()
            .getImplementationTypes()
            .then(
              (response) => {
                let options = [];
                options.push({
                  label: '...',
                  value: null,
                  data: { Description: '..', PK_ID: null },
                });
                if (response?.result)
                  response?.result.forEach((o) =>
                    options.push({
                      label: o.Description,
                      value: o.PK_ID,
                      data: o,
                    })
                  );
                resolve(options);
              },
              (r) => {
                reject();
              }
            );
        });
        break;
      case 'companygroup_details_noEmailTicketMessage':
        return new Promise<any>((resolve, reject) => {
          PageObejctHelper.getCustomerClient()
            .getNoEmailTicketMessages()
            .then(
              (response) => {
                let options = [];
                //options.push({ label: '...', value: null, data: { Message: "..", PK_ID: null } });
                if (response?.result)
                  response?.result.forEach((o) =>
                    options.push({
                      label: o.Message,
                      value: o.PK_ID,
                      data: o,
                    })
                  );
                resolve(options);
              },
              (r) => {
                reject();
              }
            );
        });
        break;
      case 'companygroup_customeradditionalparamkeys':
        return new Promise<any>((resolve, reject) => {
          PageObejctHelper.getCustomerClient()
            .getCustomerAdditionalParamKeys(null)
            .then(
              (response) => {
                let options = [];
                //options.push({ label: 'Select params', value: null, data: { Title: "Select params", PK_ID: null } });
                if (response?.result)
                  response?.result.forEach((o) =>
                    options.push({
                      label: o.Title,
                      value: o.PK_ID,
                      data: o,
                    })
                  );
                resolve(options);
              },
              (r) => {
                reject();
              }
            );
        });
        break;
      case 'help_support_locations':
        return new Promise<any>((resolve, reject) => {
          PageObejctHelper.getDashboarClient()
            .getActiveCustomers()
            .then(
              (response) => {
                let page = Session.menu.menuItems.selected.pages.selected;
                PageObejctHelper.getDashboarClient()
                  .notConfiguredCustomers(page)
                  .then((notConfigured) => {
                    let options = [];

                    if (response?.result) {
                      let customers = JSON.parse(
                        JSON.stringify(response?.result.Customers)
                      );
                      customers
                        .filter(
                          (c) => notConfigured.result.indexOf(c.Code) != -1
                        )
                        .forEach((c) => {
                          c.Disabled = true;
                        });
                      customers.forEach((c) => {
                        c.BlockedReason = null;
                        options.push({ label: c.Name, value: c.Code, data: c });
                      }
                      );
                    }
                    resolve(options);
                  });
              },
              (r) => {
                reject();
              }
            );
        });
        break;
      default:
        return new Promise<any>((resolve, reject) => {
          resolve(null);
        });
    }
  }

  private static getDashboarClient(): DashboardClient {
    if (!PageObejctHelper.dashboardClient) {
      PageObejctHelper.dashboardClient =
        AppInjector.get<DashboardClient>(DashboardClient);
    }
    return PageObejctHelper.dashboardClient;
  }

  private static getCustomerClient(): CustomerClient {
    if (!PageObejctHelper.customerClient) {
      PageObejctHelper.customerClient =
        AppInjector.get<CustomerClient>(CustomerClient);
    }
    return PageObejctHelper.customerClient;
  }

  private static getFinanceClient(): FinanceClient {
    if (!PageObejctHelper.financeClient) {
      PageObejctHelper.financeClient =
        AppInjector.get<FinanceClient>(FinanceClient);
    }
    return PageObejctHelper.financeClient;
  }
}
