import { FormProperty } from './formProperty';
import { PropertyTypes } from '../enum/propertyTypes';
import { EventEmitter } from '@angular/core';
import { GSMultiSelectControl } from '../../controls/gsControls';

export class FormMultiselectProperty extends FormProperty {
    options?: any[];
    source: any;
    type = PropertyTypes.Multiselect;
    component = GSMultiSelectControl;
    changed = new EventEmitter<any>();
    selectedValue: any;
  defaultLabel = 'Choose';
    constructor(name: string, label: string, options?: [], row?: number, column?: number,
        rowspan?: number, columnspan?: number, valid?: boolean) {
        super(name, label, row, column,
            rowspan, columnspan, valid);
      this.options = options ? options : this.options;    
    }
}
