import { Injectable } from '@angular/core';
import { createStore, withProps, setProp, select } from '@ngneat/elf';
import {
  withEntities,
  selectAllEntities,
  selectActiveEntity,
  addEntities,
  deleteEntities,
  withActiveId,
  setActiveId,
  updateEntities,
  getActiveId,
  getActiveEntity,
  getAllEntities,
  resetActiveId,
  entitiesPropsFactory,
  upsertEntitiesById,
  selectEntity,
  updateEntitiesByPredicate,
  hasEntity,
  setEntities,
  setEntitiesMap,
  upsertEntities,
  deleteAllEntities,
} from '@ngneat/elf-entities';
import { stateHistory } from '@ngneat/elf-state-history';

import { UntilDestroy } from '@ngneat/until-destroy';
import {
  CustomerProperties,
  WizardCustomer,
  WorkflowDefinition,
  WorkflowDefinitionTask,
} from './entity';
import { map } from 'rxjs';
import { WorkflowClientExecutionTypes } from '../components/workflow/workflow-definitions/workflow-definitions.component';

const idKey = 'Code' as const;
const pk_idKey = 'PK_ID' as const;

const { workflowEntitiesRef, withWorkflowEntities } =
  entitiesPropsFactory('workflow');

const storeFactory = () =>
  createStore(
    {
      name: 'wizard-customers',
    },
    withEntities<WizardCustomer, typeof idKey>({
      initialValue: [],
      idKey: 'Code',
    }),
    withWorkflowEntities<{
      id;
      activeId?;
      definitions: WorkflowDefinition[];
    }>(),

    withProps({
      selectedWFExecutionConfiguration: undefined,
    }),
    withActiveId()
  );

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class CustomerStore {
  private readonly store = storeFactory();
  propsStateHistory = stateHistory(this.store, {
    resetFutureOnNewState: true,
  });

  get selected() {
    return this.store.query(getActiveEntity());
  }

  get activeWF() {
    return this.store.query(getActiveEntity({ ref: workflowEntitiesRef }));
  }

  customers$ = this.store.pipe(selectAllEntities());
  selected$ = this.store.pipe(selectActiveEntity());
  customerWorkflowDefinitions$ = this.selected$.pipe(
    map((c) => c.workflow?.definitions)
  );
  workflowEntities$ = this.store.pipe(
    selectActiveEntity({ ref: workflowEntitiesRef }),
    map((data) => {
      return data?.definitions;
    })
  );

  selectedWFDefinition$ = this.store.pipe(
    selectActiveEntity({ ref: workflowEntitiesRef }),
    map((data) => {
      return data.definitions.find((x) => x.PK_ID === data.activeId);
    })
  );
  get selectedWFDefinition() {
    return this.activeWF?.definitions.find(
      (x) => x.PK_ID == this.activeWF.activeId
    );
  }

  get isGenericWorkflow(): boolean {
    return (
      this.selectedWFDefinition?.Configurations?.find(
        (c) =>
          c.FK_WorkflowClientExecutionType ==
          WorkflowClientExecutionTypes.MANUALGENERIC
      ) !== undefined
    );
  }

  get ids() {
    return this.store?.value?.ids;
  }

  selectedWFExecutionDefinition$ = this.store.pipe(
    select((state) => state.selectedWFExecutionConfiguration)
  );

  addCustomer(value: WizardCustomer) {
    if (!this.store.query(hasEntity(value.Code, { ref: workflowEntitiesRef })))
      this.store.update(addEntities(value, {}));
    this.store.update(setActiveId(value.Code));
  }

  removeCustomer(code: number) {
    this.store.update(deleteEntities(code));
    this.store.update(deleteEntities(code, { ref: workflowEntitiesRef }));
    const entities = this.store.query(getAllEntities());
    if (entities.length) this.store.update(setActiveId(entities[0].Code));
    else this.store.update(resetActiveId());
  }

  clear() {
    this.store.reset();
  }

  selectCustomer(code: number) {
    this.store.update(setActiveId(code));
  }

  setCustomerWorkflowDefinitions(
    workflowDefs: WorkflowDefinition[],
    customerCode?: number
  ) {
    customerCode = customerCode ?? this.selected.Code;
    if (
      !this.store.query(hasEntity(customerCode, { ref: workflowEntitiesRef }))
    )
      this.store.update(
        addEntities(
          { id: customerCode, definitions: workflowDefs },
          { ref: workflowEntitiesRef }
        )
      );
    else this.selectCustomer(customerCode);
  }

  updateCustomerWorkflowDefinitions(
    workflowDefs: WorkflowDefinition[],
    customerCode?: number
  ) {
    customerCode = customerCode ?? this.selected.Code;
    if (this.store.query(hasEntity(customerCode, { ref: workflowEntitiesRef })))
      this.store.update(
        upsertEntities(
          { id: customerCode, definitions: workflowDefs },
          { ref: workflowEntitiesRef }
        )
      );
  }

  removeCustomerWorkflowDefinitions(code?: number) {
    code = code ?? this.selected.Code;
    if (this.store.query(hasEntity(code, { ref: workflowEntitiesRef })))
      this.store.update(deleteEntities(code, { ref: workflowEntitiesRef }));
  }

  setCustomerWorkflowTasks(
    tasks: WorkflowDefinitionTask[],
    workflowDefId: number,
    customerCode?: number
  ) {
    customerCode = customerCode ?? this.selected.Code;

    this.store.update(
      updateEntities(
        customerCode,
        (entity) => {
          const def = entity.definitions.find((x) => x.PK_ID === workflowDefId);
          def.Tasks = tasks.map((t) => {
            return { ...t, ...{ TaskName: t.WorkflowTask.Name } };
          });

          return {
            ...entity,
            ...{ definitions: [...entity.definitions] },
          };
        },
        { ref: workflowEntitiesRef }
      )
    );
  }

  updateWorkflowTasks(workflowDefId: number, task: WorkflowDefinitionTask) {
    this.store.update(
      updateEntities(
        this.selected.Code,
        (entity) => {
          const def = entity.definitions.find((x) => x.PK_ID === workflowDefId);
          const index = def.Tasks.indexOf(
            def.Tasks.find((x: any) => x.PK_ID == task.PK_ID)
          );
          def.Tasks.splice(index, 1, task);
          return {
            ...entity,
            ...{ definitions: [...entity.definitions] },
          };
        },
        { ref: workflowEntitiesRef }
      )
    );
  }

  addWorkflowTasks(workflowDefId: number, task: WorkflowDefinitionTask) {
    this.store.update(
      updateEntities(
        this.selected.Code,
        (entity) => {
          const def = entity.definitions.find((x) => x.PK_ID === workflowDefId);
          if (!def.Tasks) def.Tasks = [];
          def.Tasks.push(task);
          return {
            ...entity,
            ...{ definitions: [...entity.definitions] },
          };
        },
        { ref: workflowEntitiesRef }
      )
    );
  }

  updateWorkflowTasksParameter(
    workflowDefId: number,
    taskId: number,
    propertyName: string,
    propertyValue: string
  ) {
    this.store.update(
      updateEntities(
        this.selected.Code,
        (entity) => {
          const def = entity.definitions.find((x) => x.PK_ID === workflowDefId);
          const task = def.Tasks.find((x) => x.PK_ID == taskId);
          const prop = task.WorkflowTask.Params.find(
            (x) => x.Key == propertyName
          );
          if (prop) prop.Value = propertyValue;
          else
            task.WorkflowTask.Params.filter(
              (x) => x.Meta.DataType == 'Group'
            ).forEach((x) => {
              const cProp = x.Children.find((c) => c.Key == propertyName);
              if (cProp) cProp.Value = propertyValue;
            });
          return {
            ...entity,
            ...{ definitions: [...entity.definitions] },
          };
        },
        { ref: workflowEntitiesRef }
      )
    );
  }

  addCustomerWFDefinition(
    workflowDef: WorkflowDefinition,
    customerCode?: number
  ) {
    customerCode = customerCode ?? this.selected.Code;

    this.store.update(
      updateEntities(
        customerCode,
        (entity) => ({
          ...entity,
          definitions: [...(entity.definitions ?? []), ...[workflowDef]],
        }),
        { ref: workflowEntitiesRef }
      )
    );
  }

  updateCustomerWFDefinition(workflowDef, reload = false) {
    const customerCode = this.selected.Code;

    this.store.update(
      updateEntities(
        customerCode,
        (entity) => {
          const i = entity.definitions.indexOf(
            entity.definitions.find((x) => x.PK_ID === workflowDef.PK_ID)
          );
          entity.definitions.splice(i, 1, workflowDef);

          if (reload)
            return {
              ...entity,
              ...{ definitions: [...entity.definitions] },
            };
          else return { ...entity };
        },

        {
          ref: workflowEntitiesRef,
        }
      )
    );
  }

  selectWorkflowDefinition(wfDef) {
    this.store.update(
      updateEntities(
        this.selected.Code,
        (e) => ({
          ...e,
          activeId: wfDef.PK_ID,
        }),
        {
          ref: workflowEntitiesRef,
        }
      )
    );
  }
  resetWorkflowDefinition() {
    this.store.update(
      updateEntities(
        this.selected.Code,
        (e) => ({
          ...e,
          activeId: undefined,
        }),
        {
          ref: workflowEntitiesRef,
        }
      )
    );
  }

  selectWorkflowDefinitionExecutionConfiguration(configuration) {
    this.store.update(
      setProp('selectedWFExecutionConfiguration', configuration)
    );
  }
  resetWorkflowDefinitionExecutionConfiguration() {
    this.store.update(setProp('selectedWFExecutionConfiguration', undefined));
  }

  updateCustomerWFDefinitionExecutionConfig(config, reload = false) {
    const customerCode = this.selected.Code;

    this.store.update(
      updateEntities(
        customerCode,
        (entity) => {
          const index = this.selectedWFDefinition.Configurations.indexOf(
            this.store.value.selectedWFExecutionConfiguration
          );
          this.selectedWFDefinition.Configurations.splice(index, 1, config);
          if (reload)
            return {
              ...entity,
              ...{ definitions: [...entity.definitions] },
            };
          else return { ...entity };
        },
        { ref: workflowEntitiesRef }
      )
    );
  }

  addCustomerWFDefinitionExecutionConfig(config, customerCode?: number) {
    customerCode = customerCode ?? this.selected.Code;

    this.store.update(
      updateEntities(
        customerCode,
        (entity) => {
          const configurations = this.selectedWFDefinition.Configurations;
          this.selectedWFDefinition.Configurations = [
            ...(configurations ?? []),
            ...[config],
          ];
          return {
            ...entity,
            ...{ definitions: [...entity.definitions] },
          };
        },
        { ref: workflowEntitiesRef }
      )
    );
  }
}
