import {
  Directive,
  ElementRef,
  OnInit,
  Input,
  Renderer2,
  RendererStyleFlags2,
  HostListener,
  inject,
} from '@angular/core';
import {
  Item,
  ValidationInfo,
  EventCollection,
  Column,
} from '../entity/entities';
import { TableColumn } from '../entity/table/tableColumn';
import { FormProperty } from '../entity/form/formProperty';
import { ItemUtil } from '../utils/utils';
import { FormPasswordProperty } from '../entity/form/formPassword.property';
import { FormDateRangeProperty } from '../entity/form/formDateRange.property';
import { ValidationTypes } from '../entity/enum/validationTypes';
import { FormBuilder } from '../builder/gs.form.builder';
import { TranslateService } from '@ngx-translate/core';
import { regExLib } from '../regEx/regExLib';

@Directive({
  selector: '[change]',
})
export class ChangeDirective implements OnInit {
  private _item: Item;
  private _items: any;
  //@Input() item: Item;
  //@Input() items;

  @Input() get item() {
    return this._item;
  }
  set item(value) {
    this._item = value;
    this.subscribe();
    this.validateOnInit();
  }

  @Input() get items() {
    return this._items;
  }

  set items(value) {
    this._items = value;
    this.subscribe();
    this.validateOnInit();
  }

  @Input() get properties() {
    return this._properties;
  }
  set properties(value) {
    this._properties = value;
    this.subscribe();
    this.validateOnInit();
  }

  @Input()
  set propertiesType(value) {
    if (value) {
      this._properties = FormBuilder.build(value, this.translate);
      this.subscribe();
      this.validateOnInit();
    }
  }

  _properties: FormProperty[];
  @Input() columns: TableColumn[];
  @Input() skipValidateOnInit = false;
  translate = inject(TranslateService);

  @HostListener('rowDataChanged', ['$event']) onTableRowChange(e) {
    if (e.item) {
      e.item.reCheckDirty();
      this.validateCell(e.item, e.column);
    }
  }

  @HostListener('onChange', ['$event']) onFormDataChange(e) {
    if (e.item) {
      e.item.reCheckDirty();
      this.validateProperty(e.item, e.property);
    }
  }

  ngOnInit() {
    this.subscribe();
    this.validateOnInit();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  private unsubscribe() {
    if (this.item && this.properties) {
      this.item.onSetData.unsubscribe(this.dataSet);
      this.item.onReValidate.unsubscribe(this.dataSet);

      this.properties.forEach((prop) => {
        prop.onDoValidate.unsubscribe(this.doValidate);
      });
    } else if (this.items && this.columns) {
      if (this.items.onAdded) this.items.onAdded.unsubscribe(this.itemAdded);
    }
  }

  private subscribe() {
    if (this.item && this.properties) {
      this.item.onSetData.subscribe(this.dataSet, this);
      this.item.onReValidate.subscribe(this.dataSet, this);
      this.properties.forEach((prop) => {
        prop.onDoValidate.subscribe(this.doValidate, this);
      });
    } else if (this.items && this.columns) {
      if (this.items.onAdded) this.items.onAdded.subscribe(this.itemAdded);
      this.items.values.forEach((i) => {
        i.onReValidate.subscribe(this.dataSet);
      });
    }
  }

  private validateCell(item: Item, column: TableColumn) {
    if (column && column.validate) {
      this.validateTableCell(item, column);
    } else if (
      this.item &&
      this.item.validationInfo &&
      this.item.validationInfo.length > 0
    ) {
      this.item.validationInfo
        .filter((vi) => vi.column.name == column.name)
        .forEach((vi) =>
          this.item.validationInfo.splice(this.item.validationInfo.indexOf(vi))
        );
    }
  }

  private validateProperty(item: Item, property: FormProperty) {
    if (property.validate && property.visible) {
      this.validateFormControl(item, property);
    } else if (this.item.validationInfo?.length > 0) {
      this.item.validationInfo
        .filter((vi) => vi.property.name == property.name)
        .forEach((vi) =>
          this.item.validationInfo.splice(this.item.validationInfo.indexOf(vi))
        );
    }
  }

  private validateOnInit(revalidate?: boolean) {
    if (this.item && this.properties) {
      //this is for the case where the same item is used on different forms, so we cannot just remove all validationInfo like set it to [].
      this.properties.forEach((p) => {
        let infoToRemoveOnInit = this.item.validationInfo.find(
          (vi) => vi.fieldName == p.name
        );
        if (infoToRemoveOnInit)
          this.item.validationInfo.splice(
            this.item.validationInfo.indexOf(infoToRemoveOnInit),
            1
          );
      });

      this.properties
        .filter((prop) => prop.validate && prop.visible)
        .forEach((prop) => {
          var forceValidation = false;
          if (
            this.item.parameters['forceValidation'] != null &&
            this.item.parameters['forceValidation']
          )
            forceValidation = true;
          if (!this.skipValidateOnInit || (revalidate && forceValidation))
            this.validateFormControl(this.item, prop);
        });
    } else if (this.items && this.columns) {
      this.columns
        .filter((col) => col.validate)
        .forEach((col) => {
          this.items.values.forEach((item) => {
            this.validateTableCell(item, col);
          });
        });
    }
  }

  private validateTableCell(item: Item, column: TableColumn) {
    if (!item.validationInfo.some((vi) => vi.fieldName == column.name))
      item.validationInfo.push(
        new ValidationInfo(column.name, column, undefined, true)
      );

    const columnValInfo = item.validationInfo.find(
      (vi) => vi.fieldName == column.name
    );
    if (column.validationRegEx) {
      columnValInfo.valid = column.validationRegEx.test(
        ItemUtil.getPropertyData(item, column.name)
      );

      if (columnValInfo.valid)
        columnValInfo.valid = this.validateColumnValueIsEmpty(item, column);
    } else if (
      column.validationReference &&
      column.validationTypeForReference
    ) {
      columnValInfo.valid = this.validateCellByReference(
        null,
        column,
        item,
        column.name,
        column.validationReference,
        column.validationTypeForReference
      );
      const isReadOnly =
        item.parameters && item.parameters[`${column.name}_readOnly`];
      if (columnValInfo.valid && !isReadOnly)
        columnValInfo.valid = this.validateColumnValueIsEmpty(item, column);
    } else {
      if (ItemUtil.getPropertyData(item, column.name))
        columnValInfo.valid = true;
      else if (item.parameters && item.parameters[`${column.name}_readOnly`])
        columnValInfo.valid = true;
      else columnValInfo.valid = this.validateColumnValueIsEmpty(item, column);
    }
  }

  private validateColumnValueIsEmpty(item: Item, column: TableColumn) {
    return !(
      (!column.validateAllowNull &&
        ItemUtil.getPropertyData(item, column.name) == undefined) ||
      (!column.validateAllowNull &&
        ItemUtil.getPropertyData(item, column.name) == null) ||
      (!column.validateAllowNull &&
        ItemUtil.getPropertyData(item, column.name).toString().trim() === '') ||
      (!column.validateAllowNull &&
        ItemUtil.getPropertyData(item, column.name).toString().trim() == 'NaN')
    );
  }

  private validateFormControl(item: Item, property: FormProperty) {
    // if (property.visible) {
    if (!item.validationInfo.some((vi) => vi.fieldName == property.name))
      item.validationInfo.push(
        new ValidationInfo(property.name, undefined, property, true)
      );
    //} else {
    //  let existingVi = item.validationInfo.find(vi => vi.fieldName == property.name)
    //  if (existingVi)
    //    existingVi.valid = true;
    //}

    if (property.validationRegEx) {
      item.validationInfo.find((vi) => vi.fieldName == property.name).valid =
        property.validationRegEx.test(
          ItemUtil.getPropertyData(item, property.name)
        );

      if (item.validationInfo.find((vi) => vi.fieldName == property.name).valid)
        item.validationInfo.find((vi) => vi.fieldName == property.name).valid =
          !(
            (!property.validateAllowNull &&
              ItemUtil.getPropertyData(item, property.name) == undefined) ||
            (!property.validateAllowNull &&
              ItemUtil.getPropertyData(item, property.name) == null) ||
            (!property.validateAllowNull &&
              ItemUtil.getPropertyData(item, property.name).toString().trim() ==
                '') ||
            (!property.validateAllowNull &&
              ItemUtil.getPropertyData(item, property.name).toString().trim() ==
                'NaN')
          );
    } else if (property.validationType) {
      item.validationInfo.find((vi) => vi.fieldName == property.name).valid =
        this.validatePropertyByValidationType(property, item);

      //if (item.validationInfo.find(vi => vi.fieldName == property.name).valid)
      //  item.validationInfo.find(vi => vi.fieldName == property.name).valid = !(!property.validateAllowNull && ItemUtil.getPropertyData(item, property.name) == undefined
      //    || !property.validateAllowNull && ItemUtil.getPropertyData(item, property.name) == null
      //    || !property.validateAllowNull && ItemUtil.getPropertyData(item, property.name).toString().trim() == ''
      //    || !property.validateAllowNull && ItemUtil.getPropertyData(item, property.name).toString().trim() == 'NaN');
    } else
      item.validationInfo.find((vi) => vi.fieldName == property.name).valid = !(
        (!property.validateAllowNull &&
          ItemUtil.getPropertyData(item, property.name) == undefined) ||
        (!property.validateAllowNull &&
          ItemUtil.getPropertyData(item, property.name) == null) ||
        (!property.validateAllowNull &&
          ItemUtil.getPropertyData(item, property.name).toString().trim() ==
            '') ||
        (!property.validateAllowNull &&
          ItemUtil.getPropertyData(item, property.name).toString().trim() ==
            'NaN')
      );

    //check if date range
    if (property instanceof FormDateRangeProperty) {
      let value = ItemUtil.getPropertyData(item, property.name);
      if (
        value &&
        (!value.length || value.length < 2 || !value[0] || !value[1])
      )
        item.validationInfo.find((vi) => vi.fieldName == property.name).valid =
          false;
    }

    //check if this property value is the same as equalTo
    if (property instanceof FormPasswordProperty && property.validateEqualTo) {
      item.validationInfo.find((vi) => vi.fieldName == property.name).valid =
        ItemUtil.getPropertyData(item, property.name) ==
        ItemUtil.getPropertyData(item, property.validateEqualTo);
    }

    //check if equalFrom is the same as this value and validate equalFrom
    if (
      property instanceof FormPasswordProperty &&
      property.validateEqualFrom
    ) {
      if (
        !item.validationInfo.some(
          (vi) => vi.fieldName == property.validateEqualFrom
        )
      )
        item.validationInfo.push(
          new ValidationInfo(
            property.validateEqualFrom,
            undefined,
            property,
            true
          )
        );

      item.validationInfo.find(
        (vi) => vi.fieldName == property.validateEqualFrom
      ).valid =
        !(
          (!property.validateAllowNull &&
            ItemUtil.getPropertyData(item, property.validateEqualFrom) ==
              undefined) ||
          (!property.validateAllowNull &&
            ItemUtil.getPropertyData(item, property.validateEqualFrom) ==
              null) ||
          (!property.validateAllowNull &&
            ItemUtil.getPropertyData(item, property.validateEqualFrom)
              .toString()
              .trim() == '') ||
          (!property.validateAllowNull &&
            ItemUtil.getPropertyData(item, property.validateEqualFrom)
              .toString()
              .trim() == 'NaN')
        ) &&
        ItemUtil.getPropertyData(item, property.name) ==
          ItemUtil.getPropertyData(item, property.validateEqualFrom);
    }
  }

  itemAdded = (item: Item) => {
    this.columns
      .filter((col) => col.validate)
      .forEach((col) => {
        this.validateTableCell(item, col);
      });
    item.onSetData.subscribe(this.dataSet, this);
    item.onReValidate.subscribe(this.dataSet, this);
  };

  dataSet = (item: Item) => {
    this.validateOnInit(true);
  };

  doValidate = (property) => {
    this.validateProperty(this.item, property);
  };

  validatePropertyByValidationType(property: FormProperty, item: Item) {
    switch (property.validationType) {
      case ValidationTypes.SSN_4existing_or_9new:
        if (item.data.PkId && item.data.SSN.length == 4) {
          return true;
        } else if (!item.data.PkId && item.data.SSN.length == 9) {
          return true;
        } else return false;
      case ValidationTypes.DwollaFundingSource_AccountNumber:
        if (item.data.PkId) {
          return true;
        } else if (
          !item.data.PkId &&
          item.data?.AccountNumber?.length >= 4 &&
          item.data?.AccountNumber?.length <= 17
        ) {
          return true;
        } else return false;
      case ValidationTypes.DwollaFundingSource_RoutingNumber:
        if (item.data.PkId) {
          return true;
        } else if (!item.data.PkId && item.data?.RoutingNumber?.length == 9) {
          return true;
        } else return false;
      case ValidationTypes.required:
        const reqValue = ItemUtil.getPropertyData(item, property.name);
        if ([undefined, null, ''].includes(reqValue)) return false;
        return true;
    }
    return false;
  }

  validateCellByReference(
    property: FormProperty,
    column: TableColumn,
    item: Item,
    name: string,
    reference: string,
    validationType: ValidationTypes,
    cutLoop: boolean = false
  ) {
    let valid = true;
    if (
      column.readonly ||
      (item.parameters && item.parameters[`${column.name}_readOnly`])
    )
      return valid;
    switch (validationType) {
      case ValidationTypes.equal:
        valid =
          ItemUtil.getPropertyData(item, name) ==
          ItemUtil.getPropertyData(item, reference);
        break;
      case ValidationTypes.lessThan:
        valid =
          ItemUtil.getPropertyData(item, name) <
          ItemUtil.getPropertyData(item, reference);
        break;
      case ValidationTypes.lessThanOrEqual:
        valid =
          ItemUtil.getPropertyData(item, name) <=
          ItemUtil.getPropertyData(item, reference);
        break;
      case ValidationTypes.greaterThan:
        valid =
          ItemUtil.getPropertyData(item, name) >
          ItemUtil.getPropertyData(item, reference);
        break;
      case ValidationTypes.greaterThanOrEqual:
        valid =
          ItemUtil.getPropertyData(item, name) >=
          ItemUtil.getPropertyData(item, reference);
        break;
      case ValidationTypes.uniqueNotEmpty:
        valid =
          ItemUtil.getPropertyData(item, name) &&
          !this._items.values
            .filter((v) => v.key != item.key)
            .some(
              (v) =>
                (ItemUtil.getPropertyData(v, reference)
                  ? ItemUtil.getPropertyData(v, reference)
                      .toString()
                      .toLowerCase()
                  : '') ==
                (ItemUtil.getPropertyData(item, name)
                  ? ItemUtil.getPropertyData(item, name)
                      .toString()
                      .toLowerCase()
                  : '')
            );

        if (!cutLoop)
          this._items.values
            .filter((v) => v.key != item.key)
            .forEach((i) => {
              let vi = i.validationInfo.find((vi) => vi.fieldName == name);
              if (vi)
                vi.valid = this.validateCellByReference(
                  null,
                  column,
                  i,
                  name,
                  reference,
                  validationType,
                  true
                );
              else {
              }
            });

        break;

      case ValidationTypes.peReportTemplateValidation:
        let data = ItemUtil.getPropertyData(item, name);
        let valid_Fk_PayrollConfigFieldType =
          data.Fk_PayrollConfigFieldType && true;

        if (data.Fk_PayrollConfigFieldType) {
          //check also if some other item in this column has option for field type that is different in IsKeyColumn...if so, then it's invalid
          let isKey = column.options.find(
            (o) => o.value == data.Fk_PayrollConfigFieldType
          ).isKeyField;

          //check if all are different in terms of is-key field items, but exclude Fk_PayrollConfigFieldType=6 (free field)
          this._items.values
            .filter((v) => v.data[name].Fk_PayrollConfigFieldType)
            .forEach((it) => {
              let validItem = true;
              let option = column.options.find(
                (o) => o.value == it.data[name].Fk_PayrollConfigFieldType
              );
              if (
                option &&
                option.isKeyField != isKey &&
                data.Fk_PayrollConfigFieldType != 6 &&
                it.data[name].Fk_PayrollConfigFieldType != 6
              ) {
                valid_Fk_PayrollConfigFieldType = false;
                validItem = false;
              }

              if (validItem)
                validItem = it.data[name].Fk_PayrollConfigFieldType && true;

              //set 'general' validation info for invalid item
              let vi = it.validationInfo.find((vi) => vi.fieldName == name);
              if (!vi) {
                it.validationInfo.push(
                  new ValidationInfo(name, column, null, validItem)
                );
              } else vi.valid = validItem;

              //set '_Fk_PayrollConfigFieldType' validation info for invalid item
              let vi_Fk_PayrollConfigFieldType = it.validationInfo.find(
                (vi) => vi.fieldName == name + '_Fk_PayrollConfigFieldType'
              );
              if (!vi_Fk_PayrollConfigFieldType) {
                it.validationInfo.push(
                  new ValidationInfo(
                    name + '_Fk_PayrollConfigFieldType',
                    column,
                    null,
                    validItem
                  )
                );
              } else vi_Fk_PayrollConfigFieldType.valid = validItem;
            });
        }

        let valid_Value =
          (data.Fk_PayrollConfigFieldType != 6 && data.Value) ||
          data.Fk_PayrollConfigFieldType == 6;
        valid = valid_Fk_PayrollConfigFieldType && valid_Value;

        //add additional validation info
        let vi_Fk_PayrollConfigFieldType = item.validationInfo.find(
          (vi) => vi.fieldName == name + '_Fk_PayrollConfigFieldType'
        );
        if (!vi_Fk_PayrollConfigFieldType) {
          item.validationInfo.push(
            new ValidationInfo(
              name + '_Fk_PayrollConfigFieldType',
              column,
              null,
              valid_Fk_PayrollConfigFieldType
            )
          );
        } else
          vi_Fk_PayrollConfigFieldType.valid = valid_Fk_PayrollConfigFieldType;

        let vi_Value = item.validationInfo.find(
          (vi) => vi.fieldName == name + '_Value'
        );
        if (!vi_Value) {
          item.validationInfo.push(
            new ValidationInfo(name + '_Value', column, null, valid_Value)
          );
        } else vi_Value.valid = valid_Value;

        break;

      case ValidationTypes.fromReferenceOrNullExcludingSelf:
        valid = true;
        let value = ItemUtil.getPropertyData(item, name);
        if (!value) return valid;

        valid = false;
        this._items.values.forEach((it) => {
          if (it.data != item.data) {
            if (value == ItemUtil.getPropertyData(it, reference)) valid = true;
          }
        });
        break;
      case ValidationTypes.commissionSalesTrack:
        valid = true;
        let trackTypeColumn = this.columns.find((c) => c.name == 'TrackType');
        let invalidItems = this.items.values.filter(
          (v) =>
            v.data.TrackType == item.data.TrackType &&
            v.data.Track_Number == item.data.Track_Number
        );

        if (invalidItems.length > 1) {
          invalidItems.forEach((ii) => {
            let vi = ii.validationInfo.find(
              (vi) => vi.fieldName == 'TrackType'
            );
            if (!vi) {
              ii.validationInfo.push(
                new ValidationInfo('TrackType', trackTypeColumn, null, false)
              );
            } else vi.valid = false;
          });
          if (name == 'TrackType') valid = false;
        } else invalidItems = [];

        if (trackTypeColumn.options) {
          this.items.values
            .filter(
              (v) =>
                !trackTypeColumn.options
                  .map((o) => o.value)
                  .some((x) => x == v.data.TrackType)
            )
            .forEach((ii) => {
              let vi = ii.validationInfo.find(
                (vi) => vi.fieldName == 'TrackType'
              );
              if (!vi) {
                ii.validationInfo.push(
                  new ValidationInfo('TrackType', trackTypeColumn, null, false)
                );
              } else vi.valid = false;
              if (name == 'TrackType') valid = false;
            });

          this.items.values
            .filter(
              (v) =>
                !invalidItems
                  .map((i) => i.data.Pk_Id)
                  .some((x) => x == v.data.Pk_Id)
            )
            .filter((v) =>
              trackTypeColumn.options
                .map((o) => o.value)
                .some((x) => x == v.data.TrackType)
            )
            .forEach((ii) => {
              let vi = ii.validationInfo.find(
                (vi) => vi.fieldName == 'TrackType'
              );
              if (!vi) {
                ii.validationInfo.push(
                  new ValidationInfo('TrackType', trackTypeColumn, null, true)
                );
              } else vi.valid = true;
            });
        }

        if (
          name == 'TrackType' &&
          this.items.values.find((v) => v.data.Pk_Id == item.data.Pk_Id)
        )
          valid = this.items.values
            .find((v) => v.data.Pk_Id == item.data.Pk_Id)
            .validationInfo.find((vi) => vi.fieldName == 'TrackType').valid;

        if (name == 'Track_Number' && !ItemUtil.getPropertyData(item, name))
          valid = false;
        break;
      case ValidationTypes.requiredIfRelatedValueIs:
        const colValue = ItemUtil.getPropertyData(item, column.name);
        let vi = item.validationInfo.find(
          (vi) => vi.fieldName == column.validationReference
        );
        if (!vi) {
          item.validationInfo.push({
            column: this.columns.find(
              (c) => c.name == column.validationReference
            ),
            fieldName: column.validationReference,
            valid: true,
          });
          if (!vi) return true;
          if (colValue != column.requestedValue) {
            vi.valid = true;
            return true;
          }
          const relatedValue = ItemUtil.getPropertyData(
            item,
            column.validationReference
          );

          if (relatedValue != undefined) {
            vi.valid = true;
            return true;
          }
          vi.valid = false;
        }
        return true;

        break;
      case ValidationTypes.required:
        const reqRelatedValue = ItemUtil.getPropertyData(
          item,
          column.validationReference
        );
        if (reqRelatedValue != column.requestedValue) return true;
        const reqValue = ItemUtil.getPropertyData(item, column.name);
        if (reqValue != undefined) return true;
        return false;
      case ValidationTypes.WFMaxAttempts:
        const maxAttValue = ItemUtil.getPropertyData(item, 'MaxAttempts');
        if (maxAttValue > 1) {
          if (!item.parameters.contains('WaitBetweenAttempts_readOnly'))
            item.parameters['WaitBetweenAttempts_readOnly'] = {};
          item.parameters['WaitBetweenAttempts_readOnly'] = false;

          let waitColumn = this.columns.find(
            (c) => c.name == 'WaitBetweenAttempts'
          );

          var v = regExLib.regex_GreaterThanZero.test(
            ItemUtil.getPropertyData(item, waitColumn.name)
          );

          var wvi = item.validationInfo.find(
            (vi) => vi.fieldName == 'WaitBetweenAttempts'
          );
          if (!wvi) {
            item.validationInfo.push({
              column: this.columns.find((c) => c.name == 'WaitBetweenAttempts'),
              fieldName: 'WaitBetweenAttempts',
              valid: v,
            });
          } else {
            wvi.valid = v;
          }
        } else if (maxAttValue <= 1) {
          if (!item.parameters.contains('WaitBetweenAttempts_readOnly'))
            item.parameters['WaitBetweenAttempts_readOnly'] = {};
          item.parameters['WaitBetweenAttempts_readOnly'] = true;
          var wvi = item.validationInfo.find(
            (vi) => vi.fieldName == 'WaitBetweenAttempts'
          );
          if (!wvi) {
            item.validationInfo.push({
              column: this.columns.find((c) => c.name == 'WaitBetweenAttempts'),
              fieldName: 'WaitBetweenAttempts',
              valid: true,
            });
          } else {
            wvi.valid = true;
          }
        }

        valid = regExLib.regex_GreaterThanZero.test(
          ItemUtil.getPropertyData(item, column.name)
        );
        break;
      case ValidationTypes.WFWaitBetweenAttempts:
        var maxAttColumn = this.columns.find((c) => c.name == 'MaxAttempts');
        var maxAtt = ItemUtil.getPropertyData(item, maxAttColumn.name);

        if (maxAtt <= 1) {
          if (!item.parameters.contains('WaitBetweenAttempts_readOnly'))
            item.parameters['WaitBetweenAttempts_readOnly'] = {};
          item.parameters['WaitBetweenAttempts_readOnly'] = true;
          valid = true;
        } else {
          if (!item.parameters.contains('WaitBetweenAttempts_readOnly'))
            item.parameters['WaitBetweenAttempts_readOnly'] = {};
          item.parameters['WaitBetweenAttempts_readOnly'] = false;
          var validValue = regExLib.regex_GreaterThanZero.test(
            ItemUtil.getPropertyData(item, 'WaitBetweenAttempts')
          );
          valid = validValue;
        }
        break;
      case ValidationTypes.WFScheduleFrequency:
        //2,3,5,6
        var frequency = ItemUtil.getPropertyData(item, column.name);
        if (frequency == 2 || frequency == 5 || frequency == 6) {
          if (!item.parameters.contains('ScheduleFrequencyValue_readOnly'))
            item.parameters['ScheduleFrequencyValue_readOnly'] = {};
          item.parameters['ScheduleFrequencyValue_readOnly'] = false;

          var frequencyValue = ItemUtil.getPropertyData(
            item,
            'ScheduleFrequencyValue'
          );
          if (
            frequencyValue == null ||
            (frequencyValue != null &&
              !regExLib.regex_GreaterThanZero.test(frequencyValue))
          ) {
            var wvi = item.validationInfo.find(
              (vi) => vi.fieldName == 'ScheduleFrequencyValue'
            );
            if (!wvi) {
              item.validationInfo.push({
                column: this.columns.find(
                  (c) => c.name == 'ScheduleFrequencyValue'
                ),
                fieldName: 'ScheduleFrequencyValue',
                valid: false,
              });
            } else {
              wvi.valid = false;
            }
          }
        } else if (frequency == 1 || frequency == 3) {
          //once or daily
          if (!item.parameters.contains('ScheduleFrequencyValue_readOnly'))
            item.parameters['ScheduleFrequencyValue_readOnly'] = {};
          item.parameters['ScheduleFrequencyValue_readOnly'] = true;
          var wvi = item.validationInfo.find(
            (vi) => vi.fieldName == 'ScheduleFrequencyValue'
          );
          if (!wvi) {
            item.validationInfo.push({
              column: this.columns.find(
                (c) => c.name == 'ScheduleFrequencyValue'
              ),
              fieldName: 'ScheduleFrequencyValue',
              valid: true,
            });
          } else {
            wvi.valid = true;
          }
        } else if (frequency == 4) {
          if (!item.parameters.contains('ScheduleFrequencyValue_readOnly'))
            item.parameters['ScheduleFrequencyValue_readOnly'] = {};
          item.parameters['ScheduleFrequencyValue_readOnly'] = false;

          var frequencyValue = ItemUtil.getPropertyData(
            item,
            'ScheduleFrequencyValue'
          );
          if (frequencyValue != null && frequencyValue.includes(':')) {
            frequencyValue = frequencyValue.split(':')[0];
          }
          if (
            frequencyValue == null ||
            frequencyValue == '' ||
            (frequencyValue != null &&
              !regExLib.regex_GreaterThanZero.test(frequencyValue))
          ) {
            var wvi = item.validationInfo.find(
              (vi) => vi.fieldName == 'ScheduleFrequencyValue'
            );
            if (!wvi) {
              item.validationInfo.push({
                column: this.columns.find(
                  (c) => c.name == 'ScheduleFrequencyValue'
                ),
                fieldName: 'ScheduleFrequencyValue',
                valid: false,
              });
            } else {
              wvi.valid = false;
            }
          }
        }
        break;
      case ValidationTypes.WFScheduleFrequencyValue:
        var frequency = ItemUtil.getPropertyData(item, 'FK_ScheduleFrequency');
        if (frequency == 2 || frequency == 5 || frequency == 6) {
          if (!item.parameters.contains('ScheduleFrequencyValue_readOnly'))
            item.parameters['ScheduleFrequencyValue_readOnly'] = {};
          item.parameters['ScheduleFrequencyValue_readOnly'] = false;

          var frequencyValue = ItemUtil.getPropertyData(
            item,
            'ScheduleFrequencyValue'
          );
          if (
            frequencyValue == null ||
            (frequencyValue != null &&
              !regExLib.regex_GreaterThanZero.test(frequencyValue))
          ) {
            valid = false;
          }
        } else if (frequency == 1 || frequency == 3) {
          //once or daily
          if (!item.parameters.contains('ScheduleFrequencyValue_readOnly'))
            item.parameters['ScheduleFrequencyValue_readOnly'] = {};
          item.parameters['ScheduleFrequencyValue_readOnly'] = true;
          valid = true;
        } else if (frequency == 4) {
          if (!item.parameters.contains('ScheduleFrequencyValue_readOnly'))
            item.parameters['ScheduleFrequencyValue_readOnly'] = {};
          item.parameters['ScheduleFrequencyValue_readOnly'] = false;

          var frequencyValue = ItemUtil.getPropertyData(
            item,
            'ScheduleFrequencyValue'
          );
          if (frequencyValue != null && frequencyValue.includes(':')) {
            frequencyValue = frequencyValue.split(':')[0];
          }

          if (
            frequencyValue == null ||
            frequencyValue == '' ||
            (frequencyValue != null &&
              !regExLib.regex_GreaterThanZero.test(frequencyValue))
          ) {
            valid = false;
          }
        }
        break;
    }

    return valid;
  }
}
