import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, HostListener } from '@angular/core'
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'change-password',
  templateUrl: './changePassword.dialog.html'
})
export class ChangePasswordDialog implements OnInit, OnDestroy {
  //#region -------Properties
  @Input() model;
  @Input() form;
  
  @Output() onApply: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  //#endregion

  //#region -------Constructor
  constructor(public translate: TranslateService) {

  }
  //#endregion

  //#region -------NG Events
  ngOnInit() {
  }

  ngOnDestroy() {
  }
  //#endregion

  //#region -------UI Events
  private apply() {
    this.onApply.emit(this.model)
  }

  private cancel() {
    this.onCancel.emit({ cancel:true })
  }

  onMouseClick(e) {

  }
  //#endregion

  //#region -------Private
  //#endregion
}
