import { Pipe, PipeTransform } from '@angular/core';
import { MenuModel } from '../entity/enum/menuModel';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
    name: 'gstranslate'
})
export class GsTranslatePipe implements PipeTransform {

  constructor( private translate: TranslateService) {

  }
    transform(value: string, ...args: any) {
        let params = {};
        if (args)
            args.forEach((a, i) => {
              params[`arg${i}`] = a;
            })
        return this.translate.instant(value, params);
    }

}
