import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  Optional,
  Injectable,
  ChangeDetectorRef,
} from '@angular/core';
import { GSBaseControl } from './gs.base.control';
import { FormProperty } from '../entity/form/formProperty';
import { Item } from '../entity/entities';
import { formMaskInputProperty } from '../entity/form/formMaskInput.property';

@Component({
  selector: 'gs-mask-input',
  templateUrl: './gs.maskInput.control.html',
})
@Injectable()
export class GSMaskInputControl
  extends GSBaseControl 
  implements OnInit, OnDestroy
{
  //#region -------Properties
  //#endregion

  //#region -------Constructor
  constructor(
    @Optional() prop: formMaskInputProperty,
    @Optional() item: Item,
    private changeDr: ChangeDetectorRef
  ) {
    super(changeDr);
    this.property = prop;
    this.item = item;
  }
  //#endregion

  //#region -------NG Eents
  ngOnInit() {}

  ngOnDestroy() {}
  //#endregion

  //#region -------UI Events
  change(e) {
    if (this.property.isMaskChanged) {
      this.property.isMaskChanged = false;
      return;
    }
    if (e || e == '') {
      let previousValue = this.data[this.name];
      this.value = e;
      this.item.reCheckDirty();
      this.onChange.emit({
        propertyName: this.name,
        previousValue: previousValue,
        newValue: this.value,
        event: e,
        item: this.item,
        property: this.property,
      });
      this.property.changed.emit({
        propertyName: this.name,
        previousValue: previousValue,
        newValue: this.value,
        event: e,
        item: this.item,
        property: this.property,
      });
    }
  }

  keydown(e) {
    this.property.keydown.emit({ propertyName: this.name, event: e });
    var charCode = e.which || e.keyCode;
    //tab
    if (charCode == 9) {
      let previousValue = this.data[this.name];
      this.onChange.emit({
        propertyName: this.name,
        previousValue: previousValue,
        newValue: this.value,
        event: e,
        item: this.item,
        property: this.property,
      });
      this.property.changed.emit({
        propertyName: this.name,
        previousValue: previousValue,
        newValue: this.value,
        event: e,
        item: this.item,
        property: this.property,
      });
    }
  }

  onpaste(e) {
    this.property.onpaste.emit({ propertyName: this.name, event: e });
  }
  //#endregion

  //#region -------Private
  //#endregion
}
