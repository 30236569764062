import { HttpInterceptor } from '@angular/common/http';
import { catchError } from 'rxjs';
import { Notification, NotificationType, Session } from '../entity/entities';

export class ErrorInterceptor implements HttpInterceptor {
  intercept(req, next) {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error.status == 404) {
          let errorNotification: Notification = new Notification(
            error.statusText,
            (typeof error.error == 'string') ? error.error : `Selected resource could not be found.`,
            NotificationType.Warning,
            10000
          );
          Session.notifications.add(errorNotification.id, errorNotification);
        }
        else {
          let errorNotification: Notification = new Notification(
            error.statusText == 'OK' ? '' : error.statusText,
            error.error
              ? error.error.Message
                ? error.error.Message
                : error.error
              : '',
            NotificationType.Error
          );
          Session.notifications.add(errorNotification.id, errorNotification);
        }
        
        throw error;
      })
    );
  }
}
