import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';
import { SecurityClient } from 'src/app/client/security.client';
import { HelpPath } from '../entity/enums';

@Directive({
  selector: '[helper]',
})
export class HelperDireciteve {
  @Input('path') path: HelpPath;
  constructor(
    private securityClient: SecurityClient,
    private renderer: Renderer2,
    private element: ElementRef
  ) {}

  @HostListener('click')
  showHelp() {
    this.securityClient.showHelp('test',null).then((response) => {
      let helpSrc = `data:application/pdf;base64,${response.result}`;
    });
  }
}
