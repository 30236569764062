import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CacheService } from 'ng2-cache';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BaseClient } from '../common/client/base.client';
import { AppConfig } from '../app.config';
import { SvcClients } from '../common/client/SvcClients';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class HubClient extends BaseClient {
  constructor() {
    super();
  }

  public subscribe(request: {
    clientId: string;
    customerCode: number;
    notificationEvent: any;
    requestId?: any;
    expiresInSeconds: number;
  }) {
    //request = $.extend({}, request, { packageId: 0 /*appConfig.packageId*/ });
    return this.post(
      SvcClients.AdminSvc,
      'hub/subscribe',
      request,
      null,
      null,
      true,
      null,
      false,
      request.requestId
    );
  }

  public unsubscribe(request: {
    clientId: string;
    customerCode: number;
    notificationEvent: any;
    requestId?: any;
  }) {
    return this.post(
      SvcClients.AdminSvc,
      'hub/unsubscribe',
      request,
      null,
      null,
      true,
      null,
      false,
      request.requestId
    );
  }

  public unsubscribeAll(request: { clientId: string }) {
    return this.get(
      SvcClients.AdminSvc,
      'hub/unsubscribeAllEvents/' + request.clientId,
      null
    );
  }

  public getNotificationEvents() {
    return this.get(SvcClients.AdminSvc, 'hub/notification-events', null);
  }
}
