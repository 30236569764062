import { FormProperty } from './formProperty';
import { PropertyTypes } from '../enum/propertyTypes';
import { EventEmitter } from '@angular/core';
import { GSRadioButtonControl } from '../../controls/gsControls';

export class FormRadioButtonProperty extends FormProperty {
    type = PropertyTypes.RadioButton;
    options?: any[];   
    changed = new EventEmitter<any>();
    selectedValue: any;
    component = GSRadioButtonControl;
  constructor(name: string, label: string, row?: number, column?: number,
    rowspan?: number, columnspan?: number, validate?: boolean, public vertical?: boolean, public hideNoOptions?: boolean, public noOptionsColor?: string, public noOptionsLabel?: string
    , public overflowY?: string, public maxHeight?: string, public noLeftMargin?: boolean) {
        super(name, label, row , column ,
            rowspan, columnspan, validate);        
    }
}
