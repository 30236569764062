<div class="row form-property">
  <div
    class="col-lg-{{tooltip?11:12}}"
    [ngClass]="{'no-padding-right':tooltip}"
  >
    <span class="p-float-label">
      <input
        phoneMask
        id="{{name}}"
             [item]="item"
             [property]="property"
             [stringItem]="stringItem"
        [(ngModel)]="stringItem.value"
        class="p-filled p-filled"
        [readonly]="readonly"
        [ngClass]="{'disabled':readonly,'property-invalid':notValid}"
        (ngModelChange)="change($event)"
        (keydown)="keydown($event)"
        (paste)="onpaste($event)"
        pInputText
        style="width: 100%"
      />
      <label class="gs-float-label" [ngClass]="{'error-color':notValid}">{{label}}</label>
      <span
        *ngIf="notValid"
        class="form-error"
        pTooltip="{{property.validationMessage}}"
            tooltipPosition="bottom" style="width:25px;height:25px;"
      >
        <i class="fas fa-info-circle fa-2x form-valid-icon error-color"></i>
      </span>
    </span>
  </div>
  <div *ngIf="tooltip" class="col-lg-1 property-tooltip">
    <span pTooltip="{{property.tooltip}}">
      <i class="fas fa-info-circle master-color"></i>
    </span>
  </div>
</div>
