import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  Injector,
  NgModuleRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { CustomerStore } from '../../../@state/customer.store';
import { WorkflowService } from '../../../services/workflow.service';
import { tap, takeUntil, first, switchMap, take } from 'rxjs/operators';
import { Subject, combineLatest, from, of } from 'rxjs';

import { TableBuilder } from 'src/app/common/builder/gs.table.builder';
import { ColumnTypes, TableTypes } from 'src/app/common/entity/enums';
import { FormTypes } from 'src/app/common/entity/enum/formTypes';
import { CustomerBaseComponent } from '../../customerBase.component';
import {
  Item,
  NotificationType,
  Session,
  Notification,
} from 'src/app/common/entity/entities';
import { FormBuilder } from 'src/app/common/builder/gs.form.builder';
import { CustomerClient } from 'src/app/client/customer.client';
import { TranslateService } from '@ngx-translate/core';
import {
  ClientTypes,
  ExecutionStatuses,
  WorkflowExecutionTypes,
} from '../../../../../common/entity/enum/workflowExecutionStatuses';
import {
  Guid,
  addItems,
  scrollTo,
  updateItems,
} from '../../../../../common/utils/utils';
import { FormDropdownProperty } from '../../../../../common/entity/form/formDropdown.property';
import { FormInputSwitchProperty } from '../../../../../common/entity/form/formInputSwitch.property';
import { GSPlaceholder } from '../../../../../common/directives/placeholder';
import { FormDateProperty } from '../../../../../common/entity/form/formDate.property';
import { DatePipe } from '@angular/common';
import { BroadcastService } from '../../../../../common/client/broadcast.service';
import { NotificationEvents } from '../../../../../common/entity/enum/notificationEvents';
import { FormButtonProperty } from '../../../../../common/entity/form/formButton.property';
import { FormProperty } from '../../../../../common/entity/form/formProperty';
import {
  getWorkflowDefinitions,
  handleExecutionConfigColumns,
} from '../workflow.fn';
import { FormTextboxProperty } from '../../../../../common/entity/form/formTextbox.property';
import { TableColumn } from '../../../../../common/entity/table/tableColumn';
import { regExLib } from '../../../../../common/regEx/regExLib';
import { ValidationTypes } from 'src/app/common/entity/enum/validationTypes';
import { TableControl } from 'src/app/common/component/table.control';
import { FormEmailProperty } from 'src/app/common/entity/form/formEmail.property';

export enum WorkflowClientExecutionTypes {
  MANUAL = 2,
  SCHEDULED = 1,
  ADMIN = 4,
  WFINVOKE = 3,
  MANUALGENERIC = 5,
}

@Component({
  selector: 'workflow-definitions',
  templateUrl: './workflow-definitions.component.html',
  styleUrls: ['./workflow-definitions.component.css'],
})
export class WorkflowDefinitionsComponent
  extends CustomerBaseComponent
  implements OnInit
{
  destroy$ = new Subject<void>();
  TableTypes = TableTypes;
  FormTypes = FormTypes;
  showAddDefinition = false;
  showExecuteWorkflow = false;
  showAddExecutionConfiguration = false;
  workflowParameters: WorkflowParameters;
  taskParamItem: Item;

  executionProperties = [];
  selectedExecConfiguration: any;
  newWfDefinitionItem;
  definitionDetailsProperties: FormProperty[] = [];

  @ViewChildren(GSPlaceholder)
  public placeholders: QueryList<GSPlaceholder>;
  @ViewChild('wfTable') wfTable: TableControl;

  workflowTaskProperties: Record<string, any> = {};

  showDeleteWFDefinition: boolean = false;
  showDeleteWFExecConfig: boolean = false;
  get addDefinitionTitle() {
    if (this.newWfDefinitionItem != null) return 'Add Workflow definition';
    else if (this.customerStore.selectedWFDefinition$ != null)
      return 'Edit Workflow definition';
  }

  get executeWorkflowTitle() {
    return 'Execute Admin Workflow definition';
  }

  get runWorkflowTitle() {
    return 'Run workflow';
  }

  get executionConfigTitle() {
    return 'Add execution config';
  }

  get wizardItem() {
    return this.selected.items.get('pdap_integration_customer_workflows');
  }

  get wfDefinitions() {
    return this.wizardItem?.items.get('wfDefinitions');
  }

  get selectedWFDefinition() {
    return this.wfDefinitions?.items.selected;
  }

  get processingButton(): FormButtonProperty {
    return this.execColumns?.find((p) => p.name == 'Processing');
  }

  get clientTypeProperty(): FormDropdownProperty {
    return this.definitionDetailsProperties?.find(
      (p) => p.name == 'ClientType'
    ) as FormDropdownProperty;
  }

  get functionTypeProperty(): FormDropdownProperty {
    return this.definitionDetailsProperties?.find(
      (p) => p.name == 'FunctionType'
    ) as FormDropdownProperty;
  }

  get descriptionProperty(): FormTextboxProperty {
    return this.definitionDetailsProperties?.find(
      (p) => p.name == 'Description'
    ) as FormTextboxProperty;
  }

  get activeProperty(): FormInputSwitchProperty {
    return this.definitionDetailsProperties?.find(
      (p) => p.name == 'Active'
    ) as FormInputSwitchProperty;
  }

  get localStorageKey_expandItems() {
    return `${this.customerStore.selected.Code}_expandedRowKeys`;
  }

  get localStorage_expandItems() {
    return localStorage.getItem(this.localStorageKey_expandItems)
      ? JSON.parse(localStorage.getItem(this.localStorageKey_expandItems))
      : [];
  }

  get pageSize() {
    return this.wizardItem?.parameters['pageSize'] || 10;
  }

  expandedRowKeys;

  execColumns = [];

  constructor(
    public customerStore: CustomerStore,
    private workflowService: WorkflowService,
    private customerClient: CustomerClient,
    private translate: TranslateService,
    private resolver: ComponentFactoryResolver,
    private datepipe: DatePipe,
    private broadcastService: BroadcastService
  ) {
    super();
    this.definitionDetailsProperties = FormBuilder.build(
      FormTypes.Workflow_Definitions_Details,
      this.translate
    );
    this.execColumns = TableBuilder.build(
      TableTypes.Workflow_Definitions_Configuration
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.taskParamItem = new Item(null, {});

    this.setDisableParameterRef('FK_WorkflowClientExecutionType');
    this.setDisableParameterRef('FK_TimeZone');
    this.setDisableParameterRef('FK_ScheduleFrequency');
    this.setDisableParameterRef('MaxAttempts');
    this.setDisableParameterRef('WaitBetweenAttempts');
    this.setDisableParameterRef('ScheduleFrequencyValue');
    this.setDisableParameterRef('FunctionType');
    this.setDisableParameterRef('Active');
    const functionTypeCol = this.execColumns.find(
      (x) => x.name == 'FunctionType'
    );
    functionTypeCol.options = this.getFunctionTypeOptions();

    setTimeout(() => {
      this.handleOnSetWizardItem();
      this.handleOnClearWizardItem();
    }, 100);

    this.menuItem?.items.onSelected.subscribe((item) => {
      if (this.wizardItem) this.loadWFDefinitions();
      else
        item.onSetItem$.pipe().subscribe((i) => {
          if (i?.key == 'pdap_integration_customer_workflows') {
            setTimeout(() => {
              this.loadWFDefinitions();
            }, 100);
            this.handleOnSetWizardItem();
            this.handleOnClearWizardItem();
          }
        });
    }, this);

    this.executionProperties = FormBuilder.build(
      FormTypes.Workflow_Definitions_Execution_Configuration,
      this.translate
    );

    this.functionTypeProperty.options = this.getFunctionTypeOptions();

    this.setOptions();
    if (this.customerStore?.selected?.Code) {
      this.loadWFDefinitions();
    } else if (this.customerStore.ids.length) {
      this.customerStore.selectCustomer(this.customerStore.ids[0]);
      this.loadWFDefinitions();
    } else if (this.menuItem?.items?.selected?.code) {
      this.customerStore.selectCustomer(this.customerStore.ids[0]);
      this.loadWFDefinitions();
    }
  }

  handleOnClearWizardItem() {
    this.wizardItem.items.onClearItems$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.expandedRowKeys = undefined;
      });
  }

  handleOnSetWizardItem() {
    this.wizardItem.onSetItem$
      .pipe(
        tap((i: Item) => {
          if (i?.key == 'wfDefinitions') {
            this.expandedRowKeys = {};
            i.onSetItem$
              .pipe(
                tap((wfItem: Item) => {
                  if (wfItem) {
                    if (!wfItem?.parameters.contains('column'))
                      wfItem.parameters['column'] = {};
                    if (!wfItem?.parameters.column['Delete'])
                      wfItem.parameters.column['Delete'] = {};

                    wfItem.parameters.column['Delete'].class =
                      'background-color-erorr';

                    if (
                      wfItem?.items
                        .get('Configurations')
                        ?.items.values.find(
                          (x) =>
                            x.data.FK_WorkflowClientExecutionType ==
                            WorkflowClientExecutionTypes.MANUALGENERIC
                        ) != undefined
                    )
                      wfItem.parameters.column['Delete'].visible = false;
                    else wfItem.parameters.column['Delete'].visible = true;
                  }
                  if (
                    wfItem?.data?.Configurations?.find(
                      (c) =>
                        c.FK_WorkflowClientExecutionType ==
                        WorkflowClientExecutionTypes.MANUALGENERIC
                    ) !== undefined
                  ) {
                    wfItem.parameters['workflowDef_active'] = true;
                    wfItem.parameters['workflowDef_details'] = true;
                    wfItem.parameters['acceptChanges'] = true;
                    wfItem.parameters['column'] = { Code: { hide: true } };
                    if (!wfItem?.parameters.contains('column'))
                      wfItem.parameters['column'] = {};
                    if (!wfItem?.parameters.column['Delete'])
                      wfItem.parameters.column['Delete'] = {};
                    wfItem.parameters.column['Delete'].visible = false;
                    wfItem.readonly = true;
                  }
                  if (wfItem && this.localStorage_expandItems?.length) {
                    const index = this.localStorage_expandItems.indexOf(
                      wfItem.data.PK_ID.toString()
                    );
                    if (index !== -1) this.addExpandedKey(wfItem);
                  }
                  if (wfItem && wfItem.data?.Expanded)
                    this.addExpandedKey(wfItem);
                  if (
                    localStorage.getItem(
                      `${this.customerStore.selected.Code}_WF_currentPage`
                    )
                  )
                    this.wfTable.navigateToPage(
                      +localStorage.getItem(
                        `${this.customerStore.selected.Code}_WF_currentPage`
                      )
                    );
                  this.focusLastEditedItem(wfItem);
                }),
                takeUntil(this.destroy$)
              )
              .subscribe();
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  loadWFDefinitions() {
    const data = this.wizardItem.data;
    if (data && !this.wfDefinitions?.items?.values?.length)
      getWorkflowDefinitions(
        this.selected.code,
        this.workflowService,
        this.customerStore,
        this.wizardItem
      );
  }

  getFunctionTypeOptions() {
    return [
      {
        label: 'Select option',
        value: null,
      },
      {
        label: 'PAYROLL EXPORT',
        value: 'PAYROLL_EXPORT',
      },
      {
        label: 'SYNC',
        value: 'SYNC',
      },
      {
        label: 'CLOSE DATE',
        value: 'CLOSE_DATE',
      },
      {
        label: 'LOAD CARD',
        value: 'LOAD_CARD',
      },
    ];
  }

  setDisableParameterRef(colName) {
    const execTypeCol = this.execColumns.find((c) => c.name === colName);
    execTypeCol.disabledParameterReference = `${colName}_readOnly`;
  }

  setOptions() {
    const executionTypes$ =
      this.workflowService.GetWorkflowClientExecutionType();
    const scheduleFrequencyTypes$ =
      this.workflowService.GetScheduleFrequencyType();
    const timeZone$ = from(this.customerClient.getTimeZones());
    combineLatest([executionTypes$, scheduleFrequencyTypes$, timeZone$])
      .pipe(
        tap(([execTypes, scheduledTypes, timeZone]) => {
          this.handleOptions(
            this.execColumns,
            'FK_WorkflowClientExecutionType',
            execTypes.result,
            'Code'
          );
          this.handleOptions(
            this.execColumns,
            'FK_ScheduleFrequency',
            scheduledTypes.result,
            'Name'
          );
          this.handleOptions(
            this.execColumns,
            'FK_TimeZone',
            timeZone.result,
            'DisplayName',
            'ID'
          );
          this.handleOptions(
            this.executionProperties,
            'TimeZone',
            timeZone.result,
            'DisplayName',
            'ID'
          );
        })
      )
      .subscribe();
  }

  handleOptions(source, colName, data, label, value = 'PK_ID') {
    const col = source.find((x) => x.name == colName);
    if (!col || !data?.length) return;

    col.options = [
      ...[{ value: null, label: 'Please Select Options', isDisabled: false }],
      ...data.map((x) => {
        return {
          value: x[value],
          label: x[label],
          data: x,
          isDisabled:
            x[label] == 'WFInvoke' || x[label] == 'ManualGeneric'
              ? true
              : false,
        };
      }),
    ];
  }

  override loadData(item): void {
    if (!this.item) this.selected.setItem(null, this.itemName);
  }

  rowAction(e) {
    if (e.columnName === 'Details') {
      e.item.select();
      this.customerStore.selectWorkflowDefinition(e.row);
      this.showAddDefinition = true;
      this.clientTypeProperty.readonly = true;
      this.handelDetailsProps(false);
    } else if (e.columnName == 'Delete') {
      e.item.select();
      this.customerStore.selectWorkflowDefinition(e.row);
      //this.selectedWFDefinition.data['Deleted'] = true;
      this.showDeleteWFDefinition = true;
    }

    if (e.action === 'expand') {
      this.selectWfDefinition(e.item);
      this.handleExpandItems(e.item.data, e.action);
      this.customerStore.selectWorkflowDefinition(e.row);
      const customerCode = this.customerStore.selected.Code;
      if (e.row.PK_ID < 0) {
        if (!e.row.Tasks) {
          this.customerStore.setCustomerWorkflowTasks(
            [],
            e.row.PK_ID,
            customerCode
          );
        }
        this.handleConfigurationActions(e.item);
      } else if (e.row.PK_ID > 0) {
        if (!e.row.Tasks?.length) {
          let manualGenericCfg = e.row?.Configurations?.find(
            (c) =>
              c.FK_WorkflowClientExecutionType ==
              WorkflowClientExecutionTypes.MANUALGENERIC
          );
          let isManualGeneric = manualGenericCfg != undefined;
          let gsFunctionType = manualGenericCfg?.FunctionType ?? '';

          this.workflowService
            .getWorkflowDefinitionTasks(
              e.row.PK_ID,
              customerCode,
              isManualGeneric,
              gsFunctionType
            )
            .subscribe((data) => {
              e.item.parameters['acceptChanges'] = true;
              e.item.data = { ...e.item.data, ...{ Tasks: data.result } };
              const wfDefinitionItem =
                this.wizardItem.items.get('wfDefinitions');
              const index = wfDefinitionItem.items.values.indexOf(e.item);
              const wfDefinitions = this.wizardItem.data.wfDefinitions;
              wfDefinitions.splice(index, 1, e.item.data);
              //update original data for wf definitions item
              wfDefinitionItem.data = wfDefinitions;
              if (!wfDefinitionItem.isDirty) wfDefinitionItem.acceptChanges();
              //update original data for wizard item
              this.wizardItem.data = {
                ...this.wizardItem.data,
                ...{
                  wfDefinitions,
                },
              };

              this.customerStore.setCustomerWorkflowTasks(
                data.result,
                e.row.PK_ID,
                customerCode
              );
              if (!this.wizardItem.isDirty) this.wizardItem.acceptChanges();
              if (!this.wfDefinitions.isDirty)
                this.wfDefinitions.acceptChanges();

              this.handleConfigurationActions(e.item);
            });
        } else this.handleConfigurationActions(e.item);
      }
    } else if (e.action === 'collapse') {
      this.handleExpandItems(e.row, e.action);
    }
  }

  handleConfigurationActions(item: Item) {
    item.items.values
      .find((i) => i.key == 'Configurations')
      .items.values.forEach((cfg: Item) => {
        cfg.parameters['FK_WorkflowClientExecutionType_readOnly'] =
          cfg.data.PK_ID > 0;
        handleExecutionConfigColumns(
          cfg,
          cfg.data.FK_WorkflowClientExecutionType,
          item
        );

        cfg.parameters['column'] = {
          Run: {
            name: 'Run',
            description: 'Run',
            visible:
              cfg.data.FK_WorkflowClientExecutionType ==
                WorkflowExecutionTypes.Admin && cfg.data.Active,
            disabled: this.customerStore.selectedWFDefinition.Tasks.length == 0,
            icon: 'fas fa-play-circle',
            type: ColumnTypes.Button,
          },
          Processing: {
            name: 'Processing',
            description: '',
            visible: false,
            icon: 'fa fa-spinner fa-spin',
            type: ColumnTypes.Button,
          },
          Active: {
            name: 'Active',
            description: 'Active',
            disabled:
              cfg.data.FK_WorkflowClientExecutionType ==
              WorkflowExecutionTypes.ManualGeneric,
            type: ColumnTypes.BoolSwitch,
          },
        };

        if (!cfg.parameters.contains('column')) cfg.parameters['column'] = {};
        if (!cfg.parameters.column['Delete'])
          cfg.parameters.column['Delete'] = {};

        cfg.parameters.column['Delete'].class = 'background-color-erorr';

        if (
          cfg.data.FK_WorkflowClientExecutionType ==
          WorkflowExecutionTypes.ManualGeneric
        )
          cfg.parameters.column['Delete'].visible = false;
        else cfg.parameters.column['Delete'].visible = true;

        if (
          cfg.data.FK_WorkflowClientExecutionType ==
          WorkflowExecutionTypes.ManualGeneric
        ) {
          cfg.acceptChanges();
          cfg.readonly = true;

          this.wizardItem.reCheckDirty();
          if (!this.wizardItem.isDirty) this.wizardItem.acceptChanges();
        }
      });
  }

  handelDetailsProps(visible) {
    this.definitionDetailsProperties
      .filter((x) => ['hasAdmin', 'hasManual'].includes(x.name))
      .forEach((prop) => (prop.visible = visible));
  }

  change(e) {
    if (e.columnName === 'Active') {
      this.customerStore.selectWorkflowDefinition(e.row);
      this.customerStore.updateCustomerWFDefinition(e.row);
      this.wizardItem.reCheckDirty();
    }
  }

  add(e) {
    this.newWfDefinitionItem = new Item(null, { ClientType: undefined });
    this.showAddDefinition = true;
    this.handelDetailsProps(true);
    this.clientTypeProperty.readonly = false;
  }

  applyDefinition(e) {
    if (!e?.item) return;
    if (e?.item.data.PK_ID === undefined) {
      const colId = +localStorage.getItem('wfDefinitionId') - 1;
      localStorage.setItem('wfDefinitionId', colId.toString());
      const data = { ...e.item.data, ...{ PK_ID: colId, Tasks: [] } };
      this.customerStore.addCustomerWFDefinition(data);
      this.customerStore.selectWorkflowDefinition(data);
      if (e.item.data.hasAdmin) {
        const execId = this.generateId('executionConfigId');
        const data = {
          PK_ID: execId,
          MaxAttempts: 1,
          FK_WorkflowClientExecutionType: WorkflowClientExecutionTypes.ADMIN,
          FK_TimeZone: this.getClientTimeZone(e.item),
          Active: true,
        };
        this.customerStore.addCustomerWFDefinitionExecutionConfig(data);
      }
      if (e.item.data.hasManual) {
        const data = {
          PK_ID: this.generateId('executionConfigId'),
          MaxAttempts: 1,
          FK_WorkflowClientExecutionType: WorkflowClientExecutionTypes.MANUAL,
          FK_TimeZone: this.getClientTimeZone(e.item),
          FunctionType: e.item.data.FunctionType,
        };
        this.customerStore.addCustomerWFDefinitionExecutionConfig(data);
      }
      setTimeout(() => {
        const execItem = this.wfDefinitions.items.values.find(
          (i) => i.data.PK_ID == colId
        );
        const confItem = execItem.items.get('Configurations');
        confItem?.onSetItem$
          .pipe(
            tap((i: Item) => {
              confItem.items.values.forEach((child) => {
                handleExecutionConfigColumns(
                  child,
                  child.data.FK_WorkflowClientExecutionType,
                  execItem
                );
              });
            }),
            takeUntil(this.destroy$)
          )
          .subscribe();
      }, 100);
    } else {
      this.customerStore.updateCustomerWFDefinition(e.item.data, true);
      this.customerStore.resetWorkflowDefinition();
    }
    this.updateExpandItems(e.item.original?.PK_ID, e.item.data?.PK_ID);
    delete this.newWfDefinitionItem;
    this.showAddDefinition = false;
    this.functionTypeProperty.visible = this.functionTypeProperty.validate =
      false;
    this.wizardItem.reCheckDirty();
  }

  closeDefinition(e) {
    this.customerStore.resetWorkflowDefinition();
    delete this.newWfDefinitionItem;
  }

  executeWorkflowDefinition(e) {
    this.workflowParameters.workflowTaskParameters =
      new Array<WorkflowTaskParameters>();

    e.item.items.values.forEach((item) => {
      let taskParams = new WorkflowTaskParameters();
      taskParams.taskSequence = item.parameters.workflowTaskSequence;

      taskParams.parameters = item.data;

      // override for date properties
      if (
        item.data[
          `${item.parameters.workflowTaskSequence}_BusinessDate_wf_date`
        ]
      ) {
        taskParams.parameters['BusinessDate'] =
          item.data[
            `${item.parameters.workflowTaskSequence}_BusinessDate_wf_date`
          ];
      }

      if (
        item.data[
          `${item.parameters.workflowTaskSequence}_BusinessDateTo_wf_date`
        ]
      ) {
        taskParams.parameters['BusinessDateTo'] =
          item.data[
            `${item.parameters.workflowTaskSequence}_BusinessDateTo_wf_date`
          ];
      }

      if (
        taskParams.parameters.hasOwnProperty(
          `${item.parameters.workflowTaskSequence}_BusinessDate_wf_date`
        )
      )
        delete taskParams.parameters[
          `${item.parameters.workflowTaskSequence}_BusinessDate_wf_date`
        ];

      if (
        taskParams.parameters.hasOwnProperty(
          `${item.parameters.workflowTaskSequence}_BusinessDateTo_wf_date`
        )
      )
        delete taskParams.parameters[
          `${item.parameters.workflowTaskSequence}_BusinessDateTo_wf_date`
        ];

      Object.entries(item.data)
        .filter(
          ([k, v]) =>
            !k.endsWith('_wf_date') &&
            k.startsWith(`${item.parameters.workflowTaskSequence}_`)
        )
        .forEach((property) => {
          let propertyName = property[0];
          let propertyValue = property[1];
          let originalPropertyName = propertyName.replace(
            `${item.parameters.workflowTaskSequence}_`,
            ''
          );
          if (taskParams.parameters.hasOwnProperty(originalPropertyName)) {
            taskParams.parameters[originalPropertyName] = propertyValue;
            delete taskParams.parameters[propertyName];
          }
        });

      if (
        item.data.DeletePrevious !== undefined ||
        item.data.Generate !== undefined ||
        item.data.Send !== undefined
      ) {
        taskParams.parameters['Actions'] = {
          DeletePrevious: item.data.DeletePrevious ?? false,
          Generate: item.data.Generate ?? false,
          Send: item.data.Send ?? false,
        };

        if (taskParams.parameters.hasOwnProperty('DeletePrevious'))
          delete taskParams.parameters.DeletePrevious;

        if (taskParams.parameters.hasOwnProperty('Generate'))
          delete taskParams.parameters.Generate;

        if (taskParams.parameters.hasOwnProperty('Send'))
          delete taskParams.parameters.Send;
      }

      if (
        item.data.LoadCommission !== undefined ||
        item.data.LoadTips !== undefined ||
        item.data.LoadWages !== undefined
      ) {
        taskParams.parameters['LoadCategory'] = {
          LoadTips: item.data.LoadTips ?? false,
          LoadWages: item.data.LoadWages ?? false,
          LoadCommission: item.data.LoadCommission ?? false,
        };

        if (taskParams.parameters.hasOwnProperty('LoadTips'))
          delete taskParams.parameters.LoadTips;

        if (taskParams.parameters.hasOwnProperty('LoadWages'))
          delete taskParams.parameters.LoadWages;

        if (taskParams.parameters.hasOwnProperty('LoadCommission'))
          delete taskParams.parameters.LoadCommission;
      }

      this.workflowParameters.workflowTaskParameters.push(taskParams);
    });

    this.subscribe(
      this.workflowParameters.processID,
      this.workflowParameters.clientCode
    );

    this.workflowService
      .executeWorkflow(this.workflowParameters)
      .subscribe(() => {
        this.selectedExecConfiguration.item.parameters.column.Run.disabled =
          true;
        this.selectedExecConfiguration.item.parameters.column.Processing.visible =
          true;
        this.workflowParameters = new WorkflowParameters();
        this.taskParamItem = new Item(null, {});
      });

    this.showExecuteWorkflow = false;
  }

  closeExecuteWorkflowDefinition(e) {
    this.taskParamItem = new Item(null, {});
    this.customerStore.resetWorkflowDefinition();
  }

  configAction(e, parent) {
    if (e.columnName === 'Details') {
      this.customerStore.selectWorkflowDefinition(parent.data);
      this.customerStore.selectWorkflowDefinitionExecutionConfiguration(e.row);
      this.showAddExecutionConfiguration = true;
    } else if (e.columnName === 'Run') {
      this.selectedExecConfiguration = e;
      this.workflowParameters = new WorkflowParameters();
      this.workflowParameters.workflowClientExecutionCfgID = e.row.PK_ID;
      this.workflowParameters.executionType = WorkflowExecutionTypes.Admin;
      this.workflowParameters.gSFunctionType = e.row.FunctionType;
      this.workflowParameters.gSClientType =
        parent.data.ClientType == 'Customer' ? 2 : 1;
      this.workflowParameters.processID = Guid.newGuid().toString();
      this.workflowParameters.userFullName = Session.userName;
      this.workflowParameters.userCode = Session.userCode;
      this.workflowParameters.clientCode =
        parent.data.ClientType == 'Customer'
          ? this.customerStore.selected.Code
          : this.selected.data.CompanyGroup.Code;

      let taskParams: Record<string, any> = {};
      this.showExecuteWorkflow = true;

      this.customerStore.activeWF.definitions
        .find(
          (d) =>
            d.PK_ID == parent.data.PK_ID &&
            d.Description == parent.data.Description
        )
        .Tasks.filter((t) => t.Active == true)
        .forEach((task) => {
          let taskItem = new Item(null, {});
          taskItem.parameters['workflowTaskSequence'] = task.Sequence;
          this.taskParamItem.items.add(
            task.Sequence + ' ' + task.WorkflowTask.Title,
            taskItem
          );

          taskParams[task.Sequence + ' ' + task.WorkflowTask.Title] =
            this.mapParameters(
              task.WorkflowTask.Params,
              this.taskParamItem.items.get(
                task.Sequence + ' ' + task.WorkflowTask.Title
              ),
              task
            );
        });

      this.workflowTaskProperties = taskParams;
      this.showExecuteWorkflow = true;

      setTimeout(() => {
        this.renderProperties(this.workflowTaskProperties);
      }, 100);
    } else if (e.columnName == 'Delete') {
      this.selectedExecConfiguration = e;
      this.customerStore.selectWorkflowDefinition(parent.data);
      this.customerStore.selectWorkflowDefinitionExecutionConfiguration(e.row);
      this.showDeleteWFExecConfig = true;
    }
  }

  configChange(e, parent) {
    if (
      e.columnName == 'FK_WorkflowClientExecutionType' &&
      [WorkflowExecutionTypes.Admin, WorkflowExecutionTypes.Manual].includes(
        e.originalEvent
      )
    )
      this.validateMaxAdminAndManualExecutions();
    this.customerStore.selectWorkflowDefinition(parent.data);
    this.customerStore.selectWorkflowDefinitionExecutionConfiguration(e.row);
    this.customerStore.updateCustomerWFDefinitionExecutionConfig(e.row, true);
    if (e.columnName == 'FK_WorkflowClientExecutionType') {
      handleExecutionConfigColumns(e.item, e.originalEvent, parent);
    } else if (e.columnName == 'FK_ScheduleFrequency') {
      e.item.data['ScheduleFrequencyValue'] = null;
      e.item.reValidate();
    } else if (e.columnName == 'MaxAttempts') {
      if (e.item.data['MaxAttempts'] == 1) {
        e.item.data['WaitBetweenAttempts'] = null;
        e.item.reValidate();
      }
    }

    if (
      e.item.data.FK_WorkflowClientExecutionType ==
      WorkflowExecutionTypes.ManualGeneric
    ) {
      e.item.acceptChanges();
      e.item.readonly = true;
    }
    this.wizardItem.reCheckDirty();
  }

  validateMaxAdminAndManualExecutions() {}

  configAdd(e, parent) {
    this.customerStore.selectWorkflowDefinition(parent.data);
    if (
      !parent.data.Configurations?.find(
        (c) =>
          c.FK_WorkflowClientExecutionType ==
          WorkflowClientExecutionTypes.MANUALGENERIC
      )
    ) {
      const colId = this.generateId('executionConfigId');
      const data = {
        PK_ID: colId,
        MaxAttempts: 1,
        FK_TimeZone: this.getClientTimeZone(parent),
        FK_WorkflowClientExecutionType: null,
      };
      this.customerStore.addCustomerWFDefinitionExecutionConfig(data);

      setTimeout(() => {
        this.handleConfigurationActions(parent);
      }, 10);
    }
  }

  getClientTimeZone(item) {
    return item.data.ClientType == ClientTypes.Location
      ? this.customerStore.selected?.TimeZone.ID
      : undefined;
  }

  generateId(name) {
    const colId = +localStorage.getItem(name) - 1;
    localStorage.setItem(name, colId.toString());
    return colId;
  }

  closeExecutionCfg(e) {
    this.showAddExecutionConfiguration = false;
    let taskParams: Record<string, any> = {};
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  mapParameters(params, item: Item, task) {
    const parameters = [];
    this.constructParams(params, parameters, item, task);
    return parameters;
  }

  constructParams(params, parameters, item, task) {
    params.forEach((param, i) => {
      item.data[param.Key] = param.Value;
      const mandatory = param.Meta.Mandatory;
      const validateAllowNull = !mandatory;
      const validationType = mandatory ? ValidationTypes.required : undefined;
      switch (param.Meta.DataType) {
        case 'dayreference':
          const typePlaceholder = param.Value.includes(':')
            ? param.Value.split(':')[0]
            : '';
          const valuePlaceholder = param.Value.includes(':')
            ? param.Value.split(':')[1]
            : '';

          const ddDate = new FormDateProperty(
            `${task.Sequence}_${param.Key}_wf_date`,
            param.Meta.Title,
            true
          );
          ddDate.validateAllowNull = validateAllowNull;
          ddDate.validate = mandatory;
          ddDate.validationType = validationType;
          item.data[`${task.Sequence}_${param.Key}_wf_date`] =
            this.calculateRelativeDate(typePlaceholder, valuePlaceholder);
          parameters.push(ddDate);

          break;
        case 'Group':
          this.constructParams(param.Children, parameters, item, task);
          break;
        case 'boolean': //boolean
          const pi = new FormInputSwitchProperty(
            `${task.Sequence}_${param.Key}`,
            param.Meta.Title,
            1,
            0,
            null,
            4
          );
          pi.validate = mandatory;
          pi.validationType = validationType;
          parameters.push(pi);
          item.data[`${task.Sequence}_${param.Key}`] = param.Value;
          break;
        case 'CollectionSingle': //collection
          const pd = new FormDropdownProperty(
            `${task.Sequence}_${param.Key}`,
            param.Meta.Title,
            [],
            1,
            0,
            4
          );
          pd.options = [
            ...[{ value: '', label: 'Select option' }],
            ...param.SourceList.map((x) => {
              return { value: x.Value, label: x.Label };
            }),
          ];
          parameters.push(pd);
          pd.validateAllowNull = validateAllowNull;
          pd.validate = mandatory;
          pd.validationType = validationType;
          item.data[`${task.Sequence}_${param.Key}`] = param.Value;
          break;
        case 'int':
        case 'string':
        case 'stringarray':
          const tbProp = new FormTextboxProperty(
            `${task.Sequence}_${param.Key}`,
            param.Meta.Title,
            i + 1,
            0,
            null,
            4,
            false
          );

          tbProp.validate = mandatory;
          tbProp.validationType = validationType;
          parameters.push(tbProp);
          item.data[`${task.Sequence}_${param.Key}`] = param.Value;
          break;
        case 'emaillist':
          const pEmail = new FormEmailProperty(
            `${task.Sequence}_${param.Key}`,
            param.Meta.Title
          );

          pEmail.validate = mandatory;
          pEmail.validationType = validationType;
          parameters.push(pEmail);
          item.data[`${task.Sequence}_${param.Key}`] = param.Value;
          break;
      }
    });
    parameters.forEach((p) => {
      p.changed.subscribe(this.propChange);
    });
    return parameters;
  }

  calculateRelativeDate(typePlaceholder: any, valuePlaceholder: any): any {
    const now = new Date();

    if (!typePlaceholder || !valuePlaceholder)
      return this.datepipe.transform(now, 'yyyy-MM-dd');

    switch (typePlaceholder) {
      case 'R':
        return this.datepipe.transform(
          now.setDate(now.getDate() + parseInt(valuePlaceholder)),
          'yyyy-MM-dd'
        );
      case 'W':
        return this.datepipe.transform(
          this.getDayOfWeek(now, valuePlaceholder),
          'yyyy-MM-dd'
        );
      case 'M':
        return this.datepipe.transform(
          new Date(
            now.getFullYear(),
            now.getMonth(),
            parseInt(valuePlaceholder)
          ),
          'yyyy-MM-dd'
        );
      default:
        return this.datepipe.transform(now, 'yyyy-MM-dd');
    }
  }

  getDayOfWeek(date: Date, value: string): Date {
    const dayOfWeek = parseInt(value, 10);
    if (isNaN(dayOfWeek)) {
      throw new Error(`Incorrect number provided: ${value}`);
    }
    if (dayOfWeek < 0 || dayOfWeek > 7) {
      throw new Error(`Incorrect day of week value provided: ${dayOfWeek}`);
    }
    // for sunday
    let correctedDayOfWeek = dayOfWeek === 7 ? 0 : dayOfWeek;
    return this.startOfWeek(date, correctedDayOfWeek);
  }

  startOfWeek(dt: Date, startOfWeek: number): Date {
    const currentDayOfWeek = dt.getDay();
    let diff = (7 + (currentDayOfWeek - startOfWeek)) % 7;
    return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() - diff);
  }

  propChange = (val) => {
    const taskPrefix = val.propertyName.substring(
      0,
      val.propertyName.indexOf('_') + 1
    );

    const loadCategories = [
      taskPrefix + 'LoadTips',
      taskPrefix + 'LoadWages',
      taskPrefix + 'LoadCommission',
    ];
    if (loadCategories.indexOf(val.propertyName) > -1 && val.newValue == true) {
      loadCategories
        .filter((c) => c != val.propertyName)
        .forEach((loadCategoryName) => {
          val.item.data[loadCategoryName] = false;
        });
    }
    this.taskParamItem?.items?.values?.forEach((i) => {
      i.reValidate();
    });
  };

  private subscribe(requestId: string, customerCode: number) {
    this.broadcastService
      .subscribe({
        customerCode,
        event: NotificationEvents.WorkflowExecution_Finished,
        requestId,
      })
      .pipe(first())
      .subscribe((data) => {
        if (data?.ExecutionStatus == ExecutionStatuses.Warning) {
          let notification: Notification = new Notification(
            this.translate.instant(
              'general.warning'
            ),
            data.Message,
            NotificationType.Warning,
            3000
          );
          Session.notifications.add(notification.id, notification);
        }
        else if (data.Failed) {
          let notification: Notification = new Notification(
            this.translate.instant(
              'dashboard.bulkApproveAndClose.errorOccurred'
            ),
            data.Message,
            NotificationType.Error,
            3000
          );
          Session.notifications.add(notification.id, notification);
        } else {
          let notification: Notification = new Notification(
            this.translate.instant('general.finished'),
            data.Message,
            NotificationType.Success,
            3000
          );
          Session.notifications.add(notification.id, notification);
        }
        this.selectedExecConfiguration.item.parameters.column.Run.disabled =
          false;
        this.selectedExecConfiguration.item.parameters.column.Processing.visible =
          false;
        this.selectedExecConfiguration = null;

        this.unsubscribe(data);
      });
  }

  broadCastOffWithMessage(notificationEvent, message) {
    let requestIds = message.RequestIds;
    if (requestIds)
      requestIds.forEach((requestId) => {
        this.broadcastService.unsubscribe({
          event: notificationEvent,
          requestId,
        });
      });
    else
      this.broadcastService.unsubscribe({
        event: notificationEvent,
      });
  }

  private unsubscribe(message: any) {
    this.broadCastOffWithMessage(
      NotificationEvents.WorkflowExecution_Finished,
      message
    );
  }

  private renderProperties(tasksWithProperties: Record<string, any>) {
    for (var taskName in tasksWithProperties) {
      var properties = tasksWithProperties[taskName];

      properties?.forEach((prop, i) => {
        var placeholder = this.placeholders
          .toArray()
          .find((pc) => pc.reference == prop.name);

        if (placeholder) {
          let inputProviders = [
            prop.component,
            { provide: 'property', useFactory: () => prop },
            { provide: 'textboxProperty', useFactory: () => prop.tb },
            { provide: 'dropdownProperty', useFactory: () => prop.dd },
            {
              provide: 'item',
              useFactory: () => this.taskParamItem.items.get(taskName),
            },
          ];

          let injector = Injector.create(inputProviders);
          let factory = this.resolver.resolveComponentFactory(prop.component);
          let component;
          if (placeholder.viewContainerRef.injector) {
            component = factory.create(
              injector,
              null,
              null,
              placeholder.viewContainerRef.injector.get(NgModuleRef)
            );
          } else {
            component = factory.create(injector, null, null);
          }

          placeholder.viewContainerRef.clear();
          placeholder.viewContainerRef.insert(component.hostView, 0);
          if (component.instance) {
            component.instance.property = prop;
            component.instance.item = this.taskParamItem.items.get(taskName);
            if (prop.numberProperty)
              component.instance.numberProperty = prop.numberProperty;
            if (prop.ddProperty)
              component.instance.dropdownProperty = prop.ddProperty;
            if (prop.ddProperty2)
              component.instance.dropdownProperty2 = prop.ddProperty2;
          }
        }
      });
    }
  }

  deleteDefinition(e) {
    this.selectedWFDefinition.data['Deleted'] = true;
    this.selectedWFDefinition.data['Active'] = false;
    this.customerStore.updateCustomerWFDefinition(
      this.selectedWFDefinition.data,
      true
    );
    this.showDeleteWFDefinition = false;
    //this.selectedWFDefinition.readonly = true;
    this.selectedWFDefinition.parameters['Active_readOnly'] = true;
    var cfgItem = this.selectedWFDefinition.items.get('Configurations');
    cfgItem?.items.values.forEach((x) => {
      x.parameters['FK_WorkflowClientExecutionType_readOnly'] = true;
      x.parameters['FunctionType_readOnly'] = true;
      x.parameters['FK_TimeZone_readOnly'] = true;
      x.parameters['TimeWithSpinnersReadOnly'] = true;
      x.parameters['MaxAttempts_readOnly'] = true;
      x.parameters['WaitBetweenAttempts_readOnly'] = true;
      x.parameters['FK_ScheduleFrequency_readOnly'] = true;
      x.parameters['ScheduleFrequencyValue_readOnly'] = true;
      x.parameters['Active_readOnly'] = true;
    });

    this.selectedWFDefinition.updateData(this.selectedWFDefinition.data);
    setTimeout(() => {
      this.selectedWFDefinition.reCheckDirty();
      this.wizardItem.reCheckDirty();
    }, 100);
  }

  closeDeleteDefinition(e) {
    this.showDeleteWFDefinition = false;
  }

  deleteExecConfigration(e) {
    this.selectedExecConfiguration.item.data['Deleted'] = true;
    this.selectedExecConfiguration.item.data['Active'] = false;
    this.customerStore.updateCustomerWFDefinitionExecutionConfig(
      this.selectedExecConfiguration.item.data,
      true
    );
    this.selectedExecConfiguration.item.reCheckDirty();
    this.showDeleteWFExecConfig = false;

    if (this.selectedExecConfiguration.item.data.Deleted == true) {
      this.selectedExecConfiguration.item.parameters[
        'FK_WorkflowClientExecutionType_readOnly'
      ] = true;
      this.selectedExecConfiguration.item.parameters['FunctionType_readOnly'] =
        true;
      this.selectedExecConfiguration.item.parameters['FK_TimeZone_readOnly'] =
        true;
      this.selectedExecConfiguration.item.parameters[
        'TimeWithSpinnersReadOnly'
      ] = true;
      this.selectedExecConfiguration.item.parameters['MaxAttempts_readOnly'] =
        true;
      this.selectedExecConfiguration.item.parameters[
        'WaitBetweenAttempts_readOnly'
      ] = true;
      this.selectedExecConfiguration.item.parameters[
        'FK_ScheduleFrequency_readOnly'
      ] = true;
      this.selectedExecConfiguration.item.parameters[
        'ScheduleFrequencyValue_readOnly'
      ] = true;
      this.selectedExecConfiguration.item.parameters['Active_readOnly'] = true;
    }
  }

  closeDeleteExecConfiguration(e) {
    this.showDeleteWFExecConfig = false;
  }

  changeTaskItem(e) {}

  handleExpandItems(data, action) {
    const pk_id = data.PK_ID.toString();

    const expandItems = localStorage.getItem(this.localStorageKey_expandItems)
      ? JSON.parse(localStorage.getItem(this.localStorageKey_expandItems))
      : [];
    if (action == 'collapse') {
      const index = expandItems.indexOf(pk_id);
      if (index != -1) expandItems.splice(index, 1);
    } else if (action == 'expand') {
      const index = expandItems.indexOf(pk_id);
      if (index == -1) expandItems.push(pk_id);
    }
    localStorage.setItem(
      this.localStorageKey_expandItems,
      JSON.stringify(expandItems)
    );
  }

  updateExpandItems(oldValue, newValue) {
    const expandItems = this.localStorage_expandItems;
    if (!expandItems.length) return;
    const index = expandItems.indexOf(oldValue);
    if (index == -1) return;
    expandItems.splice(index, 1);

    expandItems.push(newValue);
    localStorage.setItem(
      this.localStorageKey_expandItems,
      JSON.stringify(expandItems)
    );
  }

  pageChange(e) {
    localStorage.setItem(
      `${this.customerStore.selected.Code}_WF_currentPage`,
      e.page.toString()
    );
  }

  addExpandedKey(item) {
    if (!this.expandedRowKeys) this.expandedRowKeys = {};
    this.expandedRowKeys[item.guid.toString()] = true;
  }

  selectWfDefinition(item) {
    this.wfDefinitions?.items.values.forEach((i) => i.unselect());
    item.select();
  }

  onSelectRow(e) {
    this.selectWfDefinition(e);
  }

  focusLastEditedItem(item) {
    if (this.localStorage_expandItems?.length) {
      const index = this.wfDefinitions.items.values.indexOf(item);
      if (index == this.wfDefinitions.items.values.length - 1) {
        const lastId =
          this.localStorage_expandItems[
            this.localStorage_expandItems?.length - 1
          ];
        const lastItem = this.wfDefinitions.items.values.find(
          (x) => x.data.PK_ID == lastId
        );
        if (lastItem) scrollTo(lastItem);
      }
    }
  }

  pageSizeChange(e) {
    this.wizardItem.parameters['pageSize'] = e;
  }
}

export class WorkflowParameters {
  processID: string;
  executionType: number;
  clientCode: number;
  gSClientType: number;
  gSFunctionType: string;
  userCode: number;
  userFullName: string;
  workflowClientExecutionCfgID: number;
  workflowTaskParameters: Array<WorkflowTaskParameters>;
}

export class WorkflowTaskParameters {
  taskSequence: number;
  parameters: { [key: string]: any };
}
