import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  EventEmitter,
  Output,
  AfterViewInit,
  ViewChild,
  ContentChildren,
  QueryList,
  TemplateRef,
  Renderer2,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ColumnTypes } from '../entity/enum/columnTypes';
import { LazyLoadEvent, SortEvent, MenuItem, PrimeTemplate } from 'primeng/api';
import { Session, Item } from '../entity/entities';
import { FilterTypes } from '../entity/enum/filterTypes';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '../entity/table/tableColumn';
import { Memo } from '../decorators/decorators';

import { environment } from '../../../environments/environment';
import { DecimalPipe, DatePipe } from '@angular/common';
import { TableToExcelService } from '../utils/tableToExcelService';
import { TableToPDFService } from '../utils/tableToPdfService';
import { Paginator } from 'primeng/paginator';

@Component({
  selector: 'gs-table',
  templateUrl: './table.control.html',
})
export class TableControl
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  @Input() columns: Array<TableColumn>;
  @Input() frozenColumns: Array<TableColumn>;
  @Input() pageSize: number = 10;
  @Input() totalResultsCnt: number;
  @Input() pagedResultsRequestKey: string;
  @Input() isLazyLoad: boolean = false;
  @Input() showPaginator: boolean = false;
  @Input() loading = { value: false };
  @Input() scrollable: boolean = false;
  @Input() scrollHeight: string = 'flex';
  @Input() showSelectAll: boolean = false;
  @Input() items;
  @Input() wide = false; //defines will table be wide as parent element
  @Input() readOnly: boolean = false;
  @Input() virtualScroll: boolean = false;
  @Input() virtualRowHeight = this.virtualScroll ? 34 : 0;
  @Input() globalFilterFields = [];
  @Input() set defaultSortField(value) {
    Session.cache.set('TablesInfo', 'TableSortField_' + this.itemName, value);
  }
  @Input() set sortOrder(value) {
    Session.cache.set('TablesInfo', 'TableSortOrder_' + this.itemName, value);
  } // = 1;
  @Input() showAddRow;
  @Input() showEditTable;
  @Input() showPrint;
  @Input() showSave;
  @Input() footerItem;
  @Input() printSaveTitle;
  @Input() printLayout = false;
  @Input() hideHeaders = false;
  @Input() frozenWidth;
  @Input() tableStyle;
  @Input() style;
  @Input() headerNoWrap = false;
  @Input() stickyHeader = false;
  @Input() showInvalidMessage = true;
  @Input() markSelected = false;
  @Input() canExpand = false;
  @Input() tableClass: string;
  @Input() customExpand: boolean = false; //if is true than it will handle for each row will expand be show
  @Input() stickyHeaderTop: string; //value in px when header is sticky how much will be position from top
  @Input() landscapePrint = false;
  @Input() rowsPerPageOptions;
  @Input() itemName;
  @Input() autoSelectFirst;
  @Input() expandedRowKeys = {};
  @Input() includeFooterDataInSave: boolean = true;
  @Input() onlyCsv: boolean = false;
  @Input() addButtonTrainingVideo;
  @Input() showSerialNumber = false;
  @Output() pageSizeChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() defaultSortFieldChange: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() sortOrderChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() lazyLoad = new EventEmitter<any>();
  @Output() rowAction = new EventEmitter<any>();
  @Output() rowDataChanged = new EventEmitter<any>();
  @Output() selectRow = new EventEmitter<any>();
  @Output() selectItem = new EventEmitter<any>();
  @Output() selectCell = new EventEmitter<any>();
  @Output() addRow = new EventEmitter<any>();
  @Output() editTable = new EventEmitter<any>();
  @Output() print = new EventEmitter<any>();
  @Output() save = new EventEmitter<any>();
  @Output() globalFilterChanged = new EventEmitter<any>();

  Math = Math;
  ColumnTypes = ColumnTypes;
  firstLoad: boolean = true;
  firstRowIndex: number = 0;
  expandedRows = {};
  filters: any;
  expandedRow;
  selectAll = false;
  globalFilter;
  showCustomerMultiselectApply;
  lastCustomerMultiselectFilterShow;
  saveAsMenuItems: MenuItem[];
  savePdf = false;
  printItems;
  printFooterItem;
  printColumns = [];
  filteredResultsCount;

  @ViewChild('dt') datatable;
  @ViewChild('dtPrint') dtPrint;
  @ViewChild('dtPrintHolderNoDisplay') dtPrintHolderNoDisplay;
  @ViewChild('dtPrintHolder') dtPrintHolder;
  @ViewChild('paginator') paginator: Paginator;

  @ContentChildren(PrimeTemplate) templates: QueryList<PrimeTemplate>;

  gsExpand: TemplateRef<any>;
  gsheader: TemplateRef<any>;
  gsfilter: TemplateRef<any>;

  public boolStatuses = [
    { label: 'checkmark-icon', value: true },
    { label: 'close-icon', value: false },
  ];

  get totalResultsCnt_Calculated() {
    if (!this.globalFilter) return this.totalResultsCnt ?? this.values?.length;
    else return this.filteredResultsCount;
  }

  get visibleColums() {
    return this.columns.filter((col) => col.visible == true);
  }

  get values() {
    if (this.items) {
      if (!this.globalFilter) {
        let result = this.items.values.filter((i) => i.visible);
        if (this.firstRowIndex >= result.length && !this.lazyLoad)
          this.firstRowIndex = 0;
        return result;
      } else {
        let res = this.items.values.filter((v) =>
          this.globalFilterFields.some(
            (gff) =>
              v.data[gff] != null &&
              v.data[gff].toString().toLowerCase().indexOf(this.globalFilter) >
                -1
          )
        );
        this.filteredResultsCount = res ? res.length : 0;
        if (this.firstRowIndex >= res.length && !this.lazyLoad)
          this.firstRowIndex = 0;
        return res;
      }
    }
  }

  get paginatorText() {
    if (!this.globalFilter)
      return this.loading.value
        ? ''
        : this.totalResultsCnt
        ? this.translate.instant(
            'general.paginatorPageReportNoGlobalFiltered',
            {
              first: this.firstRowIndex + 1,
              last:
                this.firstRowIndex + this.pageSize > this.totalResultsCnt
                  ? this.totalResultsCnt
                  : this.firstRowIndex + this.pageSize,
              total: this.totalResultsCnt,
            }
          )
        : this.translate.instant('general.paginatorPageReportNoData');
    else {
      if (this.filteredResultsCount)
        return this.loading.value
          ? ''
          : this.totalResultsCnt
          ? this.translate.instant(
              'general.paginatorPageReportGlobalFiltered',
              {
                first: this.firstRowIndex + 1,
                last:
                  this.firstRowIndex + this.pageSize > this.filteredResultsCount
                    ? this.filteredResultsCount
                    : this.firstRowIndex + this.pageSize,
                filtered: this.filteredResultsCount,
                total: this.totalResultsCnt,
              }
            )
          : this.translate.instant('general.paginatorPageReportNoData');
      else
        return this.loading.value
          ? ''
          : this.totalResultsCnt
          ? this.translate.instant(
              'general.paginatorPageReportGlobalFilteredNoResults',
              { total: this.totalResultsCnt }
            )
          : this.translate.instant('general.paginatorPageReportNoData');
    }
  }

  get hasFilter() {
    if (this.columns) return this.columns.some((c) => c.filter);
  }

  get areAllSelected() {
    if (this.items) return !this.items.values.some((i) => !i.checked);
  }

  get expanded() {
    if (this.items)
      return this.items.values.some(
        (i) => i.parameters && i.parameters.expaneded
      );
  }

  validField(item: Item, colName) {
    let validationInfo = item.validationInfo.find(
      (vi) => vi.fieldName == colName
    );
    if (validationInfo) return validationInfo.valid;

    return true;
  }

  textAlign(columnType) {
    switch (columnType) {
      case ColumnTypes.Text:
      case ColumnTypes.DateTime:
      case ColumnTypes.TimeWithSpinners:
      case ColumnTypes.TextInput:
      case ColumnTypes.Dropdown:
      case ColumnTypes.CustomerTextMultiSelectFilter:
        return 'left';
      case ColumnTypes.Currency:
      case ColumnTypes.NumberInput:
        return 'right';
      default:
        return 'center';
    }
  }

  setGlobalFilter(value, filterType) {
    this.globalFilter = value.toString().toLowerCase();
    if (this.values.length < this.firstRowIndex + 1) {
      this.datatable.first = 0;
      this.firstRowIndex = 0;
    }

    this.globalFilterChanged.emit(this.values);
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      if (data1.data['excludeFromSort']) return -Math.max();
      let value1 = data1.data[event.field];
      let value2 = data2.data[event.field];
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        if (Number.isInteger(value1) && Number.isInteger(value2))
          result = +value1 < +value2 ? -1 : +value1 > +value2 ? 1 : 0;
        else result = value1.localeCompare(value2);
      } else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }

  getValue(data, name) {
    if (name) {
      if (name.indexOf('.') == -1) return data[name];
      else {
        let props = name.split('.');
        let l = 0;
        let prop;
        while (props.length > l) {
          if (l == 0 && data[props[l]] == undefined) return;
          else prop = prop ? prop[props[l]] : data[props[l]];
          l++;
        }
        return prop;
      }
    }
  }

  constructor(
    private translate: TranslateService,
    private decimalPipe: DecimalPipe,
    private datePipe: DatePipe,
    private renderer: Renderer2,
    private tableToExcelService: TableToExcelService,
    private tableToPdfService: TableToPDFService
  ) {}

  ngOnDestroy(): void {
    if (document.getElementById('iframe'))
      document.body.removeChild(document.getElementById('iframe'));
  }

  ngOnInit() {
    this.rowsPerPageOptions = this.rowsPerPageOptions ?? [
      this.pageSize,
      this.pageSize == 20 ? this.pageSize * 2 : 20,
      50,
    ];
    if (!this.sortOrder && this.itemName)
      this.sortOrder = Session.cache.get(
        'TablesInfo',
        'TableSortOrder_' + this.itemName
      );
    if (!this.defaultSortField && this.itemName)
      this.defaultSortField = Session.cache.get(
        'TablesInfo',
        'TableSortField_' + this.itemName
      );

    this.saveAsMenuItems = this.onlyCsv
      ? [
          {
            label: this.translate.instant('general.csv'),
            icon: 'fas fa-file',
            command: (event) => {
              this.saveAsCsvClk(event);
            },
          },
        ]
      : [
          {
            label: this.translate.instant('general.excel'),
            icon: 'fas fa-file-excel',
            command: (event) => {
              this.saveAsExcelClk(event);
            },
          },
          {
            label: this.translate.instant('general.csv'),
            icon: 'fas fa-file',
            command: (event) => {
              this.saveAsCsvClk(event);
            },
          },
          //{
          //label: this.translate.instant('general.pdf'), icon: 'fas fa-file-pdf', command: (event) => {
          //  this.saveAsPdfClk(event);
          //}}
        ];

    if (
      Session.menu.menuItems.selected.parameters[
        this.pagedResultsRequestKey + 'Filters'
      ]
    )
      this.filters =
        Session.menu.menuItems.selected.parameters[
          this.pagedResultsRequestKey + 'Filters'
        ];
    else {
      this.filters = {};
      this.columns.forEach((c) => {
        this.filters[c.name] = { matchMode: c.filterType, value: null };
      });
      Session.menu.menuItems.selected.parameters[
        this.pagedResultsRequestKey + 'Filters'
      ] = this.filters;
    }
    if (this.columns.some((c) => c.expand))
      for (let i of this.items.values) {
        this.expandedRows[
          i.data[this.columns.find((c) => c.expand)['identity']]
        ] = 0;
      }

    this.columns.forEach((col) => {
      if (!col.align) col.align = this.textAlign(col.type);
    });
  }

  ngAfterContentInit() {
    if (this.autoSelectFirst && this.values && this.values.length > 0) {
      // this.selection.push(this.values[0]);
      this.onSelectRow(this.items.values[0]);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.expandedRowKeys) {
      this.setExpandedRowKeys();
    }
  }

  ngAfterViewInit() {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'gsexpand':
          this.gsExpand = item.template;
          break;
        case 'gsfilter':
          this.gsfilter = item.template;
          break;
        case 'gsheader':
          this.gsheader = item.template;
          break;
      }
    });
  }

  setExpandedRowKeys() {
    if (this.expandedRowKeys) {
      const props = Object.getOwnPropertyNames(this.expandedRowKeys);
      props.forEach((prop) => {
        if (this.expandedRowKeys[prop]) {
          const i = this.items.values.find((i) => i.guid == prop);
          this.rowAction.emit({
            row: i.data,
            action: 'expand',
            item: i,
          });
        }
      });
    }
  }

  public setPage(index) {
    this.firstRowIndex = this.pageSize * index;
  }

  onSort($event) {
    let existingOrder;
    let existingField;

    if (this.itemName) {
      existingOrder = Session.cache.get(
        'TablesInfo',
        'TableSortOrder_' + this.itemName
      );
      existingField = Session.cache.get(
        'TablesInfo',
        'TableSortField_' + this.itemName
      );

      if (existingOrder != $event.order) {
        this.sortOrderChange.emit($event.order);
        Session.cache.set(
          'TablesInfo',
          'TableSortOrder_' + this.itemName,
          $event.order
        );
      }

      if (existingField != $event.field) {
        this.defaultSortFieldChange.emit($event.field);
        Session.cache.set(
          'TablesInfo',
          'TableSortField_' + this.itemName,
          $event.field
        );
      }
    } else {
      this.sortOrderChange.emit($event.order);
      this.defaultSortFieldChange.emit($event.field);
    }
  }

  onPage($event) {
    this.pageSize = $event.rows;
    this.firstRowIndex = $event.first;
    this.pageSizeChange.emit($event.rows);
    this.pageChange.emit($event);
    if (this.datatable.lazy) {
      const event = this.datatable.createLazyLoadMetadata();
      event.first = this.firstRowIndex;
      event.rows = $event.rows;
      this.loadDataLazy(event);
    }
  }

  onSelectRow(item) {
    if (item.parameters['selectedRow'] != true) {
      this.doMarkSelected(item);
      this.selectRow.emit(item.data);
      this.selectItem.emit(item);
    }
  }

  public doMarkSelected(item) {
    if (this.markSelected) {
      this.items.values.forEach((i) => (i.parameters.selectedRow = false));
      item.parameters.selectedRow = true;
    }
  }

  onRowExpand(e) {
    this.expandedRow = this.items.values.indexOf(e.data);
    this.rowAction.emit({
      originalEvent: e.event,
      row: e.data.data,
      action: 'expand',
      item: e.data,
    });
  }

  onRowCollapse(e) {
    this.rowAction.emit({
      originalEvent: e.event,
      row: e.data.data,
      action: 'collapse',
    });
  }

  onSelectCell(event) {
    this.selectCell.emit(event);
  }

  addRowClk($event) {
    this.addRow.emit($event);
  }

  editTableClk($event) {
    this.editTable.emit($event);
  }

  printClk($event, type) {
    if (type == 'PDF') this.savePdf = true;
    //in order to reduce font size
    else this.savePdf = false;

    this.printColumns = [];
    this.columns.forEach((col) => {
      if (
        col.type != ColumnTypes.BoolShowAction &&
        col.type != ColumnTypes.DeleteAction &&
        col.type != ColumnTypes.EditAction &&
        col.type != ColumnTypes.EditActionText
      )
        this.printColumns.push(col);
      else {
        //add action column to print columns only if the column is used in footer...
        if (
          this.footerItem &&
          this.footerItem.data[col.name]?.toString().trim() != ''
        ) {
          let col_ = JSON.parse(JSON.stringify(col));
          col_.description = '';
          this.printColumns.push(col_);
        }
      }
    });

    this.printItems = this.createPrintItem();
    this.printFooterItem = this.createPrintFooterItem();
    //this.spinner.show();
    this.waitForPrint(this.doPrint, this, type);
  }

  doPrint = (type) => {
    const printContent = this.dtPrintHolder.nativeElement;
    if (this.landscapePrint) {
      this.renderer.addClass(printContent, 'landscape');
      this.renderer.addClass(printContent, 'page');
    }

    var blob = new Blob([printContent.innerHTML], { type: 'text/html' });
    const blobUrl = URL.createObjectURL(blob);
    if (document.getElementById('iframe'))
      document.body.removeChild(document.getElementById('iframe'));
    const iframe = document.createElement('iframe');
    iframe.id = 'iframe';
    iframe.style.display = 'none';
    iframe.src = blobUrl;

    let style;
    document.body.appendChild(iframe);
    if (this.landscapePrint) {
      let css = `
        .page
        {
         -webkit-transform: rotate(-90deg);
         -moz-transform:rotate(-90deg);
         filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
        }`;
      style = this.renderer.createElement('style');
      this.renderer.setAttribute(style, 'media', 'print');
      this.renderer.setAttribute(style, 'type', 'text/css');
      style.appendChild(document.createTextNode(css));
    }

    setTimeout(() => {
      document.querySelectorAll('link, style').forEach((htmlElement) => {
        frames[0].document.head.appendChild(htmlElement.cloneNode(true));
      });
      if (this.landscapePrint) frames[0].document.head.appendChild(style);
      iframe.focus();
      iframe.contentWindow.print();
    }, 500);

    this.printItems.items.clear();
    this.printFooterItem = null;
  };

  waitForPrint = (callback, holder, type) => {
    if (!holder.dtPrint) {
      setTimeout(function () {
        window.requestAnimationFrame(function () {
          holder.waitForPrint(callback, holder, type);
        });
      }, 100);
    } else {
      callback(type);
    }
  };

  saveAsPdfClk(event) {
    this.printClk(event, 'PDF');
    this.save.emit('PDF');
  }

  saveAsExcelClk(event) {
    this.printItems = this.createPrintItem();
    this.printFooterItem = this.createPrintFooterItem();
    this.printItems.setItem(this.printFooterItem.data, '_footer');

    const dataToExport = [
      ...this.tableToExcelService.getDataForExport(
        this.printItems.items.values.map((v) => v.data),
        this.columns.filter(
          (c) =>
            c.type != ColumnTypes.BoolShowAction &&
            c.type != ColumnTypes.DeleteAction &&
            c.type != ColumnTypes.EditAction &&
            c.type != ColumnTypes.EditActionText &&
            c.type != ColumnTypes.ButtonText
        )
      ),
    ];
    this.tableToExcelService.exportAsExcelFile(
      dataToExport,
      'export from grid'
    );
    this.save.emit('Excel');
  }

  saveAsCsvClk(event) {
    this.printItems = this.createPrintItem();
    this.printFooterItem = this.createPrintFooterItem();
    this.printItems.setItem(this.printFooterItem.data, '_footer');

    //const dataToExport = [
    //  ...this.tableToExcelService.getDataForExport(this.printItems.items.values.map(v => v.data), this.columns)
    //];
    this.tableToExcelService.exportAsCsvFile(
      this.printItems.items.values.map((v) => v.data),
      this.columns.filter(
        (c) =>
          c.type != ColumnTypes.BoolShowAction &&
          c.type != ColumnTypes.DeleteAction &&
          c.type != ColumnTypes.EditAction &&
          c.type != ColumnTypes.EditActionText &&
          c.type != ColumnTypes.ButtonText
      ),
      'export from grid'
    );
    this.save.emit('Excel');
  }

  onRowAction(event) {
    this.rowAction.emit(event);
  }

  onRowDataChanged(event) {
    this.rowDataChanged.emit(event);
  }

  public emitRowDataChanged(item, column) {
    this.rowDataChanged.emit({
      originalEvent: null,
      row: item ? item.data : undefined,
      column: column,
      columnName: column.name,
      item: item,
    });
  }

  handleChange($event, item, columnName: string, column: TableColumn) {
    if (
      column &&
      (column.type == ColumnTypes.DeleteAction ||
        column.type == ColumnTypes.EditAction ||
        column.type == ColumnTypes.Button ||
        column.type == ColumnTypes.EditActionText ||
        column.type == ColumnTypes.BoolShowAction)
    )
      this.rowAction.emit({
        originalEvent: $event,
        row: item.data,
        column: column,
        columnName: columnName,
        item: item,
      });
    else
      this.rowDataChanged.emit({
        originalEvent: $event,
        row: item ? item.data : undefined,
        column: column,
        columnName: columnName,
        item: item,
      });
  }

  handleRadioButtonChange(
    $event,
    item,
    columnName: string,
    column: TableColumn,
    value
  ) {
    this.rowDataChanged.emit({
      originalEvent: $event,
      row: item.data,
      column: column,
      columnName: columnName,
      selectedValue: value,
      item: item,
    });
  }

  loadDataLazy($event: LazyLoadEvent) {
    this.loading.value = true;
    this.firstRowIndex = $event.first;

    let filters = [];
    let sorts = [];

    Object.getOwnPropertyNames($event.filters).forEach((k) => {
      this.filters[k].value = $event.filters[k].value;
      this.filters[k].matchMode = $event.filters[k].matchMode;
    });

    Object.getOwnPropertyNames(this.filters).forEach((k) => {
      if (this.filters[k].value != undefined)
        filters.push({
          field: k,
          value: this.filters[k].value,
          matchMode: this.filters[k].matchMode,
        });
    });

    if ($event.sortField) {
      sorts.push({ sortField: $event.sortField, sortOrder: $event.sortOrder });
    }
    let loadRequest = {
      pageIndex: $event.first / $event.rows,
      pageSize: $event.rows,
      sortingInfo: sorts,
      filteringInfo: filters,
    };

    if (environment.portalCode == 'PAP') {
      Session.menu.menuItems.get('pdap_integration').parameters[
        this.pagedResultsRequestKey
      ] = loadRequest;
      Session.menu.menuItems.get('pdap_integration').parameters[
        this.pagedResultsRequestKey + 'Filters'
      ] = this.filters;
    }

    this.lazyLoad.emit(loadRequest);
    this.firstLoad = false;
  }

  toggleShowCustomerMultiselectApply() {
    if (!this.showCustomerMultiselectApply)
      this.showCustomerMultiselectApply = true;
    else this.showCustomerMultiselectApply = false;
  }

  customerMultiselectFilterPanelShow(column) {
    this.lastCustomerMultiselectFilterShow = column.multiSelectionFilterValue;
  }

  customerMultiselectFilterPanelHide(column) {
    column.multiSelectionFilterValue = this.lastCustomerMultiselectFilterShow;
    this.showCustomerMultiselectApply = false;
  }

  customerMultiselectFilterChanged(column) {
    if (
      JSON.stringify(column.multiSelectionFilterValue) !=
      JSON.stringify(this.lastCustomerMultiselectFilterShow)
    )
      this.showCustomerMultiselectApply = true;
    else this.showCustomerMultiselectApply = false;
  }

  applyCustomerMultiselectFilter(column) {
    this.showCustomerMultiselectApply = false;
    this.lastCustomerMultiselectFilterShow = column.multiSelectionFilterValue;
  }

  createPrintItem() {
    let printItem = new Item(null, null);

    //make a copy
    this.values.forEach((i) => {
      printItem.setItem(
        JSON.parse(JSON.stringify(i.data)),
        this.items.keys[this.items.values.indexOf(i)]
      );
    });

    //now format all data to apropriate strings
    this.columns.forEach((col) => {
      printItem.items.values.forEach((row) => {
        switch (col.type) {
          case ColumnTypes.Currency:
            row.data[col.name] = this.decimalPipe.transform(
              row.data[col.name],
              '1.2-2'
            );
            break;
          case ColumnTypes.Date:
            row.data[col.name] = this.datePipe.transform(row.data[col.name]);
            break;
          case ColumnTypes.DateTime:
          case ColumnTypes.DateTimeString:
            row.data[col.name] = this.datePipe.transform(
              row.data[col.name],
              'medium'
            );
            break;
          case ColumnTypes.DateTimeFormat:
            row.data[col.name] = this.datePipe.transform(
              row.data[col.name],
              col.dateFormat
            );
            break;
          case ColumnTypes.TimeSpan:
            row.data[col.name] =
              Math.floor(row.data[col.name] / 60)
                .toString()
                .padStart(2, '0') +
              ':' +
              (row.data[col.name] % 60).toString().padStart(2, '0');
            break;
          case ColumnTypes.TimeWithSpinners:
            if (
              row.data[col.name] &&
              row.data[col.name]?.toString().trim() != ''
            )
              row.data[col.name] = row.data[col.name].substr(0, 5);
            break;
          case ColumnTypes.Dropdown:
            if (
              row.data[col.name] &&
              row.data[col.name]?.toString().trim() != ''
            )
              row.data[col.name] = col.options.find(
                (o) => o.value == row.data[col.name]
              ).label;
            break;
          case ColumnTypes.ItemDropdown:
            break;
          case ColumnTypes.BoolShowAction:
          case ColumnTypes.DeleteAction:
          case ColumnTypes.EditAction:
          case ColumnTypes.EditActionText:
            row.data[col.name] = undefined;
            break;
          default:
            break;
        }
      });
    });
    return printItem;
  }

  createPrintFooterItem() {
    let printFooterItem = new Item(null, this.createFooterPrintData());
    return printFooterItem;
  }

  createPrintData() {
    let result = { keys: [], data: [] };

    this.items.keys.forEach((key) => {
      result.keys.push(key);
    });

    //now format all data to apropriate strings
    this.values.forEach((row) => {
      let newRow = {};
      result.data.push(newRow);
      this.columns.forEach((col) => {
        switch (col.type) {
          case ColumnTypes.Currency:
            newRow[col.name] = this.decimalPipe.transform(
              row.data[col.name],
              '1.2-2'
            );
            break;
          case ColumnTypes.Date:
            newRow[col.name] = this.datePipe.transform(row.data[col.name]);
            break;
          case ColumnTypes.DateTime:
          case ColumnTypes.DateTimeString:
            newRow[col.name] = this.datePipe.transform(
              row.data[col.name],
              'medium'
            );
            break;
          case ColumnTypes.DateTimeFormat:
            newRow[col.name] = this.datePipe.transform(
              row.data[col.name],
              col.dateFormat
            );
            break;
          case ColumnTypes.TimeSpan:
            newRow[col.name] =
              Math.floor(row.data[col.name] / 60)
                .toString()
                .padStart(2, '0') +
              ':' +
              (row.data[col.name] % 60).toString().padStart(2, '0');
            break;
          case ColumnTypes.TimeWithSpinners:
            if (
              row.data[col.name] &&
              row.data[col.name]?.toString().trim() != ''
            )
              newRow[col.name] = row.data[col.name].substr(0, 5);
            else newRow[col.name] = undefined;
            break;
          case ColumnTypes.Dropdown:
            if (row[col.name] && row[col.name]?.toString().trim() != '')
              newRow[col.name] = col.options.find(
                (o) => o.value == row.data[col.name]
              ).label;
            else newRow[col.name] = undefined;
            break;
          case ColumnTypes.ItemDropdown:
            newRow[col.name] = row.data[col.name];
            break;
          case ColumnTypes.BoolShowAction:
          case ColumnTypes.DeleteAction:
          case ColumnTypes.EditAction:
          case ColumnTypes.EditActionText:
            newRow[col.name] = undefined;
            break;
          default:
            newRow[col.name] = row.data[col.name];
            break;
        }
      });
    });

    return result;
  }

  createFooterPrintData() {
    let data = {};
    //now format all data to apropriate strings
    if (this.footerItem && this.includeFooterDataInSave)
      this.columns.forEach((col) => {
        switch (col.type) {
          case ColumnTypes.Currency:
            data[col.name] = this.decimalPipe.transform(
              this.footerItem.data[col.name],
              '1.2-2'
            );
            break;
          case ColumnTypes.Date:
            data[col.name] = this.datePipe.transform(
              this.footerItem.data[col.name]
            );
            break;
          case ColumnTypes.DateTime:
          case ColumnTypes.DateTimeString:
            data[col.name] = this.datePipe.transform(
              this.footerItem.data[col.name],
              'medium'
            );
            break;
          case ColumnTypes.DateTimeFormat:
            data[col.name] = this.datePipe.transform(
              this.footerItem.data[col.name],
              col.dateFormat
            );
            break;
          case ColumnTypes.TimeSpan:
            data[col.name] =
              Math.floor(this.footerItem.data[col.name] / 60)
                .toString()
                .padStart(2, '0') +
              ':' +
              (this.footerItem.data[col.name] % 60).toString().padStart(2, '0');
            break;
          case ColumnTypes.TimeWithSpinners:
            if (
              this.footerItem.data[col.name] &&
              this.footerItem.data[col.name]?.toString().trim() != ''
            )
              data[col.name] = this.footerItem.data[col.name].substr(0, 5);
            else data[col.name] = undefined;
            break;
          case ColumnTypes.Dropdown:
            if (
              this.footerItem.data[col.name] &&
              this.footerItem.data[col.name]?.toString().trim() != ''
            )
              data[col.name] = col.options.find(
                (o) => o.value == this.footerItem.data[col.name]
              ).label;
            else data[col.name] = undefined;
            break;
          case ColumnTypes.ItemDropdown:
            data[col.name] = this.footerItem.data[col.name];
            break;
          default:
            data[col.name] = this.footerItem.data[col.name];
            break;
        }
      });
    return data;
  }

  getIcon(item, column) {
    let iconValuePair = column.iconValueSet.find(
      (iv) => iv.value == item.data[column.name]
    );
    if (iconValuePair)
      return column.iconValueSet.find(
        (iv) => iv.value == item.data[column.name]
      ).icon;
  }

  getIconColor(item, column) {
    let iconValuePair = column.iconValueSet.find(
      (iv) => iv.value == item.data[column.name]
    );
    if (iconValuePair)
      return column.iconValueSet.find(
        (iv) => iv.value == item.data[column.name]
      ).color;
  }

  navigateToPage(page: number) {
    this.paginator.changePage(page);
  }

  resetSort() {
    this.datatable.sortOrder = 0;
    this.datatable.sortField = '';
    this.datatable.reset();
  }
}
