import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  QueryList,
  ContentChildren,
  TemplateRef,
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeTemplate } from 'primeng/api';
import { Item } from '../entity/entities';

import { EditLevel } from '../entity/enum/editLevel';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'gs-dialog',
  templateUrl: './gsDialog.component.html',
})
export class GSDialogComponent
  implements OnInit, OnDestroy, AfterContentChecked, AfterViewInit
{
  @Input() item: Item;
  @Input() header: string;
  @Input() modal: boolean;
  @Input() closable: boolean;
  @Input() baseZIndex: number;
  @Input() draggable: boolean;
  @Input() resizable: boolean;
  @Input() editLevel: EditLevel;
  @Input() innerWidth: any;
  @Input() styleClass: any;
  @Input() dialogCss: string;
  @Input() forceShowApply: boolean;
  @Input() enableApply: boolean = false;
  @Input() forceShowUndo: boolean;
  @Input() editUnavailableMessage: string;
  @Input() editNoRightsMessage: string;
  @Input() showCommentOnApply: false;
  @Input() forceHideUndo: false;
  @Input() forceHideEdit: false;
  @Input() applyLabel: string = this.translate.instant('general.apply');

  @Input() get visible(): any {
    return this._visible;
  }
  set visible(value: any) {
    this._visible = value;
    this.visibleChange.emit(value);
    //if (this._visible && !this.maskVisible) {
    //  this.maskVisible = true;
    //}
  }

  @Output() onApply = new EventEmitter<any>();
  @Output() onApplyItem = new EventEmitter<any>();
  @Output() onEdit = new EventEmitter<any>();
  @Output() onClose = new EventEmitter<any>();
  @Output() onUndo = new EventEmitter<any>();
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  EditLevel = EditLevel;

  @ContentChildren(PrimeTemplate) templates: QueryList<PrimeTemplate>;
  gsDialogContent: TemplateRef<any>;
  gsDialogFooter: TemplateRef<any>;
  gsDialogHeader: TemplateRef<any>;

  @ViewChild('noteDialog') noteDialog: OverlayPanel;
  note: any = {};

  get updateEnabledReasonNote() {
    if (
      !this.showCommentOnApply ||
      (this.showCommentOnApply && this.noteDialog)
    )
      return (
        !this.showCommentOnApply ||
        (this.showCommentOnApply &&
          this.noteDialog.overlayVisible &&
          this.note.comment) ||
        (this.showCommentOnApply && !this.noteDialog.overlayVisible)
      );
  }

  _visible: boolean;
  cancelConfirmationVisible;
  get undoEnabled() {
    if (this.item?.isDirty) return true;
    return false;
  }
  get applyEnabled() {
    if (this.item?.isDirty && this.item.isValid) return true;
    return false;
  }

  get closeLabel() {
    if (this.editLevel == EditLevel.Edit)
      return this.translate.instant('general.cancel');
    else return this.translate.instant('general.close');
  }

  constructor(
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.templates.forEach((t) => {
      switch (t.getType()) {
        case 'gsDialogContent':
          this.gsDialogContent = t.template;
          break;
        case 'gsDialogFooter':
          this.gsDialogFooter = t.template;
          break;
        case 'gsDialogHeader':
          this.gsDialogHeader = t.template;
          break;
      }
    });
    this.cdr.detectChanges();
  }

  ngAfterContentChecked(): void {}

  ngOnDestroy(): void {}
  ngOnInit(): void {}

  doEdit(event) {
    this.onEdit.emit(event);
  }

  doApply(event) {
    if (this.showCommentOnApply) {
      if (!this.noteDialog.overlayVisible) {
        if (!this.noteDialog.overlayVisible) this.noteDialog.toggle(event);
      } else if (this.note.comment) {
        if (this.noteDialog.overlayVisible) this.noteDialog.toggle(event);

        //call update, if success, close....
        this.onApply.emit(this.note.comment);
      }
    } else {
      this.onApply.emit(event);
      this.onApplyItem.emit({ event, item: this.item });
      this.noteDialog.hide();
    }
  }

  doUndo(event) {
    if (this.onUndo.observers.length > 0) this.onUndo.emit(event);
    else this.revertData();
  }

  cancelConfirmed(event) {
    this.doCloseFinal(event);
    this.cancelConfirmationVisible = false;
  }

  cancelQuit(event) {
    this.cancelConfirmationVisible = false;
  }

  doClose(event) {
    if (this.editLevel == EditLevel.Edit && this.item && this.item.isDirty) {
      this.cancelConfirmationVisible = true;
    } else this.doCloseFinal(event);
  }

  doCloseFinal(event) {
    this.visible = false;
    this.doUndo(event);
    this.onClose.emit(event);
  }

  revertData() {
    if (this.item) {
      this.item.revertData();

      if (this.item.items.values)
        this.item.items.values.forEach((i) => i.revertData());
    }
  }

  hideNoteDialog(e) {
    this.note.comment = undefined;
  }
}
