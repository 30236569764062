<div *ngIf="!wfLogItem.items?.values.length">
  <p>No log details available.</p>
</div>
<div *ngIf="wfLogItem.items?.values.length">
  <gs-table
    #wfLogDetailsTable
    *ngIf="wfLogItem"
    [ngStyle]="{'padding': 5+'px', 'padding-left': 10+'px'}"
    change
    [pageSize]="pageSize"
    (pageSizeChange)="pageSizeChange($event)"
    [totalResultsCnt]="logDetailsCount"
    [showPaginator]="true"
    [wide]="true"
    [readOnly]="true"
    [columns]="columns"
    [items]="wfLogItem.items"
  >
  </gs-table>
</div>
