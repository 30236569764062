export enum MenuModel {
  Home = -1,
  Setup = 14,
  Setup_Wizard = 40,
  Setup_Customers = -1,
  Setup_Wizard_CompanyInfo = 41,
  Setup_Wizard_BusinessLocalization = 42,
  Setup_Wizard_Datasource = 43,
  Setup_Wizard_InstalationPakcage = 44,
  Security = 15,

  PDP_Dashboard = 1,
  PDP_Mydata = 2,
  PDP_Profile = 3,
  PDP_Reports = 4,
  PDP_Controlpanel = 5,
  PDP_Help = 6,
  PDP_Inbox = 7,
  PDP_Setup = 8,
  PDP_Timeattend = 9,
}
