import { EventEmitter } from '@angular/core';
import { GSSubject } from '../entities';
import { ColumnTypes } from '../enum/columnTypes';
import { ValidationTypes } from '../enum/validationTypes';

export class TableColumn {
  public click = new GSSubject();
  public align: 'left' | 'right' | 'center' = 'center';
  public label;
  public dateFormat;
  public tooltip: string;
  public disabled = false;
  public iconValueSet = [];
  public dateOnly = false;
  public color;
  public class;
  public validationReference: string;
  public requestedValue: any;
  public validationTypeForReference: ValidationTypes;
  public validationRegEx: RegExp;
  public disabledParameterReference: string;
  public visibleParameterReference: string;
  public maxWidthPX?: number;
  public width?: number;
  public autoWidth = false;
  public visible = true;
  public widthPercentage = false;
  public hoursFormat;
  public showAsString = false;
  public order: number;
  public trainingVideo;
  public related: string;

  changed = new EventEmitter<any>();

  constructor(
    public name?: string,
    public description?: string,
    public type?: ColumnTypes,
    public filter?: boolean,
    public filterType?: string,
    public sort?: boolean,
    public minWidthPX?: number,
    public selectRow?: boolean,
    public readonly?: boolean,
    public icon?: string,
    public options?,
    public enableReference?: string,
    public showNull?: boolean,
    public minutesFormat?: boolean,
    public expand?: boolean,
    public validate?: boolean,
    public minDate?: Date,
    public maxDate?: Date,
    public dropdownSearch?: boolean,
    public multiSelectionFilterValue?,
    public filterPlaceholder?: string,
    public hideClearFilter?: boolean,
    public closeOnSelect?: boolean,
    public minFractionDigits?: number,
    public maxFractionDigits?: number,
    public showFilterClear?: boolean,
    public validateAllowNull?: boolean,
    public placeholder?: string,
    public options_2?: any
  ) {}

  //optionsPETemplateValue(key) {
  //  let options = [];

  //  this.optionsPETemplate_Value[key].forEach(o => options.push({value:o.lalue, label: o.label}))

  //}
}
