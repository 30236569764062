import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
  Input,
  Inject,
  Optional,
  Injectable,
  QueryList,
  ElementRef,
  ViewChildren,
  ChangeDetectorRef,
} from '@angular/core';
import { GSBaseControl } from './gs.base.control';
import { FormDropdownProperty } from '../entity/form/formDropdown.property';
import { Item, Session } from '../entity/entities';
import { FormLocationDropdownProperty } from '../entity/form/formLocationDropdown.property';

@Component({
  selector: 'gs-location-dropdown',
  templateUrl: './gs.locationDropdown.control.html',
})
@Injectable()
export class GSLocationDropdownControl
  extends GSBaseControl
  implements OnInit, OnDestroy
{
  //#region -------Properties

  get showClear() {
    return this.property.showClear;
  }
  get options() {
    return this.property.options;
  }
  @ViewChildren('selectOption') selectOption: QueryList<ElementRef>;
  optionHandled = false;
  //#endregion
  preselected = false;
  //#region -------Constructor
  constructor(
    @Optional() prop: FormDropdownProperty,
    @Optional() item: Item,
    private changeDr: ChangeDetectorRef
  ) {
    super(changeDr);
    this.property = prop;
    this.item = item;
  }
  //#endregion

  //#region -------NG Eents
  ngOnInit() {
    (this.property as FormLocationDropdownProperty).optionsSet.subscribe(
      this.preselect
    );
    if ((this.property as FormLocationDropdownProperty).options)
      this.preselect();
  }

  ngOnDestroy() {}

  ngAfterContentChecked() {
    if (this.selectOption && this.selectOption.toArray().length) {
      if (!this.optionHandled) {
        this.selectOption
          .toArray()
          .filter((e) =>
            e.nativeElement.children[0].classList.contains(
              'disabled_opacity-no-color'
            )
          )
          .forEach((e) => {
            e.nativeElement.parentElement.classList.add('no-event');
          });
        this.optionHandled = true;
      }
    } else this.optionHandled = false;
  }
  //#endregion

  //#region -------UI Events
  preselect = () => {
    if (!this.preselected) {
      this.preselected = true;
      if (this.property.options) {
        let first = this.options.find(
          (l) => !l.data.BlockedReason && !l.data.Disabled
        );
        if (
          first &&
          (this.property.options.length == 1 || this.property.preselectFirst)
        ) {
          this.value = first.value;
          (this.item as Item).reValidate();
          (this.item as Item).reCheckDirty();

          //if (Session.menu.menuItems.selected && Session.menu.menuItems.selected.items.selected)
          //  Session.menu.menuItems.selected.items.selected.name = first.label;

          this.onChange.emit({
            propertyName: this.name,
            previousValue: null,
            newValue: this.value,
            event: {},
            item: this.item,
            property: this.property,
          });
          this.property.changed.emit({
            propertyName: this.name,
            previousValue: null,
            newValue: this.value,
            event: {},
            item: this.item,
            property: this.property,
          });
        }
      }
    }

    const menuItem = Session.menu.menuItems.selected;
    if (!this.value && menuItem.items.selected) {
      const selected = this.options.find(
        (o) => o.value == menuItem.items.selected.code
      );
      if (!selected.data.BlockedReason && !selected.data.Disabled) {
        this.change(selected);
        menuItem.pages.selected.data.customerName = selected.label;
      } else menuItem.pages.selected.data.customerName = null;
    } else if (this.value) {
      menuItem.pages.selected.data.customerName = this.options.find(
        (o) => o.value == this.value
      ).label;
    }
  };

  change(e) {
    if (e) {
      let val = this.value;
      let option = this.options.find((o) => o.data.Code == e.value);
      if (option.data.Disabled) {
        this.value = e.value;
        setTimeout(() => {
          this.value = val;
        }, 100);

        return;
      }
      const menuItem = Session.menu.menuItems.selected;
      if (menuItem.pages.selected!=null)
        menuItem.pages.selected.data.customerName = option.label;
      let item;
      if (!menuItem.items.get(option.data.Code.toString()))
        item = menuItem.setItem(
          { Code: option.data.Code, GroupCode: option.data.GroupCode },
          option.data.Code
        );
      else item = menuItem.items.get(option.data.Code.toString());
      item?.select();
      //if (Session.menu.menuItems.selected && Session.menu.menuItems.selected.items.selected)
      //  Session.menu.menuItems.selected.items.selected.name = option.label;

      let previousValue = this.data[this.name];
      this.value = e.value;
      this.item.reCheckDirty();
      this.onChange.emit({
        propertyName: this.name,
        previousValue: previousValue,
        newValue: this.value,
        event: e,
        item: this.item,
        property: this.property,
      });
      this.property.changed.emit({
        propertyName: this.name,
        previousValue: previousValue,
        newValue: this.value,
        event: e,
        item: this.item,
        property: this.property,
      });
    }
  }

  //#endregion

  //#region -------Private
  //#endregion
}
