<div *ngIf="item" [ngClass]="{'row': !column, 'form-property':!column}">
  <div
    [ngClass]="{'no-padding-right': tooltip, 'col-lg-11': tooltip && !column,  'col-lg-12': !tooltip && !column}"
  >
    <span [ngClass]="{'p-float-label':!column}">
      <p-dropdown
        [appendTo]="'body'"
        id="{{name}}"
        [options]="options"
        (onChange)="change($event)"
        [ngModel]="value"
        [readonly]="readonly"
        [ngClass]="{'disabled':readonly || item && item.parameters[name].disabled, 'dropdown-property-invalid':notValid}"
        class="disabled ui-inputwrapper-focus ui-inputwrapper-focus"
        [style]="{'width': '100%'}"
        [panelStyle]="{'max-height': '400px','min-width': '400px'}"
        [filter]="true"
        [autoDisplayFirst]="false"
        filterBy="data.ShortName,data.FKey, data.Description"
      >
        <ng-template let-selected pTemplate="selectedItem">
          <span style="vertical-align: middle"
            >{{selected ? selected.data.Code + (selected.data.ShortName?'
            ('+selected.data.ShortName+')':'') :''}}</span
          >
        </ng-template>
        <ng-template let-opt pTemplate="item">
          <div #selectOption class="row" [margin-left]="10" [margin-right]="5">
            <div
              class="lightborder ui-helper-clearfix col-lg-11 col-sm-11 col-md-11"
              style="position: relative; border-bottom: 1px solid lightgrey"
            >
              <span class="text-l bold">{{opt.data.ShortName}}</span>
              <br />
              <span *ngIf="opt.data.Description" class="text-s">
                {{opt.data.FKey}}
                <br />
                {{opt.data.Description}}
              </span>
            </div>
          </div>
        </ng-template>
      </p-dropdown>
      <label class="gs-float-label" [ngClass]="{'error-color':notValid}">{{label}}</label>
      <span
        *ngIf="notValid"
        class="form-error"
        pTooltip="{{property.validationMessage}}"
        tooltipPosition="top"
      >
        <i
          class="fas fa-info-circle fa-2x form-valid-icon with-icon error-color"
        ></i>
      </span>
    </span>
  </div>
  <div *ngIf="tooltip" class="col-lg-1 property-tooltip">
    <span pTooltip="{{property.tooltip}}">
      <i class="fas fa-info-circle master-color"></i>
    </span>
  </div>
</div>
