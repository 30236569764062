import { FormProperty } from './formProperty';
import { PropertyTypes } from '../enum/propertyTypes';
import { EventEmitter } from '@angular/core';
import { GSButtonControl } from '../../controls/gsControls';

export class FormButtonProperty extends FormProperty {
  type = PropertyTypes.Button;
  click = new EventEmitter<any>();
  component = GSButtonControl;
  icon: string;
  disabled = false;

  constructor(name: string, label: string, icon: string, row?: number, column?: number,
        rowspan?: number, columnspan?: number, validate?: boolean, public color?: string) {
    super(name, label, row, column,
      rowspan, columnspan, validate);
    this.icon = icon;
  }
}
