import { Directive, HostListener, OnInit, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { FormPhoneNumberProperty } from '../entity/form/formPhoneNumber.property';
import { Item } from '../entity/entities';
import { ItemUtil } from '../utils/utils';

@Directive({
  selector: '[phoneMask]'
})
export class PhoneMaskDirective implements OnInit {
  private _property: FormPhoneNumberProperty;
  private _item: Item;
  private _stringItem;

  @Input() get property() {
    return this._property;
  }
  set property(value) {
    this._property = value;
  }
  @Input() get item() {
    return this._item;
  }
  set item(value) {
    this._item = value;
  }

  @Input() get stringItem() {
    return this._stringItem;
  }
  set stringItem(value) {
    this._stringItem = value;
  }

  constructor(public ngControl: NgControl) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }

  ngOnInit() {
    //this.ngModel.valueChanges.subscribe((event) => {
    //  this.onInputChange(event, false);
    //});

    this.initiate();
  }

  initiate() {
    let v = ItemUtil.getPropertyData(this.item, this.property.name);
    let newVal = v == null ? "" : v.replace(/\D/g, '');
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '($1)');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else if (newVal.length <= 10) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    } else {
      newVal = newVal.substring(0, 10);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    }
    this.stringItem.value = newVal;
    var data = newVal.replace(/[^0-9]/g, '');
    ItemUtil.setPropertyData(this.item, this.property.name, data);
    this.ngControl.valueAccessor.writeValue(this.stringItem.value);
  }

  onInputChange(event, backspace) {
    let newVal = event.replace(/\D/g, '');
    if (backspace && newVal.length <= 6) {
      newVal = newVal.substring(0, newVal.length - 1);
    }
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '($1)');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else if (newVal.length <= 10) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    } else {
      newVal = newVal.substring(0, 10);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    }

    
    if (this.item && this.property && this.property.name && this.stringItem) {
      this.stringItem.value = newVal;
      this.ngControl.valueAccessor.writeValue(this.stringItem.value);

      var data = newVal.replace(/[^0-9]/g, '');
      ItemUtil.setPropertyData(this.item, this.property.name, data);
    }
    else {
      this.ngControl.valueAccessor.writeValue(newVal);

      if (newVal != this.ngControl.control.value)
        this.ngControl.control.setValue(newVal)
    }

  }
}
