import { Injectable, Component } from '@angular/core';
//import { AppConfig } from "../../app.config";
import { CacheService } from 'ng2-cache';
import { BaseClient } from '../common/client/base.client';
import { AppConfig } from '../app.config';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { PagedResultRequest, Session } from '../common/entity/entities';
import { TranslateService } from '@ngx-translate/core';
import { SvcClients } from '../common/client/SvcClients';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class FinanceClient extends BaseClient {
  constructor() {
    super();
  }

  proccessRawBankStatements(model: any, message: string) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPTabapayReconciliation/ProcessLoadedBankStatements`,
      model,
      message
    );
  }

  reprocessLoadedBankStatement(model: any, message: string) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPTabapayReconciliation/ReprocessLoadedBankStatement`,
      model,
      message
    );
  }

  getBankStatements(model: any, message: string) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPTabapayReconciliation/GetBankStatements`,
      model,
      message
    );
  }

  reprocessBankStatement(model: any, message: string) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPTabapayReconciliation/ReprocessBankStatement`,
      model,
      message
    );
  }

  getReconciledTransactionsReport(model: any, message: string) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPTabapayReconciliation/GetReconciledTransactionsReport`,
      model,
      message
    );
  }

  getReconciledTransactionsByCustomerReport(model: any, message: string) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPTabapayReconciliation/GetReconciledTransactionsByCustomerReport`,
      model,
      message
    );
  }

  getUnreconciledTransactionsReport(model: any, message: string) {
    return this.post(
      SvcClients.AdminSvc,
      `PDAPTabapayReconciliation/GetUnreconciledTransactionsReport`,
      model,
      message
    );
  }

  getMerchants() {
    return this.get(
      SvcClients.AdminSvc,
      `PDAPTabapayReconciliation/GetMerchants`
    );
  }
}
