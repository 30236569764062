import { Injectable, Component } from '@angular/core';
//import { AppConfig } from "../../app.config";
import { CacheService } from 'ng2-cache';
import { BaseClient } from '../common/client/base.client';
import { AppConfig } from '../app.config';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { PagedResultRequest, Session, Page } from '../common/entity/entities';
import { TranslateService } from '@ngx-translate/core';
import { SvcClients } from '../common/client/SvcClients';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DashboardClient extends BaseClient {
  constructor() {
    super();
  }

  //#region PAYDAY PORTAL - DASH

  getActiveCustomers() {
    return new Promise<any>((resolve) => {
      if (Session.cache.get('Customers', 'All'))
        resolve(Session.cache.get('Customers', 'All'));
      else
        this.get(
          SvcClients.AdminSvc,
          `PayDayAdminPortal/getAllActiveCustomers`
        ).then((data) => {
          Session.cache.set('Customers', 'All', data);
          resolve(Session.cache.get('Customers', 'All'));
        });
    });
  }

  getActiveCustomersGroupInfo() {
    return new Promise<any>((resolve) => {
      this.get(
        SvcClients.AdminSvc,
        `PayDayAdminPortal/getActiveCustomersGroup`
      ).then((data) => {
        resolve(data);
      });
    });
  }

  notConfiguredCustomers(page) {
    return new Promise<any | void>((resolve) => {
      let pageId = page.data.ID;
      if (!pageId) resolve(null);
      if (
        Session.cache.get(`Customers`, `NotCofiguredCustomersForPage_${pageId}`)
      )
        resolve(
          Session.cache.get(
            'Customers',
            `NotCofiguredCustomersForPage_${pageId}`
          )
        );
      else
        this.get(
          SvcClients.AdminSvc,
          `PayDayAdminPortal/notConfiguredCustomers?pageId=${pageId}`
        ).then((data) => {
          Session.cache.set(
            'Customers',
            `NotCofiguredCustomersForPage_${pageId}`,
            data
          );
          resolve(
            Session.cache.get(
              'Customers',
              `NotCofiguredCustomersForPage_${pageId}`
            )
          );
        });
    });
  }

  //#region-------CALENDAR

  getCustomerDates(cutomerCode) {
    return this.get(
      SvcClients.ResourceSvc,
      `Customer?customerCode=${cutomerCode}`
    );
  }

  getCustomerDatesSpecific(cutomerCode, month, year, numberOfMonths = 1) {
    return this.get(
      SvcClients.ResourceSvc,
      `Customer?year=${year}&month=${month}&customerCode=${cutomerCode}&numberOfMonths=${numberOfMonths}`
    );
  }

  getContinuesPaymentConfig(customerCode) {
    return this.get(
      SvcClients.ResourceSvc,
      `G5CustomerPayout/ContinuesPaymentConfig?customerCode=${customerCode}`
    );
  }

  syncWithPOS(cutomerCode, businessDate: Date, message, requestId) {
    return this.put(
      SvcClients.ResourceSvc,
      `CustomerSyncDate?customerCode=${cutomerCode}`,
      { BusinessDate: this.datepipe.transform(businessDate, 'yyyy-MM-dd') },
      message,
      undefined,
      'apiUrl',
      requestId
    );
  }

  getCustomerShifts(message: string, customerCode: number, businessDate) {
    return this.get(
      SvcClients.ResourceSvc,
      `CustomerShifts?customerCode=${customerCode}&businessDate=${this.datepipe.transform(
        businessDate,
        'yyyy-MM-dd'
      )}`,
      message
    );
  }

  getCustomerPOSSettings(customerCode: number) {
    return this.get(
      SvcClients.ResourceSvc,
      `G5CustomerTime/GetCustomerPOSSettings?customerCode=${customerCode}`
    );
  }

  customerClosedDateCheck(
    message: string,
    customerCode: number,
    businessDate: Date
  ) {
    return this.get(
      SvcClients.ResourceSvc,
      `CustomerCloseDate?customerCode=${customerCode}&businessDate=${this.datepipe.transform(
        businessDate,
        'yyyy-MM-dd'
      )}`,
      message
    );
  }

  getCustomerCloseDate(
    message: string,
    customerCode: number,
    businessDate: Date,
    processId: number
  ) {
    return this.put(
      SvcClients.ResourceSvc,
      `CustomerCloseDate`,
      {
        CustomerCode: customerCode,
        BusinessDate: this.datepipe.transform(businessDate, 'yyyy-MM-dd'),
        ProcessID: processId,
      },
      message
    );
  }

  getCustomerCloseDateWF(
    message: string,
    customerCode: number,
    businessDate: Date,
    processId: string,
    processType: number
  ) {
    return this.post(
      SvcClients.ResourceSvc,
      `CustomerCloseDate`,
      {
        CustomerCode: customerCode,
        BusinessDate: this.datepipe.transform(businessDate, 'yyyy-MM-dd'),
        ProcessID: processId,
        ProcessType: processType,
      },
      message
    );
  }

  getTipScam(
    message: string,
    customerCode: number,
    businessDate: Date,
    shiftId?
  ) {
    return this.get(
      SvcClients.ResourceSvc,
      `TipScam?businessDate=${this.datepipe.transform(
        businessDate,
        'yyyy-MM-dd'
      )}&shiftId=${shiftId}&customerCode=${customerCode}`,
      message
    );
  }

  setTipScam(message: string, data) {
    return this.post(SvcClients.ResourceSvc, `TipScam`, data, message);
  }

  customerOpenDateCheck(
    message: string,
    customerCode: number,
    businessDate: Date
  ) {
    return this.get(
      SvcClients.ResourceSvc,
      `CustomerOpenDate?customerCode=${customerCode}&businessDate=${this.datepipe.transform(
        businessDate,
        'yyyy-MM-dd'
      )}`,
      message
    );
  }

  getCustomerOpenDate(
    message: string,
    customerCode: number,
    businessDate: Date,
    processId: number
  ) {
    return this.put(
      SvcClients.ResourceSvc,
      `CustomerOpenDate`,
      {
        CustomerCode: customerCode,
        BusinessDate: this.datepipe.transform(businessDate, 'yyyy-MM-dd'),
        ProcessID: processId,
      },
      message
    );
  }

  //#endregion

  //#region-------TIME
  getCustomerTime(message: string, customerCode: number, businessDate: Date) {
    return this.get(
      SvcClients.ResourceSvc,
      `G5CustomerTime/GetTime?customerCode=${customerCode}&businessDate=${this.datepipe.transform(
        businessDate,
        'yyyy-MM-dd'
      )}`,
      message
    );
  }

  getCustomerTimeEmployeeDetails(
    message: string,
    customerCode: number,
    businessDate: Date,
    employeeId: number
  ) {
    return this.get(
      SvcClients.ResourceSvc,
      `G5CustomerTime/GetTimeEmployeeDetails?customerCode=${customerCode}&businessDate=${this.datepipe.transform(
        businessDate,
        'yyyy-MM-dd'
      )}&employeeId=${employeeId}`,
      message
    );
  }

  deleteBreak(
    message: string,
    customerCode: number,
    businessDate: Date,
    id: number,
    note: string
  ) {
    return this.delete(
      SvcClients.ResourceSvc,
      `CustomerTimeBreak/DeleteBreak?customerCode=${customerCode}&businessDate=${this.datepipe.transform(
        businessDate,
        'yyyy-MM-dd'
      )}&id=${id}&note=${note}`,
      message
    );
  }

  timeRollback(customerCode: number, requestId: string, model: any) {
    return this.post(
      SvcClients.ResourceSvc,
      `G5CustomerTime/RollbackTimeAndAttendance?customerCode=${customerCode}`,
      model,
      '',
      null,
      false,
      null,
      false,
      requestId
    );
  }

  getCustomerTimeWeekly(
    message: string,
    customerCode: number,
    dateFrom: Date,
    dateTo: Date
  ) {
    return this.get(
      SvcClients.ResourceSvc,
      `G5CustomerTime/GetTimeWeekly?customerCode=${customerCode}&dateFrom=${this.datepipe.transform(
        dateFrom,
        'yyyy-MM-dd'
      )}&dateTo=${this.datepipe.transform(dateTo, 'yyyy-MM-dd')}`,
      message
    );
  }

  getTimeWeeklyEmployeeDetails(
    message: string,
    customerCode: number,
    dateFrom: Date,
    dateTo: Date,
    employeeId: number
  ) {
    return this.get(
      SvcClients.ResourceSvc,
      `G5CustomerTime/GetTimeWeeklyEmployeeDetails?customerCode=${customerCode}&dateFrom=${this.datepipe.transform(
        dateFrom,
        'yyyy-MM-dd'
      )}&dateTo=${this.datepipe.transform(
        dateTo,
        'yyyy-MM-dd'
      )}&employeeId=${employeeId}`,
      message
    );
  }
  //timeRollback
  //#endregion

  deleteTimeCard(
    message: string,
    customerCode: number,
    requestId: string,
    businessDate: Date,
    id: number,
    note: string
  ) {
    return this.delete(
      SvcClients.ResourceSvc,
      `G5CustomerTime/DeleteTime?customerCode=${customerCode}&businessDate=${this.datepipe.transform(
        businessDate,
        'yyyy-MM-dd'
      )}&id=${id}&note=${note}`,
      message,
      null,
      false,
      null,
      false,
      requestId
    );
  }

  addUpdateBreaks(message: string, customerCode: number, model: any) {
    return this.post(
      SvcClients.ResourceSvc,
      `CustomerTimeBreak/AddUpdateBreak?customerCode=${customerCode}`,
      model,
      message
    );
  }

  addUpdateTimeCardsAndBreaks(
    message: string,
    customerCode: number,
    requestId: string,
    model: any
  ) {
    return this.post(
      SvcClients.ResourceSvc,
      `G5CustomerTime/AddUpdatTimeCardsAndBreaks?customerCode=${customerCode}`,
      model,
      message,
      null,
      false,
      null,
      false,
      requestId
    );
  }

  //#region------- POS DATA
  getCustomerPosData(
    message: string,
    customerCode: number,
    businessDate: Date
  ) {
    return this.get(
      SvcClients.ResourceSvc,
      `G5CustomerPosData/GetPosData?customerCode=${customerCode}&businessDate=${this.datepipe.transform(
        businessDate,
        'yyyy-MM-dd'
      )}`,
      message
    );
  }

  updateCustomerSinglePosData(
    message: string,
    customerCode: number,
    data: any,
    requestId: string
  ) {
    return this.post(
      SvcClients.ResourceSvc,
      `G5CustomerPosData/UpdateSinglePosData?customerCode=${customerCode}`,
      data,
      message,
      null,
      false,
      null,
      false,
      requestId
    );
  }

  updateCustomerMultiPosData(
    message: string,
    customerCode: number,
    requestId: string,
    data: any
  ) {
    return this.post(
      SvcClients.ResourceSvc,
      `G5CustomerPosData/UpdateMultiPosData?customerCode=${customerCode}`,
      data,
      message,
      null,
      false,
      null,
      false,
      requestId
    );
  }

  checkOpenShift(
    message: string,
    customerCode: number,
    businessDate: Date,
    shiftID: number
  ) {
    return this.get(
      SvcClients.ResourceSvc,
      `CustomerOpenShift/CustomerOpenShiftCheck?customerCode=${customerCode}&businessDate=${this.datepipe.transform(
        businessDate,
        'yyyy-MM-dd'
      )}&shiftId=${shiftID}`,
      message
    );
  }

  openShift(message: string, customerCode: number, data: any) {
    return this.put(
      SvcClients.ResourceSvc,
      `CustomerOpenShift/CustomerOpenShift?customerCode=${customerCode}`,
      data,
      message
    );
  }

  checkCloseShift(
    message: string,
    customerCode: number,
    businessDate: Date,
    shiftID: number
  ) {
    return this.get(
      SvcClients.ResourceSvc,
      `CustomerCloseShift/CustomerCloseShiftCheck?customerCode=${customerCode}&businessDate=${this.datepipe.transform(
        businessDate,
        'yyyy-MM-dd'
      )}&shiftId=${shiftID}`,
      message
    );
  }

  closeShift(message: string, customerCode: number, data: any) {
    return this.put(
      SvcClients.ResourceSvc,
      `CustomerCloseShift/CustomerCloseShift?customerCode=${customerCode}`,
      data,
      message
    );
  }

  setShiftAdjustment(
    message: string,
    customerCode: number,
    requestId: string,
    data: any
  ) {
    return this.post(
      SvcClients.ResourceSvc,
      `CustomerCheckOuts/BatchEdit?customerCode=${customerCode}`,
      data,
      message,
      null,
      false,
      null,
      false,
      requestId
    );
  }

  getPosDistributionOverride(customerCode, businessDate, shiftID, message) {
    return this.get(
      SvcClients.ResourceSvc,
      `PoolDistributionRules?customerCode=${customerCode}&businessDate=${this.datepipe.transform(
        businessDate,
        'yyyy-MM-dd'
      )}&shift_PK=${shiftID}`,
      message
    );
  }

  setPosDistributionOverride(customerCode, requestId: string, data, message) {
    return this.put(
      SvcClients.ResourceSvc,
      `PoolDistributionRules?customerCode=${customerCode}`,
      data,
      message,
      null,
      null,
      requestId
    );
  }
  //#endregion

  //#region-------TEAM UPS
  getCustomerTeamUps(
    message: string,
    customerCode: number,
    businessDate: Date
  ) {
    return this.get(
      SvcClients.ResourceSvc,
      `CustomerTeamUps/GetTeamUps?customerCode=${customerCode}&businessDate=${this.datepipe.transform(
        businessDate,
        'yyyy-MM-dd'
      )}`,
      message
    );
  }
  //#endregion

  //#region-------BUSINESS RULES
  getCustomerBusinessRule(
    message: string,
    customerCode: number,
    businessDate: Date
  ) {
    return this.get(
      SvcClients.ResourceSvc,
      `CustomerBusinessRules?customerCode=${customerCode}&businessDate=${this.datepipe.transform(
        businessDate,
        'yyyy-MM-dd'
      )}`,
      message
    );
  }
  //#endregion

  //#region-------CALCULATED DATA
  getCustomerBreakDowns(
    message: string,
    customerCode: number,
    businessDate: Date
  ) {
    return this.get(
      SvcClients.ResourceSvc,
      `G5CustomerCalculatedData/Get?customerCode=${customerCode}&businessDate=${this.datepipe.transform(
        businessDate,
        'yyyy-MM-dd'
      )}`,
      message
    );
  }

  updateCalculatedData(message: string, customerCode: number, data: any) {
    return this.post(
      SvcClients.ResourceSvc,
      `G5CustomerCalculatedData/Update?customerCode=${customerCode}`,
      data,
      message
    );
  }

  getWithholdData(customerCode: number, businessDate, shift, id) {
    return this.get(
      SvcClients.ResourceSvc,
      `G5CustomerCalculatedData/GetWithholds?customerCode=${customerCode}&businessDate=${this.datepipe.transform(
        businessDate,
        'yyyy-MM-dd'
      )}&shift_PK=${shift}&calculationReport_PK=${id}`,
      null
    );
  }

  updateWithholdData(message: string, customerCode: number, data: any) {
    return this.post(
      SvcClients.ResourceSvc,
      `G5CustomerCalculatedData/UpdateWithholds?customerCode=${customerCode}`,
      data,
      message
    );
  }

  calculatedDataBatchUpdate(message: string, customerCode: number, model: any) {
    return this.post(
      SvcClients.ResourceSvc,
      `G5CustomerCalculatedData/BatchUpdate?customerCode=${customerCode}`,
      model,
      message
    );
  }

  getCalculatedDataDetails(
    customerCode: number,
    businessDate,
    businessDateTo,
    shift,
    employeeID,
    jobID
  ) {
    let date = this.datepipe.transform(businessDate, 'yyyy-MM-dd');
    let dateto = this.datepipe.transform(businessDateTo, 'yyyy-MM-dd');
    if (jobID)
      return this.get(
        SvcClients.ResourceSvc,
        `EmployeeTipOut?customerCode=${customerCode}&EmployeeID=${employeeID}&ShiftID=${shift}&DateFrom=${date}&DateTo=${dateto}&jobID=${jobID}`,
        null
      );
    else
      return this.get(
        SvcClients.ResourceSvc,
        `EmployeeTipOut?customerCode=${customerCode}&EmployeeID=${employeeID}&ShiftID=${shift}&DateFrom=${date}&DateTo=${dateto}`,
        null
      );
  }

  getEmployeeSharedDetailsByShift(
    customerCode: number,
    businessDate,
    businessDateTo,
    shift,
    employeeId,
    jobID
  ) {
    let date = this.datepipe.transform(businessDate, 'yyyy-MM-dd');
    let dateto = this.datepipe.transform(businessDateTo, 'yyyy-MM-dd');

    return this.get(
      SvcClients.ResourceSvc,
      `EmployeeTipOut/getEmployeeSharedDetailsByShift?customerCode=${customerCode}&shiftID=${shift}&dateFrom=${date}&dateTo=${dateto}&jobId=${jobID}&employeeId=${employeeId}`,
      null
    );
  }

  getEmployeeSharedTipTransitionsForShift(
    customerCode: number,
    businessDate,
    businessDateTo,
    shift,
    employeeId,
    groupType,
    typeOfChange
  ) {
    let date = this.datepipe.transform(businessDate, 'yyyy-MM-dd');
    let dateto = this.datepipe.transform(businessDateTo, 'yyyy-MM-dd');

    return this.get(
      SvcClients.ResourceSvc,
      `EmployeeTipOut/GetEmployeeSharedTipTransitionsForShift?customerCode=${customerCode}&shiftID=${shift}&dateFrom=${date}&dateTo=${dateto}&groupType=${groupType}&typeOfChange=${typeOfChange}&employeeId=${employeeId}`,
      null
    );
  }

  getEmployeeSharedTipTransitionsDetails(
    customerCode: number,
    businessDate,
    jobId,
    checkId,
    groupType,
    typeOfChange
  ) {
    let date = this.datepipe.transform(businessDate, 'yyyy-MM-dd');

    return this.get(
      SvcClients.ResourceSvc,
      `EmployeeTipOut/GetEmployeeSharedTipTransitionsDetails?customerCode=${customerCode}&busienssDate=${date}&jobId=${jobId}&checkId=${checkId}&groupType=${groupType}&typeOfChange=${typeOfChange}`,
      null
    );
  }

  getCommissionSalesSingle(
    customerCode: number,
    fk_CalculationReport: number,
    message
  ) {
    return this.get(
      SvcClients.ResourceSvc,
      `G5CustomerCommissionSales?customerCode=${customerCode}&fk_CalculationReport=${fk_CalculationReport}`,
      message
    );
  }

  editCommissionSingle(customerCode: number, model, message) {
    return this.post(
      SvcClients.ResourceSvc,
      `G5CustomerCommissionSales?customerCode=${customerCode}`,
      model,
      message
    );
  }

  getCalculatedDataTeamUp(
    customerCode: number,
    businessDate,
    shift,
    employeeID,
    jobID,
    newTeam
  ) {
    let date = this.datepipe.transform(businessDate, 'yyyy-MM-dd');
    return this.get(
      SvcClients.ResourceSvc,
      `CustomerTeamUps/TeamUpsAvailable?customerCode=${customerCode}&BusinessDate=${date}&ShiftID=${shift}&JobID=${jobID}&EmployeeID=${employeeID}&NewTeam=${newTeam}`,
      null
    );
  }

  setCalculatedDataTeamUp(customerCode: number, requestId: string, data) {
    return this.put(
      SvcClients.ResourceSvc,
      `CustomerTeamUps?customerCode=${customerCode}`,
      data,
      null,
      null,
      null,
      requestId
    );
  }

  getCalculatedDataDistributionOverride(
    customerCode: number,
    businessDate,
    shift,
    employeeID,
    jobID
  ) {
    let date = this.datepipe.transform(businessDate, 'yyyy-MM-dd');
    return this.get(
      SvcClients.ResourceSvc,
      `EmployeePoolDistributionRules?customerCode=${customerCode}&businessDate=${date}&employeeId=${employeeID}&jobID=${jobID}&shiftID=${shift}`,
      null
    );
  }

  setCalculatedDataDistributionOverride(
    customerCode: number,
    requestId: string,
    data
  ) {
    return this.put(
      SvcClients.ResourceSvc,
      `EmployeePoolDistributionRules?customerCode=${customerCode}`,
      data,
      null,
      null,
      null,
      requestId
    );
  }

  //#endregion

  //#region-------COMISSION
  getCustomerComissionSales(
    message: string,
    customerCode: number,
    businessDate: Date
  ) {
    return this.get(
      SvcClients.ResourceSvc,
      `CustomerCommissionSales?customerCode=${customerCode}&businessDate=${this.datepipe.transform(
        businessDate,
        'yyyy-MM-dd'
      )}`,
      message
    );
  }

  updateComission(message: string, customerCode: number, data: any) {
    var model = {
      ModelList: [],
    };
    model.ModelList.push({
      CalculationReport_ID: data.commcol_calculationreportpk,
      CommShare: data.commcol_commshare,
      Note: data.note,
    });
    return this.post(
      SvcClients.ResourceSvc,
      `CustomerCommissionSales?customerCode=${customerCode}`,
      model,
      message
    );
  }
  //#endregion

  //#region -------DAILY TOTALS
  getCustomerDailyTotals(code, dateFrom, dateTo, ShiftID?) {
    let from = this.datepipe.transform(dateFrom, 'yyyy-MM-dd');
    let to = dateTo ? this.datepipe.transform(dateTo, 'yyyy-MM-dd') : null;
    return this.get(
      SvcClients.ResourceSvc,
      `CustomerDailyTotals?customerCode=${code}&dateFrom=${from}&dateTo=${to}&shiftId=${ShiftID}`
    );
  }
  //#endregion

  //#region -------OPTIONS \ResourceService/api/CustomerJobs?businessDate=2019-05-17
  getCustomerJobs(code, businessDate) {
    businessDate = this.datepipe.transform(businessDate, 'yyyy-MM-dd');
    return this.get(
      SvcClients.ResourceSvc,
      `CustomerJobs?customerCode=${code}&businessDate=${businessDate}`
    );
  }

  getAllCustomerJobs(code) {
    return this.get(
      SvcClients.ResourceSvc,
      `CustomerJobs?customerCode=${code}`
    );
  }

  getCustomerEmployees(code) {
    return this.get(
      SvcClients.ResourceSvc,
      `CustomerEmployees?customerCode=${code}`
    );
  }

  getTimeCustomerEmployees(code, businessDate) {
    businessDate = this.datepipe.transform(businessDate, 'yyyy-MM-dd');
    return this.get(
      SvcClients.ResourceSvc,
      `G5CustomerTime/GetEmployees?customerCode=${code}&businessDate=${businessDate}`
    );
  }

  //#endregion

  //#region------- PAYOUT

  getPayout(cutomerCode, dateFrom, dateTo, message) {
    return this.get(
      SvcClients.ResourceSvc,
      `G5CustomerPayout?customerCode=${cutomerCode}&dateFrom=${dateFrom}&dateTo=${dateTo}&byShift=${false}&byPosition=${false}`,
      message
    );
  }

  getWagePayout(cutomerCode, dateFrom, dateTo, message) {
    return this.get(
      SvcClients.ResourceSvc,
      `G5CustomerPayout/Wage?customerCode=${cutomerCode}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
      message
    );
  }

  getCommissionPayout(cutomerCode, dateFrom, dateTo, message) {
    return this.get(
      SvcClients.ResourceSvc,
      `G5CustomerPayout/Commission?customerCode=${cutomerCode}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
      message
    );
  }

  payoutLoadAll(customerCode, data, message) {
    return this.post(
      SvcClients.ResourceSvc,
      `LoadCard?customerCode=${customerCode}`,
      data,
      message
    );
  }

  payoutLoadAllWF(customerCode, data, message) {
    return this.post(
      SvcClients.ResourceSvc,
      `LoadCard/LoadCardWF?customerCode=${customerCode}`,
      data,
      message
    );
  }

  payoutPayAll(cutomerCode, data, message) {
    return this.post(
      SvcClients.ResourceSvc,
      `PaymentReport/ManageEmployeePayment?customerCode=${cutomerCode}`,
      data,
      message
    );
  }

  cancelPayAll(cutomerCode, data, message) {
    return this.post(
      SvcClients.ResourceSvc,
      `PaymentReport/ManageEmployeePayment?customerCode=${cutomerCode}`,
      data,
      message
    );
  }

  //#endregion

  //#region-------PAYROLL

  getPayrollPeriod(customerCode, message) {
    return this.get(
      SvcClients.ResourceSvc,
      `Payroll/GetPayrollPeriods?customerCode=${customerCode}`,
      message
    );
  }

  generatePayrollPeriod(
    customerCode,
    dateFrom,
    dateTo,
    message,
    action = undefined,
    forceWebApiCall: boolean = true,
    storeCacheKey: string = null,
    supressErrorNotification: boolean = false,
    requestIdOverride: string = null,
    params = null
  ) {
    return this.get(
      SvcClients.ResourceSvc,
      `Payroll/G5GeneratePayrollPeriod?customerCode=${customerCode}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
      message,
      action,
      forceWebApiCall,
      storeCacheKey,
      supressErrorNotification,
      requestIdOverride,
      params
    );
  }

  getPayrollDataModel(customerCode, message) {
    return this.get(
      SvcClients.ResourceSvc,
      `Payroll/GetPayrollDataModel?customerCode=${customerCode}`,
      message
    );
  }

  getPayrollData(customerCode, payrollExportId, message) {
    return this.get(
      SvcClients.ResourceSvc,
      `Payroll/GetPayrollData?customerCode=${customerCode}&payrollExportId=${payrollExportId}`,
      message
    );
  }

  deletePayrollPeriod(customerCode, payrollExportId) {
    return this.delete(
      SvcClients.ResourceSvc,
      `Payroll/DeletePayrollPeriod?customerCode=${customerCode}&payrollExportId=${payrollExportId}`
    );
  }

  deletePayrollPeriodItem(
    customerCode,
    payrollExportItemId,
    note,
    payrollExportId
  ) {
    return this.delete(
      SvcClients.ResourceSvc,
      `Payroll/DeletePayrollPeriodItem?customerCode=${customerCode}&payrollExportItemId=${payrollExportItemId}&note=${note}&payrollExportId=${payrollExportId}`
    );
  }
  lockPayrollPeriod(customerCode, payrollExportId) {
    return this.post(
      SvcClients.ResourceSvc,
      `Payroll/LockPayrollPeriod?customerCode=${customerCode}&payrollExportId=${payrollExportId}`,
      {}
    );
  }

  openPayrollPeriod(customerCode, payrollExportId) {
    return this.post(
      SvcClients.ResourceSvc,
      `Payroll/OpenPayrollPeriod?customerCode=${customerCode}&payrollExportId=${payrollExportId}`,
      {},
      ''
    );
  }

  exportPayroll(
    customerCode,
    payrollExportId,
    fileName,
    shouldReturnData,
    requestId?
  ) {
    return this.downloadPayrollExportFile(
      SvcClients.ResourceSvc,
      `Payroll/ExportPayroll?customerCode=${customerCode}&payrollExportId=${payrollExportId}&shouldReturnData=${shouldReturnData}`,
      fileName,
      requestId
    );
  }

  downloadPayrollDataFromS3(wfClientExecutionProcessID) {
    return this.downloadFile(
      SvcClients.AdminSvc,
      `PDAPDataExport/GetS3FileData?processId=${wfClientExecutionProcessID}`
    );
  }

  approvePeriodsTemplate(
    fk_PayrollConfigCustomerValidation: number,
    customerCode: number,
    message
  ) {
    return this.get(
      SvcClients.ResourceSvc,
      `Payroll/ApprovePeriodsTemplate?fk_PayrollConfigCustomerValidation=${fk_PayrollConfigCustomerValidation}&customerCode=${customerCode}`,
      message
    );
  }

  syncToPayroll(
    customerCode,
    payrollExportId,
    message: string = null,
    action = undefined,
    forceWebApiCall: boolean = true,
    storeCacheKey: string = null,
    supressErrorNotification: boolean = false,
    requestIdOverride: string = null,
    params = null
  ) {
    return this.get(
      SvcClients.ResourceSvc,
      `Payroll/ExportPayroll?customerCode=${customerCode}&payrollExportId=${payrollExportId}`,
      message,
      action,
      forceWebApiCall,
      storeCacheKey,
      supressErrorNotification,
      requestIdOverride,
      params
    );
  }

  saveManualChanges(customerCode, object, message) {
    return this.post(
      SvcClients.ResourceSvc,
      `Payroll/SaveManualChanges?customerCode=${customerCode}`,
      object,
      ''
    );
  }

  getPayrollManualChanges(customerCode, payrollExportId) {
    return this.get(
      SvcClients.ResourceSvc,
      `Payroll/GetManualChanges?customerCode=${customerCode}&payrollExportId=${payrollExportId}`
    );
  }

  //#endregion

  //#region MONTHLY OVERVIEW
  getEmployeeMonthyOverviewForAllCustomers(dateFrom, dateTo, message) {
    return this.get(
      SvcClients.ResourceSvc,
      `EmployeeTipOut/GetEmployeeGratshareAmountForPeriod?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      message
    );
  }

  getEmployeeMonthyOverviewShiftDetails(
    customerCode: number,
    dateFrom,
    dateTo,
    message
  ) {
    return this.get(
      SvcClients.ResourceSvc,
      `EmployeeCustomerShiftDetails/GetEmployeeTipOutByShift?customerCode=${customerCode}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
      message
    );
  }

  getEmployeeMonthyOverviewTipShareDetails(
    customerCode: number,
    businessDate,
    message
  ) {
    return this.get(
      SvcClients.ResourceSvc,
      `TipShare/GetEmployeesForTipShare?customerCode=${customerCode}&businessDate=${businessDate}`,
      message
    );
  }

  setTipShare(customerCode: number, model: any, message) {
    return this.post(
      SvcClients.ResourceSvc,
      `TipShare/SaveTipShare?customerCode=${customerCode}`,
      model,
      message
    );
  }

  //#endregion

  //#region PAY ME
  getPayMeAmounts(message) {
    return this.get(SvcClients.ResourceSvc, `PayMe/GetAmounts`, message);
  }

  loadPayMeAmounts(model, message) {
    return this.post(
      SvcClients.ResourceSvc,
      `PayMe/LoadAmounts`,
      model,
      message
    );
  }

  //#endregion

  //#region TRANSACTIONS WITH END DATE
  getTransactionsWithEndDate(customerCode: number, model: any, message) {
    return this.post(
      SvcClients.ResourceSvc,
      `G5TransactionsWithEndDate/GetTransactions?customerCode=${customerCode}`,
      model,
      message
    );
  }

  saveTransactionsWithEndDate(customerCode: number, model: any, message) {
    return this.post(
      SvcClients.ResourceSvc,
      `G5TransactionsWithEndDate/SaveTransactions?customerCode=${customerCode}`,
      model,
      message
    );
  }

  getTransactionsWithEndDatePaged(customerCode: number, model: any, message) {
    return this.post(
      SvcClients.ResourceSvc,
      `G5TransactionsWithEndDate/GetTransactionsPaged?customerCode=${customerCode}`,
      model,
      message
    );
  }

  getTransactionsWithEndDateDetails(
    customerCode: number,
    employeePK: number,
    businessDate: Date,
    message
  ) {
    return this.get(
      SvcClients.ResourceSvc,
      `G5TransactionsWithEndDate/GetTransactionDetails?customerCode=${customerCode}&employeePK=${employeePK}&businessDate=${this.datepipe.transform(
        businessDate,
        'yyyy-MM-dd'
      )}`,
      message
    );
  }

  getChecksForEmployee(
    customerCode: string,
    employeeId: string,
    jobId: string,
    shiftId: number,
    businessDate: string,
    message
  ) {
    return this.get(
      SvcClients.ResourceSvc,
      `TransactionDetails/GetChecksForEmployee?customerCode=${customerCode}&employeeId=${employeeId}&jobId=${jobId}&shiftId=${shiftId}&businessDate=${this.datepipe.transform(
        businessDate,
        'yyyy-MM-dd'
      )}`,
      message
    );
  }

  deleteCheckUpdates(
    originalCheckID: string,
    customerCode: number,
    businessDate: string,
    message
  ) {
    return this.get(
      SvcClients.ResourceSvc,
      `TransactionDetails/DeleteUpdatedChecks?originalCheckID=${originalCheckID}&customerCode=${customerCode}&businessDate=${this.datepipe.transform(
        businessDate,
        'yyyy-MM-dd'
      )}`,
      message
    );
  }

  saveCheckUpdates(model: any, requestId: string, message) {
    return this.post(
      SvcClients.ResourceSvc,
      `TransactionDetails/SaveUpdatedChecks`,
      model,
      message,
      null,
      false,
      null,
      false,
      requestId
    );
  }

  //#endregion

  getTrainingVideo(code: number) {
    return this.get(SvcClients.AdminSvc, `TrainingVideo/GetVideo?code=${code}`);
  }

  getTrainingVideoAll() {
    return this.get(SvcClients.AdminSvc, `TrainingVideo/GetAll`);
  }
}
