<div class="row">
  <div class="col-lg-3" style="position: absolute; height: 95%">
    <div class="col-lg-12" style="padding-top: 15px; position: absolute">
      <span class="p-float-label">
        <input
          id="float-input"
          (keyup)="filter($event)"
          type="search"
          pInputText
        />
        <label for="float-input">Search for help</label>
      </span>
    </div>
    <div
      class="col-lg-12"
      style="
        position: absolute;
        overflow-y: scroll;
        overflow-x: hidden;
        top: 50px;
        height: 95%;
      "
    >
      <ul class="list-unstyled userManual">
        <li
          *ngFor="let manual of userManuals"
          [ngClass]="{ 'userManual-selected': manual.selected }"
          (click)="showHelp(manual)"
        >
          {{ manual.name }}
        </li>
      </ul>
    </div>
  </div>
  <div
    class="col-lg-9"
    style="
      position: absolute;
      height: 95%;
      margin-left: 26.1%;
      border-left: 1px solid lightgray;
    "
  >
    <div *ngIf="helpSrc" style="padding: 5px">
      <button
        [margin-right]="8"
        pButton
        icon="fas fa-save"
        type="button"
        pTooltip="Download file"
        prevent-click
        (click)="downloadFile()"
      ></button>
      <a #downloadLink href="#"></a>
    </div>
    <pdf-viewer
      *ngIf="helpSrc"
      [src]="helpSrc"
      [original-size]="true"
      [fit-to-page]="true"
      [render-text]="false"
    ></pdf-viewer>
  </div>
</div>
