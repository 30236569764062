import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'gsFilter',
    pure: false
})
export class GsFilterPipe implements PipeTransform {
    transform(items: any[], filterFunction: string): any {
        if (!items || !filterFunction) {
            return items;
        }
        switch (filterFunction) {
            case 'activeTrackingGroups':
                return this.filterTrackingGroups(items, true);
            case 'nonActiveTrackingGroups':
                return this.filterTrackingGroups(items, false);
        }
        return;
    }

    private filterTrackingGroups(items,active) {
        return items.filter(item => item.Active == active);
    }
}
