<div class="row">
  <div class="col-lg-3" style="position: absolute; height: 95%">
    <div class="col-lg-12" style="padding-top: 15px; position: absolute">
      <span class="p-float-label">
        <input
          id="float-input"
          (keyup)="filter($event)"
          type="search"
          pInputText
        />
        <label for="float-input">Search for video</label>
      </span>
    </div>
    <div
      class="col-lg-12"
      style="
        position: absolute;
        overflow-y: scroll;
        overflow-x: hidden;
        top: 50px;
        height: 95%;
      "
    >
      <ul class="list-unstyled userManual">
        <li
          *ngFor="let video of videos"
          class="video-title"
          [ngClass]="{ 'video-selected': video.selected }"
          (click)="showVideo(video)"
          pTooltip="{{ video.name }}"
        >
          {{ video.name }}
        </li>
      </ul>
    </div>
  </div>
  <div
    class="col-lg-9"
    style="
      position: absolute;
      height: 95%;
      margin-left: 26.1%;
      border-left: 1px solid lightgray;
    "
  >
    <div>
      <div
        margin-left="20"
        *ngIf="selected"
        class="display-flex justifiy-space-between"
      >
        <div class="iframe-video-title" pTooltip="{{ selected.name }}">
          {{ selected.name }}
        </div>
        <div>
          <button
            [margin-right]="8"
            pButton
            icon="fas fa-upload"
            type="button"
            label="Open in new window"
            pTooltip="Download file"
            prevent-click
            class="new-tab-video"
            (click)="newTab()"
          ></button>
        </div>
      </div>
      <div #container></div>
    </div>
    <!-- <iframe
        [src]="selected.src"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
        [title]="selected.name"
      ></iframe> -->
  </div>
</div>
