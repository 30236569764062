import { FormProperty } from './formProperty';
import { PropertyTypes } from '../enum/propertyTypes';
import { EventEmitter } from '@angular/core';
import { GSInputNumberControl } from '../../controls/gsControls';
import { format } from 'url';

export class FormNumberProperty extends FormProperty {
  type = PropertyTypes.Number;
  changed = new EventEmitter<any>();
  keydown = new EventEmitter<any>();
  onpaste = new EventEmitter<any>();
  mode: string;
  minFractionDigits: number;
  maxFractionDigits: number;
  component = GSInputNumberControl;
  min: number;
  max: number;
  maxLength: number;
  formatNumber: boolean;
  showButtons: boolean;

  constructor(
    name: string,
    label: string,
    mode?: string,
    minFractionDigits?: number,
    maxFractionDigits?: number,
    row?: number,
    column?: number,
    rowspan?: number,
    columnspan?: number,
    validate?: boolean,
    validationRegExp?: RegExp,
    align?,
    maxLength?: number,
    formatNumber?: boolean
  ) {
    super(
      name,
      label,
      row,
      column,
      rowspan,
      columnspan,
      validate,
      validationRegExp,
      align
    );
    this.mode = mode;
    this.minFractionDigits = minFractionDigits;
    this.maxFractionDigits = maxFractionDigits;
    this.maxLength = maxLength;
    this.formatNumber = formatNumber == null ? true : formatNumber;
  }
}
