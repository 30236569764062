import { Directive, HostListener } from "@angular/core";

@Directive({
    selector: "[prevent-click]"
})
export class PreventClick {
    @HostListener("click", ["$event"])
    public onClick(event: any): void {
        if (!event)
            event = window.event;
        event.preventDefault();
        //IE9 & Other Browsers
        if (event.stopPropagation) {
            event.stopPropagation();
        }
        //IE8 and Lower
        else {
            event.cancelBubble = true;
        }
    }
}
