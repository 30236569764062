<p-dialog
  [(visible)]="visible"
  [modal]="modal"
  [closable]="false"
  [draggable]="draggable"
  [resizable]="resizable"
  [style.max-width]="innerWidth + 'px'"
  [styleClass]="styleClass"
  [class]="dialogCss"
>
  <p-header>
    <ng-container *ngIf="gsDialogHeader">
      <ng-container
        *ngTemplateOutlet="gsDialogHeader; context: { $implicit: item }"
      ></ng-container>
    </ng-container>
    <ng-container *ngIf="!gsDialogHeader">
      <span style="float: left; text-transform: uppercase">{{ header }}</span>
      <span style="float: right">
        <button
          *ngIf="closable"
          class="gs-dialog_close"
          type="button"
          pButton
          icon="pi pi-times"
          (click)="doClose($event)"
        ></button>
      </span>
    </ng-container>
  </p-header>

  <ng-container *ngIf="gsDialogContent">
    <ng-container
      *ngTemplateOutlet="gsDialogContent; context: { $implicit: item }"
    ></ng-container>
  </ng-container>

  <p-footer>
    <ng-container *ngIf="gsDialogFooter">
      <ng-container
        *ngTemplateOutlet="gsDialogFooter; context: { $implicit: item }"
      ></ng-container>
    </ng-container>
    <ng-container *ngIf="!gsDialogFooter">
      <div style="margin-top: 10px">
        <div style="float: left">
          <button
            *ngIf="editLevel == EditLevel.Edit && !forceHideEdit"
            class="edit"
            type="button"
            pButton
            icon="fa fa-pencil-square-o"
            (click)="doEdit($event)"
            label="{{ 'general.edit' | gstranslate }}"
          ></button>
          <p
            class="color-erorr bold text-m left-text new-line"
            *ngIf="editLevel == EditLevel.Unavailable"
          >
            <i class="fas fa-exclamation-triangle" [padding-right]="5"></i
            >{{ editUnavailableMessage }}
          </p>
          <p
            class="color-erorr bold text-m left-text new-line"
            *ngIf="editLevel == EditLevel.NoRights"
          >
            <i class="fas fa-exclamation-triangle" [padding-right]="5"></i
            >{{ editNoRightsMessage }}
          </p>
        </div>
        <span style="float: right">
          <button
            style="margin-left: 5px"
            *ngIf="
              (editLevel == EditLevel.Edit ||
                (editLevel == EditLevel.NoEdit && forceShowUndo)) &&
              !forceHideUndo
            "
            type="button"
            pButton
            icon="fas fa-undo"
            label="{{ 'general.undo' | gstranslate }}"
            [disabled]="!undoEnabled"
            class="undo"
            (click)="doUndo($event)"
          ></button>
          <button
            style="margin-left: 5px"
            *ngIf="
              editLevel == EditLevel.Edit ||
              (editLevel == EditLevel.NoEdit && forceShowApply)
            "
            type="button"
            pButton
            icon="fas fa-save"
            label="{{ applyLabel }}"
            [disabled]="(!applyEnabled || !updateEnabledReasonNote) && !enableApply"
            (click)="doApply($event)"
          ></button>
          <button
            style="margin-left: 5px"
            type="button"
            pButton
            icon="pi pi-times"
            [label]="closeLabel"
            (click)="doClose($event)"
            class="closebtn"
          ></button>
        </span>
      </div>
    </ng-container>
  </p-footer>
</p-dialog>

<!--CANCEL CHANGES CONFIRMATION DIALOG-->
<p-dialog
  [(visible)]="cancelConfirmationVisible"
  [header]="'general.cancelChangesConfirmationHeader' | gstranslate"
  modal="true"
  [closable]="false"
>
  {{ "general.cancelChangesConfirmationMessage" | gstranslate }}
  <p-footer>
    <button
      type="button"
      pButton
      icon="pi pi-check"
      (click)="cancelConfirmed($event)"
      label="{{ 'general.ok' | gstranslate }}"
    ></button>
    <button
      type="button"
      pButton
      icon="pi pi-times"
      class="closebtn"
      (click)="cancelQuit($event)"
      label="{{ 'general.no' | gstranslate }}"
    ></button>
  </p-footer>
</p-dialog>

<p-overlayPanel
  [appendTo]="'body'"
  [dismissable]="true"
  (onHide)="hideNoteDialog($event)"
  [showCloseIcon]="true"
  #noteDialog
>
  <step-history-log [history]="note"></step-history-log>
</p-overlayPanel>
