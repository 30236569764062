import {
  SignalRConnection,
  IConnectionOptions,
  ConnectionStatuses,
  ConnectionStatus,
  ConnectionTransport,
  ConnectionTransports,
} from 'ng2-signalr';
import { Session, NotificationType, Notification } from '../entity/entities';
import { environment } from '../../../environments/environment';
import { NotificationEvents } from '../entity/enum/notificationEvents';
import { AppInjector } from '../../app.module';
import { HubClient } from '../../client/hub.client';

export class BroadcastingClient {
  private static connection: SignalRConnection = undefined;
  private static running: boolean = false;
  private static reconnectCounter: number = 0;
  private static subscriptions: Array<BroadcastingSubscription> = [];
  private static hubClient: HubClient;
  private static stopped: boolean;

  public static start(): Promise<any> {
    return new Promise((resolve) => {
      if (!BroadcastingClient.running) {
        let options: IConnectionOptions = {
          withCredentials: true,
          qs: {
            clientId: Session.broadcastingClientId,
            token: Session.token,
            portalCode: environment.portalCode,
          },
          url: environment.broadcastingUrl,
          hubName: 'broadcastingHub',
        };
        let connection = Session.broadcaster.createConnection(options);
        connection.status.subscribe((status: ConnectionStatus) => {
          console.info('Broadcaster status changed to: ' + status.name);
          if (
            status.name == ConnectionStatuses.disconnected.name &&
            !BroadcastingClient.stopped
          ) {
            BroadcastingClient.running = false;
            if (BroadcastingClient.reconnectCounter == 0) {
              console.warn(
                'Disconnected from broadcasting hub. Trying to reconnect...'
              );
              BroadcastingClient.reconnect().then(() => resolve(null));
            } else resolve(null);
          }
        });
        //connection.errors.subscribe((error) => { console.error(error) });
        connection
          .start()
          .then((conn: SignalRConnection) => {
            BroadcastingClient.running = true;
            console.log('Broadcasting hub connected, connection Id=' + conn.id);
            BroadcastingClient.connection = conn;
            BroadcastingClient.connection
              .listenFor('broadcast')
              .subscribe((message: any) => {
                console.info(
                  'Broadcast message received=' + JSON.stringify(message)
                );
                if (message && message.NotificationEvent >= 0) {
                  let subscription = BroadcastingClient.subscriptions.find(
                    (item) => item.event == message.NotificationEvent
                  );
                  if (subscription && subscription.callbacks) {
                    subscription.callbacks.forEach((callback, i) => {
                      callback(message);

                      subscription.callbacks.splice(i, 1);
                    });
                  }
                }
              });
            BroadcastingClient.reconnectCounter = 0;
            resolve(null);
          })
          .catch((error) => {
            BroadcastingClient.running = false;
            if (BroadcastingClient.reconnectCounter == 0) {
              console.warn(
                'Disconnected from broadcasting hub. Trying to reconnect...'
              );
              BroadcastingClient.reconnect().then(() => resolve(null));
            } else resolve(null);
          });
      } else resolve(null);
    });
  }

  public static stop(): Promise<any> {
    return new Promise((resolve) => {
      if (BroadcastingClient.connection) {
        BroadcastingClient.stopped = true;
        BroadcastingClient.running = false;
        this.reconnectCounter = 0;
        BroadcastingClient.connection.stop();
        console.log('Broadcasting hub connection stopped.');
      }
    });
  }

  public static on(
    customerCode: number,
    event: NotificationEvents,
    callback: (...msg: any[]) => void,
    requestId?: string,
    expiresInSeconds?: number
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      if (environment.broadcastingEnabled) {
        //console.info('Turning on: ' + callback);
        if (BroadcastingClient.connection && BroadcastingClient.running) {
          console.info(
            `Sending SUBSCRIBE request to broadcasting hub for event ${event.toString()} with request ${requestId}.`
          );
          BroadcastingClient.connection
            .invoke(
              'Subscribe',
              Session.broadcastingClientId,
              customerCode,
              event,
              requestId,
              expiresInSeconds
            )
            .then(() => {
              console.info(
                `Sending SUBSCRIBE request to broadcasting hub for event ${event.toString()} with requestId ${requestId} succeeded.`
              );
              BroadcastingClient.registerCallback(
                event,
                customerCode,
                requestId,
                callback
              );
              resolve(null);
            })
            .catch((error) => {
              console.warn(
                `Could not send SUBSCRIBE request for event ${event.toString()} with requestId ${requestId} to broadcasting hub. Using synchronization service...`
              );
              BroadcastingClient.getHubClient()
                .subscribe({
                  clientId: Session.broadcastingClientId,
                  customerCode,
                  notificationEvent: event,
                  requestId: requestId,
                  expiresInSeconds: expiresInSeconds,
                })
                .then(
                  (success) => {
                    console.info(
                      `Sending SUBSCRIBE request to synchronization service for event ${event.toString()} with requestId ${requestId} succeeded.`
                    );
                    BroadcastingClient.registerCallback(
                      event,
                      customerCode,
                      requestId,
                      callback
                    );
                    resolve(null);
                  },
                  (fail) => {
                    console.warn(
                      `Error while sending SUBSCRIBE request to synchronization service for event ${event.toString()} with requestId ${requestId}` +
                        fail
                    );
                    reject();
                  }
                );
            });
        } else {
          console.log(
            `Broadcasting hub not connected. Using synchronization service to send SUBSCRIBE request for event ${event.toString()} with requestId ${requestId}.`
          );
          BroadcastingClient.getHubClient()
            .subscribe({
              clientId: Session.broadcastingClientId,
              customerCode,
              notificationEvent: event,
              requestId: requestId,
              expiresInSeconds: expiresInSeconds,
            })
            .then(
              (success) => {
                console.info(
                  `Sending SUBSCRIBE request to synchronization service for event ${event.toString()} with requestId ${requestId} succeeded.`
                );
                BroadcastingClient.registerCallback(
                  event,
                  customerCode,
                  requestId,
                  callback
                );
                resolve(null);
              },
              (fail) => {
                console.warn(
                  `Error while sending SUBSCRIBE request to synchronization service for event ${event.toString()} with requestId ${requestId}.` +
                    fail
                );
                reject();
              }
            );
        }
      } else resolve(null);
    });
  }

  public static offAll(callback: (...msg: any[]) => void) {
    if (environment.broadcastingEnabled) {
      //console.info('Turning off: ' + callback);
      if (BroadcastingClient.subscriptions.length) {
        for (
          let index = BroadcastingClient.subscriptions.length - 1;
          index >= 0;
          index--
        ) {
          let subscription = BroadcastingClient.subscriptions[index];
          subscription.callbacks = [];
          BroadcastingClient.subscriptions.splice(index, 1);
        }
      }
      BroadcastingClient.unsubscribeAll();
    }
  }

  public static off(
    customerCode: number,
    event: NotificationEvents,
    callback: (...msg: any[]) => void,
    requestId?: string
  ) {
    if (environment.broadcastingEnabled) {
      //console.info('Turning off: ' + callback);
      BroadcastingClient.unsubscribe(customerCode, event, requestId);
      if (BroadcastingClient.subscriptions.length) {
        for (
          let index = BroadcastingClient.subscriptions.length - 1;
          index >= 0;
          index--
        ) {
          let subscription = BroadcastingClient.subscriptions[index];
          if (
            subscription.event == event &&
            subscription.customerCode == (customerCode ? customerCode : null)
          ) {
            subscription.callbacks = [];
            BroadcastingClient.subscriptions.splice(index, 1);
          }
        }
      }
    }
  }

  private static unsubscribe(
    customerCode: number,
    event: NotificationEvents,
    requestId?
  ) {
    if (BroadcastingClient.connection && BroadcastingClient.running) {
      console.info(
        `Sending UNSUBSCRIBE request to broadcasting hub for event ${event.toString()} with request ${requestId}`
      );
      BroadcastingClient.connection
        .invoke(
          'Unsubscribe',
          Session.broadcastingClientId,
          customerCode,
          event,
          requestId
        )
        .then(() => {
          console.info(
            `Sending UNSUBSCRIBE request to broadcasting hub for event ${event.toString()} with requestId ${requestId} succeeded`
          );
        })
        .catch((error) => {
          console.warn(
            `Could not send UNSUBSCRIBE request for event ${event.toString()} with requestId ${requestId} to broadcasting hub. Using synchronization service...`
          );
          BroadcastingClient.getHubClient()
            .unsubscribe({
              clientId: Session.broadcastingClientId,
              customerCode,
              notificationEvent: event,
              requestId: requestId,
            })
            .then(
              (success) => {
                console.info(
                  `Sending UNSUBSCRIBE request to synchronization service for event ${event.toString()} with requestId ${requestId} succeeded.`
                );
              },
              (fail) => {
                console.warn(
                  `Error while sending UNSUBSCRIBE request to synchronization service for event ${event.toString()} with requestId ${requestId}` +
                    fail
                );
              }
            );
        });
    } else {
      console.log(
        `Broadcasting hub not connected. Using synchronization service to send UNSUBSCRIBE request for event ${event.toString()} with requestId ${requestId}.`
      );
      BroadcastingClient.getHubClient()
        .unsubscribe({
          clientId: Session.broadcastingClientId,
          customerCode,
          notificationEvent: event,
          requestId: requestId,
        })
        .then(
          (success) => {
            console.info(
              `Sending UNSUBSCRIBE request to synchronization service for event ${event.toString()} with requestId ${requestId} succeeded.`
            );
          },
          (fail) => {
            console.warn(
              `Error while sending UNSUBSCRIBE request to synchronization service for event ${event.toString()} with requestId ${requestId}.` +
                fail
            );
          }
        );
    }
  }

  private static unsubscribeAll() {
    if (BroadcastingClient.connection && BroadcastingClient.running) {
      console.info('Sending unsubscribe request to broadcast hub.');
      BroadcastingClient.connection
        .invoke('UnubscribeAllEvents', Session.broadcastingClientId, event)
        .then(() => {
          console.info('Sending unsubscribe request succeeded.');
        })
        .catch((error) => {
          console.warn(
            'Could not send unsubscribe request to broadcast hub. Using synchronization service...'
          );
          BroadcastingClient.getHubClient()
            .unsubscribeAll({ clientId: Session.broadcastingClientId })
            .then(
              (success) => {
                console.info('Sending unsubscribe request succeeded.');
              },
              (fail) => {
                console.warn(
                  'Error while sending subscription request to Web API. ' + fail
                );
              }
            );
        });
    } else {
      console.log(
        'Broadcasting hub not connected. Using synchronization service...'
      );
      BroadcastingClient.getHubClient()
        .unsubscribeAll({ clientId: Session.broadcastingClientId })
        .then(
          (success) => {
            console.info('Sending unsubscribe request succeeded.');
          },
          (fail) => {
            console.warn(
              'Error while sending subscription request to Web API. ' + fail
            );
          }
        );
    }
  }

  private static registerCallback(
    event: NotificationEvents,
    customerCode: number,
    requestId: string,
    callback: (...msg: any[]) => void
  ) {
    let subscription = BroadcastingClient.subscriptions.find((item) =>
      item.event == event && customerCode == customerCode
        ? customerCode
        : null && requestId == requestId
        ? requestId
        : null
    );
    if (!subscription) {
      subscription = {
        event: event,
        customerCode: customerCode ? customerCode : null,
        requestId: requestId ? requestId : null,
        callbacks: [],
      };
      BroadcastingClient.subscriptions.push(subscription);
    }
    subscription.callbacks.push(callback);
  }

  private static reconnect(): Promise<any> {
    return new Promise((resolve) => {
      if (
        BroadcastingClient.reconnectCounter <=
        environment.broadcastingMaxReconnectCount
      ) {
        ++BroadcastingClient.reconnectCounter;
        let timeout = Math.random() * 10000;
        setTimeout(() => {
          console.info(
            'Reconnecting to broadcasting hub. Take: ' +
              BroadcastingClient.reconnectCounter
          );
          BroadcastingClient.start().then(() => resolve(null));
        }, timeout);
      } else {
        console.error(
          'Could not connect to broadcasting hub. Maximal number of attempts has been reached.'
        );
        let notification: Notification = new Notification(
          'Failure',
          'Could not connect to broadcasting hub. Maximal number of attempts has been reached. Please try sign-in again.',
          NotificationType.Warning
        );
        Session.notifications.add(notification.id, notification);
        resolve(null);
      }
    });
  }

  private static getHubClient(): HubClient {
    if (!BroadcastingClient.hubClient) {
      BroadcastingClient.hubClient = AppInjector.get<HubClient>(HubClient);
    }
    return BroadcastingClient.hubClient;
  }
}

export class BroadcastingSubscription {
  public event: NotificationEvents;
  public customerCode: number;
  public requestId: string;
  public callbacks: Array<any> = [];
}
