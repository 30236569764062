import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, HostListener, SecurityContext } from '@angular/core'
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'manage-agreement',
  templateUrl: './manageAgreement.dialog.html'
})
export class ManageAgreementDialog implements OnInit, OnDestroy {
  //#region -------Properties
  @Input() agreementURl;
  @Input() showHeaderAndText = true;
  _url;

  get urlString() {
    if (this.agreementURl && !this._url)
      this._url = this.sanitizer.bypassSecurityTrustResourceUrl(this.agreementURl);//this.sanitizer.bypassSecurityTrustResourceUrl(this.agreementURl);
    return this._url
  }

  innerHTML;
  
  //#endregion

  //#region -------Constructor
  constructor(public translate: TranslateService, private sanitizer: DomSanitizer) {

  }
  //#endregion

  //#region -------NG Events
  ngOnInit() {
    this.innerHTML = this.sanitizer.sanitize(SecurityContext.URL,`<iframe src="${this.agreementURl}" width="100%" height="700"></iframe>`);
  }

  ngOnDestroy() {
  }
  //#endregion

  //#region -------UI Events
  //#endregion

  //#region -------Private
  //#endregion
}
