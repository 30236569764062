import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  Optional,
  Injectable,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { GSBaseControl } from './gs.base.control';
import { Item } from '../entity/entities';
import { FormNumberProperty } from '../entity/form/formNumber.property';

@Component({
  selector: 'gs-input-number',
  templateUrl: './gs.inputNumber.control.html',
})
@Injectable()
export class GSInputNumberControl
  extends GSBaseControl
  implements OnInit, OnDestroy
{
  @Input() inTable = false;
  @Input() placeholder: string;
  //#region -------Properties
  //#endregion

  //#region -------Constructor
  constructor(
    @Optional() prop: FormNumberProperty,
    @Optional() item: Item,
    private changeDr: ChangeDetectorRef
  ) {
    super(changeDr);
    this.property = prop;
    this.item = item;
  }
  //#endregion

  //#region -------NG Eents
  ngOnInit() {}

  ngOnDestroy() {}
  //#endregion

  //#region -------UI Events
  change(e) {
    if (e.value || e.value == '') {
      if (
        this.property.maxLength != null &&
        e.value.toString().length > this.property.maxLength
      ) {
        e.value = this.value;
        e.originalEvent.srcElement.value = e.value;
      }
      if (this.property.min != null && e.value < this.property.min)
        e.value = this.property.min;
      if (this.property.max != null && e.value > this.property.max)
        e.value = this.property.max;
    }
    let previousValue = this.data[this.name];
    this.value = e.value;
    this.item.reCheckDirty();
    this.onChange.emit({
      propertyName: this.name,
      previousValue: previousValue,
      newValue: this.value,
      event: e.originalEvent,
      item: this.item,
      property: this.property,
    });
    this.property.changed.emit({
      propertyName: this.name,
      previousValue: previousValue,
      newValue: this.value,
      event: e.originalEvent,
      item: this.item,
      property: this.property,
    });
  }

  //#endregion

  //#region -------Private
  //#endregion
}
