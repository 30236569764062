export class Tools {
    static generateGUID(): string {
        var d = new Date().getTime();

        if (window.performance && typeof window.performance.now === "function") {
            d += performance.now(); //use high-precision timer if available
        }
        var guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });

        return guid;
    }
}

export class BrowserStorage {
    public static useFor(target: any) {
        target._webstorage = true;
    }
}

const KEY_PREFIX = "gratsync";
export class WebStorageUtility {
    static generateStorageKey(key: string): string {
        return `${KEY_PREFIX}_${key}`;
    }

    static get(storage: Storage, key: string): any {
        let storageKey = WebStorageUtility.generateStorageKey(key);

        let value = storage.getItem(storageKey);

        return WebStorageUtility.getGettable(value);
    }

    static set(storage: Storage, key: string, value: any): void {
        let storageKey = WebStorageUtility.generateStorageKey(key);

        storage.setItem(storageKey, WebStorageUtility.getSettable(value));
    }

    static remove(storage: Storage, key: string): void {
        let storageKey = WebStorageUtility.generateStorageKey(key);

        storage.removeItem(storageKey);
    }

    private static getSettable(value: any): string {
        return typeof value === "string" ? value : JSON.stringify(value);
    }

    private static getGettable(value: string): any {
        try {
            return JSON.parse(value);
        } catch (e) {
            return value;
        }
    }
}
