<!-- <div #infoTable>
  <table  *ngIf="item"  width="100%">
    <tr *ngFor="let row of item.data; let i = index"  style="height:inherit;margin-bottom:3px">
      <ng-container *ngIf="i==0">
        <td  class="text-xl bold" style="width:80%; padding: 5px;">
          <span><i class="fas fa-user fa-2x navigation-master-color" style="padding-right:8px"></i></span>
          <span style="vertical-align:super">{{selectedItem.data.brkdowncol_name}}</span>
        </td>
        <td class="bold" style="width:20%; padding: 5px; border-left:1px solid darkgrey; text-align: right">
          <span>${{row.Amount}}</span>
        </td>
      </ng-container>

      <ng-container  *ngIf="i>0">
        <td [ngStyle]="{'background-color':i%2===0?'#fff':'#f4f4f4'}"  style="width:80% ; padding: 5px;">
          <span>{{row.Name}}: {{row.Description}}</span>
        </td>
        <td [ngStyle]="{'background-color':i%2===0?'#fff':'#f4f4f4'}" style="width:20%; padding: 5px; border-left:1px solid darkgrey; text-align:right">
          <span>${{row.Amount}}</span>
        </td>
      </ng-container>
    </tr>
  </table>
</div>
<div>
  <span style="float:left">
    <button class="edit" type="button" pButton icon="fa fa-print" (click)="print()" label="Print"></button>
  </span>
</div> -->

<div
  class="row"
  [ngStyle]="{'min-width':(breakDownDetails && !breakDownDetailsTransactions) ?windowSize*0.7+'px':(breakDownDetailsTransactions?windowSize*0.9+'px':'300px')}"
>
  <div
    *ngIf="item"
    class="col-lg-{{!breakDownDetails?12:(!breakDownDetailsTransactions?4:3)}} shift-details-container"
  >
    <ul class="list-unstyled">
      <li>
        <div *ngFor="let detailsItem of item.items.values">
          <div
            class="flex align-items-center px-2 shift-container"
            [class.break-down-selected]="detailsItem.selected"
          >
            <div class="row shift-info">
              <div class="col-lg-8">{{detailsItem.data.Name}}</div>
              <div class="col-lg-4">{{detailsItem.data.Amount}}</div>
            </div>
            <div class="row shift-description">
              <div class="col-lg-12">{{detailsItem.data.Description}}</div>
            </div>
            <ng-container *ngFor="let details of detailsItem.data.Details">
              <div class="row shift-details">
                <div class="col-lg-8">{{details.Name}}</div>
                <div class="col-lg-4">${{details.Amount}}</div>
              </div>
            </ng-container>
            <div
              class="button-container"
              *ngIf="detailsItem.data.ShowBreakdown"
            >
              <button
                pButton
                pRipple
                type="button"
                label="See Breakdown"
                icon="pi pi-arrow-right"
                iconPos="right"
                styleClass="p-button-sm"
                class="p-button-rounded p-button-success button-small"
                (click)="seeBreakdown(detailsItem)"
              ></button>
            </div>
          </div>
        </div>
        <ng-container *ngIf="tipsDetailsInfo">
          <div *ngFor="let tips of tipsDetailsInfo; let i=index">
            <ng-container *ngIf="i==0">
              <div class="flex align-items-center px-2 shift-container">
                {{tips}}
              </div>
            </ng-container>
            <div
              *ngIf="i!=0"
              class="flex align-items-center px-2 shift-container"
            >
              <div class="row shift-info">
                <div class="col-lg-8">{{tips.Name}}</div>
                <div class="col-lg-4">{{tips.Amount}}</div>
              </div>
            </div>
          </div>
        </ng-container>
      </li>
    </ul>
  </div>

  <div
    *ngIf="breakDownDetails"
    class="col-lg-{{!breakDownDetailsTransactions?8:5}}"
  >
    <div *ngIf="breakDownDetails" class="row break-down-details-header">
      <div class="col-lg-4">Check Id</div>
      <div class="col-lg-3" style="padding: 0px">Open/Close</div>
      <div class="col-lg-2" style="padding: 0px">Time</div>
      <div class="col-lg-2">Amount</div>
      <div class="col-lg-1" style="padding: 0px"></div>
    </div>
    <p-scroller
      *ngIf="breakDownDetails"
      [items]="breakDownDetails"
      [itemSize]="50"
    >
      <ng-template pTemplate="item" let-item let-options="options">
        <div
          class="scroll-item"
          [ngClass]="{'odd': options.odd}"
          style="min-height: 50px; max-height: 75px"
        >
          <div
            class="row break-down-details"
            [class.break-down-selected]="item.selected"
          >
            <div class="col-lg-4">{{item.data.CheckId}}</div>
            <div class="col-lg-3" style="padding: 0px">
              {{item.data.OpenTime | timeUS:true}}-{{item.data.CloseTime|
              timeUS:true}}
            </div>
            <div class="col-lg-2" style="padding: 0px">
              {{item.data.Time| timeToString}}
            </div>
            <div class="col-lg-2">${{item.data.Amount}}</div>
            <div class="col-lg-1" style="padding: 0px">
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-arrow-right button-icon-small"
                iconPos="right"
                styleClass="p-button-sm"
                class="p-button-rounded p-button-success button-small"
                (click)="transactionDetails(item)"
              ></button>
            </div>
          </div>
        </div>
      </ng-template>
    </p-scroller>
    <div *ngIf="breakDownDetails" class="row break-down-details-footer">
      <div class="count">Count {{breakDownDetails.length}}</div>
      <div class="total">Total ${{breakDownDetails_sum}}</div>
    </div>
  </div>

  <div *ngIf="breakDownDetailsTransactions" class="col-lg-4">
    <div
      *ngIf="breakDownDetailsTransactions"
      class="row break-down-details-header"
    >
      <div class="col-lg-7">Employee Name</div>
      <div class="col-lg-3">Time</div>
      <div class="col-lg-2">Amount</div>
    </div>
    <p-scroller
      *ngIf="breakDownDetailsTransactions"
      [items]="breakDownDetailsTransactions"
      [itemSize]="50"
    >
      <ng-template pTemplate="item" let-item let-options="options">
        <div
          class="scroll-item"
          [ngClass]="{'odd': options.odd}"
          style="min-height: 35px; max-height: 75px"
        >
          <div class="row break-down-details">
            <div class="col-lg-7">{{item.data.EmployeeName}}</div>
            <div class="col-lg-3" style="padding: 0px">
              {{item.data.Time| timeToString}}
            </div>
            <div class="col-lg-2">${{item.data.Amount}}</div>
          </div>
        </div>
      </ng-template>
    </p-scroller>
  </div>
</div>
