import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { BrowserHelper } from '../utils/utils';
import { Session } from '../entity/entities';

@Pipe({ name: 'dateFormat' })
export class DateFormatPipe implements PipeTransform {
  transform(value, args: string): string {
    if (!value)
      return '';
    if (!value.getTime || (value.getTime && isNaN(value.getTime())))
      return value;
    //switch (args) {
    //  case "datetime":
    //    let datetime = new DatePipe(Session.lang).transform(value, Session.dateTimeFormat);
    //    return BrowserHelper.detectIE() ? datetime.slice(17, 33) : datetime;
    //  case "datetimebatch":
    //    let datetimebatch = new DatePipe(Session.lang).transform(value, Session.dateTimeFormat + ':ss');
    //    return BrowserHelper.detectIE() ? datetimebatch.slice(17, 33) : datetimebatch;
    //  case "time":
    //    return new DatePipe(Session.lang).transform(value, Session.timeFormat);
    //  case "date":
    //  default:
    //    return new DatePipe(Session.lang).transform(value, Session.dateFormat);
    //}
  }
}
