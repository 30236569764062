import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  Optional,
  Injectable,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { GSBaseControl } from './gs.base.control';
import { DatePipe } from '@angular/common';
import { FormDateProperty } from '../entity/form/formDate.property';
import { Item } from '../entity/entities';
import { ItemUtil } from '../utils/utils';

@Component({
  selector: 'gs-calendar',
  templateUrl: './gs.calendar.control.html',
  providers: [DatePipe],
})
@Injectable()
export class GSCalendarControl
  extends GSBaseControl
  implements OnInit, OnDestroy, OnChanges
{
  //#region -------Properties
  private _date;
  private isSet = false;

  get date() {
    if (this.value == '') this.value = null;
    if (!this.isSet) {
      this._date = this.value ? new Date(this.value.length === 10 ? this.datepipe.transform(this.value, 'yyyy-MM-dd').toString() + "T00:00:00" : this.value) : null;
      this.isSet = true;
    } else if (
      this._date != null &&
      this.value != null &&
      this.value != undefined &&
      this.datepipe.transform(this._date, 'yyyy-MM-ddTHH:mm:ss') !=
      this.datepipe.transform(new Date(this.value.length === 10 ? this.datepipe.transform(this.value, 'yyyy-MM-dd').toString() + "T00:00:00" : this.value), 'yyyy-MM-ddTHH:mm:ss')
    ) {
      //else if (this._date != new Date(this.value)) {
      this._date = this.value ? new Date(this.value.length === 10 ? this.datepipe.transform(this.value, 'yyyy-MM-dd').toString() + "T00:00:00" : this.value) : null;
      this.isSet = true;
    }

    if (!this.value) this._date = null;
    return this._date;
  }

  set date(value) {
    if (value == '') value = null;
    this.value = this.datepipe.transform(value, 'yyyy-MM-ddTHH:mm:ss');
    this._date = value;
    this.isSet = false;
  }

  //#endregion

  //#region -------Constructor
  constructor(
    @Inject(DatePipe) private datepipe: DatePipe,
    private changeDr: ChangeDetectorRef
  ) {
    super(changeDr);
  }
  //#endregion

  //#region -------NG Eents
  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item) {
      if (this.item.data[this.name]) this.date = this.item.data[this.name];
    }
  }

  ngOnDestroy() {}
  //#endregion

  //#region -------UI Events
  change(e) {
    if (e == '') e = null;
    let previousValue = this.data[this.name];
    this.value = e;
    this.item.reCheckDirty();
    this.onChange.emit({
      propertyName: this.name,
      newValue: this.value,
      event: e,
      item: this.item,
      property: this.property,
    });
    this.property.changed.emit({
      propertyName: this.name,
      previousValue: previousValue,
      newValue: this.value,
      event: e,
      item: this.item,
      property: this.property,
    });
  }
  //#endregion

  //#region -------Private

  //#endregion
}
