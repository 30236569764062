export interface WizardCustomer {
  Code: number;
  TimeZone: any;
  workflow?: {
    definitions: WorkflowDefinition[];
  };
}

export interface WorkflowDefinition {
  id: number;
  PK_ID: number;
  ClientType: number;
  Description: string;
  Active: boolean;
  Configurations: any;
  Tasks: WorkflowDefinitionTask[];
}

export interface WorkflowDefinitionTask {
  PK_ID: number;
  Active?: boolean;
  Sequence?: number;
  WorkflowTask?: any;
}

export interface WorkflowTaskModel {
  PK_ID: number;
  Name: string;
  Description: string;
  Params: WorkflowTaskParameter[];
}

export interface WorkflowTaskParameter {
  Key;
  Meta;
  SourceList;
  Value;
}

export interface CustomerProperties {
  workflowColumns?: any;
}
