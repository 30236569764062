import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { Injectable } from '@angular/core';
import { Item } from '../entity/entities';

export class ItemUtil {
  public static getPropertyData(item, propertyName) {
    if (item) {
      return propertyName.split('.').reduce(function (p, prop) {
        if (p) {
          if (prop.indexOf('[') > -1 && prop.indexOf(']') > -1) {
            let arrayName = prop.substr(0, prop.indexOf('['));
            let index = prop.substr(
              prop.indexOf('[') + 1,
              prop.length - prop.indexOf(']')
            );
            return p[arrayName][index];
          } else return p[prop];
        }
      }, item.data);
    }
  }

  public static setPropertyData(item, propertyName, value) {
    propertyName.split('.').reduce(function (p, prop) {
      if (!p) return p;
      if (propertyName.endsWith(prop)) p[prop] = value;

      if (prop.indexOf('[') > -1 && prop.indexOf(']') > -1) {
        let arrayName = prop.substr(0, prop.indexOf('['));
        let index = prop.substr(
          prop.indexOf('[') + 1,
          prop.length - prop.indexOf(']')
        );
        return p[arrayName][index];
      } else return p[prop];
    }, item.data);
  }
}

export class BrowserHelper {
  public static detectIE(): number | boolean {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }
}

export class Guid {
  public static newGuid(): Guid {
    return new Guid(
      'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      })
    );
  }
  public static get empty(): string {
    return '00000000-0000-0000-0000-000000000000';
  }
  public get empty(): string {
    return Guid.empty;
  }
  public static isValid(str: string): boolean {
    const validRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return validRegex.test(str);
  }
  private value: string = this.empty;
  constructor(value?: string) {
    if (value) {
      if (Guid.isValid(value)) {
        this.value = value;
      }
    }
  }
  public toString() {
    return this.value;
  }

  public toJSON(): string {
    return this.value;
  }
}

@Injectable()
export class UpdateService {
  unconfirmedUpdate: boolean = false;

  constructor(public updates: SwUpdate) {
    if (updates.isEnabled) {
      //immediate reload when LOGIN page is hit and new version exists
      updates.checkForUpdate();

      //check for new versions every 5min (300000s)
      interval(300000).subscribe(() => {
        updates.checkForUpdate();
        //FOR DEBUG UNCOMMENT
        //.then(() => {
        //  console.log('checking for updates')
        //})
        if (this.unconfirmedUpdate) this.promptUser();
      });
    }
  }

  public checkForUpdates(): void {
    this.updates.available.subscribe((event) => this.promptUser());
  }

  private promptUser(): void {
    this.updates.activateUpdate().then(() => {
      //DONT SHOW POPUP IN CASE NEW VERISON EXITS ON LOGIN PAGE
      if (document.URL.endsWith('login')) document.location.reload();
      else {
        if (
          confirm(
            'New version available! \n\nBy clicking OK new version will be reloaded immediately, \notherwise you will be prompted again in 5 minutes!'
          )
        ) {
          document.location.reload();
        } else this.unconfirmedUpdate = true;
      }
    });
  }
}

export function getPageObject(menuItem, name) {
  const pageButton = menuItem.pages.selected?.buttons.get(name);
  const res = pageButton
    ? pageButton
    : menuItem.pages.selected?.tabs?.values
        .map((x) => x.buttons)
        .filter((x) => x.values.length)
        .reduce((c, p) => {
          return [...c, ...p.values];
        }, [])
        .find((x) => x.code == name);

  if (!res) console.log(`Element with name ${name} not found!`);
  return res;
}

export function getPageObjectFromElement(element, name) {
  const res = element.tabs?.values
    .map((x) => x.buttons)
    .filter((x) => x.values.length)
    .reduce((c, p) => {
      return [...c, ...p.values];
    }, [])
    .find((x) => x.code == name);

  if (!res) console.log(`Element with name ${name} not found!`);
  return res;
}

export function getPageObjectFromPage(page, name) {
  const pageButton = page?.buttons?.get(name);
  const res = pageButton
    ? pageButton
    : page?.tabs?.values
        .map((x) => x.buttons)
        .filter((x) => x.values.length)
        .reduce((c, p) => {
          return [...c, ...p.values];
        }, [])
        .find((x) => x.code == name);
  if (!res) console.log(`Element with name ${name} not found!`);
  return res;
}

export function updateItems(value: any, item: Item, translate) {
  value.forEach((val, i) => {
    let child = item.items.values[i];
    if (!child) {
      child = item.setItem({}, i);
      child.updateData(val);
    } else if (JSON.stringify(child.data) != JSON.stringify(val))
      child.updateData(val);

    child.reCheckDirty();
    Object.getOwnPropertyNames(val)
      .filter((x) => val[x] instanceof Array && val[x].length)
      .forEach((prop) => {
        if (child.items.get(prop))
          updateItems(val[prop], child.items.get(prop), translate);
        else {
          const subChild = child.setItem(val[prop], prop);
          addItems(val[prop], subChild, translate);
        }
      });
    if (child.parameters?.acceptChanges) {
      child.acceptChanges();
    }
  });
  if (!item.items.values.some((i) => i.parameters?.acceptChanges))
    item.reCheckDirty();
  handleItems(item, translate);

  return item.items;
}

export function addItems(value: any, parent: Item, translate) {
  value.forEach((val, i) => {
    const child = parent.setItem(val, i.toString());
    Object.getOwnPropertyNames(val)
      .filter((x) => val[x] instanceof Array && val[x].length)
      .forEach((prop) => {
        if (child.items.get(prop)) updateItems(val[prop], child, translate);
        else {
          const subChild = child.setItem(val[prop], prop);
          addItems(val[prop], subChild, translate);
        }
      });
  });
  parent.reCheckDirty();
  handleItems(parent, translate);
}

export function handleItems(parent: Item, translate) {
  parent?.items?.values
    ?.filter((i) => !i.parameters?.acceptChanges)
    .forEach((child) => {
      child.parameters['added'] =
        child.parameters['invalidMessage'] =
        child.parameters['warningMessage'] =
          undefined;
      if (child?.data.PK_ID < 0)
        child.parameters['added'] = translate.instant('general.itemAdded');
      if (child?.data.Deleted)
        child.parameters['invalidMessage'] = translate.instant(
          'general.itemRemoved'
        );
      if (child?.dirty)
        child.parameters['warningMessage'] =
          translate.instant('general.itemEdited');
    });
}

export function scrollTo(item) {
  setTimeout(() => {
    let el = document.getElementById(item.guid.toString());
    el.scrollIntoView({ behavior: 'smooth' });
  }, 200);
}
