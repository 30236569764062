<div style="min-width: 250px; ">
  <div class="table-header-text" ><label>{{'general.stepHistoryLogComment' |gstranslate}}</label></div>
  <div><textarea pInputTextarea [rows]="8" [cols]="40" autoResize="false" style="border: var(--input-border)" [(ngModel)]="history.comment"></textarea></div>
  <div *ngIf="showApply">
    <button *ngIf="showApply" [margin-right]="8" pButton
            icon="fas fa-save"
            type="button"
            [disabled]="applyDisabled"
            label="{{'general.apply' | gstranslate }}"
            (click)="apply($event)"></button>
  </div>
</div>
