<div class="side-background" [margin]="3" [padding]="5">
  <ng-container *ngIf="wizardItem">
    <ng-container
      *ngIf="customerStore.workflowEntities$ | async as definitions"
    >
      <gs-table
        #wfTable
        change
        [pageSize]="pageSize"
        [totalResultsCnt]="definitions.length"
        [showPaginator]="true"
        [wide]="true"
        [columns]="TableTypes.Workflow_Definitions | tableColumns"
        [items]="
          customerStore.workflowEntities$
            | async
            | storageToItems : 'wfDefinitions' : wizardItem
        "
        (rowAction)="rowAction($event)"
        (rowDataChanged)="change($event)"
        (addRow)="add($event)"
        [showAddRow]="true"
        [canExpand]="true"
        [expandedRowKeys]="expandedRowKeys"
        (pageChange)="pageChange($event)"
        (pageSizeChange)="pageSizeChange($event)"
        (selectItem)="onSelectRow($event)"
      >
        <ng-template pTemplate="gsexpand" let-row let-rowIndex="rowIndex">
          <div
            [margin-top]="20"
            [padding-bottom]="20"
            class="col-lg-12 radio-button-section w-background"
          >
            <span class="content-title-label header-title">
              {{ "workflow.executionConfiguration" | gstranslate }}
            </span>
            <div [padding-top]="10">
              <div class="row">
                <gs-table
                  change
                  [wide]="true"
                  [tableClass]="'wide-table'"
                  [showAddRow]="!row.data.Deleted"
                  [columns]="execColumns"
                  [items]="row.items.get('Configurations')?.items"
                  (rowAction)="configAction($event, row)"
                  (rowDataChanged)="configChange($event, row)"
                  (addRow)="configAdd($event, row)"
                >
                </gs-table>
              </div>
            </div>
          </div>

          <app-workflow-task-list [wfDefinition]="row"></app-workflow-task-list>
        </ng-template>
      </gs-table>
    </ng-container>
  </ng-container>
</div>
<ng-container
  *ngIf="newWfDefinitionItem || selectedWFDefinition as definitionItem"
>
  <gs-dialog
    *ngIf="showAddDefinition"
    [(visible)]="showAddDefinition"
    [header]="addDefinitionTitle"
    modal="true"
    closable="true"
    [item]="definitionItem"
    [editLevel]="0"
    [forceShowApply]="true"
    [enableApply]="false"
    [showCommentOnApply]="false"
    (onApplyItem)="applyDefinition($event)"
    (onClose)="closeDefinition($event)"
    dialogCss="dialog35"
  >
    <ng-template
      pTemplate="gsDialogContent"
      let-dialogItem
      let-rowIndex="rowIndex"
    >
      <ng-container *ngIf="showAddDefinition">
        <app-definition-details
          [item]="definitionItem"
          [properties]="definitionDetailsProperties"
        ></app-definition-details>
        <!-- <gs-form
          change
          [item]="dialogItem"
          [properties]="definitionDetailsProperties"
          (onChange)="wfDefinitionChange($event)"
        >
        </gs-form> -->
      </ng-container>
    </ng-template>
  </gs-dialog>
</ng-container>
<gs-dialog
  *ngIf="showExecuteWorkflow"
  [(visible)]="showExecuteWorkflow"
  [header]="executeWorkflowTitle"
  modal="true"
  closable="true"
  [item]="taskParamItem"
  [editLevel]="0"
  [forceShowApply]="true"
  [enableApply]="taskParamItem.isValid"
  (onApplyItem)="executeWorkflowDefinition($event)"
  [applyLabel]="runWorkflowTitle"
  (onClose)="closeExecuteWorkflowDefinition($event)"
  dialogCss="dialog35"
>
  <ng-template pTemplate="gsDialogContent" let-item let-rowIndex="rowIndex">
    <ng-container>
      <div
        [margin-top]="20"
        [padding-bottom]="20"
        class="col-lg-12 radio-button-section w-background"
      >
        <span class="content-title-label header-title">
          {{ "workflow.taskParameters" | gstranslate }}
        </span>

        <ng-container *ngFor="let task of workflowTaskProperties | keyvalue">
          <div
            [margin-top]="20"
            [padding-bottom]="20"
            class="col-lg-12 radio-button-section w-background"
          >
            <span class="content-title-label">{{ task.key }}</span>
            <ng-container *ngFor="let property of task.value">
              <ng-container
                *ngIf="taskParamItem.items.get(task.key) as taskItem"
              >
                <div
                  change
                  [properties]="task.value"
                  [item]="taskItem"
                  class="gs-form-property col-lg-6"
                >
                  <div
                    id="{{ property.name }}"
                    placeholder
                    reference="{{ property.name }}"
                  ></div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-template>
</gs-dialog>

<ng-container
  *ngIf="showDeleteWFDefinition || selectedWFDefinition as definitionItem"
>
  <gs-dialog
    *ngIf="showDeleteWFDefinition"
    [(visible)]="showDeleteWFDefinition"
    [header]="'Delete Workflow definition'"
    modal="true"
    closable="true"
    [item]="definitionItem"
    [editLevel]="0"
    [forceShowApply]="true"
    [enableApply]="true"
    [showCommentOnApply]="false"
    (onApplyItem)="deleteDefinition($event)"
    (onClose)="closeDeleteDefinition($event)"
    dialogCss="dialog35"
  >
    <ng-template
      pTemplate="gsDialogContent"
      let-dialogItem
      let-rowIndex="rowIndex"
    >
      <ng-container *ngIf="showDeleteWFDefinition">
        <div *ngIf="selectedWFDefinition.data.Active">
          This configuration is still active, are you sure you want to
          deactivate and delete it along with all execution configurations
          configured?
        </div>
        <div *ngIf="selectedWFDefinition.data.Active != true">
          Are you sure you want to delete this configuration along with all
          execution configurations configured?
        </div>
      </ng-container>
    </ng-template>
  </gs-dialog>
</ng-container>

<ng-container
  *ngIf="
    showDeleteWFExecConfig || selectedExecConfiguration?.item as execConfigItem
  "
>
  <gs-dialog
    *ngIf="showDeleteWFExecConfig"
    [(visible)]="showDeleteWFExecConfig"
    [header]="'Delete execution configuration'"
    modal="true"
    closable="true"
    [item]="execConfigItem"
    [editLevel]="0"
    [forceShowApply]="true"
    [enableApply]="true"
    [showCommentOnApply]="false"
    (onApplyItem)="deleteExecConfigration($event)"
    (onClose)="closeDeleteExecConfiguration($event)"
    dialogCss="dialog35"
  >
    <ng-template
      pTemplate="gsDialogContent"
      let-dialogItem
      let-rowIndex="rowIndex"
    >
      <ng-container *ngIf="showDeleteWFExecConfig">
        <div *ngIf="selectedExecConfiguration.item.data.Active">
          This execution configuration is still active, are you sure you want to
          deactivate and delete it?
        </div>
        <div *ngIf="selectedExecConfiguration.item.data.Active != true">
          Are you sure you want to delete this execution configuration?
        </div>
      </ng-container>
    </ng-template>
  </gs-dialog>
</ng-container>
