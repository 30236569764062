import { Pipe, PipeTransform, inject } from '@angular/core';
import { EventCollection, Item, MenuItem, Session } from '../entity/entities';
import { addItems, handleItems, updateItems } from '../utils/utils';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'storageToItems',
})
export class StorageToItemsPipe implements PipeTransform {
  translate = inject(TranslateService);

  transform(
    value: unknown,
    itemName: string,
    parent: Item
  ): EventCollection<Item> {
    if (!(value instanceof Array)) return;

    if (parent.items.get(itemName)) {
      const item = parent.items.get(itemName);
      updateItems(value, item, this.translate);
      //item.reCheckDirty();
      if (!item.items.values.some((i) => i.parameters?.acceptChanges))
        parent.reCheckDirty();
      else
        item.items.values
          .filter((i) => i.parameters?.acceptChanges)
          .forEach((i) => {
            delete i.parameters.acceptChanges;
          });
      handleItems(parent, this.translate);
      setTimeout(() => {
        parent.onRenderFinish$.next();
      }, 500);
      return item.items;
    } else {
      const item = parent.setItem(value, itemName);
      addItems(value, item, this.translate);
      parent.data = { ...parent.data, ...{ [itemName]: value } };
      parent.acceptChanges();
      parent.reCheckDirty();
      handleItems(parent, this.translate);
      setTimeout(() => {
        parent.onRenderFinish$.next();
      }, 500);
      return item.items;
    }
  }
}

@Pipe({
  name: 'toItem',
})
export class ToItemPipe implements PipeTransform {
  transform(value: unknown, clone = true): Item {
    let data;
    if (value && clone) data = JSON.parse(JSON.stringify(value));
    return new Item(null, (clone ? data : value) ?? {});
  }
}
